import React, { useEffect, useRef, useState } from "react";
import useStateWithCallback from "use-state-with-callback";
import { IAGrid } from "../../Components/IInterfaces";
import { CommonUtil } from "../../utils/CommonUtil";
import Flatpickr from "react-flatpickr";
import { useNavigate } from "react-router-dom";
import EquipCellRenderer from "../Organization/EquipCellRenderer";
import EquipAvailTypeRenderer from "../Organization/EquipAvailTypeRenderer";
import { NetworkManager } from "../../Components/NetworkManager";
import SingleSelectComp from "../../Components/SingleSelectComp";
import AGGrid from "../../Components/AGGrid";
import EquipPublicCellRenderer from "../Organization/EquipPublicCellRenderer";
import DetectDevice from "../../utils/DetectDevice";
import MGrid from "../../Components/MGrid";
import "./../DashBoards/searchDashStyles.css";
import { loadMapApi } from "../../utils/GoogleMapsUtils";
import $ from "jquery";
import { Constants } from "../Languages/Constants";
import SearchDBCellRenderer from "./SearchDBCellRenderer";
import OfferCreateRenderer from "../Organization/OfferCreateRenderer";
import DashboardPopupModal from "./DashboardPopupModal";
import BusyLoader from "../../Components/BusyLoader";
import { reAdjust } from "../../utils/ScrollHandler";
import {Events} from "../Languages/Events";

let gMap: any = null;
var markers: any = [];
let marker: any = null;
var markerCluster: any = null;
let renderer: any = null;
var gMap1: any = null;
var gMap2: any = null;

let defLat: number = 43.87066544557103;
let defLng: number = -80.63276494904989;
let fromAddress: string = "";
let fromLatitude: any = "";
let fromLongitude: any = "";

let toAddress: string = "";
let toLatitude: any = "";
let toLongitude: any = "";

var searchType = "Reposition";
let REPOSITION = "Reposition";
let RENTAL = "Rental";
let visitedCount = 0

let tabClicked = false;

let latlngbounds: any = null;
let myLatlng: any = null;
let nPoints: any = [];
let points: any = [];
let IS_MAP_CLICK: boolean = false;
let Is_FILTER: boolean = false;

let repNPoints: any = [];
let nMarkers: any = [];
let repNMarkers: any = [];
let circleMarkers: any = [];
let polylines: any = [];

let dateformat: object = { dateFormat: "m-d-Y", altFormat: "M-d-Y" };
let equiptyes: any = [];
let infowindowEquipment: any = null;
let copySearchData: any = [];
let filterData: any = [];
let currentTileId = "";

var rowExist: boolean = false;
let isSearchButtonClicked = false;

//let isPublicSearch = false;

let bookColumns = [
  {
    field: "BooK",
    headerName: "BooK",
    resizable: true,
    width: 150,
    sortable: true,
    filter: "agTextColumnFilter",
    cellRenderer: SearchDBCellRenderer,
  },
  {
    field: "Offer",
    headerName: "Offer",
    resizable: true,
    width: 150,
    sortable: true,
    filter: "agTextColumnFilter",
    cellRenderer: OfferCreateRenderer,
  },
];

let repColumns = [
  {
    field: "departureAddress",
    headerName: CommonUtil.getMessageText(
      Constants.DEPARTURE_ADDR,
      "Departure Address"
    ),
    tooltipField: "departureAddress",
    resizable: true,
    width: 200,
    sortable: true,
    filter: "agNumberColumnFilter",
  },
  {
    field: "arrivalAddress",
    headerName: CommonUtil.getMessageText(
      Constants.ARRIVAL_ADDRESS,
      "Arrival Address"
    ),
    tooltipField: "arrivalAddress",
    resizable: true,
    width: 200,
    sortable: true,
    filter: "agNumberColumnFilter",
  },
  {
    field: "departureDate",
    headerName: CommonUtil.getMessageText(
      Constants.DEPARTURE_DATE,
      "Departure Date"
    ),
    tooltipField: "departureDate",
    resizable: true,
    width: 200,
    sortable: true,
    filter: "agNumberColumnFilter",
  },
  {
    field: "arrivalDate",
    headerName: CommonUtil.getMessageText(
      Constants.ARRIVAL_DATE_DB,
      "Arrival Date"
    ),
    tooltipField: "arrivalDate",
    resizable: true,
    width: 200,
    sortable: true,
    filter: "agNumberColumnFilter",
  },
  {
    field: "noOfDaysAllowed",
    headerName: CommonUtil.getMessageText(
      Constants.DAYS_ALLOWED_REP,
      "Days Allowed for Reposition"
    ),
    tooltipField: "arrivalDate",
    resizable: true,
    width: 250,
    sortable: true,
    filter: "agNumberColumnFilter",
  },
  {
    field: "totalDistanceKM",
    headerName: CommonUtil.getMessageText(
      Constants.TOTAL_DISTANCE_KM,
      "Total Distance (KM)"
    ),
    resizable: true,
    width: 200,
    sortable: true,
    filter: "agNumberColumnFilter",
  },
  {
    field: "movePrice",
    headerName: CommonUtil.getMessageText(Constants.REVENUE, "Revenue"),
    tooltipField: "movePrice",
    resizable: true,
    width: 150,
    sortable: true,
    filter: "agTextColumnFilter",
  },
  {
    field: "revenuePerHour",
    headerName: CommonUtil.getMessageText(
      Constants.REVENUE_PER_HOUR,
      "Rev Per Hour"
    ),
    resizable: true,
    width: 150,
    sortable: true,
    filter: "agTextColumnFilter",
  },
  {
    field: "bobtailToPuDistanceKM",
    headerName: CommonUtil.getMessageText(
      Constants.BOBTAIL_TO_PU,
      "Bobtail to Pu (KM)"
    ),
    resizable: true,
    width: 200,
    sortable: true,
    filter: "agNumberColumnFilter",
  },
  {
    field: "hookDistanceKM",
    headerName: CommonUtil.getMessageText(Constants.HOOK_KM, "Hook (KM)"),
    resizable: true,
    width: 200,
    sortable: true,
    filter: "agNumberColumnFilter",
  },
  {
    field: "dropToTargetDistanceKM",
    headerName: CommonUtil.getMessageText(
      Constants.DROP_TO_TARGET_KM,
      "Drop To Target (KM)"
    ),
    resizable: true,
    width: 200,
    sortable: true,
    filter: "agNumberColumnFilter",
  },
  {
    field: "totalTravelTime",
    headerName: CommonUtil.getMessageText(
      Constants.EST_DRIVE_HRS,
      "Est. Drive Hours"
    ),
    resizable: true,
    width: 150,
    sortable: true,
    filter: "agNumberColumnFilter",
  },
  {
    field: "equipmentType",
    headerName: CommonUtil.getMessageText(
      Constants.EQUIP_TYPE,
      "Equipment Type"
    ),
    tooltipField: "equipmentType",
    resizable: true,
    width: 200,
    sortable: true,
    filter: "agNumberColumnFilter",
  },
  {
    field: "unitNumber",
    headerName: CommonUtil.getMessageText(
      Constants.EQUIP_NO_NAME,
      "Equipment Number / Name"
    ),
    tooltipField: "unitNumber",
    resizable: true,
    width: 250,
    sortable: true,
    filter: "agTextColumnFilter",
  },
  {
    field: "vin",
    headerName: CommonUtil.getMessageText(Constants.VIN, "VIN"),
    resizable: true,
    width: 150,
    sortable: true,
    filter: "agTextColumnFilter",
  },
  {
    field: "isGeneric",
    headerName: "Generic/Specific",
    tooltipField: "isGeneric",
    resizable: true,
    width: 200,
    sortable: true,
    filter: "agTextColumnFilter",
  },
];

let renterColumns = [
  {
    field: "departureAddress",
    headerName: "Departure Location",
    tooltipField: "departureAddress",
    resizable: true,
    width: 250,
    sortable: true,
    filter: "agNumberColumnFilter",
  },
  {
    field: "arrivalAddress",
    headerName: "Arrival Location",
    tooltipField: "arrivalAddress",
    resizable: true,
    width: 250,
    sortable: true,
    filter: "agNumberColumnFilter",
  },
  {
    field: "dailyRate",
    headerName: "Daily Rate",
    resizable: true,
    width: 150,
    sortable: true,
    filter: "agTextColumnFilter",
  },
  {
    field: "weeklyRate",
    headerName: "Weekly Rate",
    resizable: true,
    width: 150,
    sortable: true,
    filter: "agTextColumnFilter",
  },
  {
    field: "monthlyRate",
    headerName: "Monthly Rate",
    resizable: true,
    width: 150,
    sortable: true,
    filter: "agTextColumnFilter",
  },
  {
    field: "distanceRate",
    headerName: "Distance Rate",
    resizable: true,
    width: 150,
    sortable: true,
    filter: "agTextColumnFilter",
  },
  {
    field: "engineRate",
    headerName: "Engine Rate",
    resizable: true,
    width: 150,
    sortable: true,
    filter: "agTextColumnFilter",
  },
  {
    field: "securityDeposit",
    headerName: "Security Deposit",
    resizable: true,
    width: 200,
    sortable: true,
    filter: "agNumberColumnFilter",
  },
  {
    field: "rating",
    headerName: "Ratings",
    resizable: true,
    width: 150,
    sortable: true,
    filter: "agNumberColumnFilter",
  },
  {
    field: "brand",
    headerName: "Brand",
    resizable: true,
    width: 200,
    sortable: true,
    filter: "agNumberColumnFilter",
  },
  {
    field: "equipmentType",
    headerName: "Equipment Type",
    resizable: true,
    width: 200,
    sortable: true,
    filter: "agNumberColumnFilter",
  },
];

const SearchDashboardCopy = (props: any) => {
  const { isMobile } = DetectDevice();
  const gMapRef = useRef<HTMLDivElement>(null);
  const fromLocationRef = useRef<HTMLInputElement>(null);
  const toLocationRef = useRef<HTMLInputElement>(null);
  const[equipType, setEquipType] = useState(false);
  const[modelYear, setModelYear] = useState(false);
  const[axisCount, setAxisCount] = useState(false);

  const stDateRef = useRef<Flatpickr>(null);
  const endDateRef = useRef<Flatpickr>(null);

  const [switchbtn, setSwitchbtn] = useState(REPOSITION);

  const [switchview, setSwitchview] = useState(false);
  const [maphide, setMaphide] = useState(false);
  const [mapshow, setMapshow] = useState(false);

  const [stDate, setStDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [fromCity, setFromCity] = useState("");
  const [toCity, setToCity] = useState("");

  const [rowinfo, setRowinfo] = useState(null);
  const [rowShow, setRowShow] = useState(false);

  const [allowedDeviation, setAllowedDeviation] = useState("100");
  const [isIntialCheck, setisIntialCheck] = useState(0);
  const [repositionCount, setRepositionCount] = useState(0);
  const [renterCount, setRenterCount] = useState(0);
  const [repositions, setRepositions] = useState<any>([]);
  const [filterRecords, setFilterRecords] = useState<any>([]);

  const [bestMatchCount, setBestMatchCount] = useState(0);
  const [nearMatchCount, setNearMatchCount] = useState(0);
  const [isBestMatchVisible, setBestMatchVisible] = useState(false);
  const gridRef = useRef<IAGrid>(null);
  var newMatchesChecked: boolean = false;

  const [dbSearchType, setDBSearchType] = useState("");

  const [dateOptions, setDateOptions] = useState(dateformat); //CommonUtil.AVAIL_DATE_FORMAT);

  const equipDropDown = useRef<HTMLDivElement>(null);
  const equipModalYear = useRef<HTMLDivElement>(null);
  const equipAxies = useRef<HTMLDivElement>(null);
  const equipLength = useRef<HTMLDivElement>(null);

  const chkSelectAll = useRef<HTMLInputElement>(null);
  const chkDryVan = useRef<HTMLInputElement>(null);
  const chkLog = useRef<HTMLInputElement>(null);
  const chkDropDeck = useRef<HTMLInputElement>(null);
  const chkFlatBed = useRef<HTMLInputElement>(null);
  const chkReeferVan = useRef<HTMLInputElement>(null);
  const chkStorageVan = useRef<HTMLInputElement>(null);
  const chkPlateDryVan = useRef<HTMLInputElement>(null);
  const chkHeatedVan = useRef<HTMLInputElement>(null);
  const chkScrapMetal = useRef<HTMLInputElement>(null);
  const chkRecycling = useRef<HTMLInputElement>(null);
  const chkChip = useRef<HTMLInputElement>(null);
  const chkDump = useRef<HTMLInputElement>(null);
  const chkLowbed = useRef<HTMLInputElement>(null);
  const chkChassis = useRef<HTMLInputElement>(null);
  const chkTank = useRef<HTMLInputElement>(null);
  const chkTankCrude = useRef<HTMLInputElement>(null);
  const chkTankDryBulk = useRef<HTMLInputElement>(null);
  const chkTankPetroleum = useRef<HTMLInputElement>(null);
  const chkTankPropane = useRef<HTMLInputElement>(null);
  const chkTankChemical = useRef<HTMLInputElement>(null);
  const chkOther = useRef<HTMLInputElement>(null);
  const chkSpotter = useRef<HTMLInputElement>(null);

  const chkMySearches = useRef<HTMLInputElement>(null);
  const chkEquipType = useRef<HTMLInputElement>(null);

  const chkBestMatches = useRef<HTMLInputElement>(null);
  const chkNearMatches = useRef<HTMLInputElement>(null);

  const minRef = useRef<HTMLInputElement>(null);
  const maxRef = useRef<HTMLInputElement>(null);

  const minAxies = useRef<HTMLInputElement>(null);
  const maxAxies = useRef<HTMLInputElement>(null);

  const [popupShow, setPopupShow] = useState(false);
  const [markerData, setMarkerData] = useState(null);

  const [totalDriveTime, setTotalDriveTime] = useState(0);
  const [totalMiles, setTotalMiles] = useState(0);

  const [isSearchClicked, setSearchClicked] = useState(false);

  const [tileHeight, setTileHeight] = useState(window.innerHeight - 250);
  const [mapHeight, setMapHeight] = useState(window.innerHeight - 250);
  const [isPublicSearch, setPublicSearch] = useState(false);

  const navigate = useNavigate();

  const [repositionsOld, setReps] = useState([]);

  const rowStyle = { fontSize: "12px" };

  const [searchColumns, setSearchColumns] = useState([
    {
      field: "departureAddress",
      headerName: CommonUtil.getMessageText(
        Constants.DEPARTURE_ADDR,
        "Departure Address"
      ),
      tooltipField: "departureAddress",
      resizable: true,
      width: 200,
      sortable: true,
      filter: "agNumberColumnFilter",
    },
    {
      field: "arrivalAddress",
      headerName: CommonUtil.getMessageText(
        Constants.ARRIVAL_ADDRESS,
        "Arrival Address"
      ),
      tooltipField: "arrivalAddress",
      resizable: true,
      width: 200,
      sortable: true,
      filter: "agNumberColumnFilter",
    },
    {
      field: "departureDate",
      headerName: CommonUtil.getMessageText(
        Constants.DEPARTURE_DATE,
        "Departure Date"
      ),
      tooltipField: "departureDate",
      resizable: true,
      width: 200,
      sortable: true,
      filter: "agNumberColumnFilter",
    },
    {
      field: "arrivalDate",
      headerName: CommonUtil.getMessageText(
        Constants.ARRIVAL_DATE_DB,
        "Arrival Date"
      ),
      tooltipField: "arrivalDate",
      resizable: true,
      width: 200,
      sortable: true,
      filter: "agNumberColumnFilter",
    },
    {
      field: "noOfDaysAllowed",
      headerName: CommonUtil.getMessageText(
        Constants.DAYS_ALLOWED_REP,
        "Days Allowed for Reposition"
      ),
      tooltipField: "arrivalDate",
      resizable: true,
      width: 250,
      sortable: true,
      filter: "agNumberColumnFilter",
    },
    {
      field: "totalDistanceKM",
      headerName: CommonUtil.getMessageText(
        Constants.TOTAL_DISTANCE_KM,
        "Total Distance (KM)"
      ),
      resizable: true,
      width: 200,
      sortable: true,
      filter: "agNumberColumnFilter",
    },
    {
      field: "movePrice",
      headerName: CommonUtil.getMessageText(Constants.REVENUE, "Revenue"),
      tooltipField: "movePrice",
      resizable: true,
      width: 150,
      sortable: true,
      filter: "agTextColumnFilter",
    },
    {
      field: "revenuePerHour",
      headerName: CommonUtil.getMessageText(
        Constants.REVENUE_PER_HOUR,
        "Rev Per Hour"
      ),
      resizable: true,
      width: 150,
      sortable: true,
      filter: "agTextColumnFilter",
    },
    {
      field: "bobtailToPuDistanceKM",
      headerName: CommonUtil.getMessageText(
        Constants.BOBTAIL_TO_PU,
        "Bobtail to Pu (KM)"
      ),
      resizable: true,
      width: 200,
      sortable: true,
      filter: "agNumberColumnFilter",
    },
    {
      field: "hookDistanceKM",
      headerName: CommonUtil.getMessageText(Constants.HOOK_KM, "Hook (KM)"),
      resizable: true,
      width: 200,
      sortable: true,
      filter: "agNumberColumnFilter",
    },
    {
      field: "dropToTargetDistanceKM",
      headerName: CommonUtil.getMessageText(
        Constants.DROP_TO_TARGET_KM,
        "Drop To Target (KM)"
      ),
      resizable: true,
      width: 200,
      sortable: true,
      filter: "agNumberColumnFilter",
    },
    {
      field: "totalTravelTime",
      headerName: CommonUtil.getMessageText(
        Constants.EST_DRIVE_HRS,
        "Est. Drive Hours"
      ),
      resizable: true,
      width: 150,
      sortable: true,
      filter: "agNumberColumnFilter",
    },
    {
      field: "equipmentType",
      headerName: CommonUtil.getMessageText(
        Constants.EQUIP_TYPE,
        "Equipment Type"
      ),
      tooltipField: "equipmentType",
      resizable: true,
      width: 200,
      sortable: true,
      filter: "agNumberColumnFilter",
    },
    {
      field: "unitNumber",
      headerName: CommonUtil.getMessageText(
        Constants.EQUIP_NO_NAME,
        "Equipment Number / Name"
      ),
      tooltipField: "unitNumber",
      resizable: true,
      width: 250,
      sortable: true,
      filter: "agTextColumnFilter",
    },
    {
      field: "vin",
      headerName: CommonUtil.getMessageText(Constants.VIN, "VIN"),
      resizable: true,
      width: 150,
      sortable: true,
      filter: "agTextColumnFilter",
    },
    {
      field: "isGeneric",
      headerName: "Generic/Specific",
      tooltipField: "isGeneric",
      resizable: true,
      width: 200,
      sortable: true,
      filter: "agTextColumnFilter",
    },
  ]);

  const [filterColumns, setFilterColumns] = useState([
    {
      field: "depAddr",
      headerName: CommonUtil.getMessageText(
        Constants.DEPARTURE_ADDR,
        "Departure Address"
      ),
      tooltipField: "departureAddress",
      resizable: true,
      width: 200,
      sortable: true,
      filter: "agNumberColumnFilter",
    },
    {
      field: "arrAddr",
      headerName: CommonUtil.getMessageText(
        Constants.ARRIVAL_ADDRESS,
        "Arrival Address"
      ),
      tooltipField: "arrivalAddress",
      resizable: true,
      width: 200,
      sortable: true,
      filter: "agNumberColumnFilter",
    },
    {
      field: "depDate",
      headerName: CommonUtil.getMessageText(
        Constants.DEPARTURE_DATE,
        "Departure Date"
      ),
      tooltipField: "departureDate",
      resizable: true,
      width: 200,
      sortable: true,
      filter: "agNumberColumnFilter",
    },
    {
      field: "arrDate",
      headerName: CommonUtil.getMessageText(
        Constants.ARRIVAL_DATE_DB,
        "Arrival Date"
      ),
      tooltipField: "arrivalDate",
      resizable: true,
      width: 200,
      sortable: true,
      filter: "agNumberColumnFilter",
    },
    {
      field: "avlCount",
      headerName: "Availability Count",
      resizable: true,
      width: 250,
      sortable: true,
      filter: "agNumberColumnFilter",
    },
    {
      field: "equipType",
      headerName: "Equipment Type",
      resizable: true,
      width: 200,
      sortable: true,
      filter: "agNumberColumnFilter",
    },
  ]);

  const [rentersearchColumns, setRenterSearchColumns] = useState([
    {
      field: "dailyRate",
      headerName: "Daily Rate",
      resizable: true,
      width: 150,
      sortable: true,
      filter: "agTextColumnFilter",
    },
    {
      field: "weeklyRate",
      headerName: "Weekly Rate",
      resizable: true,
      width: 150,
      sortable: true,
      filter: "agTextColumnFilter",
    },
    {
      field: "monthlyRate",
      headerName: "Monthly Rate",
      resizable: true,
      width: 150,
      sortable: true,
      filter: "agTextColumnFilter",
    },
    {
      field: "distanceRate",
      headerName: "Distance Rate",
      resizable: true,
      width: 150,
      sortable: true,
      filter: "agTextColumnFilter",
    },
    {
      field: "engineRate",
      headerName: "Engine Rate",
      resizable: true,
      width: 150,
      sortable: true,
      filter: "agTextColumnFilter",
    },
    {
      field: "departureAddress",
      headerName: "Departure Location",
      tooltipField: "departureAddress",
      resizable: true,
      width: 250,
      sortable: true,
      filter: "agNumberColumnFilter",
    },
    {
      field: "arrivalAddress",
      headerName: "Arrival Location",
      tooltipField: "arrivalAddress",
      resizable: true,
      width: 250,
      sortable: true,
      filter: "agNumberColumnFilter",
    },
    {
      field: "securityDeposit",
      headerName: "Security Deposit",
      resizable: true,
      width: 200,
      sortable: true,
      filter: "agNumberColumnFilter",
    },
    {
      field: "rating",
      headerName: "Ratings",
      resizable: true,
      width: 150,
      sortable: true,
      filter: "agNumberColumnFilter",
    },
    {
      field: "brand",
      headerName: "Brand",
      resizable: true,
      width: 200,
      sortable: true,
      filter: "agNumberColumnFilter",
    },
    {
      field: "equipmentType",
      headerName: "Equipment Type",
      resizable: true,
      width: 200,
      sortable: true,
      filter: "agNumberColumnFilter",
    },
  ]);

  useEffect(() => {
    // let columns = repColumns.concat(bookColumns);
    //console.log(columns);

    $("#divMyAvailabilities").hide();
    if (
      CommonUtil.ORGANIZATION_TYPE == CommonUtil.ORG_TYPE_OWNER ||
      CommonUtil.ORGANIZATION_TYPE == CommonUtil.ORG_TYPE_OWNER_RENTER
    ) {
      $("#divMyAvailabilities").show();
    }

    if (localStorage.getItem("PublicSearch") == "true") {
      setTileHeight(window.innerHeight - 210);
      setMapHeight(window.innerHeight - 160);
      setPublicSearch(true);
    } else {
      setTileHeight(window.innerHeight - 250);
      setMapHeight(window.innerHeight - 190);
      setPublicSearch(false);
    }

    Is_FILTER = false;
    fromLatitude = "";
    fromLongitude = "";
    toLongitude = "";
    toLatitude = "";
    fromAddress = "";
    toAddress = "";
    equiptyes = [
      chkDryVan,
      chkLog,
      chkDropDeck,
      chkFlatBed,
      chkReeferVan,
      chkStorageVan,
      chkPlateDryVan,
      chkHeatedVan,
      chkScrapMetal,
      chkRecycling,
      chkChip,
      chkDump,
      chkLowbed,
      chkChassis,
      chkTank,
      chkTankCrude,
      chkTankDryBulk,
      chkTankPetroleum,
      chkTankPropane,
      chkTankChemical,
      chkOther,
      chkSpotter,
    ];

    searchType = REPOSITION;
    setDBSearchType(searchType);

    document.addEventListener("mousedown", handleClickOutside);

    //setSearchDBType(searchType);
    if (window.google) {
      loadGoogleMap();
    } else {
      const googleMapScript = loadMapApi();
      if (googleMapScript) {
        googleMapScript.addEventListener("load", function () {
          loadGoogleMap();
        });
      }
    }
    function onClickBook() {
      console.log("ajsjdj");
    }
    window.addEventListener("resize", function (e) {
      reAdjust();
    });
    
    document.addEventListener(Events.SWITCH_CUSTOMER_EVENT, onSwitchCustomer);

    return () => {
      document.removeEventListener(Events.SWITCH_CUSTOMER_EVENT, onSwitchCustomer);
      document.removeEventListener("mousedown", handleClickOutside);
      window.removeEventListener("resize", function () {
        reAdjust();
      });
    };
  }, []);
  let updateMap= false;
  useEffect(()=>{
  try{
      if(nMarkers?.length <= 1){ 
        
      if(nMarkers[0] )   {
            gMap.setZoom(5);
            gMap.panTo(nMarkers[0]?.getPosition())
      }
      // }else{
        // gMap.fitBounds(latlngbounds);
      }
  }catch(e){
    console.log(e," here... error");
  }
  },[repositions?.length,IS_MAP_CLICK])

  useEffect(()=>{
    try{
      console.log(isIntialCheck,renterCount,repositionCount);
      
      console.log(`----------------${isIntialCheck}-----------------------`);
      if(isIntialCheck === 2 && !tabClicked){
        if(renterCount === 0 && repositionCount === 0){
         console.log("----------------both are zero-----------------------");
         setisIntialCheck(3)         
        }else if(repositionCount === 0){
          onClickRental();
          setisIntialCheck(4)
        }        
      }
    }catch(e){}
  },[isIntialCheck,renterCount,repositionCount])

  let onSwitchCustomer = (event: any)=>{
    console.log(event,"on switch");
    try{
      tabClicked = false;
      visitedCount=0;
      setisIntialCheck(0);
      setTimeout(()=>{
        getRepositionSummary();
      })    
    }catch(e){}
  }

  const reAdjust = function () {
    let windowWidth: any = $(window).width();
    let windowHeight: any = $(window).height();

    if (localStorage.getItem("PublicSearch") == "true") {
      setTileHeight(window.innerHeight - 210);
      setMapHeight(window.innerHeight - 160);
      // setPublicSearch(true);
    } else {
      setTileHeight(window.innerHeight - 250);
      setMapHeight(window.innerHeight - 190);
      // setPublicSearch(false);
    }

    //console.log(windowWidth,windowHeight);
  };

  let loadGoogleMap = () => {
    if (stDateRef.current) {
      stDateRef.current.flatpickr.setDate(new Date());
      let strDate: string = CommonUtil.getReservationDateString(new Date());
      setStDate(strDate);
    }
    if (endDateRef.current) {
      let currTime: number = new Date().getTime();
      currTime = currTime + 24 * 60 * 60 * 1000;
      endDateRef.current.flatpickr.setDate(new Date(currTime));
      let strDate: string = CommonUtil.getReservationDateString(
        new Date(currTime)
      );
      setEndDate(strDate);
    }

    if (gMapRef.current) {
      gMap = new google.maps.Map(gMapRef.current, {
        zoom: 15,
        center: new google.maps.LatLng(defLat, defLng),
        gestureHandling: "greedy",
        streetViewControl: true,
        zoomControl: true,
        fullscreenControl: true,
        draggableCursor: "pointer",
        fullscreenControlOptions: {
          position: google.maps.ControlPosition.TOP_RIGHT,
        },
        mapTypeControlOptions: {
          position: google.maps.ControlPosition.BOTTOM_RIGHT,
        },
      });

      if (gMap) {
        setTimeout(() => {
          getGooglePlaces();
          getRepositionSummary();
        });
      }
    }
  };
  let getGooglePlaces = () => {
    const fromLocation = document.getElementById(
      "fromLocation"
    ) as HTMLInputElement;
    CommonUtil.getPlaceAddressByMap(fromLocation, (addr: any) => {
      //console.log(result);
      fromAddress = addr["rawAddress"];
      fromLatitude = addr["lat"];
      fromLongitude = addr["lng"];
      setFromCity(addr["city"]);

      $("#fromLocation").val(fromAddress);
    });

    const toLocation = document.getElementById(
      "toLocation"
    ) as HTMLInputElement;
    CommonUtil.getPlaceAddressByMap(toLocation, (addr: any) => {
      //console.log(result);

      toAddress = addr["rawAddress"];
      toLatitude = addr["lat"];
      toLongitude = addr["lng"];
      setToCity(addr["city"]);

      $("#toLocation").val(toAddress);
    });
  };

  let getRepositionSummary = (switchType: string = REPOSITION) => {
    clearClusterMarkers();
    clearCircleMarkers();
    setRepositions([]);
    setFilterRecords([]);

    setBestMatchVisible(false);
    isSearchButtonClicked = false;

    if (chkNearMatches.current) {
      chkNearMatches.current.checked = false;
    }

    let eDate = null;
    let sDate = null;

    if (stDateRef.current) {
      sDate = stDateRef.current.flatpickr.selectedDates[0];
      // date1 = stDate;
    }
    if (endDateRef.current) {
      eDate = endDateRef.current.flatpickr.selectedDates[0];
      //date2 = edDate;
    }

    let reqObj: any = {};
    reqObj.allowedDeviation = allowedDeviation;
    reqObj.dropLocationNearToPickUpLocation = true;
    reqObj.allowedDeviation =
      Number(allowedDeviation) * CommonUtil.MILES_TO_METERS;
    reqObj.arrivalDate = CommonUtil.getReservationDateString(eDate); //endDate;
    reqObj.departureDate = CommonUtil.getReservationDateString(sDate); //stDate;
    reqObj.searchType = switchType;
    var summaryAPI: string = "api/session/search/public/summary/v2";
    if (CommonUtil.PUBLIC_SEARCH_DB) {
    } else {
      summaryAPI = "api/search/private?saasOrgId=" + CommonUtil.getSaasOrgId();
    }
    //+CommonUtil.getSaasOrgId();
    NetworkManager.sendJsonRequest(
      summaryAPI,
      reqObj,
      "",
      "POST",
      true,
      (dataObj:any)=>{
        onGetRepositionSummary(dataObj);
        getDistanceBetweenLocations();
        showSummaryData();
      },
      onLoginError
    );
    
  };
  let onGetRepositionSummary = (dataObj: any) => {
    clearRepositionMarkers();
    clearClusterMarkers();
    clearCircleMarkers();
    clearAllFilters();
    let records: any = [];

    if (dataObj) {
      var restData = CommonUtil.parseData(dataObj);
      if (restData) {
        if (Array.isArray(restData)) {
          records = restData;
          copySearchData = restData;
        } else {
          records.push(restData);
          copySearchData.push(restData);
        }
      }
    }
    let count = 0;
    let bestCount = 0;
    let nearestCount = 0;
    records.forEach((eItem: any, eIdx: number) => {
      if (eItem.withInAllowedDeviation) {
        bestCount =
          bestCount +
          Number(eItem.availableQuantity ? eItem.availableQuantity : 1);
      } else {
        nearestCount =
          nearestCount +
          Number(eItem.availableQuantity ? eItem.availableQuantity : 1);
      }
      count =
        count + Number(eItem.availableQuantity ? eItem.availableQuantity : 1);
      if (eItem.totalDistance) {
        eItem.totalDistanceKM = CommonUtil.convertMetersToKM(
          Number(eItem.totalDistance)
        );
      }
      if (eItem.bobtailToPuDistance) {
        eItem.bobtailToPuDistanceKM = CommonUtil.convertMetersToKM(
          Number(eItem.bobtailToPuDistance)
        );
        eItem.bobtailToPuDistanceMile = CommonUtil.convertMetersToMiles(
          Number(eItem.bobtailToPuDistance)
        );
      }
      if (!fromAddress) {
        eItem.bobtailToPuDistanceKM = 0;
      }
      if (eItem.hookDistance) {
        eItem.hookDistanceKM = CommonUtil.convertMetersToKM(
          Number(eItem.hookDistance)
        );
        eItem.hookDistanceMile = CommonUtil.convertMetersToMiles(
          Number(eItem.hookDistance)
        );
      }
      if (eItem.dropToTargetDistance) {
        eItem.dropToTargetDistanceKM = CommonUtil.convertMetersToKM(
          Number(eItem.dropToTargetDistance)
        );
        eItem.dropToTargetDistanceMile = CommonUtil.convertMetersToMiles(
          Number(eItem.dropToTargetDistance)
        );
      }
      if (!toAddress) {
        eItem.dropToTargetDistanceKM = 0;
        eItem.dropToTargetDistanceMile = 0;
      }
      if (eItem.totalTravelTime) {
        eItem.totalTravelTime = (
          Number(eItem.totalTravelTime) /
          (60 * 60)
        ).toFixed(0);
      }
      if (eItem.movePrice != undefined && eItem.movePrice != null) {
        eItem.movePrice = eItem.currency + " $ " + eItem.movePrice;
      }

      if (eItem.revenuePerHour) {
        eItem.revenuePerHour = eItem.currency + " " + eItem.revenuePerHour;
      }

      let fromLoc = "";
      fromLoc = eItem.departureCity + "," + eItem.departureState;
      eItem.fromLocTip = fromLoc;
      if (fromLoc && fromLoc.length > 20) {
        eItem.fromLoc = fromLoc.substring(0, 20) + " ...";
      } else {
        eItem.fromLoc = fromLoc; //.substring(0,20)+" ...";
      }

      let toLoc = "";
      toLoc = eItem.arrivalCity + "," + eItem.arrivalState;
      eItem.toLocTip = toLoc;
      if (toLoc && toLoc.length > 20) {
        eItem.toLoc = toLoc.substring(0, 20) + " ...";
      } else {
        eItem.toLoc = toLoc;
      }

      eItem.unitNumber = eItem.genericEquipmentName
        ? eItem.genericEquipmentName
        : eItem.unitNumber;
      eItem.isGeneric =
        eItem.isGenericEquipment == "Y" ? "Generic" : "Specific";
    });

    getDistanceBetweenLocations();

    //let count = 0;//records.length;
    for (let j = 0; j < records.length; j++) {
      // count = count+Number(records[j].availableQuantity?records[j].availableQuantity:1);
    }
    setRepositionCount(count);
    setBestMatchCount(bestCount);
    setNearMatchCount(nearestCount);    
    console.log("----from reposition");
    visitedCount++;
    setisIntialCheck(visitedCount);

    latlngbounds = new google.maps.LatLngBounds();
    for (let i = 0; i < records.length; i++) {
      myLatlng = new google.maps.LatLng(
        records[i].departureLatitude,
        records[i].departureLongitude
      );
      latlngbounds.extend(myLatlng);
      // markerCluster.addMarker(marker);
    }
    if (latlngbounds && records.length > 0) {
      if (gMap) {
        console.log("here 1 ",nPoints?.length);   
        // if(nPoints?.length <= 1){                 
        //   setTimeout(()=>{
        //     gMap.setZoom(5);
        //     const myLatlng = new google.maps.LatLng(
        //       records[0].departureLatitude,
        //       records[0].departureLongitude
        //     );
        //     gMap.panTo(myLatlng)
        //   })       
        // }else{
          gMap.fitBounds(latlngbounds);
        // }
      }         
    }
    if (bestCount == 0) {
      if (chkNearMatches.current) {
        chkNearMatches.current.checked = true;
      }
    }
    records = getFilterData();
    showRepositionAvailabilities(records);    
    // copySearchData = JSON.parse(JSON.stringify(records));//[...records];

    gMap.addListener("idle", () => {
      //console.log( google.maps.geometry.spherical.computeDistanceBetween(point1,point2));
      console.log("idle changed");
      console.log(gMap.getZoom());
      if (!IS_MAP_CLICK) {
        // showSelectedTabMarkers();
        let records = getFilterData();
        showRepositionAvailabilities(records);
      }
    });
    gMap.addListener("zoom_changed", () => {
      console.log("zoom changed");
      clearCircleMarkers();
      console.log(gMap.getZoom());
      if (!IS_MAP_CLICK) {
        let records = getFilterData();
        showRepositionAvailabilities(records);
      }
    });
    gMap.addListener("click", () => {
      if (infowindowEquipment) {
        infowindowEquipment.close();
      }
    });

    // if(switchbtn === REPOSITION)
  };

  let getFilterData = () => {
    let items: any = [];
    let isMySearches: boolean = chkMySearches.current?.checked || false;
    //let isBestMatches:boolean = (chkBestMatches.current?.checked) || false;
    let isNearMatches: boolean = chkNearMatches.current?.checked || false;
    let isEquipType: boolean = isEquipmentTypeFilter();
    let selEquipTypes = getSelectedEquipmentTypes();
    if(selEquipTypes.length){
      setEquipType(true);
      //setModelYear(false)
      //setAxisCount(false)
    }

    let isModalYear: boolean = isModalYearFilter();
    if(isModalYear){
      setModelYear(true);
      //setEquipType(false);
      //setAxisCount(false)
    }
  
    let isEquipAxis: boolean = isEquipAxisFilter();
    if(isEquipAxis){
      setAxisCount(true);
      //setModelYear(false);
      //setEquipType(false);
    }
    copySearchData.forEach((item: any, idx: number) => {
      if (item) {
        let latLng: any = {};
        latLng.lat = item.departureLatitude;
        latLng.lng = item.departureLongitude;
        //console.log(gMap1.getBounds().contains(latLng),gMap1.getBounds())

        if (gMap.getBounds().contains(latLng)) {
          // console.log(item);
          if (isEquipType && isModalYear && isEquipAxis) {
            if (item.saasOrgId == CommonUtil.getSelectedOrgId()) {
              let equipItem = isEquipmentAvailable(item, selEquipTypes);
              if (equipItem) {
                let isExist = isEquipmentModalYearAvailable(equipItem);
                if (isExist) {
                  let isAxisExist = isEquipmentAxisAvailable(equipItem);
                  if (isAxisExist) {
                    if (
                      !isNearMatches &&
                      isSearchButtonClicked &&
                      searchType == REPOSITION
                    ) {
                      if (item.withInAllowedDeviation) {
                        items.push(item);
                      }
                    } else {
                      items.push(item);
                    }
                  }
                }
              }
            }
          } else if (
            isMySearches &&
            isEquipType &&
            isModalYear &&
            isEquipAxis
          ) {
            if (item.saasOrgId == CommonUtil.getSelectedOrgId()) {
              let equipItem = isEquipmentAvailable(item, selEquipTypes);
              if (equipItem) {
                let isExist = isEquipmentModalYearAvailable(equipItem);
                if (isExist) {
                  let isAxisExist = isEquipmentAxisAvailable(equipItem);
                  if (isAxisExist) {
                    if (
                      !isNearMatches &&
                      isSearchButtonClicked &&
                      searchType == REPOSITION
                    ) {
                      if (item.withInAllowedDeviation) {
                        items.push(item);
                      }
                    } else {
                      items.push(item);
                    }
                  }
                }
              }
            }
          } else if (isMySearches && isModalYear && isEquipAxis) {
            if (item.saasOrgId == CommonUtil.getSelectedOrgId()) {
              let isExist = isEquipmentModalYearAvailable(item);
              if (isExist) {
                let isAxisExist = isEquipmentAxisAvailable(item);
                if (isAxisExist) {
                  if (
                    !isNearMatches &&
                    isSearchButtonClicked &&
                    searchType == REPOSITION
                  ) {
                    if (item.withInAllowedDeviation) {
                      items.push(item);
                    }
                  } else {
                    items.push(item);
                  }
                }
              }
            }
          } else if (isMySearches && isModalYear && isEquipAxis) {
            if (item.saasOrgId == CommonUtil.getSelectedOrgId()) {
              let isExist = isEquipmentModalYearAvailable(item);
              if (isExist) {
                let isAxisExist = isEquipmentAxisAvailable(item);
                if (isAxisExist) {
                  if (
                    !isNearMatches &&
                    isSearchButtonClicked &&
                    searchType == REPOSITION
                  ) {
                    if (item.withInAllowedDeviation) {
                      items.push(item);
                    }
                  } else {
                    items.push(item);
                  }
                }
              }
            }
          } else if (isMySearches && isEquipType) {
            if (item.saasOrgId == CommonUtil.getSelectedOrgId()) {
              let equipItem = isEquipmentAvailable(item, selEquipTypes);
              if (equipItem) {
                if (
                  !isNearMatches &&
                  isSearchButtonClicked &&
                  searchType == REPOSITION
                ) {
                  if (equipItem.withInAllowedDeviation) {
                    items.push(equipItem);
                  }
                } else {
                  items.push(equipItem);
                }
              }
            }
          } else if (isMySearches && isEquipType) {
            if (item.saasOrgId == CommonUtil.getSelectedOrgId()) {
              let equipItem = isEquipmentAvailable(item, selEquipTypes);
              if (equipItem) {
                if (
                  !isNearMatches &&
                  isSearchButtonClicked &&
                  searchType == REPOSITION
                ) {
                  if (equipItem.withInAllowedDeviation) {
                    items.push(equipItem);
                  }
                } else {
                  items.push(equipItem);
                }
              }
            }
          } else if (isEquipType && isModalYear && isEquipAxis) {
            let equipItem = isEquipmentAvailable(item, selEquipTypes);
            if (equipItem) {
              let isExist = isEquipmentModalYearAvailable(equipItem);
              if (isExist) {
                let isAxisExist = isEquipmentAxisAvailable(equipItem);
                if (isAxisExist) {
                  if (
                    !isNearMatches &&
                    isSearchButtonClicked &&
                    searchType == REPOSITION
                  ) {
                    if (equipItem.withInAllowedDeviation) {
                      items.push(equipItem);
                    }
                  } else {
                    items.push(equipItem);
                  }
                }
              }
            }
          } else if (isEquipType && isModalYear && isEquipAxis) {
            let equipItem = isEquipmentAvailable(item, selEquipTypes);
            if (equipItem) {
              let isExist = isEquipmentModalYearAvailable(equipItem);
              if (isExist) {
                let isAxisExist = isEquipmentAxisAvailable(item);
                if (isAxisExist) {
                  if (
                    !isNearMatches &&
                    isSearchButtonClicked &&
                    searchType == REPOSITION
                  ) {
                    if (equipItem.withInAllowedDeviation) {
                      items.push(equipItem);
                    }
                  } else {
                    items.push(equipItem);
                  }
                }
              }
            }
          } else if (isEquipType && isModalYear) {
            let equipItem = isEquipmentAvailable(item, selEquipTypes);
            if (equipItem) {
              let isExist = isEquipmentModalYearAvailable(equipItem);
              if (isExist) {
                if (
                  !isNearMatches &&
                  isSearchButtonClicked &&
                  searchType == REPOSITION
                ) {
                  if (equipItem.withInAllowedDeviation) {
                    items.push(equipItem);
                  }
                } else {
                  items.push(equipItem);
                }
              }
            }
          } else if (isEquipType && isEquipAxis) {
            let equipItem = isEquipmentAvailable(item, selEquipTypes);
            if (equipItem) {
              let isExist = isEquipmentAxisAvailable(equipItem);
              if (isExist) {
                if (
                  !isNearMatches &&
                  isSearchButtonClicked &&
                  searchType == REPOSITION
                ) {
                  if (equipItem.withInAllowedDeviation) {
                    items.push(equipItem);
                  }
                } else {
                  items.push(equipItem);
                }
              }
            }
          } else if (isModalYear && isEquipAxis) {
            let isExist = isEquipmentModalYearAvailable(item);
            if (isExist) {
              let isAxisExist = isEquipmentAxisAvailable(item);
              if (isAxisExist) {
                if (
                  !isNearMatches &&
                  isSearchButtonClicked &&
                  searchType == REPOSITION
                ) {
                  if (item.withInAllowedDeviation) {
                    items.push(item);
                  }
                } else {
                  items.push(item);
                }
              }
            }
          } else if (isMySearches && isModalYear) {
            if (item.saasOrgId == CommonUtil.getSelectedOrgId()) {
              let isExist = isEquipmentModalYearAvailable(item);
              if (isExist) {
                if (
                  !isNearMatches &&
                  isSearchButtonClicked &&
                  searchType == REPOSITION
                ) {
                  if (item.withInAllowedDeviation) {
                    items.push(item);
                  }
                } else {
                  items.push(item);
                }
              }
            }
          } else if (isMySearches && isEquipAxis) {
            if (item.saasOrgId == CommonUtil.getSelectedOrgId()) {
              let isAxisExist = isEquipmentAxisAvailable(item);
              if (isAxisExist) {
                if (
                  !isNearMatches &&
                  isSearchButtonClicked &&
                  searchType == REPOSITION
                ) {
                  if (item.withInAllowedDeviation) {
                    items.push(item);
                  }
                } else {
                  items.push(item);
                }
              }
            }
          } else if (isMySearches) {
            if (item.saasOrgId == CommonUtil.getSelectedOrgId()) {
              if (
                !isNearMatches &&
                isSearchButtonClicked &&
                searchType == REPOSITION
              ) {
                if (item.withInAllowedDeviation) {
                  items.push(item);
                }
              } else {
                items.push(item);
              }
            }
          } else if (isEquipType) {
            let equipItem = isEquipmentAvailable(item, selEquipTypes);
            if (equipItem) {
              if (
                !isNearMatches &&
                isSearchButtonClicked &&
                searchType == REPOSITION
              ) {
                if (item.withInAllowedDeviation) {
                  items.push(item);
                }
              } else {
                items.push(item);
              }
            }
          } else if (isModalYear) {
            let isExist = isEquipmentModalYearAvailable(item);
            if (isExist) {
              if (
                !isNearMatches &&
                isSearchButtonClicked &&
                searchType == REPOSITION
              ) {
                if (item.withInAllowedDeviation) {
                  items.push(item);
                }
              } else {
                items.push(item);
              }
            }
          } else if (isEquipAxis) {
            let isAxisExist = isEquipmentAxisAvailable(item);
            if (isAxisExist) {
              if (
                !isNearMatches &&
                isSearchButtonClicked &&
                searchType == REPOSITION
              ) {
                if (item.withInAllowedDeviation) {
                  items.push(item);
                }
              } else {
                items.push(item);
              }
            }
          } else if (isNearMatches) {
            items.push(item);
          } else if (searchType == REPOSITION) {
            if (item.withInAllowedDeviation) {
              items.push(item);
            }
          } else {
            items.push(item);
          }
        }
      }
    });
    console.log(items);
    const allItems = items.map((ele:any)=>{
      return {...ele,hookDistanceMile:CommonUtil.convertMetersToMiles(ele.hookDistance),bobtailToPuDistanceMile:CommonUtil.convertMetersToMiles(ele.bobtailToPuDistance)}
    });
    return allItems;
  };

  let isEquipmentAvailable = (item: any, equipTypes: any) => {
    for (let eq: number = 0; eq < equipTypes.length; eq++) {
      if (item.equipmentType.toLowerCase() == equipTypes[eq].toLowerCase()) {
        return item;
      }
    }
    return null;
  };

  let isEquipmentModalYearAvailable = (item: any) => {
    if (
      Number(item.year) >= Number(minRef.current?.value) &&
      Number(item.year) <= Number(maxRef.current?.value)
    ) {
      return true;
    } else {
      return false;
    }
  };

  let isEquipmentAxisAvailable = (item: any) => {
    if (
      Number(item.numberOfAxles) >= Number(minAxies.current?.value) &&
      Number(item.numberOfAxles) <= Number(maxAxies.current?.value)
    ) {
      return true;
    } else {
      return false;
    }
  };

  let isEquipmentTypeFilter = () => {
    for (let eq = 0; eq < equiptyes.length; eq++) {
      if (equiptyes[eq].current && equiptyes[eq].current.checked) {
        return true;
      }
    }
    return false;
  };
  let getSelectedEquipmentTypes = () => {
    let items: any = [];
    equiptyes.forEach((cItem: any, cIdx: number) => {
      if (cItem.current && cItem.current.checked) {
        items.push(cItem.current.value);
      }
    });

    return items;
  };

  let isModalYearFilter = () => {
    if (
      (minRef.current && minRef.current.value) ||
      (maxRef.current && maxRef.current.value)
    ) {
      return true;
    } else {
      return false;
    }
  };

  let isEquipAxisFilter = () => {
    if (
      (minAxies.current && minAxies.current.value) ||
      (maxAxies.current && maxAxies.current.value)
    ) {
      return true;
    } else {
      return false;
    }
  };

  // isEquipAxisFilter

  let showRepositionAvailabilities = (records: any) => {
    IS_MAP_CLICK = false
    setRepositions(records);

    setReps(records);
    latlngbounds = new google.maps.LatLngBounds();
    points = [];
    let filterRec: any = [];
    for (let i = 0; i < records.length; i++) {
      myLatlng = new google.maps.LatLng(
        records[i].departureLatitude,
        records[i].departureLongitude
      );
      records[i].consolidate = false;
      records[i].lat = records[i].departureLatitude;
      records[i].lng = records[i].departureLongitude;
      records[i].availCount = records[i].availableQuantity
        ? records[i].availableQuantity
        : 1;
      points.push(records[i]);
      latlngbounds.extend(myLatlng);

      let filterObj: any = {};
      let depAddr: string =
        records[i].departureCity + "," + records[i].departureState;
      let arrAddr: string =
        records[i].arrivalCity + "," + records[i].arrivalState;
      let depDate: string = records[i].departureDate; //+","+records[i].arrivalState;
      let arrDate: string = records[i].arrivalDate;
      let equipType: string = records[i].equipmentType;
      let avlCount: string = records[i].availableQuantity
        ? records[i].availableQuantity
        : 1;

      filterObj.depAddr = depAddr;
      filterObj.arrAddr = arrAddr;
      filterObj.depDate = depDate;
      filterObj.arrDate = arrDate;
      filterObj.equipType = equipType;
      if (filterRec.length == 0) {
        filterObj.avlCount = avlCount;
        filterRec.push(filterObj);
      } else {
        let flag: boolean = false;
        let idx: number = 0;
        filterRec.forEach((fItem: any, fIdx: number) => {
          if (
            fItem.depAddr == depAddr &&
            fItem.arrAddr == arrAddr &&
            fItem.depDate == depDate &&
            fItem.arrDate == arrDate &&
            fItem.equipType == equipType
          ) {
            flag = true;
            idx = fIdx;
            avlCount = avlCount + fItem.avlCount;
          }
        });
        if (flag) {
          filterRec[idx].avlCount = avlCount;
        } else {
          filterObj.avlCount = avlCount;
          filterRec.push(filterObj);
        }
        //filterObj.avlCount = avlCount;
      }

      // markerCluster.addMarker(marker);
    }
    setFilterRecords(filterRec);
    if (latlngbounds && records.length > 0) {
      if (gMap) {
        //gMap.fitBounds(latlngbounds);
      }
    }
    showSelectedTabMarkers();
  };
  let showSummaryData = () => {
    let eDate = null;
    let sDate = null;

    if (stDateRef.current) {
      sDate = stDateRef.current.flatpickr.selectedDates[0];
      // date1 = stDate;
    }
    if (endDateRef.current) {
      eDate = endDateRef.current.flatpickr.selectedDates[0];
      //date2 = edDate;
    }

    let reqObj: any = {};
    reqObj.allowedDeviation = allowedDeviation;
    reqObj.dropLocationNearToPickUpLocation = true;
    // reqObj.allowedDeviation = (Number(allowedDeviation) * CommonUtil.MILES_TO_METERS);
    reqObj.arrivalDate = CommonUtil.getReservationDateString(eDate); //endDate;
    reqObj.departureDate = CommonUtil.getReservationDateString(sDate); //

    reqObj.allowedDeviation = allowedDeviation;
    reqObj.dropLocationNearToPickUpLocation = true;
    reqObj.searchType = RENTAL;
    //var summaryAPI:string = "api/session/search/public/summary/v2";
    var summaryAPI: string = "api/session/search/public/summary/v2";
    if (CommonUtil.PUBLIC_SEARCH_DB) {
    } else {
      summaryAPI = "api/search/private?saasOrgId=" + CommonUtil.getSaasOrgId();
    } ///v2";//+CommonUtil.getSaasOrgId();
    NetworkManager.sendJsonRequest(
      summaryAPI,
      reqObj,
      "",
      "POST",
      true,
      (dataObj: any) => {
        let records: any = [];
        if (dataObj) {
          var restData = CommonUtil.parseData(dataObj);
          if (restData) {
            if (Array.isArray(restData)) {
              records = restData;
            } else {
              records.push(restData);
            }
          }
        }
        //let count = records.length;
        let count1 = 0; //records.length;
        for (let k = 0; k < records.length; k++) {
          // console.log(records[k].availableQuantity);
          count1 =
            count1 +
            Number(
              records[k].availableQuantity ? records[k].availableQuantity : 1
            );
        }
        setRenterCount(count1);
        console.log("----from rental");   
        visitedCount++;     
        setisIntialCheck(visitedCount);
      },
      onLoginError
    );
  };

  let getRentalSummary = () => {
    isSearchButtonClicked = false;
    let eDate = null;
    let sDate = null;

    if (stDateRef.current) {
      sDate = stDateRef.current.flatpickr.selectedDates[0];
      // date1 = stDate;
    }
    if (endDateRef.current) {
      eDate = endDateRef.current.flatpickr.selectedDates[0];
      //date2 = edDate;
    }

    let reqObj: any = {};
    reqObj.allowedDeviation = allowedDeviation;
    reqObj.dropLocationNearToPickUpLocation = true;
    // reqObj.allowedDeviation = (Number(allowedDeviation) * CommonUtil.MILES_TO_METERS);
    reqObj.arrivalDate = CommonUtil.getReservationDateString(eDate); //endDate;
    reqObj.departureDate = CommonUtil.getReservationDateString(sDate); //

    // let reqObj:any = {};
    reqObj.allowedDeviation = allowedDeviation;
    reqObj.dropLocationNearToPickUpLocation = true;
    reqObj.searchType = RENTAL;
    setBestMatchVisible(false);
    //var summaryAPI:string = "api/session/search/public/summary/v2";///v2";//+CommonUtil.getSaasOrgId();
    var summaryAPI: string = "api/session/search/public/summary/v2";
    if (CommonUtil.PUBLIC_SEARCH_DB) {
    } else {
      summaryAPI = "api/search/private?saasOrgId=" + CommonUtil.getSaasOrgId();
    }
    NetworkManager.sendJsonRequest(
      summaryAPI,
      reqObj,
      "",
      "POST",
      true,
      onGetRentalSummary,
      onLoginError
    );
  };

  let onGetRentalSummary = (dataObj: any) => {
    let records: any = [];
    if (dataObj) {
      var restData = CommonUtil.parseData(dataObj);
      if (restData) {
        if (Array.isArray(restData)) {
          records = restData;
          copySearchData = restData;
        } else {
          records.push(restData);
          copySearchData.push(restData);
        }
      }
    }
    //let count = records.length;
    let count1 = 0; //records.length;
    for (let k = 0; k < records.length; k++) {
      // console.log(records[k].availableQuantity);
      count1 =
        count1 +
        Number(records[k].availableQuantity ? records[k].availableQuantity : 1);
    }
    records.forEach((eItem: any, eIdx: number) => {
      if (eItem.dailyRate) {
        eItem.dailyRate = eItem.currency + " " + eItem.dailyRate;
      }
      if (eItem.weeklyRate) {
        eItem.weeklyRate = eItem.currency + " " + eItem.weeklyRate;
      }
      if (eItem.monthlyRate) {
        eItem.monthlyRate = eItem.currency + " " + eItem.monthlyRate;
      }
      if (eItem.distanceRate) {
        eItem.distanceRate = eItem.currency + " " + eItem.distanceRate;
      }
      if (eItem.engineRate) {
        eItem.engineRate = eItem.currency + " " + eItem.engineRate;
      }
      let fromLoc = "";
      fromLoc = eItem.departureCity + "," + eItem.departureState;
      eItem.fromLocTip = fromLoc;
      if (fromLoc && fromLoc.length > 20) {
        eItem.fromLoc = fromLoc.substring(0, 20) + " ...";
      } else {
        eItem.fromLoc = fromLoc; //.substring(0,20)+" ...";
      }

      let toLoc = "";
      toLoc = eItem.arrivalCity + "," + eItem.arrivalState;
      eItem.toLocTip = toLoc;
      if (toLoc && toLoc.length > 20) {
        eItem.toLoc = toLoc.substring(0, 20) + " ...";
      } else {
        eItem.toLoc = toLoc;
      }
    });
    setRepositions([]);
    setFilterRecords([]);
    clearAllFilters();
    setRenterCount(count1);
    clearRepositionMarkers();
    clearClusterMarkers();
    clearCircleMarkers();
    clearAllFilters();

    points = [];
    latlngbounds = new google.maps.LatLngBounds();
    for (let i = 0; i < records.length; i++) {
      myLatlng = new google.maps.LatLng(
        records[i].departureLatitude,
        records[i].departureLongitude
      );
      latlngbounds.extend(myLatlng);
      // markerCluster.addMarker(marker);
    }
    if (latlngbounds && records.length > 0) {
      if (gMap) {
        console.log("here 2 ",nPoints?.length);
        // if(nPoints?.length <=  1){         
        //   setTimeout(()=>{
        //     gMap.setZoom(5);
        //     const myLatlng = new google.maps.LatLng(
        //       records[0].departureLatitude,
        //       records[0].departureLongitude
        //     );
        //     gMap.panTo(myLatlng)
        //   })          
        // }else{
          gMap.fitBounds(latlngbounds);
        // }
      }
    }

    showRepositionAvailabilities(records);
  };
  let getCirclePath = (circle: any, numPts: any, lngs: any) => {
    //var numPts = 2;
    //latlngs
    var path: any = [];
    for (var i = 0; i < numPts; i++) {
      let latlng: any = google.maps.geometry.spherical.computeOffset(
        circle.getCenter(),
        circle.getRadius(),
        (i * 360) / numPts
      );
      path.push({
        lat: latlng.lat(),
        lng: latlng.lng(),
        qty: lngs[i].availCount,
        availabilityId: lngs[i].availabilityId,
        latlngs: [lngs[i]],
      });
    }
    return path;
  };

  let clearCircleMarkers = () => {
    for (let x1 = 0; x1 < circleMarkers.length; x1++) {
      if (circleMarkers[x1]) {
        circleMarkers[x1].setMap(null);
      }
    }
    circleMarkers = [];
  };

  let clearPolyLines = () => {
    for (let x1 = 0; x1 < polylines.length; x1++) {
      if (polylines[x1]) {
        polylines[x1].setMap(null);
      }
    }
    polylines = [];
  };

  let clearClusterMarkers = () => {
    for (let x1 = 0; x1 < nMarkers.length; x1++) {
      if (nMarkers[x1]) {
        nMarkers[x1].setMap(null);
      }
    }
    nMarkers = [];
  };

  let clearRepositionMarkers = () => {
    for (let x1 = 0; x1 < markers.length; x1++) {
      if (markers[x1]) {
        markers[x1].setMap(null);
      }
    }
    markers = [];
  };

  let showSelectedTabMarkers = () => {
    //clearCircleMarkers();
    clearPolyLines();
    let gIcon: any = null;
    let zoom = gMap.getZoom();
    let zooms = CommonUtil.MAP_ZOOMS;
    //console.log(zoom);
    // console.log(google.maps.geometry.spherical.computeDistanceBetween(point1,point2),zoom);
    let copyPoints = JSON.parse(JSON.stringify(points)); //points.concat();
    let copyPoints1 = JSON.parse(JSON.stringify(points)); //points.concat();
    nPoints = [];
    let dist = 0;
    let latLngPoints: any = [];
    for (let k = 0; k < copyPoints.length; k++) {
      let availCount = 0;
      let isCluster: boolean = false;
      latLngPoints = [];
      if (copyPoints[k] && !copyPoints[k].consolidate) {
        availCount = copyPoints[k].availCount;
        let X1 = new google.maps.LatLng(copyPoints[k].lat, copyPoints[k].lng);
        latLngPoints.push(copyPoints[k]);
        for (let l = 0; l < copyPoints1.length; l++) {
          if (
            !copyPoints1[l].consolidate &&
            copyPoints[k].availabilityId != copyPoints1[l].availabilityId
          ) {
            let X2 = new google.maps.LatLng(
              copyPoints1[l].lat,
              copyPoints1[l].lng
            );
            dist = google.maps.geometry.spherical.computeDistanceBetween(
              X1,
              X2
            );

            // if((zoom<3 && dist<distMeters4) || (zoom<4 && dist<distMeters) || (zoom<2 && dist<distMeters1) || (zoom<1 && dist<distMeters2)){
            for (let y1 = 0; y1 < zooms.length; y1++) {
              let y1Item: any = zooms[y1];
              if (y1Item && y1Item.maxZoom && y1Item.maxDist) {
                if (zoom >= y1Item.minZoom && zoom <= y1Item.maxZoom) {
                  if (dist >= y1Item.minDist && dist <= y1Item.maxDist) {
                    availCount = availCount + copyPoints1[l].availCount;
                    latLngPoints.push(copyPoints1[l]);
                    isCluster = true;
                    copyPoints1[l].consolidate = true;
                    copyPoints[l].consolidate = true;
                    break;
                  }
                }
              }
            }

            // }
          }
        }
        let newPoint: any = {};
        newPoint.latLng = X1;
        newPoint.lat = X1.lat();
        newPoint.lng = X1.lng();
        newPoint.arrivalLat = copyPoints1[k].arrivalLatitude
          ? copyPoints1[k].arrivalLatitude
          : "";
        newPoint.arrivalLng = copyPoints1[k].arrivalLongitude
          ? copyPoints1[k].arrivalLongitude
          : "";
        newPoint.availCount = availCount ? availCount : 1;
        newPoint.address = copyPoints1[k].departureAddress;
        newPoint.arrivaladdress = copyPoints1[k].arrivalAddress
          ? copyPoints1[k].arrivalAddress
          : "";
        newPoint.availabilityId = copyPoints1[k].availabilityId;
        newPoint.isCluster = isCluster;
        newPoint.latlngs = latLngPoints;
        copyPoints[k].consolidate = true;
        copyPoints1[k].consolidate = true;
        nPoints.push(newPoint);
      }
    }

    console.log(nPoints);
    for (let x1 = 0; x1 < nMarkers.length; x1++) {
      nMarkers[x1].setMap(null);
    }
    nMarkers = [];

    nPoints.forEach((nPoint: any, idx: number) => {
      if (nPoint.isCluster) {
        gIcon = CommonUtil.showClusterAvailableCount(nPoint.availCount); //nPoint.availabilityId);
      } else {
        gIcon = CommonUtil.showAvailableCount(nPoint.availCount); //nPoint.availabilityId);
      }

      let tip = "Departure: " + nPoint.address.toString() + "\n";
      tip = tip + "Arrival: " + nPoint.arrivaladdress.toString();

      let marker = new google.maps.Marker({
        position: nPoint.latLng,
        map: gMap,
        icon: gIcon,
      });
      nMarkers.push(marker);

      google.maps.event.addListener(marker, "mouseover", function () {
        // console.log(nPoint.isCluster);
        if (!nPoint.isCluster) {
          try{
           if(google.maps.geometry.spherical.computeDistanceBetween(nPoint,gMap.getCenter()) > 5)
            gMap.setCenter(nPoint);     
          }catch(e){
            console.log(e);
          }     
          setTimeout(()=>{
            displayInfoWindow(nPoint, marker);
          },500);          
        } else {
          tip = "Please zoom in to see the details for individual availability";
          marker.setTitle(tip);
        }
      });

      if (marker) {
        var markerListerner = marker.addListener("click", () => {
          console.log(nPoint);

          IS_MAP_CLICK = false;
          let latlngbounds1: any = null;
          let clusterPoints: any = [];
          if (nPoint && nPoint.isCluster) {
            let latlngs = nPoint.latlngs;
            let copyLatLng = nPoint.latlngs;
            let clutserLatLng: any = null;
            let cMmarker: any = null;
            if (latlngs && latlngs.length > 0) {
              latlngbounds1 = new google.maps.LatLngBounds();
              latlngs.forEach((nItem: any, nNum: number) => {
                if (nPoint.isCluster) {
                  gIcon = CommonUtil.showClusterAvailableCount(
                    nItem.availCount
                  );
                } else {
                  gIcon = CommonUtil.showAvailableCount(nItem.availCount);
                }

                let lat = nItem.departureLatitude;
                let lng = nItem.departureLongitude;
                let tempLatLng = new google.maps.LatLng(lat, lng);

                clutserLatLng = new google.maps.LatLng(lat, lng);
                cMmarker = new google.maps.Marker({
                  position: clutserLatLng,
                  map: gMap,
                  icon: gIcon,
                  title: nItem.departureAddress.toString(),
                });
                nMarkers.push(cMmarker);
                latlngbounds1.extend(clutserLatLng);
              });

              if (latlngbounds1) {
                if (gMap) {
                  //IS_MAP_CLICK = false;
                  marker.setMap(null);
                  // latlngbounds1.extend(new google.maps.LatLng(37.3387849157642, -121.88));
                  gMap.fitBounds(latlngbounds1);
                  IS_MAP_CLICK = false;
                  console.log(gMap.getZoom());
                  if (gMap.getZoom() == CommonUtil.MAP_MAX_ZOOM) {
                    //latlngbounds1.extend(new google.maps.LatLng(37.3387849157642, -121.8852525));
                    gMap.fitBounds(latlngbounds1);
                    IS_MAP_CLICK = false;
                    // circleMarkers = [];
                    var circle = new google.maps.Circle({
                      map: gMap,
                      strokeWeight: 0.1,
                      fillOpacity: 0,
                      radius: 3, // 10 miles in metres
                    });
                    if (markerListerner) {
                      markerListerner.remove();
                    }
                    if (cMmarker) {
                      circle.bindTo("center", cMmarker, "position");
                      circleMarkers.push(circle);
                      let circlePath = getCirclePath(
                        circle,
                        latlngs.length,
                        latlngs
                      );
                      console.log(circlePath);

                      if (circlePath && circlePath.length > 0) {
                        let latlngbounds1 = new google.maps.LatLngBounds();
                        circlePath.forEach((cPath: any, ijx: number) => {
                          gIcon = CommonUtil.showAvailableCount(cPath.qty);
                          let latLng = new google.maps.LatLng(
                            cPath.lat,
                            cPath.lng
                          );
                          // let tip = "Departure: "+nItem.address.toString()+"\n";
                          // tip = tip+"Arrival: "+nItem.arrivaladdress.toString();
                          latlngbounds1.extend(latLng);
                          let zmarker = new google.maps.Marker({
                            position: latLng,
                            map: gMap,
                            icon: gIcon,
                          });
                          circleMarkers.push(zmarker);
                          google.maps.event.addListener(
                            marker,
                            "mouseover",
                            function () {
                              // console.log(nPoint.isCluster);
                              displayInfoWindow(cPath, marker);
                            }
                          );
                          zmarker.addListener("click", () => {
                            console.log(cPath);
                            setPopupShow(true);
                            IS_MAP_CLICK = true;
                            clearPolyLines();
                            let dLat = 0;
                            let dLng = 0;
                            if (cPath && cPath.latlngs && cPath.latlngs[0]) {
                              dLat = cPath.latlngs[0].arrivalLatitude;
                              dLng = cPath.latlngs[0].arrivalLongitude;
                              cPath.arrivalLat = dLat;
                              cPath.arrivalLng = dLng;
                            }
                            const lineSymbol = {
                              path: "M 0,-1 0,1",
                              strokeOpacity: 1,
                              scale: 4,
                            };
                            /*const line = new google.maps.Polyline({
                                                            path: [
                                                                { lat: cPath.lat, lng: cPath.lng },
                                                                { lat: cPath.arrivalLat, lng: cPath.arrivalLng },
                                                            ],
                                                            strokeOpacity: 0,
                                                            icons: [{icon: lineSymbol, offset: "0", repeat: "20px"}],
                                                            map: gMap,
                                                        });
                                                        polylines.push(line);*/
                            //displayInfoWindow(cPath,zmarker);
                            showMarkerPopup(cPath);
                            // setMarkerData(cPath);

                            let dLatLng: any = new google.maps.LatLng(
                              cPath.lat,
                              cPath.lng
                            );
                            latlngbounds1.extend(dLatLng);
                            let aLatLng = new google.maps.LatLng(
                              cPath.arrivalLat,
                              cPath.arrivalLng
                            );
                            latlngbounds1.extend(aLatLng);
                            if (latlngbounds1) {
                              if (gMap) {
                                IS_MAP_CLICK = true;
                                // let dist = google.maps.geometry.spherical.computeDistanceBetween(dLatLng, aLatLng);
                                // console.log(dist);
                                //if(dist>10000){
                                // gMap.fitBounds(latlngbounds1);
                                setTimeout(() => {
                                  IS_MAP_CLICK = false;
                                }, 6000);
                              }
                            }

                            // showClusterMarkerInfo(cPath,zmarker);

                            /*const lineSymbol = {path: "M 0,-1 0,1", strokeOpacity: 1, scale: 4,};
                                                        const line = new google.maps.Polyline({
                                                            path: [
                                                                { lat: cPath.lat, lng: cPath.lng },
                                                                { lat: dLat, lng: dLng },
                                                            ],
                                                            strokeOpacity: 0,
                                                            icons: [{icon: lineSymbol, offset: "0", repeat: "20px"}],
                                                            map: gMap,
                                                        });
                                                        displayInfoWindow(cPath,zmarker);*/
                            //clearCircleMarkers();
                          });
                        });
                      }
                    }
                  }
                }
              }
            }
          } else {
            // circleMarkers = [];
            console.log(nPoint);

            //showClusterMarkerInfo(nPoint,marker);
            // setMarkerData(nPoint);
            showMarkerPopup(nPoint);
            setPopupShow(true);
          }
        });
      }
    });
  };

  let displayInfoWindow = (markerData: any, marker: any) => {
    let gItem: any = null;
    if (markerData.latlngs && markerData.latlngs.length > 0) {
      gItem = markerData.latlngs[0];
    }
    if (gItem) {
      let newContentString =
        '<div id="infoWindow" class="col-sm-12 col-md-12 col-lg-12 col-xl-12 sec-card mb-3" style="overflow: hidden">';
      newContentString = newContentString + '<div class="A-SEC p-2">';
      newContentString = newContentString + '<div class="row">';
      newContentString =
        newContentString +
        '<div class="d-flex justify-content-between align-items-center mb-2">';

      newContentString = newContentString + '<div class="grid-name">';
      newContentString =
        newContentString + "<h5>" + gItem.equipmentType + "</h5>";
      newContentString =
        newContentString +
        '<span class="axel text-uppercase">' +
        (gItem.numberOfAxles ? gItem.numberOfAxles + " AXLES" : "") +
        " | " +
        (gItem.lengthOfTrailer ? gItem.lengthOfTrailer : "") +
        " | " +
        (gItem.year ? gItem.year : "") +
        "</span>";
      newContentString = newContentString + "</div>";
      if (searchType == REPOSITION) {
        newContentString =
          newContentString +
          '<div class="A-SEC-ONE p-1 text-center offset-md-1">';
        newContentString = newContentString + '<div class="price">';
        newContentString =
          newContentString +
          '<h6 style="fontSize:10px">' +
          gItem.movePrice +
          "</h6>";
        newContentString =
          newContentString + "<span>" + gItem.loadOut + "</span>";
        newContentString = newContentString + "</div>";
        newContentString = newContentString + "</div>";
      } else {
      }
      newContentString =
        newContentString +
        '<div class="col-1 fs-12 text-center"><span class="fa fa-star"></span>'; //+(gItem.equipmentRating?+'<span class="fa fa-star"></span>' :'<span class="fa fa-star"></span>');
      newContentString =
        newContentString +
        '<span class="fs-10 d-block">' +
        ((gItem.equipmentRating ? gItem.equipmentRating : "0") +
          "/" +
          (gItem.ratingCount ? gItem.ratingCount : "0")) +
        "</span></div>";
      newContentString = newContentString + "</div>";

      newContentString =
        newContentString +
        '<div class="d-flex align-items-center justify-content-between mb-2">';
      newContentString = newContentString + '<div class="d-flex">';
      newContentString =
        newContentString +
        '<img src="../../images/truck.svg" class="m-r-4 truck truck" />';
      newContentString =
        newContentString + '<div class="text-fade fs-12 d-block">';
      newContentString =
        newContentString +
        '<div class="grid-name mb-2"><h5>' +
        CommonUtil.getDBDate(gItem.departureDate) +
        '</h5><span class="axel">' +
        gItem.departureCity +
        "," +
        gItem.departureState +
        '</span></div><div class="grid-name"><h5>' +
        CommonUtil.getDBDate(gItem.arrivalDate) +
        '</h5><span class="axel">' +
        gItem.arrivalCity +
        "," +
        gItem.arrivalState +
        "</span></div>";
      newContentString = newContentString + "</div>";
      newContentString = newContentString + "</div>";
      // if (isSearchClicked) {
      if (true) {
        newContentString = newContentString + "<div>";
        newContentString = newContentString + '<div class="d-grid gap-2">';
        newContentString =
          newContentString +
          '<button id="btnBookNow" type="button" class="btn btn-outline-success btn-sm fs-10 position-relative"> Book now';
        newContentString =
          newContentString +
          '<span class="position-absolute top-0 fs-8 start-100 translate-middle badge rounded-pill bg-danger">' +
          (gItem.availableQuantity ? gItem.availableQuantity : "") +
          "</span>";
        newContentString = newContentString + "</button>";
        if (gItem.allowOffer == "Y") {
          newContentString =
            newContentString +
            '<button id="btnCreateOffer" type="button"  class="btn btn-outline-secondary btn-sm fs-10">CREATE OFFER</button>';
        }
        newContentString = newContentString + "</div>";
        newContentString = newContentString + "</div>";
      } else {
        newContentString = newContentString + "<div>";
        newContentString = newContentString + '<div class="d-grid gap-2">';
        newContentString =
          newContentString +
          '<button style="opacity: 0.6" disabled="true" type="button" class="btn btn-outline-success btn-sm fs-10 position-relative"> Book now';
        newContentString =
          newContentString +
          '<span class="position-absolute top-0 fs-8 start-100 translate-middle badge rounded-pill bg-danger">' +
          (gItem.availableQuantity ? gItem.availableQuantity : "") +
          "</span>";
        newContentString = newContentString + "</button>";
        if (gItem.allowOffer == "Y") {
          newContentString =
            newContentString +
            '<button type="button"  style="opacity: 0.6" disabled="true" class="btn btn-outline-secondary btn-sm fs-10">CREATE OFFER</button>';
        }
        newContentString = newContentString + "</div>";
        newContentString = newContentString + "</div>";
      }
      newContentString = newContentString + "</div>";

      newContentString = newContentString + '<div class="price-table">';
      if (searchType == RENTAL) {
        newContentString = newContentString + '<table class="table mb-0">';
        newContentString = newContentString + "<tr>";

        newContentString = newContentString + "<th>";
        newContentString = newContentString + '<div class="table-main-text">';
        newContentString =
          newContentString +
          '<div class="d-block">' +
          gItem.dailyRate +
          '<span class="table-text-fade"></span></div>';
        newContentString =
          newContentString +
          '<div class="d-block fs-10 text-fade fw-400">Daily Rate</div>';
        newContentString = newContentString + "</div>";
        newContentString = newContentString + "</th>";

        newContentString = newContentString + "<th>";
        newContentString = newContentString + '<div class="table-main-text">';
        newContentString =
          newContentString +
          '<div class="d-block">' +
          gItem.weeklyRate +
          '<span class="table-text-fade"></span></div>';
        newContentString =
          newContentString +
          '<div class="d-block fs-10 text-fade fw-400">Weekly Rate</div>';
        newContentString = newContentString + "</div>";
        newContentString = newContentString + "</th>";

        newContentString = newContentString + "<th>";
        newContentString = newContentString + '<div class="table-main-text">';
        newContentString =
          newContentString +
          '<div class="d-block">' +
          gItem.monthlyRate +
          '<span class="table-text-fade"></span></div>';
        newContentString =
          newContentString +
          '<div class="d-block fs-10 text-fade fw-400">Monthly Rate</div>';
        newContentString = newContentString + "</div>";
        newContentString = newContentString + "</th>";
        newContentString = newContentString + "</tr>";

        newContentString = newContentString + "<tr>";
        newContentString = newContentString + "<th>";
        newContentString = newContentString + '<div class="table-main-text">';
        newContentString =
          newContentString +
          '<div class="d-block">' +
          gItem.distanceRate +
          '<span class="table-text-fade"></span></div>';
        newContentString =
          newContentString +
          '<div class="d-block fs-10 text-fade fw-400">Distance Rate</div>';
        newContentString = newContentString + "</div>";
        newContentString = newContentString + "</th>";

        newContentString = newContentString + "<th>";
        newContentString = newContentString + '<div class="table-main-text">';
        newContentString =
          newContentString +
          '<div class="d-block">' +
          gItem.engineRate +
          '<span class="table-text-fade"></span></div>';
        newContentString =
          newContentString +
          '<div class="d-block fs-10 text-fade fw-400"> Engine Rate</div>';
        newContentString = newContentString + "</div>";
        newContentString = newContentString + "</th>";

        newContentString = newContentString + "</tr>";
        newContentString = newContentString + "</table>";
      } else {
        newContentString = newContentString + '<table class="table mb-0">';
        newContentString = newContentString + "<tr>";

        newContentString = newContentString + "<th>";
        newContentString = newContentString + '<div class="table-main-text">';
        newContentString =
          newContentString +
          '<div class="d-block">' +
          (gItem.noOfDaysAllowed ? gItem.noOfDaysAllowed : "") +
          '<span class="table-text-fade"></span></div>';
        newContentString =
          newContentString +
          '<div class="d-block fs-10 text-fade fw-400">Allowed Days</div>';
        newContentString = newContentString + "</div>";
        newContentString = newContentString + "</th>";

        newContentString = newContentString + "<th>";
        newContentString = newContentString + '<div class="table-main-text">';
        newContentString =
          newContentString +
          '<div class="d-block">' +
          (gItem.bobtailToPuDistanceKM ? gItem.bobtailToPuDistanceKM : "") +
          '<span class="table-text-fade"> (km)</span></div>';
        newContentString =
          newContentString +
          '<div class="d-block fs-10 text-fade fw-400">Bobtail to Pickup</div>';
        newContentString = newContentString + "</div>";
        newContentString = newContentString + "</th>";

        newContentString = newContentString + "<th>";
        newContentString = newContentString + '<div class="table-main-text">';
        newContentString =
          newContentString +
          '<div class="d-block">' +
          (gItem.hookDistanceKM ? gItem.hookDistanceKM : "") +
          '<span class="table-text-fade"> (km)</span></div>';
        newContentString =
          newContentString +
          '<div class="d-block fs-10 text-fade fw-400">Hook Distance</div>';
        newContentString = newContentString + "</div>";
        newContentString = newContentString + "</th>";

        newContentString = newContentString + "</tr>";

        newContentString = newContentString + "<tr>";

        newContentString = newContentString + "<th>";
        newContentString = newContentString + '<div class="table-main-text">';
        newContentString =
          newContentString +
          '<div class="d-block">' +
          (gItem.revenuePerHour ? gItem.revenuePerHour : "") +
          '<span class="table-text-fade"></span></div>';
        newContentString =
          newContentString +
          '<div class="d-block fs-10 text-fade fw-400">Revenue Per Hour</div>';
        newContentString = newContentString + "</div>";
        newContentString = newContentString + "</th>";

        newContentString = newContentString + "<th>";
        newContentString = newContentString + '<div class="table-main-text">';
        newContentString =
          newContentString +
          '<div class="d-block">' +
          gItem.dropToTargetDistanceKM +
          '<span class="table-text-fade"> (km)</span></div>';
        newContentString =
          newContentString +
          '<div class="d-block fs-10 text-fade fw-400"> Drop to Target</div>';
        newContentString = newContentString + "</div>";
        newContentString = newContentString + "</th>";

        newContentString = newContentString + "<th>";
        newContentString = newContentString + '<div class="table-main-text">';
        newContentString =
          newContentString +
          '<div class="d-block">' +
          (gItem.totalTravelTime ? gItem.totalTravelTime : "") +
          '<span class="table-text-fade"> hrs</span></div>';
        newContentString =
          newContentString +
          '<div class="d-block fs-10 text-fade fw-400">Estimated drive</div>';
        newContentString = newContentString + "</div>";
        newContentString = newContentString + "</th>";

        newContentString = newContentString + "</tr>";
        newContentString = newContentString + "</table>";
      }
      newContentString = newContentString + "</div>";

      newContentString = newContentString + "</div>";
      newContentString = newContentString + "</div>";
      newContentString = newContentString + "</div>";

      if (infowindowEquipment) {
        infowindowEquipment.close();
      }
      infowindowEquipment = new google.maps.InfoWindow({
        minWidth: 320,
        maxWidth: 320,
        disableAutoPan: true,
      });
      infowindowEquipment.setContent(newContentString);
      infowindowEquipment.open(gMap, marker, false);
      infowindowEquipment.setPosition(marker.getPosition());

      setTimeout(() => {
        $("#btnBookNow").on("click", () => {
          console.log(gItem);
          onClickBookItem(gItem);
        });
        $("#btnCreateOffer").on("click", () => {
          console.log(gItem);
          onClickShowAvailability(gItem);
        });
      }, 2000);
    }
  };

  let showMarkerPopup = (item: any) => {
    setMarkerData(item);
  };

  let handleClickOutside = (event: any) => {
    if (
      equipDropDown.current &&
      !equipDropDown.current.contains(event.target)
    ) {
      $("#equipDropDown").removeClass("show");
    }
    if (
      equipModalYear.current &&
      !equipModalYear.current.contains(event.target)
    ) {
      $("#equipModalYear").removeClass("show");
    }
    if (equipAxies.current && !equipAxies.current.contains(event.target)) {
      $("#equipAxies").removeClass("show");
    }
    //console.log(infowindowEquipment.contains(event.target));
    let obj = document.getElementById("infoWindow");
    if (infowindowEquipment && obj) {
      if (obj && !obj.contains(event.target)) {
        infowindowEquipment.close();
      }
    }
    /* if (equipLength.current && !equipLength.current.contains(event.target)) {
            $("#equipLength").removeClass("show");
        }*/
  };

  let onClickTileView = () => {
    $("#tileView").show();
    $("#gridView").hide();
    $("#filterView").hide();
  };

  let onClickGridView = () => {
    $("#tileView").hide();
    $("#filterView").hide();
    $("#gridView").show();
  };

  let onClickFilterView = () => {
    $("#tileView").hide();
    $("#filterView").show();
    $("#gridView").hide();
  };

  let onClickNearestMatches = () => {
    console.log(chkBestMatches.current?.checked);
    console.log(copySearchData);
    let records = getFilterData();
    showRepositionAvailabilities(records);
  };
  let onClickBestMaches = () => {
    console.log(chkBestMatches.current?.checked);
    console.log(copySearchData);
    let records = getFilterData();
    showRepositionAvailabilities(records);
  };
  let onClickMySearches = () => {
    console.log(chkMySearches.current?.checked);
    console.log(copySearchData);
    let records = getFilterData();
    showRepositionAvailabilities(records);
    /*let searchData = [...copySearchData];
        let results = [];
        if(chkMySearches.current?.checked){
             results = searchData.filter((data:any)=>{
                if(data.saasOrgId == CommonUtil.getSelectedOrgId()){
                    return true;
                }
                return false;
            });
        }else{
            results = searchData;
        }
        filterData = results;
        console.log(copySearchData,results);
        showRepositionAvailabilities(results);*/
  };

  let onClickEquipType = () => {
    console.log(chkEquipType.current?.checked);
    setTimeout(() => {
      if (chkEquipType.current?.checked) {
        $("#equipDropDown").addClass("show");
      } else {
        $("#equipDropDown").addClass("show");
      }
    });
  };

  let onClickModalYear = () => {
    $("#equipModalYear").addClass("show");
  };

  let onClickEquipAxes = () => {
    $("#equipAxies").addClass("show");
  };

  let onChangeEquipType = (chk: any) => {
    let flag: boolean = false;
    if (chk && chk.current) {
      flag = false;
      if (chk.current.checked) {
        flag = true;
      }
    }
    equiptyes.forEach((cItem: any, cIdx: number) => {
      if (cItem.current) {
        cItem.current.checked = flag;
      }
    });
  };
  let onChangeEquipTypeDryVan = (chkDry: any) => {
    if (chkDry && chkDry.current) {
      if (chkSelectAll && chkSelectAll.current) {
        chkSelectAll.current.checked = false;
      }
    }
  };

  let onClickApplyEquipTypeClear = () => {
    setEquipType(false);
    equiptyes.forEach((cItem: any, cIdx: number) => {
      if (cItem.current) {
        cItem.current.checked = false;
      }
    });
    if (chkSelectAll && chkSelectAll.current) {
      chkSelectAll.current.checked = false;
    }

    let records = getFilterData();
    showRepositionAvailabilities(records);
    closeEquipTypeFilter();
  };

  let onClickApplyEquipType = () => {
    let records = getFilterData();
    showRepositionAvailabilities(records);
    closeEquipTypeFilter();
  };

  let closeEquipTypeFilter = () => {
    $("#equipDropDown").removeClass("show");
  };

  let onClickApplyModal = () => {
    let records = getFilterData();
    showRepositionAvailabilities(records);
    $("#equipModalYear").removeClass("show");
  };

  let onClickClearModal = () => {
    if (minRef.current) {
      minRef.current.value = "";
    }
    if (maxRef.current) {
      maxRef.current.value = "";
    }
    let records = getFilterData();
    showRepositionAvailabilities(records);
    setModelYear(false)
    $("#equipModalYear").removeClass("show");
  };

  let onClickApplyAxies = () => {
    let records = getFilterData();
    showRepositionAvailabilities(records);
    $("#equipAxies").removeClass("show");
  };

  let onClickClearAxies = () => {
    if (minAxies.current) {
      minAxies.current.value = "";
    }
    if (maxAxies.current) {
      maxAxies.current.value = "";
    }
    let records = getFilterData();
    showRepositionAvailabilities(records);
    setAxisCount(false)
    $("#equipAxies").removeClass("show");
  };

  let onChangeStDate = (dateValue: any) => {
    let strDate: string = CommonUtil.getDate(dateValue);
    setStDate(strDate);

    let currTime: number = new Date(strDate).getTime();
    currTime = currTime + 24 * 60 * 60 * 1000;
    //endDateRef.current.flatpickr.setDate(new Date(currTime));
    //let strDate:string = CommonUtil.getReservationDateString(new Date(currTime));
    if (endDateRef.current) {
      endDateRef.current.flatpickr.set("minDate", new Date(dateValue));
      endDateRef.current.flatpickr.setDate(new Date(currTime));
    }
    onChangeDates();
  };
  let onChangeEdDate = (dataVal: any) => {
    let strDate: string = CommonUtil.getDate(dataVal);
    setEndDate(strDate);
    if (endDateRef.current) {
      endDateRef.current.flatpickr.setDate(new Date(dataVal));
    }
    onChangeDates();
  };
  let onChangeDates = () => {
    if (stDateRef.current && endDateRef.current) {
      var startDate = stDateRef.current.flatpickr.selectedDates[0];
      var endDate = endDateRef.current.flatpickr.selectedDates[0];

      endDateRef.current.flatpickr.set("minDate", new Date(startDate));
      stDateRef.current.flatpickr.set("maxDate", new Date(endDate));
    }
  };

  let onOpenStDate = () => {
    /*if(stDateRef.current){
            stDateRef.current.flatpickr.set("minDate",new Date());
            if(endDateRef.current){
                let endDate = endDateRef.current.flatpickr.selectedDates[0];
                if(endDate){
                    stDateRef.current.flatpickr.set("maxDate",new Date(endDate));
                }else{
                    stDateRef.current.flatpickr.set("minDate",new Date());
                }

            }else{

            }
        }*/
  };

  let onOpenEDDate = () => {
    /*if(endDateRef.current){
           if(stDateRef.current){
                var startDate = stDateRef.current.flatpickr.selectedDates[0];
                if(startDate){
                    endDateRef.current.flatpickr.set("minDate",new Date(startDate));
                }else{
                    endDateRef.current.flatpickr.set("minDate",new Date());
                }

            }else{
                endDateRef.current.flatpickr.set("minDate",new Date());
            }

        }*/
  };

  let onClickPrevFromDate = () => {
    if (stDateRef.current && stDateRef.current.flatpickr) {
      let stDate = stDateRef.current.flatpickr.selectedDates[0];
      console.log(stDate);
      let currTime: number = new Date(stDate).getTime();
      currTime = currTime - 24 * 60 * 60 * 1000;

      stDateRef.current.flatpickr.setDate(new Date(currTime));
    }
  };

  let onClickNextToDate = () => {
    if (endDateRef.current && endDateRef.current.flatpickr) {
      let edDate = endDateRef.current.flatpickr.selectedDates[0];
      console.log(edDate);
      let currTime: number = new Date(edDate).getTime();
      currTime = currTime + 24 * 60 * 60 * 1000;

      endDateRef.current.flatpickr.setDate(new Date(currTime));
    }
  };

  let onClickFromToDate = () => {
    if (endDateRef.current && endDateRef.current.flatpickr) {
      let edDate = endDateRef.current.flatpickr.selectedDates[0];
      console.log(edDate);
      let currTime: number = new Date(edDate).getTime();
      currTime = currTime - 24 * 60 * 60 * 1000;

      endDateRef.current.flatpickr.setDate(new Date(currTime));

      if (stDateRef.current) {
        let stDate = stDateRef.current.flatpickr.selectedDates[0];
        let stTime: number = new Date(stDate).getTime();
        if (currTime <= stTime) {
          stDateRef.current.flatpickr.setDate(new Date(currTime));
        }
      }
    }
  };

  let onClickNextFromDate = () => {
    if (stDateRef.current && stDateRef.current.flatpickr) {
      let stDate = stDateRef.current.flatpickr.selectedDates[0];
      console.log(stDate);

      let currTime: number = new Date(stDate).getTime();
      currTime = currTime + 24 * 60 * 60 * 1000;
      //endDateRef.current.flatpickr.setDate(new Date(currTime));
      //let strDate:string = CommonUtil.getReservationDateString(new Date(currTime));
      if (endDateRef.current) {
        // endDateRef.current.flatpickr.set("minDate",new Date(currTime));
        // endDateRef.current.flatpickr.setDate(new Date(currTime));
      }
      if (stDateRef.current) {
        // stDateRef.current.flatpickr.set("minDate",new Date(currTime));
        stDateRef.current.flatpickr.setDate(new Date(currTime));
        // currTime = currTime+(24*60*60*1000);
        //stDateRef.current.flatpickr.set("maxDate",new Date(currTime));
      }
      if (endDateRef.current) {
        let edDate = endDateRef.current.flatpickr.selectedDates[0];
        //console.log(stDate);

        let edTime: number = new Date(edDate).getTime();
        if (currTime >= edTime) {
          endDateRef.current.flatpickr.setDate(new Date(currTime));
        }
      }
      //onChangeDates();
    }
  };

  let onClickSearch = () => {
    //Is_FILTER = false;
    if (chkNearMatches.current) {
      chkNearMatches.current.checked = false;
    }
    visitedCount=0;
    tabClicked = false;
    removeTileSelection();
    setSearchClicked(true);
    setRepositions([]);
    setFilterRecords([]);
    clearRepositionMarkers();
    clearClusterMarkers();
    if (searchType == REPOSITION) {
      setBestMatchVisible(true);
      isSearchButtonClicked = true;
      let columns = repColumns.concat(bookColumns);
      setSearchColumns(columns);
      onClickSearchReposition();
    } else {
      let columns = renterColumns.concat(bookColumns);
      setRenterSearchColumns(columns);
      onClickSearchRental();
    }
  };
  let onClickSearchReposition = () => {
    getDistanceBetweenLocations();
    console.log(IS_MAP_CLICK);
    setRepositions([]);
    setFilterRecords([]);
    clearRepositionMarkers();

    let eDate = null;
    let sDate = null;
    let toLoc = "";
    let fromLoc = "";

    if (stDateRef.current) {
      sDate = stDateRef.current.flatpickr.selectedDates[0];
      // date1 = stDate;
    }
    if (endDateRef.current) {
      eDate = endDateRef.current.flatpickr.selectedDates[0];
      //date2 = edDate;
    }
    if (fromLocationRef.current && fromLocationRef.current?.value) {
      fromLoc = fromLocationRef.current?.value;
    }

    if (toLocationRef.current && toLocationRef.current?.value) {
      toLoc = toLocationRef.current?.value;
    }
    if (fromLoc) {
    } else {
      fromAddress = "";
      fromLatitude = "";
      fromLongitude = "";
    }
    if (toLoc) {
    } else {
      toAddress = "";
      toLatitude = "";
      toLongitude = "";
    }
    if ((fromAddress || toAddress) && sDate && eDate) {
      let reqObj: any = {};

      reqObj.allowedDeviation =
        Number(allowedDeviation) * CommonUtil.MILES_TO_METERS;
      reqObj.arrivalDate = CommonUtil.getReservationDateString(eDate); //endDate;
      reqObj.departureDate = CommonUtil.getReservationDateString(sDate); //stDate;
      reqObj.dropLocationNearToPickUpLocation = true;
      reqObj.searchType = searchType;

      reqObj.fromAddress = fromAddress;
      reqObj.fromLatitude = fromLatitude;
      reqObj.fromLongitude = fromLongitude;

      reqObj.toAddress = toAddress;
      reqObj.toLatitude = toLatitude;
      reqObj.toLongitude = toLongitude;

      console.log(reqObj);

      setStDate(CommonUtil.getReservationDateString(sDate));
      setEndDate(CommonUtil.getReservationDateString(eDate));

      // localStorage
      //var listAPI =  "api/session/search/public/";
      var listAPI: string = "";
      if (CommonUtil.PUBLIC_SEARCH_DB) {
        listAPI = "api/session/search/public/"; //+CommonUtil.getSaasOrgId();
      } else {
        listAPI = "api/search/private?saasOrgId=" + CommonUtil.getSaasOrgId();
      }

      NetworkManager.sendJsonRequest(
        listAPI,
        reqObj,
        "",
        "POST",
        true,
        (dataObj: any) => {
          setSearchClicked(true);
          onGetRepositionSummary(dataObj);
        },
        onLoginError
      );
    }else{
      getRepositionSummary(REPOSITION);
    }
  };

  let onClickSearchRental = () => {
    let sDate = null;
    let eDate = null;

    if (stDateRef.current) {
      sDate = stDateRef.current.flatpickr.selectedDates[0];
      // date1 = stDate;
    }
    if (endDateRef.current) {
      eDate = endDateRef.current.flatpickr.selectedDates[0];
      //date2 = edDate;
    }

    if (fromAddress && fromLongitude && fromLongitude) {
      let reqObj: any = {};
      reqObj.arrivalDate = eDate
        ? CommonUtil.getReservationDateString(eDate)
        : "";
      reqObj.departureDate = sDate
        ? CommonUtil.getReservationDateString(sDate)
        : "";
      reqObj.dropLocationNearToPickUpLocation = true;
      reqObj.searchType = dbSearchType;
      reqObj.fromAddress = fromAddress;
      reqObj.fromLatitude = fromLongitude;
      reqObj.fromLongitude = fromLongitude;

      console.log(reqObj);

      //let listAPI =  "api/session/search/public/";

      // setStRentalDate(sDate?CommonUtil.getReservationDateString(sDate):"");
      //setEndRentalDate(eDate?CommonUtil.getReservationDateString(eDate):"");

      var listAPI: string = "";
      if (CommonUtil.PUBLIC_SEARCH_DB) {
        listAPI = "api/session/search/public/"; //+CommonUtil.getSaasOrgId();
      } else {
        listAPI = "api/search/private?saasOrgId=" + CommonUtil.getSaasOrgId();
      }

      NetworkManager.sendJsonRequest(
        listAPI,
        reqObj,
        "",
        "POST",
        true,
        onGetRentalSummary,
        onLoginError
      );
    }else{
      getRentalSummary();
    }
  };
  let onGetSearchRecords = (dataObj: any) => {
    let equips = [];
    if (dataObj) {
      var restData = CommonUtil.parseData(dataObj);
      if (restData) {
        if (Array.isArray(restData)) {
          equips = restData;
        } else {
          equips.push(restData);
        }

        //setRepositionCount(count);
        let count = 0;
        equips.forEach((eItem: any, eIdx: number) => {
          count =
            count +
            Number(eItem.availableQuantity ? eItem.availableQuantity : 1);
          if (eItem.totalDistance) {
            eItem.totalDistanceKM = CommonUtil.convertMetersToKM(
              Number(eItem.totalDistance)
            );
          }
          if (eItem.bobtailToPuDistance) {
            eItem.bobtailToPuDistanceKM = CommonUtil.convertMetersToKM(
              Number(eItem.bobtailToPuDistance)
            );
            eItem.bobtailToPuDistanceMile = CommonUtil.convertMetersToMiles(
              Number(eItem.bobtailToPuDistance)
            );
          }
          if (!fromAddress) {
            eItem.bobtailToPuDistanceKM = 0;
          }
          if (eItem.hookDistance) {
            eItem.hookDistanceKM = CommonUtil.convertMetersToKM(
              Number(eItem.hookDistance)
            );
            console.log(eItem.hookDistance,CommonUtil.convertMetersToMiles(
              Number(eItem.hookDistance)
            ));
            
            eItem.hookDistanceMile = CommonUtil.convertMetersToMiles(
              Number(eItem.hookDistance)
            );
          }
          if (eItem.dropToTargetDistance) {
            eItem.dropToTargetDistanceKM = CommonUtil.convertMetersToKM(
              Number(eItem.dropToTargetDistance)
            );
            eItem.dropToTargetDistanceMile = CommonUtil.convertMetersToMiles(
              Number(eItem.dropToTargetDistance)
            );
          }
          if (!toAddress) {
            eItem.dropToTargetDistanceKM = 0;
            eItem.dropToTargetDistanceMile = 0;
          }
          if (eItem.totalTravelTime) {
            eItem.totalTravelTime = (
              Number(eItem.totalTravelTime) /
              (60 * 60)
            ).toFixed(0);
          }
          if (eItem.movePrice != undefined && eItem.movePrice != null) {
            eItem.movePrice = eItem.currency + " " + eItem.movePrice;
          }

          if (eItem.revenuePerHour) {
            eItem.revenuePerHour = eItem.currency + " " + eItem.revenuePerHour;
          }

          eItem.unitNumber = eItem.genericEquipmentName
            ? eItem.genericEquipmentName
            : eItem.unitNumber;
          eItem.isGeneric =
            eItem.isGenericEquipment == "Y" ? "Generic" : "Specific";
        });
        console.log(equips);
      }
    }
  };

  let getDistanceBetweenLocations = () => {
    const fromLatLng = { lat: fromLongitude, lng: fromLongitude };
    const toLatLng = { lat: toLatitude, lng: toLongitude };
    if (fromAddress || toAddress) {
      CommonUtil.getDistanceBetweenPoints(
        fromAddress,
        toAddress,
        (dataObj: any) => {
          // console.log(dataObj);
          let distanceInMeters = 0;
          let durationInMin = 0;
          if (dataObj && dataObj.distance && dataObj.distance.value) {
            distanceInMeters = dataObj.distance.value;
            setTotalMiles(
              Number(CommonUtil.convertMetersToMiles(distanceInMeters))
            );
          } else {
            setTotalMiles(0);
          }
          if (dataObj && dataObj.duration && dataObj.duration.text) {
            durationInMin = dataObj.duration.text;
            setTotalDriveTime(durationInMin);
          } else {
            setTotalDriveTime(0);
          }
        }
      );
    }
  };

  let clearAllFilters = () => {
    // modelYearFilter = false;
    $("#equipModalYear").removeClass("show");
    if (
      minRef.current &&
      minRef.current.value &&
      maxRef.current &&
      maxRef.current.value
    ) {
      minRef.current.value = "";
      maxRef.current.value = "";
    }
    // axlesCountFilter = false;
    $("#equipAxies").removeClass("show");
    if (
      minAxies.current &&
      minAxies.current.value &&
      maxAxies.current &&
      maxAxies.current.value
    ) {
      minAxies.current.value = "";
      maxAxies.current.value = "";
    }
    //equipmentTypeFilter = false;
    equiptyes.forEach((cItem: any, cIdx: number) => {
      if (cItem.current) {
        cItem.current.checked = false;
      }
    });
    if (chkSelectAll && chkSelectAll.current) {
      chkSelectAll.current.checked = false;
    }
    equiptyes.forEach((cItem: any, cIdx: number) => {
      if (cItem.current) {
        cItem.current.checked = false;
      }
    });
    if (chkMySearches.current) {
      chkMySearches.current.checked = false;
    }

    $("#equipDropDown").removeClass("show");
    //filterData = [];
  };

  let closeModalPopup = () => {
    setPopupShow(false);
    setRowShow(false);
  };

  let onClickLink = (item: any) => {
    console.log(item);
    /*availabilityId = data.availabilityId;//availId;
        isGenericEquip = data.isGenericEquipment;
        CommonUtil.EQUIP_RESERVE_IS_GENERIC = data.isGenericEquipment;
        CommonUtil.SELECT_EQUIPMENT_TYPE = data.equipmentType;
        getAvailabilityDetails();
        getOrgServiceFees();*/

    onClickShowAvailability(item);
  };

  let onClickBookItem = (item: any) => {
    console.log(item);
    localStorage.setItem("PublicViewTab", "true");
    localStorage.setItem("offerPopup", "false");
    showAvailDetails(item);
  };

  let onClickShowAvailability = (item: any) => {
    console.log(item);
    console.log(searchType);
    localStorage.setItem("PublicViewTab", "true");
    localStorage.setItem("offerPopup", "true");
    showAvailDetails(item);
  };
  let showAvailDetails = (item: any) => {
    let stDate = null;
    let endDate = null;
    if (searchType == REPOSITION) {
      if (stDateRef.current) {
        stDate = stDateRef.current.flatpickr.selectedDates[0];
      }
      if (endDateRef.current) {
        endDate = endDateRef.current.flatpickr.selectedDates[0];
      }
    } else {
      if (stDateRef.current) {
        stDate = stDateRef.current.flatpickr.selectedDates[0];
      }
      if (endDateRef.current) {
        endDate = endDateRef.current.flatpickr.selectedDates[0];
      }
    }
    localStorage.setItem(
      "startDate",
      stDate ? CommonUtil.getReservationDateString(stDate) : ""
    );
    localStorage.setItem(
      "endDate",
      endDate ? CommonUtil.getReservationDateString(endDate) : ""
    );

    localStorage.setItem(
      "PublicSearch",
      CommonUtil.PUBLIC_SEARCH_DB ? "true" : "false"
    );
    //let isSearch:string = String(isSearchClicked);
    localStorage.setItem("isSearchClicked", isSearchClicked ? "true" : "false");
    let siteOrigin: string = window.location.origin;
    let equipId: string = item.equipmentId;
    let avaiId: string = item.availabilityId;
    let isGeneric: string = item.isGenericEquipment;
    let equipType: string = item.equipmentType;

    siteOrigin = siteOrigin + "/?requestor=equippublicview&equipId=" + equipId; //+"&token="+ localStorage.getItem("token");
    siteOrigin = siteOrigin + "&avaiId=" + avaiId;
    siteOrigin = siteOrigin + "&isGeneric=" + isGeneric;
    siteOrigin = siteOrigin + "&equipType=" + equipType;
    var newTab = document.createElement("a");
    newTab.href = siteOrigin;
    newTab.setAttribute("target", "_blank");
    newTab.click();
    return;
  };

  let getSelectedRows = () => {
    if (gridRef.current && !rowExist) {
      rowExist = true;
      let rows: any = gridRef.current.getSelectedRows();
      console.log(rows);
      let rowItem: any = null;
      if (rows && rows[0]) {
        rowItem = rows[0];
      }
      if (rowItem) {
        onClickRepItem(rowItem);
      }
    }
    setTimeout(() => {
      rowExist = false;
    }, 100);
  };

  let onClickRepItem = (item: any, tileId?: any) => {
    // if(isSearchClicked){

    if (currentTileId) {
      $("#" + currentTileId).removeClass("resactive");
    }
    if (tileId) {
      currentTileId = tileId;
      if (currentTileId) {
        $("#" + currentTileId).addClass("resactive");
      }
    }
    if (searchType == REPOSITION) {
      IS_MAP_CLICK = true;
      clearClusterMarkers();
      clearRepositionMarkers();
      BusyLoader.showLoader();
      setTimeout(() => {
        var latlngbounds = new google.maps.LatLngBounds();
        let fromLatLng = null; //new google.maps.LatLng(fromLatitude, fromLongitude);
        let fromMarker = null;
        if (fromAddress) {
          fromLatLng = new google.maps.LatLng(fromLatitude, fromLongitude);
          fromMarker = new google.maps.Marker({
            position: fromLatLng,
            map: gMap,
            label: "1",
            title: fromAddress,
            icon: "../../images/NewIcons/r41rearloader.png",
          });
        } else {
          fromLatLng = new google.maps.LatLng(
            item.departureLatitude,
            item.departureLongitude
          );
          fromMarker = new google.maps.Marker({
            position: fromLatLng,
            map: gMap,
            label: "1",
            title: item.departureAddress,
            icon: "../../images/NewIcons/r41rearloader.png",
          });
        }

        markers.push(fromMarker);
        latlngbounds.extend(fromLatLng);

        let departureLatLng = new google.maps.LatLng(
          item.departureLatitude,
          item.departureLongitude
        );
        let departureMarker = new google.maps.Marker({
          position: departureLatLng,
          map: gMap,
          label: "2",
          title: item.departureAddress,
        });
        markers.push(departureMarker);
        latlngbounds.extend(departureLatLng);

        let arrivalLatLng = new google.maps.LatLng(
          item.arrivalLatitude,
          item.arrivalLongitude
        );

        let toLatLng = null;
        console.log("to loc:" + toLocationRef.current?.value);
        if (toLocationRef.current?.value) {
          toLatLng = new google.maps.LatLng(toLatitude, toLongitude);
          let toMarker = new google.maps.Marker({
            position: toLatLng,
            map: gMap,
            label: "4",
            title: toAddress,
            icon: "../../images/NewIcons/r4autoside.png",
          });
          markers.push(toMarker);
          latlngbounds.extend(toLatLng);
        } else {
          // toLatLng = arrivalLatLng;
          // toLatitude = item.arrivalLatitude;
          //toLongitude = item.arrivalLongitude;
          // toAddress = item.arrivalAddress;
          let toLatLng1 = new google.maps.LatLng(
            item.arrivalLatitude,
            item.arrivalLongitude
          );
          let toMarker1 = new google.maps.Marker({
            position: toLatLng1,
            map: gMap,
            label: "4",
            title: item.arrivalAddress,
            icon: "../../images/NewIcons/r4autoside.png",
          });
          markers.push(toMarker1);
          latlngbounds.extend(toLatLng1);
          toLatLng = toLatLng1;
        }

        let arrivalMarker = new google.maps.Marker({
          position: arrivalLatLng,
          map: gMap,
          label: "3",
          title: item.arrivalAddress,
        });
        markers.push(arrivalMarker);

        latlngbounds.extend(arrivalLatLng);

        var service = new google.maps.DirectionsService();
        var service_options: any = {
          origin: fromLatLng,
          destination: toLatLng,
          waypoints: [
            { location: item.departureAddress },
            { location: item.arrivalAddress },
          ],
          travelMode: "DRIVING",
        };

        //console.log("from:"+fromAddress,"to:"+toAddress,"depAddress:"+rowItem.departureAddress,"arrival address:"+rowItem.arrivalAddress);
        service.route(service_options, function (response, status) {
          //console.log(response);
          BusyLoader.hideLoader();
          if (status != "OK") {
            return;
          }
          // var google = window.google;
          renderer = new google.maps.DirectionsRenderer({});
          renderer.setMap(gMap);
          renderer.setOptions({
            suppressMarkers: true,
            preserveViewport: true,
          });
          renderer.setDirections(response);
          markers.push(renderer);
          //renderDirectionsPolylines(response);
          //allMintenanceTruckRoutes.push(renderer);
        });

        if (latlngbounds) {
          if (gMap) {
            // gMap.fitBounds(latlngbounds);
          }
        }
        setTimeout(() => {
          IS_MAP_CLICK = false;
        }, 1000);
      }, 1000);
    } else {
      item.latlngs = [item];
      item.arrivalLat = Number(item.arrivalLatitude);
      item.arrivalLng = Number(item.arrivalLongitude);
      setMarkerData(item);
      setPopupShow(true);
      /* console.log(markers)
                console.log(item.departureLatitude, item.departureLongitude);
                //var marker = null;
                if(marker){
                    // marker.setAnimation(null);
                }
                for(var i=0;i<markers.length;i++){
                    marker = markers[i];
                    if(marker){

                        if(marker.getPosition().lat() == item.departureLatitude && marker.getPosition().lng() == item.departureLongitude){
                            //marker.setAnimation(google.maps.Animation.BOUNCE);
                            // break;
                        }
                    }
                }*/
    }
    // }
  };

  let removeTileSelection = () => {
    if (currentTileId) {
      $("#" + currentTileId).removeClass("resactive");
    }
  };

  let onClickReposition = (e:any = null) => {
    if(e)
      // tabClicked = true;
    if (chkNearMatches.current) {
      chkNearMatches.current.checked = false;
    }
    isSearchButtonClicked = true;
    $("#divTotalDriveTime").css("visibility", "visible");
    removeTileSelection();
    searchType = REPOSITION;
    removeTileSelection();
    setSearchClicked(false);
    $("#divToLoc").show();
    $("#divDeviation").show();
    $("#divFromLoc").removeClass(" col-lg-12 col-md-12 col-sm-12");
    $("#divFromLoc").addClass(" col-lg-6 col-md-6 col-sm-12");

    setDBSearchType(searchType);
    if ($("#btnReposition").hasClass("btn-dark")) {
      $("#btnRental").removeClass("btn-dark");
      if (!$("#btnRental").hasClass("btn-outline-secondary")) {
        $("#btnRental").addClass("btn-outline-secondary");
      }
    } else {
      $("#btnReposition").addClass("btn-dark");
      $("#btnReposition").removeClass("btn-outline-secondary");
      $("#btnRental").removeClass("btn-dark");
      $("#btnRental").addClass("btn-outline-secondary");
    }
    $("#fromLocation").val("");
    $("#toLocation").val("");
    getRepositionSummary(REPOSITION);

    //let columns = searchColumns

    setTotalMiles(0);
    setTotalDriveTime(0);

    setSearchColumns(repColumns);
  };
  let onClickRental = (e:any = null) => {
    if(e)
      // tabClicked = true;
    if (chkNearMatches.current) {
      chkNearMatches.current.checked = false;
    }
    isSearchButtonClicked = false;
    $("#divTotalDriveTime").css("visibility", "hidden");
    removeTileSelection();
    $("#divToLoc").hide();
    $("#divDeviation").hide();
    $("#divFromLoc").removeClass(" col-lg-6 col-md-6 col-sm-12");
    $("#divFromLoc").addClass(" col-lg-12 col-md-12 col-sm-12");
    setSearchClicked(false);
    searchType = RENTAL;
    setDBSearchType(searchType);
    if ($("#btnRental").hasClass("btn-dark")) {
      // $("#btnReposition").removeClass("btn-dark");
      if (!$("#btnReposition").hasClass("btn-outline-secondary")) {
        $("#btnReposition").addClass("btn-outline-secondary");
      }
    } else {
      $("#btnReposition").addClass("btn-outline-secondary");
      $("#btnReposition").removeClass("btn-dark");
      $("#btnRental").addClass("btn-dark");
      $("#btnRental").removeClass("btn-outline-secondary");
    }
    $("#fromLocation").val("");
    $("#toLocation").val("");
    getRentalSummary();
    setTotalMiles(0);
    setTotalDriveTime(0);

    setRenterSearchColumns(renterColumns);
  };

  let onLoginError = (err: any) => {
    console.log(err);
  };

  return (
    <>
      <DashboardPopupModal
        modalShow={popupShow}
        handleClosePopup={closeModalPopup}
        markerData={markerData}
        isSearchClicked={isSearchClicked}
      />
      <div className="page-content-wrapper" style={{ overflow: "hidden" }}>
        <div id="search-db">
          <div className="container-fluid">
            <div className="sec-tabs A-SEC p-2 mb-3 mt-3">
              <div className="row">
                <div
                  className="col-xl-3 col-lg-3 col-sm-12 ser-field-mb-4"
                  style={{ width: "20%", paddingRight: "0px" }}
                >
                  <div className="new-dash m-t-1">
                    <button
                      type="button"
                      id={"btnReposition"}
                      className="btn btn-dark btn-sm m-r-4"
                      onClick={onClickReposition}
                    >
                      Repositioning &nbsp;
                      <span className="badge bg-secondary">
                        {" "}
                        {repositionCount}
                      </span>
                    </button>
                    <button
                      type="button"
                      id={"btnRental"}
                      className="btn btn-outline-secondary btn-sm"
                      onClick={onClickRental}
                    >
                      Rentals &nbsp;
                      <span className="badge bg-secondary"> {renterCount}</span>
                    </button>
                  </div>
                </div>
                <div
                  className="col-xl-5 col-lg-5 col-sm-12"
                  style={{ paddingLeft: "0px", width: "50%" }}
                >
                  <div className="s-input row">
                    <div
                      className="col-lg-6 col-md-6 col-sm-12"
                      id={"divFromLoc"}
                      style={{ paddingLeft: "0px", paddingRight: "0px" }}
                    >
                      <input
                        type="text"
                        ref={fromLocationRef}
                        id={"fromLocation"}
                        className="form-control from-location ser-field-mb-4"
                        placeholder="From Location"
                      />
                    </div>
                    <div
                      className="col-lg-6 col-md-6 col-sm-12"
                      id={"divToLoc"}
                      style={{ paddingLeft: "1px", paddingRight: "0px" }}
                    >
                      <input
                        type="text"
                        ref={toLocationRef}
                        id={"toLocation"}
                        className="form-control locationTo ser-field-mb-4"
                        placeholder="To Location"
                      />
                    </div>
                  </div>
                </div>
                <div
                  className="col-xl-4 col-lg-4 col-sm-12"
                  style={{ width: "30%" }}
                >
                  <div className="row">
                    <div
                      className="col-lg-3 col-md-12 col-sm-12 padding-style"
                      style={{ paddingLeft: "5px", paddingRight: "0px" }}
                    >
                      <div className="input-group ser-field-mb-4">
                        <Flatpickr
                          placeholder="Departure"
                          ref={stDateRef}
                          options={dateOptions}
                          onChange={onChangeStDate}
                          onOpen={onOpenStDate}
                          className="form-control calender box-shadow clsFormInputControl flat-picker font-bold"
                        />
                        {/*<button className="btn btn-outline-secondary datepicker" type="button" onClick={onClickPrevFromDate}>
                                                    <i className="fa-solid fa-angle-left"></i></button>
                                                <button className="btn btn-outline-secondary datepicker" type="button" onClick={onClickNextFromDate}>
                                                    <i className="fa-solid fa-angle-right"></i></button>*/}
                      </div>
                    </div>
                    <div
                      className="col-lg-3 col-md-12 col-sm-12 padding-style"
                      style={{ paddingLeft: "2px", paddingRight: "0px" }}
                    >
                      <div className="input-group ser-field-mb-4">
                        <Flatpickr
                          placeholder="Arrival"
                          ref={endDateRef}
                          options={dateOptions}
                          onChange={onChangeEdDate}
                          onOpen={onOpenEDDate}
                          className="form-control calender box-shadow clsFormInputControl flat-picker font-bold"
                        />
                        {/*<button className="btn btn-outline-secondary datepicker" type="button" onClick={onClickFromToDate}>
                                                    <i className="fa-solid fa-angle-left"></i></button>
                                                <button className="btn btn-outline-secondary datepicker" type="button" onClick={onClickNextToDate}>
                                                    <i className="fa-solid fa-angle-right"></i></button>*/}
                      </div>
                    </div>
                    <div
                      className="col-lg-3 col-md-12 col-sm-12 padding-style"
                      id={"divDeviation"}
                      style={{ paddingLeft: "10px", paddingRight: "0px" }}
                    >
                      <div className="input-group ser-field-mb-4">
                        <input
                          type="text"
                          className="form-control deviation"
                          value={allowedDeviation}
                          onChange={(e:any) => {
                            const onlyNums = e.currentTarget.value.replace(/[^0-9]/g, "");

                            if((Number(onlyNums) * CommonUtil.MILES_TO_METERS) <= 2000000000)
                              setAllowedDeviation(onlyNums);
                          }}
                        />
                        <span className="input-group-text">/MI</span>
                      </div>
                    </div>

                    <div
                      className="col-lg-3 col-md-12 col-sm-12 padding-style"
                      style={{ paddingLeft: "10px", paddingRight: "0px" }}
                    >
                      <button
                        type="button"
                        className="btn btn-primary btn-lg btn-block"
                        disabled={isPublicSearch ? true : false}
                        style={{ width: "100%" }}
                        onClick={onClickSearch}
                      >
                        search
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="sec-map">
              <div className="row">
                <div
                  className="col-xl-5 col-lg-12 col-md-12 m-m-b-15 float-end map-grid"
                  style={{ paddingRight: "0px" }}
                >
                  <div className="f-vh-75">
                    <div className="product-pop">
                      <button
                        type="button"
                        className="bg-white map product-pop-label btn btn-outline-danger text-dark btn-lg"
                      >
                        Map View <img src="../../images/map-view.svg" />
                      </button>
                    </div>

                    <div
                      className="map-container"
                      style={{ height: mapHeight }}
                    >
                      <div
                        ref={gMapRef}
                        id="mapRef"
                        className="map-container__map"
                        style={{ height: "100%" }}
                      ></div>
                    </div>
                  </div>
                </div>

                <div
                  className="col-xl-7 col-lg-12 col-md-12 "
                  style={{ paddingLeft: "5px" }}
                >
                  <div
                    className="col-md-3 col-12 navbuttons p-0 mt-1"
                    style={{ position: "relative" }}
                  >
                    <div
                      className="dropdown-menu exceed2 modal-year-show"
                      id="equipAxies"
                      ref={equipAxies}
                      style={{
                        zIndex: "999999991",
                        paddingBottom: "0px",
                        width: "500px",
                        position: "absolute",
                        top: "200px",
                      }}
                    >
                      <div className="row">
                        <div className="col-sm-6">
                          <div className="form-group row">
                            <label className="col-sm-12 col-form-label">
                              {CommonUtil.getMessageText(
                                Constants.MINIMUM,
                                "Minimum"
                              )}
                            </label>
                            <div className="col-sm-12">
                              <div>
                                <input
                                  ref={minAxies}
                                  type="number"
                                  className="form-control w-100 font-weight-bold"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-group row">
                            <label className="col-sm-12 col-form-label">
                              {CommonUtil.getMessageText(
                                Constants.MAXIMUM,
                                "Maximum"
                              )}
                            </label>
                            <div className="col-sm-12">
                              <div>
                                <input
                                  type="number"
                                  ref={maxAxies}
                                  className="form-control w-100 font-weight-bold"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6"></div>
                        <div className="col-12 text-right row">
                          {/* <a id="button2" onClick={onClickClearAxies}>
                            {CommonUtil.getMessageText(
                              Constants.CLEAR,
                              "Clear"
                            )}
                          </a> */}
                          <div className="col-6 d-flex flex-coloumn w-100 justify-content-end mb-2 gap-2">
                            <button
                            className="btn btn-outline-dark"
                            onClick={onClickClearAxies}
                            >
                            {CommonUtil.getMessageText(
                            Constants.CLEAR_FILTER,
                            "Clear Filter"
                            )}
                            </button>
                         
                              <button
                              className="btn-sm btn-primary"
                              type="submit"
                              onClick={onClickApplyAxies}
                              >
                              {CommonUtil.getMessageText(
                              Constants.APPLY_CLOSE,
                              "Apply & Close"
                              )}
                              </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      className="dropdown-menu exceed2 modal-year-show"
                      id="equipModalYear"
                      ref={equipModalYear}
                      style={{
                        zIndex: "999999991",
                        paddingBottom: "0px",
                        width: "500px",
                        position: "absolute",
                        top: "200px",
                      }}
                    >
                      <div className="row">
                        <div className="col-sm-6">
                          <div className="form-group row">
                            <label className="col-sm-12 col-form-label">
                              {CommonUtil.getMessageText(
                                Constants.MINIMUM,
                                "Minimum"
                              )}
                            </label>
                            <div className="col-sm-12">
                              <div>
                                <input
                                  type="number"
                                  ref={minRef}
                                  className="form-control d-inline-block w-100 font-weight-bold"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-group row">
                            <label className="col-sm-12 col-form-label">
                              {CommonUtil.getMessageText(
                                Constants.MAXIMUM,
                                "Maximum"
                              )}
                            </label>
                            <div className="col-sm-12">
                              <div>
                                <input
                                  type="number"
                                  ref={maxRef}
                                  className="form-control d-inline-block w-100 font-weight-bold"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6"></div>
                          <div className="col-6 d-flex flex-coloumn w-100 justify-content-end mb-2 gap-2">
                            <button
                            className="btn btn-outline-dark"
                            onClick={onClickClearModal}
                            >
                            {CommonUtil.getMessageText(
                            Constants.CLEAR_FILTER,
                            "Clear Filter"
                            )}
                            </button>
                          &nbsp;&nbsp;
                          <button
                            className="btn-sm btn-primary"
                            type="submit"
                            onClick={onClickApplyModal}
                          >
                            {CommonUtil.getMessageText(
                              Constants.APPLY_CLOSE,
                              "Apply & Close"
                            )}
                          </button>
                        </div>
                      </div>
                    </div>
                    <div
                      className="dropdown-menu exceed1 equip-type-show"
                      id="equipDropDown"
                      style={{
                        zIndex: "999999991",
                        paddingBottom: "0px",
                        width: "500px",
                        position: "absolute",
                        top: "300px",
                      }}
                      ref={equipDropDown}
                    >
                      <ul className="select-list">
                        <div className="row mt-3">
                          <div className="col-6">
                            <li>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="flexCheckIndeterminate"
                                  ref={chkSelectAll}
                                  value="SelectAll"
                                  onChange={() => {
                                    onChangeEquipType(chkSelectAll);
                                  }}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="flexCheckIndeterminate"
                                >
                                  {CommonUtil.getMessageText(
                                    Constants.SELECT_ALL,
                                    "Select All"
                                  )}
                                </label>
                              </div>
                            </li>
                          </div>
                          <div className="col-6">
                            <li>
                              <div className="form-check">
                                <input
                                  id="defaultCheck1"
                                  className="form-check-input"
                                  type="checkbox"
                                  value="Dry Van"
                                  ref={chkDryVan}
                                  onChange={() => {
                                    onChangeEquipTypeDryVan(chkDryVan);
                                  }}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="defaultCheck1"
                                >
                                  {CommonUtil.getMessageText(
                                    Constants.DRY_VAN,
                                    "Dry Van"
                                  )}
                                </label>
                              </div>
                            </li>
                          </div>
                          <div className="col-6">
                            <li>
                              <div className="form-check">
                                <input
                                  id="defaultCheck2"
                                  className="form-check-input"
                                  type="checkbox"
                                  ref={chkPlateDryVan}
                                  value="Plate Dry Van"
                                  onChange={() => {
                                    onChangeEquipTypeDryVan(chkPlateDryVan);
                                  }}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="defaultCheck2"
                                >
                                  {CommonUtil.getMessageText(
                                    Constants.PLATE_DRY_VAN,
                                    "Plate Dry Van"
                                  )}
                                </label>
                              </div>
                            </li>
                          </div>
                          <div className="col-6">
                            <li>
                              <div className="form-check">
                                <input
                                  id="defaultCheck3"
                                  className="form-check-input"
                                  type="checkbox"
                                  value="Storage Van"
                                  ref={chkStorageVan}
                                  onChange={() => {
                                    onChangeEquipTypeDryVan(chkStorageVan);
                                  }}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="defaultCheck3"
                                >
                                  {CommonUtil.getMessageText(
                                    Constants.STORAGE_VAN,
                                    "Storage Van"
                                  )}
                                </label>
                              </div>
                            </li>
                          </div>
                          <div className="col-6">
                            <li>
                              <div className="form-check">
                                <input
                                  id="defaultCheck4"
                                  className="form-check-input"
                                  type="checkbox"
                                  ref={chkReeferVan}
                                  value="Reefer Van"
                                  onChange={() => {
                                    onChangeEquipTypeDryVan(chkReeferVan);
                                  }}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="defaultCheck4"
                                >
                                  {CommonUtil.getMessageText(
                                    Constants.REEFER_VAN,
                                    "Reefer Van"
                                  )}
                                </label>
                              </div>
                            </li>
                          </div>
                          <div className="col-6">
                            <li>
                              <div className="form-check">
                                <input
                                  id="defaultCheck5"
                                  className="form-check-input"
                                  type="checkbox"
                                  ref={chkHeatedVan}
                                  value="Heated Van"
                                  onChange={() => {
                                    onChangeEquipTypeDryVan(chkHeatedVan);
                                  }}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="defaultCheck5"
                                >
                                  {CommonUtil.getMessageText(
                                    Constants.HEATED_VAN,
                                    "Heated Van"
                                  )}
                                </label>
                              </div>
                            </li>
                          </div>
                          <div className="col-6">
                            <li>
                              <div className="form-check">
                                <input
                                  id="defaultCheck6"
                                  className="form-check-input"
                                  type="checkbox"
                                  ref={chkFlatBed}
                                  value="Flatbed"
                                  onChange={() => {
                                    onChangeEquipTypeDryVan(chkFlatBed);
                                  }}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="defaultCheck6"
                                >
                                  {CommonUtil.getMessageText(
                                    Constants.FLAT_BED,
                                    "Flatbed"
                                  )}
                                </label>
                              </div>
                            </li>
                          </div>
                          <div className="col-6">
                            <li>
                              <div className="form-check">
                                <input
                                  id="defaultCheck7"
                                  className="form-check-input"
                                  type="checkbox"
                                  ref={chkDropDeck}
                                  value="Dropdeck"
                                  onChange={() => {
                                    onChangeEquipTypeDryVan(chkDropDeck);
                                  }}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="defaultCheck7"
                                >
                                  {CommonUtil.getMessageText(
                                    Constants.DROP_DECK,
                                    "Dropdeck"
                                  )}
                                </label>
                              </div>
                            </li>
                          </div>
                          <div className="col-6">
                            <li>
                              <div className="form-check">
                                <input
                                  id="defaultCheck8"
                                  className="form-check-input"
                                  type="checkbox"
                                  ref={chkLog}
                                  value="Log"
                                  onChange={() => {
                                    onChangeEquipTypeDryVan(chkLog);
                                  }}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="defaultCheck8"
                                >
                                  {CommonUtil.getMessageText(
                                    Constants.LOG,
                                    "Log"
                                  )}
                                </label>
                              </div>
                            </li>
                          </div>
                          <div className="col-6">
                            <li>
                              <div className="form-check">
                                <input
                                  id="defaultCheck9"
                                  className="form-check-input"
                                  type="checkbox"
                                  value="SCRAP METAL"
                                  ref={chkScrapMetal}
                                  onChange={() => {
                                    onChangeEquipTypeDryVan(chkScrapMetal);
                                  }}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="defaultCheck9"
                                >
                                  {CommonUtil.getMessageText(
                                    Constants.SCRAP_METAL,
                                    "Scrap Metal"
                                  )}
                                </label>
                              </div>
                            </li>
                          </div>

                          <div className="col-6">
                            <li>
                              <div className="form-check">
                                <input
                                  id="defaultCheck10"
                                  className="form-check-input"
                                  type="checkbox"
                                  value="Recycling"
                                  ref={chkRecycling}
                                  onChange={() => {
                                    onChangeEquipTypeDryVan(chkRecycling);
                                  }}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="defaultCheck10"
                                >
                                  {CommonUtil.getMessageText(
                                    Constants.RECYCLING,
                                    "Recycling"
                                  )}
                                </label>
                              </div>
                            </li>
                          </div>
                          <div className="col-6">
                            <li>
                              <div className="form-check">
                                <input
                                  id="defaultCheck11"
                                  className="form-check-input"
                                  type="checkbox"
                                  ref={chkChip}
                                  value="Chip"
                                  onChange={() => {
                                    onChangeEquipTypeDryVan(chkChip);
                                  }}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="defaultCheck11"
                                >
                                  {CommonUtil.getMessageText(
                                    Constants.CHIP,
                                    "Chip"
                                  )}
                                </label>
                              </div>
                            </li>
                          </div>
                          <div className="col-6">
                            <li>
                              <div className="form-check">
                                <input
                                  id="defaultCheck12"
                                  className="form-check-input"
                                  type="checkbox"
                                  ref={chkDump}
                                  value="Dump"
                                  onChange={() => {
                                    onChangeEquipTypeDryVan(chkDump);
                                  }}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="defaultCheck12"
                                >
                                  {CommonUtil.getMessageText(
                                    Constants.DUMP,
                                    "Dump"
                                  )}
                                </label>
                              </div>
                            </li>
                          </div>
                          <div className="col-6">
                            <li>
                              <div className="form-check">
                                <input
                                  id="defaultCheck13"
                                  className="form-check-input"
                                  type="checkbox"
                                  ref={chkLowbed}
                                  value="Lowbed"
                                  onChange={() => {
                                    onChangeEquipTypeDryVan(chkLowbed);
                                  }}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="defaultCheck13"
                                >
                                  {CommonUtil.getMessageText(
                                    Constants.LOWBED,
                                    "Lowbed"
                                  )}
                                </label>
                              </div>
                            </li>
                          </div>
                          <div className="col-6">
                            <li>
                              <div className="form-check">
                                <input
                                  id="defaultCheck14"
                                  className="form-check-input"
                                  type="checkbox"
                                  ref={chkChassis}
                                  value="Chassis"
                                  onChange={() => {
                                    onChangeEquipTypeDryVan(chkChassis);
                                  }}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="defaultCheck14"
                                >
                                  {CommonUtil.getMessageText(
                                    Constants.CHASIS,
                                    "Chassis"
                                  )}
                                </label>
                              </div>
                            </li>
                          </div>
                          <div className="col-6">
                            <li>
                              <div className="form-check">
                                <input
                                  id="defaultCheck15"
                                  className="form-check-input"
                                  type="checkbox"
                                  ref={chkTank}
                                  value="Tank"
                                  onChange={() => {
                                    onChangeEquipTypeDryVan(chkTank);
                                  }}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="defaultCheck15"
                                >
                                  {CommonUtil.getMessageText(
                                    Constants.TANK,
                                    "Tank"
                                  )}
                                </label>
                              </div>
                            </li>
                          </div>
                          <div className="col-6">
                            <li>
                              <div className="form-check">
                                <input
                                  id="defaultCheck16"
                                  className="form-check-input"
                                  type="checkbox"
                                  ref={chkTankCrude}
                                  value={"Tank Crude"}
                                  onChange={() => {
                                    onChangeEquipTypeDryVan(chkTankCrude);
                                  }}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="defaultCheck16"
                                >
                                  {CommonUtil.getMessageText(
                                    Constants.TANK_CRUDE,
                                    "Tank Crude"
                                  )}
                                </label>
                              </div>
                            </li>
                          </div>
                          <div className="col-6">
                            <li>
                              <div className="form-check">
                                <input
                                  id="defaultCheck17"
                                  className="form-check-input"
                                  type="checkbox"
                                  value={"Tank Dry Bulk"}
                                  ref={chkTankDryBulk}
                                  onChange={() => {
                                    onChangeEquipTypeDryVan(chkTankDryBulk);
                                  }}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="defaultCheck17"
                                >
                                  {CommonUtil.getMessageText(
                                    Constants.TANK_DRY_BULK,
                                    "Tank Dry Bulk"
                                  )}
                                </label>
                              </div>
                            </li>
                          </div>
                          <div className="col-6">
                            <li>
                              <div className="form-check">
                                <input
                                  id="defaultCheck18"
                                  className="form-check-input"
                                  type="checkbox"
                                  ref={chkTankPetroleum}
                                  value={"Tank Petroleum"}
                                  onChange={() => {
                                    onChangeEquipTypeDryVan(chkTankPetroleum);
                                  }}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="defaultCheck18"
                                >
                                  {CommonUtil.getMessageText(
                                    Constants.TANK_PETROLEUM,
                                    "Tank Petroleum"
                                  )}
                                </label>
                              </div>
                            </li>
                          </div>
                          <div className="col-6">
                            <li>
                              <div className="form-check">
                                <input
                                  id="defaultCheck19"
                                  className="form-check-input"
                                  type="checkbox"
                                  value={"Tank Propane"}
                                  ref={chkTankPropane}
                                  onChange={() => {
                                    onChangeEquipTypeDryVan(chkTankPropane);
                                  }}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="defaultCheck19"
                                >
                                  {CommonUtil.getMessageText(
                                    Constants.TANK_PROPANE,
                                    "Tank Propane"
                                  )}
                                </label>
                              </div>
                            </li>
                          </div>
                          <div className="col-6">
                            <li>
                              <div className="form-check">
                                <input
                                  id="defaultCheck20"
                                  className="form-check-input"
                                  type="checkbox"
                                  value={"Tank Chemical"}
                                  ref={chkTankChemical}
                                  onChange={() => {
                                    onChangeEquipTypeDryVan(chkTankChemical);
                                  }}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="defaultCheck20"
                                >
                                  {CommonUtil.getMessageText(
                                    Constants.TANK_CHEMICAL,
                                    "Tank Chemical"
                                  )}
                                </label>
                              </div>
                            </li>
                          </div>
                          <div className="col-6">
                            <li>
                              <div className="form-check">
                                <input
                                  id="defaultCheck21"
                                  className="form-check-input"
                                  type="checkbox"
                                  value={"Other"}
                                  ref={chkOther}
                                  onChange={() => {
                                    onChangeEquipTypeDryVan(chkOther);
                                  }}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="defaultCheck21"
                                >
                                  {CommonUtil.getMessageText(
                                    Constants.OTHER,
                                    "Other"
                                  )}
                                </label>
                              </div>
                            </li>
                          </div>
                          <div className="col-6">
                            <li>
                              <div className="form-check">
                                <input
                                  id="defaultCheck22"
                                  className="form-check-input"
                                  type="checkbox"
                                  value={"SPOTTER"}
                                  ref={chkSpotter}
                                  onChange={() => {
                                    onChangeEquipTypeDryVan(chkSpotter);
                                  }}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="defaultCheck22"
                                >
                                  {CommonUtil.getMessageText(
                                    Constants.SPOTTER,
                                    "Spotter"
                                  )}
                                </label>
                              </div>
                            </li>
                          </div>
                        </div>
                        <div className="row" style={{ paddingTop: "10px" }}>
                          <div className="col-6"></div>
                            <div className="col-6 d-flex flex-coloumn w-100 justify-content-end mb-2 gap-2">
                            <button
                            className="btn btn-outline-dark"
                            onClick={onClickApplyEquipTypeClear}
                            >
                            {CommonUtil.getMessageText(
                            Constants.CLEAR_FILTER,
                            "Clear Filter"
                            )}
                            </button>
                          &nbsp;&nbsp;
                            <button
                              className="btn-sm btn-primary"
                              type="submit"
                              onClick={onClickApplyEquipType}
                            >
                              {CommonUtil.getMessageText(
                                Constants.APPLY_CLOSE,
                                "Apply & Close"
                              )}
                            </button>
                          </div>
                        </div>
                      </ul>
                    </div>
                  </div>
                  <div className="A-SEC total-sec p-2 mb-3 t-m-t-15">
                    <div className="row d-flex align-items-center">
                      <div
                        className="col-lg-7 col-md-7 col-sm-12 total"
                        id={"divTotalDriveTime"}
                        style={{ display: "none" }}
                      >
                        <div className="d-flex justify-content-between">
                          <div className="time">
                            Total Drive Time -{" "}
                            <span className="text-red fw-600">
                              {totalDriveTime}
                            </span>
                          </div>

                          <div
                            className="time float-end xl-p-r-2"
                            style={{ paddingRight: "20%" }}
                          >
                            Total Miles -{" "}
                            <span className="text-red fw-600">
                              {totalMiles}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-8 col-lg-8 col-md-12 col-sm-12">
                        <div className="row">
                          <div
                            className="col-lg-4"
                            style={{
                              visibility: isBestMatchVisible
                                ? "visible"
                                : "hidden",
                            }}
                          >
                            <div className="time ms-xl-2">
                              <span className="text-red fw-600">
                                {bestMatchCount} -{" "}
                              </span>{" "}
                              Best Matches
                            </div>
                          </div>
                          <div
                            className="col-lg-4"
                            style={{
                              visibility: isBestMatchVisible
                                ? "visible"
                                : "hidden",
                            }}
                          >
                            <div className="mx-0">
                              <label className="checkbox">
                                <input
                                  type="checkbox"
                                  ref={chkNearMatches}
                                  onClick={onClickNearestMatches}
                                />
                                <span className="indicator"></span>
                                Near Matches - {nearMatchCount}
                              </label>
                            </div>
                          </div>
                          <div className="col">
                            <div
                              className="xl-p-l-2"
                              id={"divMyAvailabilities"}
                              style={{ display: "none" }}
                            >
                              <label className="checkbox">
                                <input
                                  ref={chkMySearches}
                                  type="checkbox"
                                  onClick={onClickMySearches}
                                />
                                <span className="indicator"></span>
                                My Availabilities
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-4 col-md-4 col-sm-12 grid-icons ">
                        <div className="d-flex  align-items-center">
                          <div
                            className="btn-group grid-list"
                            style={{ marginLeft: "130px" }}
                          >
                            <a
                              href="#"
                              id="list"
                              className="btn btn-default btn-sm active"
                              title="List"
                              onClick={onClickTileView}
                            >
                              <img src="../../images/grid-black.png" />
                            </a>
                            <a
                              href="#"
                              id="grid"
                              className="btn btn-default btn-sm"
                              title="Table"
                              onClick={onClickGridView}
                            >
                              <img src="../../images/list-red1.png" />{" "}
                            </a>
                            <a
                              href="#"
                              id="grid"
                              className="btn btn-default btn-sm"
                              title="Filter"
                              onClick={onClickFilterView}
                            >
                              <img src="../../images/list-red.png" />{" "}
                            </a>
                          </div>

                          <div className="col-lg-2 col-md-3 col-sm-12">
                            <div className="dropdown">
                              <button
                                className="btn btn-secondary dropdown-toggle filter-button-border"
                                type="button"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                                style={{ border: "0px" }}
                              >
                                {
                                  equipType || modelYear ||axisCount ? (<><i className="fa-solid fa-filter-circle-xmark" style={{color: "grey", scale:"1.2", top:"3px", position:"relative"}}></i><span style={{top:"9px"}} className="position-absolute fs-8 start-1 translate-middle badge rounded-pill bg-danger">
                                  {(equipType ? 1 : 0) + (modelYear ? + 1 : 0) + (axisCount ? 1 : 0)}
                                </span></>) : ( <i className="fa-solid fa-filter"></i>)
                                }
                              </button>
                              <ul className="dropdown-menu">
                                <li className="dropdown-item">
                                  <label className="checkbox">
                                    <input
                                      ref={chkEquipType}
                                      type="checkbox"
                                      onClick={onClickEquipType}
                                      checked={equipType}
                                    />
                                    <span className="indicator"></span>
                                    Equipment Type
                                  </label>
                                </li>
                                <li className="dropdown-item">
                                  <label className="checkbox">
                                    <input
                                      type="checkbox"
                                      onClick={onClickModalYear}
                                      checked={modelYear}
                                    />
                                    <span className="indicator"></span>
                                    Model Year
                                  </label>
                                </li>
                                <li className="dropdown-item">
                                  <label className="checkbox">
                                    <input
                                      type="checkbox"
                                      onClick={onClickEquipAxes}
                                      checked={axisCount}
                                    />
                                    <span className="indicator"></span>
                                    Axies Count
                                  </label>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div
                            className="col-lg-2 col-md-3 col-sm-12"
                            style={{
                              display:
                                dbSearchType == REPOSITION ? "none" : "none",
                            }}
                          >
                            <div className="dropdown">
                              <button
                                className="btn btn-secondary dropdown-toggle"
                                type="button"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <i className="fa-solid fa-filter"></i>
                              </button>
                              <ul className="dropdown-menu">
                                <li className="dropdown-item">
                                  <label className="checkbox">
                                    <input type="checkbox" />
                                    <span className="indicator"></span>
                                    Best Matches
                                  </label>
                                </li>
                                <li className="dropdown-item">
                                  <label className="checkbox">
                                    <input type="checkbox" />
                                    <span className="indicator"></span>
                                    Nearest Matches
                                  </label>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex-container f-vh-66" id={"tileView"}>
                    <div className="sidebar">
                      <div className="scrollbox" id="style-1">
                        <div
                          className="scrollbox-inner"
                          style={{ height: tileHeight }}
                        >
                          <div
                            className="row"
                            style={{
                              paddingLeft: "12px",
                              paddingRight: "12px",
                            }}
                          >                           
                            {(repositions.length ==0 && !tabClicked) &&
                              <div className="col-lg-12 sec-card mb-3">
                                <div className=" p-1">
                                  <div className="row">
                                    <div className="d-flex j justify-content-center align-items-center mt-2 " style={{height: "calc(100vh - 300px)"}} >
                                        <span>No Records Found</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            }
                            {repositions.map((item: any, idx: number) => {
                              // console.log(repositions)
                              let tileId = "tile" + idx;
                              var list = (
                                <div
                                  className="col-lg-4 sec-card mb-3"
                                  style={{
                                    paddingLeft: "5px",
                                    paddingRight: "5px",
                                  }}
                                  id={"tile" + idx}
                                  onClick={() => {
                                    onClickRepItem(item, tileId);
                                  }}
                                >
                                  <div className="A-SEC p-1">
                                    <div className="row">
                                      <div className="d-flex justify-content-between align-items-center mb-2">
                                        <div className="grid-name">
                                          <h5>{item.equipmentType}</h5>
                                          <span className="axel text-uppercase">
                                            {item.numberOfAxles
                                              ? item.numberOfAxles + " AXLES"
                                              : ""}{" "}
                                            |{" "}
                                            {item.lengthOfTrailer
                                              ? item.lengthOfTrailer
                                              : ""}{" "}
                                            | {item.year ? item.year : ""}
                                          </span>
                                        </div>

                                        <div
                                          className="A-SEC-ONE p-1 text-center offset-md-1"
                                          style={{
                                            display:
                                              dbSearchType == REPOSITION
                                                ? ""
                                                : "none",
                                          }}
                                        >
                                          <div className="price">
                                            <h6 style={{ fontSize: "10px" }}>
                                              {" "}
                                              {item.movePrice}
                                            </h6>
                                            <span>{item.loadType}</span>
                                          </div>
                                        </div>

                                        <div className="col-1 fs-12 text-center ">
                                          {item.equipmentRating ? (
                                            <span className="fa fa-star "></span>
                                          ) : (
                                            <span className="fa fa-star " />
                                          )}
                                          {item.equipmentRating ? (
                                            <span className="fs-10 d-block">
                                              {item.equipmentRating}/
                                              {item.ratingCount}
                                            </span>
                                          ) : (
                                            <span className="fs-10 d-block">
                                              {item.equipmentRating}/
                                              {item.ratingCount}
                                            </span>
                                          )}
                                        </div>
                                      </div>

                                      <div className="d-flex align-items-center justify-content-between mb-2">
                                        <div className="d-flex">
                                          <img
                                            src="../../images/truck.svg"
                                            className="m-r-4 truck truck"
                                          />
                                          <div className="text-fade fs-12 d-block ">
                                            <div className="grid-name mb-2">
                                              <h5>
                                                {CommonUtil.getDBDate(
                                                  item.departureDate
                                                )}
                                              </h5>
                                              <span
                                                className="axel"
                                                title={item.fromLocTip}
                                              >
                                                {item.fromLoc}
                                              </span>
                                            </div>
                                            <div className="grid-name">
                                              <h5>
                                                {CommonUtil.getDBDate(
                                                  item.arrivalDate
                                                )}
                                              </h5>
                                              <span
                                                className="axel"
                                                title={item.toLocTip}
                                              >
                                                {item.toLoc}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                        <div
                                          style={{
                                            opacity: isSearchClicked ? 1 : 1,
                                          }}
                                        >
                                          <div className="d-grid gap-2">
                                            <button
                                              type="button"
                                              disabled={
                                                isSearchClicked ? false : false
                                              }
                                              className="btn btn-outline-success btn-sm fs-10 position-relative"
                                              onClick={() => {
                                                onClickBookItem(item);
                                              }}
                                            >
                                              Book now
                                              <span className="position-absolute top-0 fs-8 start-100 translate-middle badge rounded-pill bg-danger">
                                                {item.availableQuantity
                                                  ? item.availableQuantity
                                                  : ""}
                                              </span>
                                            </button>
                                            <button
                                              type="button"
                                              disabled={
                                                isSearchClicked ? false : false
                                              }
                                              style={{
                                                display:
                                                  item.allowOffer == "Y"
                                                    ? ""
                                                    : "none",
                                              }}
                                              className="btn btn-outline-secondary btn-sm fs-10"
                                              onClick={() =>
                                                onClickShowAvailability(item)
                                              }
                                            >
                                              CREATE OFFER
                                            </button>
                                          </div>
                                        </div>
                                      </div>

                                      <div className="price-table">
                                        <table
                                          className="table mb-0"
                                          style={{
                                            display:
                                              dbSearchType == REPOSITION
                                                ? "none"
                                                : "",
                                          }}
                                        >
                                          <tr>
                                            <th>
                                              <div className="table-main-text">
                                                <div className="d-block">
                                                  {" "}
                                                  {item.dailyRate}{" "}
                                                  <span className="table-text-fade"></span>
                                                </div>
                                                <div className="d-block fs-10 text-fade fw-400">
                                                  Daily Rate
                                                </div>
                                              </div>
                                            </th>
                                            <th>
                                              <div className="table-main-text">
                                                <div className="d-block">
                                                  {" "}
                                                  {item.weeklyRate}{" "}
                                                  <span className="table-text-fade"></span>
                                                </div>
                                                <div className="d-block fs-10 text-fade fw-400">
                                                  Weekly Rate
                                                </div>
                                              </div>
                                            </th>
                                            <th>
                                              <div className="table-main-text">
                                                <div className="d-block">
                                                  {" "}
                                                  {item.monthlyRate}{" "}
                                                  <span className="table-text-fade"></span>
                                                </div>
                                                <div className="d-block fs-10 text-fade fw-400">
                                                  Monthly Rate
                                                </div>
                                              </div>
                                            </th>
                                            </tr>
                                         <tr>
                                            <th>
                                              <div className="table-main-text">
                                                {item.distanceRate}{" "}
                                                <span className="table-text-fade"></span>
                                                <div className="d-block fs-10 text-fade fw-400">
                                                  Distance Rate
                                                </div>
                                              </div>
                                            </th>
                                            <th>
                                              <div className="table-main-text">
                                                {item.engineRate}{" "}
                                                <span className="table-text-fade"></span>
                                                <div className="d-block fs-10 text-fade fw-400">
                                                  Engine Rate
                                                </div>
                                              </div>
                                            </th>
                                            <th>
                                              <div className="table-main-text">
                                                
                                                <span className="table-text-fade"></span>
                                                <div className="d-block fs-10 text-fade fw-400">
                                                  
                                                </div>
                                              </div>
                                              </th>
                                          </tr>
                                         
                                        </table>
                                        <table
                                          className="table mb-0"
                                          style={{
                                            display:
                                              dbSearchType == REPOSITION
                                                ? ""
                                                : "none",
                                          }}
                                        >
                                          <tr>
                                            <th>
                                              <div className="table-main-text">
                                                <div className="d-block">
                                                  {" "}
                                                  {item.noOfDaysAllowed}{" "}
                                                  <span className="table-text-fade"></span>
                                                </div>
                                                <div className="d-block fs-10 text-fade fw-400">
                                                  Allowed Days
                                                </div>
                                              </div>
                                            </th>
                                            <th>
                                              <div className="table-main-text">
                                                <div className="d-block">
                                                  {" "}
                                                  {item.currency === "USD" ? item.bobtailToPuDistanceMile : item.bobtailToPuDistanceKM}{" "}{" "}
                                                  <span className="table-text-fade">
                                                    ({item.currency === "USD" ? "Mile" :"km"})
                                                  </span>
                                                </div>
                                                <div className="d-block fs-10 text-fade fw-400">
                                                  Bobtail to Pickup
                                                </div>
                                              </div>
                                            </th>
                                            <th>
                                              <div className="table-main-text">
                                                <div className="d-block">
                                                  {" "}
                                                  {item.currency === "USD" ? item.hookDistanceMile : item.hookDistanceKM}{" "}
                                                  {/* {item.currency === "USD" ? item.hookDistanceMile : item.hookDistanceKM}{" "} */}
                                                  <span className="table-text-fade">
                                                    ({item.currency === "USD" ? "Mile" :"km"})
                                                  </span>
                                                </div>
                                                <div className="d-block fs-10 text-fade fw-400">
                                                  Hook Distance
                                                </div>
                                              </div>
                                            </th>

                                            <th style={{ display: "none" }}>
                                              <div className="table-main-text">
                                                {item.totalDistanceKM}{" "}
                                                <span className="table-text-fade">
                                                  (km)
                                                </span>
                                                <div className="d-block fs-10 text-fade fw-400">
                                                  Total Distance
                                                </div>
                                              </div>
                                            </th>
                                          </tr>
                                          <tr>
                                            <th>
                                              <div className="table-main-text">
                                                <div className="d-block">
                                                  {" "}
                                                  {item.revenuePerHour}{" "}
                                                  <span className="table-text-fade"></span>
                                                </div>
                                                <div className="d-block fs-10 text-fade fw-400">
                                                  Revenue per hour
                                                </div>
                                              </div>
                                            </th>
                                            <td>
                                              <div className="table-main-text">
                                                <div className="d-block">
                                                  {" "}
                                                  {item.currency === "USD" ? (item.dropToTargetDistanceMile||0) : (item.dropToTargetDistanceKM||0)}{" "}
                                                  <span className="table-text-fade">
                                                  ({item.currency === "USD" ? "Mile" :"km"})
                                                  </span>
                                                </div>
                                                <div className="d-block fs-10 text-fade fw-400">
                                                  Drop to Target
                                                </div>
                                              </div>
                                            </td>
                                            <td>
                                              <div className="table-main-text">
                                                {item.totalTravelTime}{" "}
                                                <span className="table-text-fade">
                                                  hrs
                                                </span>
                                                <div className="d-block fs-10 text-fade fw-400">
                                                  Estimated drive
                                                </div>
                                              </div>
                                            </td>
                                          </tr>
                                        </table>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                              return list;
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="flex-container"
                    id={"gridView"}
                    style={{ display: "none" }}
                  >
                    <div className="row" style={{ width: "100%" }}>
                      <div
                        className="ag-theme-alpine gridHeight"
                        style={{
                          height: tileHeight,
                          width: "100%",
                          paddingLeft: "14px",
                          paddingRight: "5px",
                        }}
                      >
                        {dbSearchType == REPOSITION ? (
                          <AGGrid
                            id="OrgRepositionSearchDBGrid"
                            cols={searchColumns}
                            rows={repositions}
                            pageSize={500}
                            rowHeight={CommonUtil.GRID_ROW_HEIGHT}
                            headerHeight={CommonUtil.GRID_HEADER_HEIGHT}
                            rowStyle={rowStyle}
                            ref={gridRef}
                            addNew={false}
                            method={{ onClickLink }}
                            onRowSelected={getSelectedRows}
                          />
                        ) : (
                          <AGGrid
                            id="OrgRentalSearchDBGrid"
                            cols={rentersearchColumns}
                            rows={repositions}
                            pageSize={500}
                            headerHeight={CommonUtil.GRID_HEADER_HEIGHT}
                            rowHeight={CommonUtil.GRID_ROW_HEIGHT}
                            ref={gridRef}
                            addNew={false}
                            method={{ onClickLink }}
                            onRowSelected={getSelectedRows}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  <div
                    className="flex-container"
                    id={"filterView"}
                    style={{ display: "none" }}
                  >
                    <div className="row" style={{ width: "100%" }}>
                      <div
                        className="ag-theme-alpine gridHeight"
                        style={{
                          height: tileHeight,
                          width: "100%",
                          paddingLeft: "14px",
                          paddingRight: "5px",
                        }}
                      >
                        <AGGrid
                          id="OrgRepositionSearchDBFilterGrid"
                          cols={filterColumns}
                          rows={filterRecords}
                          pageSize={500}
                          rowHeight={CommonUtil.GRID_ROW_HEIGHT}
                          headerHeight={CommonUtil.GRID_HEADER_HEIGHT}
                          rowStyle={rowStyle}
                          addNew={false}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SearchDashboardCopy;
