import React from "react";
import {toast} from "react-toastify";
import {CommonUtil} from "../../utils/CommonUtil";


const OrgUserFieldRenderer = (props: any) => {
    const onClickUser = () => {
        if (props.context) {
            props.context.onClickUserDetails(props.data);
        }
    }

    let onChangePrice = (val: string) => {
        //console.log(props.data);
        props.setValue(val);
    }
    return (
        <div style={{display: "flex", alignItems: "center"}}>
            <span style={{paddingRight:"5px"}}>{(CommonUtil.getCountry() == CommonUtil.CANADA) ? CommonUtil.CANADA_CURRENCY : CommonUtil.US_CURRENCY}</span>
            <input type="text" className="form-control" name={"daily"} style={{maxWidth: "60px", fontWeight:"normal"}} value={props.value} onChange={(e) => {
                const regex = /^\d*\.?\d*$/;
                const value = e.target.value;
                if (regex.test(value) || value === '') {
                  onChangePrice(e.currentTarget.value)
                }
                
            }} disabled={CommonUtil.isRootUser() ? (props.data.modelYear ? true :false) : true}/>
        </div>

    )
}

export default OrgUserFieldRenderer;