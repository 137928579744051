import React, { useEffect, useRef, useState } from "react";
import { getSelectedRangeRate, CommonUtil } from "../../utils/CommonUtil";
import { NetworkManager } from "../../Components/NetworkManager";
import BusyLoader from "../../Components/BusyLoader";
import ToastManager from "../../Components/ToastManager";
import { useNavigate } from "react-router-dom";
import Flatpickr from "react-flatpickr";
import $ from "jquery";
import DraggableComponent from "../../Components/DraggableComponent";
import { Modal } from "react-bootstrap";
import SingleSelectComp from "../../Components/SingleSelectComp";
import { publish } from "../Login/CustomEvents";
import { Events } from "../Languages/Events";
import { Constants } from "../Languages/Constants";
import StarRating from "../../Components/Rating";
import { StatusCodeUtil } from "../../utils/StatusCodeUtil";
import LoginHeader from "../Login/LoginHeader";
import EquipGalleryModal from "./EquipGalleryModal";
import RegistrationHeader from "../Login/RegistrationHeader";
import EquipmentHeader from "../Login/EquipmentHeader";
import Confirm from "../../Components/confirm";
import CostEstimate from "./CostEstimate";
import { onDownloadDoc } from "../../Components/Terms";


var equiImages: any = [];
var attchIdx: number = 0;
var attachmentList: any = [];
var dDays = 140;
var eHours = 4;
var diffDays: number = 0;
var dailyPriceRate: number = 0;
var enginePrice: number = 0;
var distancePriceRate: number = 0;
var servieFee: number = 0;
var ownerRepositioningMinimumCost: number = 0;

var insurPolicies: any = [];
var saagOrgId: any = "";

let ownerCoverageInsurace: number = 0;
let renterCoverageInsurance: number = 0;

let liabilityRateId: string = "";
let physicalDamageRateId: string = "";

let isPublicSearchEnable: boolean = false;
let isInsuranceEnabled: boolean = false;

let liabilityInsuracePerDayAmount = 0;
let physicalDamagePerDayAmount = 0;

let isLiabilityPaidByRenter: boolean = false;
let isPhysicalDamagePaidByRenter: boolean = false;
let defaultCurrency: any = '';
let estimatedValue=0;

const EquipPublicView = (props: any) => {

    const [equipmentData, setEquipmentData] = useState<any>(null);
    const [showOfferPopup, setShowOfferPopup] = useState(false);

    const [assetType, setAssetType] = useState("");
    const [equipType, setEquipType] = useState("");
    const [unitNumber, setUnitNumber] = useState("");
    const [vinNumber, setVinNumber] = useState("");
    const [brand, setBrand] = useState("");
    const [model, setModel] = useState("");
    const [year, setYear] = useState("");
    const [equipName, setEquipName] = useState("");
    const [trackingType, setTrackingType] = useState("");
    // const [conversionRate, setConversionRate] = useState<any>(null);
    const [conversioObj, setConversionObj] = useState<any>(null);
    const [offerInsPhy, setOfferInsPhy] = useState(false);
    const [offerInsLib, setOfferInsLib] = useState(false);
    const [selectedcloseNetwork, setSelectedcloseNetwork] = useState<any>("");
    const [availabilityViewType, setAvailabilityViewType] = useState<any>("");
    const [closeNetworkList, setCloseNetworkList] = useState<any>([]);


    const stDateRef = useRef<Flatpickr>(null);
    const endDateRef = useRef<Flatpickr>(null);

    const stDateRef1 = useRef<Flatpickr>(null);
    const endDateRef1 = useRef<Flatpickr>(null);

    const stDateRef2 = useRef<Flatpickr>(null);
    const edDateRef2 = useRef<Flatpickr>(null);

    const stDateAvlRepRef = useRef<Flatpickr>(null);
    const endDateAvlRepRef = useRef<Flatpickr>(null);

    const stDateAvlRepRef1 = useRef<Flatpickr>(null);
    const endDateAvlRepRef1 = useRef<Flatpickr>(null);

    const numDayRef = useRef<HTMLInputElement>(null);
    const dailyRateRef = useRef<HTMLInputElement>(null);
    const weeklyRateRef = useRef<HTMLInputElement>(null);
    const monthlyRateRef = useRef<HTMLInputElement>(null);
    const distanceRef = useRef<HTMLInputElement>(null);
    const engineRatePopupRef = useRef<HTMLInputElement>(null);
    const distanceToTravelRef = useRef<HTMLInputElement>(null);
    const engineHoursUnitRef = useRef<HTMLInputElement>(null);
    const chkLiability = useRef<HTMLInputElement>(null);
    const chkPhysicalDamage = useRef<HTMLInputElement>(null);

    const chkLiabilityRep = useRef<HTMLInputElement>(null);
    const chkPhysicalDamageRep = useRef<HTMLInputElement>(null);

    const chkLiabilityOffer = useRef<HTMLInputElement>(null);
    const chkPhysicalDamageOffer = useRef<HTMLInputElement>(null);

    //chkLiabilityOffer


    const [offerLeftrateObj, setOfferLeftrateObj] = useState<any>({});
    const [offerRightrateObj, setOfferRightrateObj] = useState<any>({});
    const [noOfDaysPopup, setNoOfDaysPopup] = useState("");
    const [dailyPricePopup, setDailyPricePopup] = useState("");
    const [weeklyPricePopup, setWeeklyPricePopup] = useState("");
    const [monthlyPricePopup, setMonthlyPricePopup] = useState("");
    const [disPricePopup, setDisPricePopup] = useState("");

    const [engineRatePopup, setEngineHoursPopup] = useState("");

    const [distanceToTravelPopup, setDistanceToTravelPopup] = useState("140");
    const [engineHoursUnitPopup, setEngineHoursUnitPopup] = useState("4");


    const [numOfAxes, setNumOfAxes] = useState("");
    const [trailerLength, setTrailerLength] = useState("");
    const [license, setELicense] = useState("");
    const [expDate, setExpDate] = useState("");

    const [axelSpread, setAxelSpread] = useState("");
    const [tireInflation, setTireInflation] = useState("");
    const [skirts, setSkirts] = useState("");
    const [pintleHook, setPintleHook] = useState("");
    const [hazmat, setHazmat] = useState("");
    const [suspension, setSuspension] = useState("");

    const [rearDoor, setRearDoor] = useState("");
    const [sideDoor, setSideDoor] = useState("");
    const [roof, setRoof] = useState("");
    const [floor, setFloor] = useState("");
    const [floorType, setFloorType] = useState("");
    const [interior, setInterior] = useState("");
    const [logistics, setLogistics] = useState("");
    const [deckBeams, setDeckBeams] = useState("");

    const [liftType, setLiftType] = useState("");
    const [lift, setLift] = useState("");
    const [liftCap, setLiftCap] = useState("");

    const [unitCapacity, setUnitCapacity] = useState("");
    const [unitMake, setUnitMake] = useState("");
    const [unitModal, setUnitModal] = useState("");
    const [unitYear, setUnitYear] = useState("");
    const [multiTemp, setMultiTemp] = useState("");

    const [deActiveInstruction, setDeActive] = useState("");
    const [activeInstruction, setActivative] = useState("");

    const [dnuConditions, setDnuConditions] = useState("");
    const [dnuEqpConditions, setDnuEqpConditions] = useState("");
    const [platformFeePaidBy, setPlatformFeePaidBy] = useState("");

    const [emails, setEmails] = useState("");
    const [deposit, setDeposit] = useState("");
    const [imagePath, setImagePath] = useState("");
    const [availType, setAVailType] = useState("");

    const [stDate, setStDate] = useState("");
    const [endDate, setEndDate] = useState("");

    const [stDateOffer, setStDateOffer] = useState("");
    const [endDateOffer, setEndDateOffer] = useState("");

    const [stDate1, setStDate1] = useState("");
    const [endDate1, setEndDate1] = useState("");

    const [stDate2, setStDate2] = useState("");
    const [endDate2, setEndDate2] = useState("");

    const [dateString, setDateString] = useState("");

    const [arrival, setArrival] = useState("");
    const [departure, setDeparture] = useState("");
    const [policyName, setPolicyName] = useState("");
    const [perDayRate, setPerDayRate] = useState("");
    const [dailyRate, setDailyRate] = useState("");
    const [weeklyRate, setWeeklyRate] = useState("");
    const [monthlyRate, setMonthlyRate] = useState("");
    const [distanceRate, setDistanceRate] = useState("");
    const [distanceUnit, setDistanceUnit] = useState("km");
    const [engineRate, setEngineRate] = useState("");
    const [quantity, setQuantity] = useState("");
    const [percentage, setPercentage] = useState("");
    const [days, setDays] = useState(0);
    const [durationPrice, setDurationPrice] = useState(0);
    const [movePrice, setMovePrice] = useState("");

    const [equipImagesPath, setEquipImagesPath] = useState<any>([]);

    const [distance, setDistance] = useState(dDays);
    const [engineUnitUsage, setEngineUnitUsage] = useState(eHours);
    const [engineUnitUsagePrice, setEngineUnitUsagePrice] = useState(0);

    const [eDisRate, setEDisRate] = useState("");
    const [priceKm, setPriceKm] = useState("");

    const [departureCity, setDepartureCity] = useState("");
    const [departureState, setDepartureState] = useState("");

    const [arrivalCity, setArrivalCity] = useState("");
    const [arrivalState, setArrivalState] = useState("");


    const [owner, setOwner] = useState("");
    const [renter, setRenter] = useState("");
    const [minInvoiceAmount, setMinInvoiceAmount] = useState(null);
    const [cad, setCAD] = useState("");
    const [percent, setPercent] = useState("");
    const [claims, setClaims] = useState("");
    const [allowOffer, setAllowOffer] = useState("");

    const [distancePrice, setDistancePrice] = useState(0);
    const [serviceFreePrice, setServiceFreePrice] = useState(0);
    const [totalAmout, setTotalAmount] = useState("");
    const [repositionPrice, totalRepositionPrice] = useState("");
    const [platformFees, setPlatformFees] = useState<any>("");
    const [insuranceAmount, setTotalInsurAmount] = useState("");
    const [insurancePercent, setInsurPercent] = useState("");

    const [isLiabilityVisible, setIsLiabilityVisible] = useState(false);
    const [isPhysicalDamageVisible, setPhysicalDamageVisible] = useState(false);

    const [rdurationPrice, totalRDurationPrice] = useState(0);
    const [rextradays, totalExtraDays] = useState(0);
    const [addtionalPrice, setAdditionalPrice] = useState("");
    const [defPrice, setDefPrice] = useState("");
    const [isFavourExit, setFavour] = useState(false);

    const [parkingLots, setParkingLots] = useState<any>([]);
    const [selDepartureObj, setSelDepartureObj] = useState<any>(null);
    const [selArrivalObj, setSelArrivalObj] = useState<any>(null);

    const [noofDays, setNoOfDays] = useState("");
    const [paidPrice, setPaidPrice] = useState("");
    //const [additionalPrice,setAdditionalPrice] = useState("");
    const [estimatedDistance, setEstimatedDistance] = useState("");
    const [pricepermile, setPricePerMile] = useState("");

    const numDayReposRef = useRef<HTMLInputElement>(null);
    const [numDayReposPopup, setNumDayReposPopup] = useState("");

    const paidPricePopupRef = useRef<HTMLInputElement>(null);
    const [paidPricePopup, setPaidPricePopup] = useState("");

    const additionalPricePopupRef = useRef<HTMLInputElement>(null);
    const [additionalPricePopup, setAdditionalPricePopup] = useState("");

    const estimatedDisPopupRef = useRef<HTMLInputElement>(null);
    const [estimatedDisPopup, setEstimatedDistancePopup] = useState("");

    const pricePerMilePopupRef = useRef<HTMLInputElement>(null);
    const [pricePerMilePopup, setPricePerMilePopup] = useState("");

    const [ratingCount, setRatingCount] = useState("");
    const [overAllRate, setOverAllRate] = useState("");

    const [insurPlans, setInsurPlans] = useState([]);
    const [selPolicyObj, setSelPolicyObj] = useState<any>(null);
    const [selPolicyObjOffer, setSelPolicyObjOffer] = useState<any>(null);


    const [perdayRate, setRatePerDay] = useState("");
    const [perdayRateRental, setRatePerDayRental] = useState("");



    const [isInuranceChecked, setInsuranceChecked] = useState(false);

    const [isInsuranceMandatory, setInsuranceMandatory] = useState(false);
    const [isInuranceOwnerChecked, setInsuranceOwnerChecked] = useState(false);
    const [isInuranceRenterChecked, setInsuranceRenterChecked] = useState(false);
    const [isLiabilityChecked, setLiabilityChecked] = useState(false);
    const [isPhysicalDamageChecked, setPhysicalDamageChecked] = useState(false);

    const [selPhysicalDamage, setSelPhysicalDamage] = useState<any>(null);
    const [selLiability, setSelLiability] = useState<any>(null);

    const [liabilityPaidByOwner, setLiabilityPaidByOwner] = useState<any>(false);
    const [liabilityPaidByRenter, setLiabilityPaidByRenter] = useState<any>(false);
    const [physicalDamagePaidByOwner, setPhysicalDamagePaidByOwner] = useState<any>(false);
    const [physicalDamagePaidByRenter, setPhysicalDamagePaidByRenter] = useState<any>(false);

    const [liabilityPlan, setLiabilityPlan] = useState("");
    const [physicalDamagePlan, setPhysicalDamagePlan] = useState("");

    const [liabilityPlanPerDay, setLiabilityPlanPerDay] = useState<any>("");
    const [physicalDamagePlanPerDay, setPhysicalDamagePlanPerDay] = useState<any>("");

    const [insAssCoverOwner, setInsAssCoverOwner] = useState("");
    const [insCivilOnwer, setInsCivilOnwer] = useState("");
    const [carrierCoverOwner, setCarrierCoverOwner] = useState("");
    const [contentCargoOwner, setContinentCargoOwner] = useState("");

    const [insAssCoverRenter, setInsAssCoverRenter] = useState("");
    const [insCivilRenter, setInsCivilRenter] = useState("");
    const [carrierCoverRenter, setCarrierCoverRenter] = useState("");
    const [contentCargoRenter, setContinentCargoRenter] = useState("");

    const [insurStatus, setInsurStatus] = useState(false);
    const [insurStatusPhy, setInsurStatusPhy] = useState(false);
    const [insurCheckStatus, setInsurCheckStatus] = useState(false);
    const [isPublicSearch, setPublicSearch] = useState(false);

    const [imageIdx, setImageIdx] = useState(0);
    const [gallary, setGallary] = useState([]);
    const [imageEquipPath, setEquipImagePath] = useState("");
    const [modalTitle, setModalTile] = useState("");

    const [imageModalShow, setImageMoalShow] = useState(false);
    const [orgName, setOrgName] = useState("");
    const [isOwner, setIsOwner] = useState(false);
    const [mesurement, setMesurment] = useState("");

    const [isLogin, setIsLogin] = useState("true");
    const [isLogout, setIsLogout] = useState("false");

    const [rDays, setRDays] = useState(0);

    const [terms,setTerms] = useState(false);
    const [agreement,setAgreement] = useState(false);
    const [termsFile,setTermsFile] = useState("");
    const [agreementFile,setAgreementFile] = useState("");
    const [isTncConfig,setIsTncConfig] = useState(false);
    const [dailyRatePlanType, setDailyRatePlanType] = useState<any>("")//Daily,Monthly,Weekly


    const navigate = useNavigate();

    const usInsuranceRate = '3.75';
    const canadaInsuranceRate = '4.75';
    let insuranceMandatoryOrg = false;
    const REPOSITION = "Reposition";
    const RENTAL = "Rental";
    const USA = "United States";
    const CANADA = "Canada";

    useEffect(() => {
        saagOrgId = "";
        isPublicSearchEnable = false;
        if (localStorage && localStorage.getItem("language") != null) {
            CommonUtil.SELECTED_LANGUAGE = localStorage.getItem("language") as string;
        }
        if (localStorage.getItem("PublicSearch") == "true") {
            isPublicSearchEnable = true;

            setPublicSearch(true);
            getPublicEquipmentDetails();
            if (CommonUtil.getSelectedOrgId()) {
                setIsLogin("false");
                setIsLogout("true");
            }
        } else {
            isPublicSearchEnable = false;
            setPublicSearch(false);
            getEquipmentDetails();
            getFavoriteById();
        }
        localStorage.setItem("PublicSearch", "false");
        if(!isPublicSearchEnable)
            getOrganizationCloseNetworks();

    }, []);


    const onChangeCloseNetwork = (dataObj: any) => {        
        setSelectedcloseNetwork(dataObj);
        // CommonUtil.isDropDownMandatory(dataObj, setAvlCarrierClass);
        // (dataObj) ? setAvlCarrierClass('select-box-valid') : setAvlCarrierClass('select-box-invalid');
    }

    let getOrgInsurancePlanList = () => {
        // saagOrgId = 16;
        let equipAllAPI: string = 'api/insurance/orgPolicies?orgId=' + saagOrgId;//CommonUtil.getSelectedOrgId();
        let obj: any = {};
        NetworkManager.sendJsonRequest(equipAllAPI, '', '', 'GET', true, onGetOrgInsurancePolicy, onLoginError);
    }
    let onGetOrgInsurancePolicy = (dataObj: any) => {
        insurPolicies = [];
        if (dataObj) {
            var restData = CommonUtil.parseData(dataObj);
            if (restData) {
                setInsuranceChecked((restData.isInsuranceEnabled == "Y" ? true : false));
                setInsuranceOwnerChecked((restData.isInsurancePaidByOwner == "Y" ? true : false));
                setInsuranceRenterChecked((restData.isInsurancePaidByRenter == "Y" ? true : false));
                setLiabilityChecked((restData.isLiabilityEnabled == "Y" ? true : false));
                setPhysicalDamageChecked((restData.isPhysicalDamageEnabled == "Y" ? true : false));

                setInsuranceMandatory((restData.insuranceMandatory == "Y" ? true : false));
                insuranceMandatoryOrg = (restData.insuranceMandatory == "Y" ? true : false);

                isInsuranceEnabled = restData.isInsuranceEnabled == "Y" ? true : false;
                if (!isInsuranceEnabled) {
                    setInsuranceChecked(true);
                    setInsuranceRenterChecked(true);
                }


                /*if(restData.organizationInsuranceAssociation){
                    let arr = [];
                    if(Array.isArray(restData.organizationInsuranceAssociation)){
                        arr = restData.organizationInsuranceAssociation;
                    }else{
                        arr.push(restData.organizationInsuranceAssociation);
                    }
                    arr.forEach((item:any,idx:number)=>{
                        insurPolicies.push(item);
                    })
                }*/
            }
        }

        setInsurPlans(insurPolicies);
        getOrgServiceFees();
    }


    let getOrgServiceFees = () => {
        let listAPI: string = "api/organization/getServiceFees?saasOrgId=" + saagOrgId;//CommonUtil.getSaasOrgId();
        NetworkManager.sendJsonRequest(listAPI, "", "", "GET", true, onGetEquipmentTypePricing, onLoginError);

        getOrganizationDetails();
    }

    let onGetEquipmentTypePricing = (dataObj: any) => {
        if (dataObj) {
            var restData = CommonUtil.parseData(dataObj);
            if (restData) {
                setOwner(restData.ownerRentalPercentage);
                servieFee = restData.renterRentalPercentage;
                if (restData.ownerRepositioningMinimumCost) {
                    ownerRepositioningMinimumCost = restData.ownerRepositioningMinimumCost;
                }
                setRenter(restData.renterRentalPercentage);
                setCAD(restData.ownerRepositioningMinimumCost);
                setPercent(restData.ownerRepositioningPercentage);
                setClaims(restData.claimFeePercentage);
                setMinInvoiceAmount(restData?.minimumInvoicingAmount);
            }
        }
        getAvailabilityDetails();
    }

    let getOrganizationDetails = () => {
        var orgUrl = 'api/organization/' + saagOrgId;
        NetworkManager.sendJsonRequest(orgUrl, '', '', 'GET', true, onOrgDetailsSuccess, onLoginError)
    }
    let onOrgDetailsSuccess = (dataObj: any) => {
        if (dataObj) {
            var restData = CommonUtil.parseData(dataObj);
            if (restData) {
                let mesure = CommonUtil.getMesurementByCountryName(restData.country);
                setMesurment(mesure);
            }
        }
    }

    let getPublicEquipmentDetails = () => {
        attchIdx = 0;
        equiImages = [];

        if (CommonUtil.SELECT_EQUIP_ID) {
            let equipmentAPI: string = "api/session/equipment/" + CommonUtil.SELECT_EQUIP_ID;
            let obj: any = {};
            NetworkManager.sendJsonRequest(equipmentAPI, "", "", "GET", true, onGetEquipmentDetails, onLoginError);
        }
    }

    let getEquipmentDetails = () => {
        attchIdx = 0;
        equiImages = [];

        if (CommonUtil.SELECT_EQUIP_ID) {
            let equipmentAPI: string = "api/equipment/" + CommonUtil.SELECT_EQUIP_ID;
            let obj: any = {};
            NetworkManager.sendJsonRequest(equipmentAPI, "", "", "GET", true, onGetEquipmentDetails, onLoginError);
        }
    }

    let getPublicAvailabilityDetails = () => {
        if (CommonUtil.SELECT_EQUIP_AVAIL_ID) {
            let parkingAPI: string = "api/session/availability/" + CommonUtil.SELECT_EQUIP_AVAIL_ID + "?saasOrgId=" + CommonUtil.getSaasOrgId();
            NetworkManager.sendJsonRequest(parkingAPI, "", "", "GET", true, onGetAvailabilityDetails, onLoginError);
        }
    }

    let getAvailabilityDetails = () => {
        if (CommonUtil.SELECT_EQUIP_AVAIL_ID) {
            let parkingAPI: string = "api/availability/" + CommonUtil.SELECT_EQUIP_AVAIL_ID + "?saasOrgId=" + CommonUtil.getSaasOrgId();
            NetworkManager.sendJsonRequest(parkingAPI, "", "", "GET", true, onGetAvailabilityDetails, onLoginError);
        }
    }

    let getFavoriteById = () => {
        if (CommonUtil.SELECT_EQUIP_AVAIL_ID) {
            let parkingAPI: string = "api/favorite/listing/" + CommonUtil.SELECT_EQUIP_AVAIL_ID + "?saasOrgId=" + CommonUtil.getSaasOrgId();
            NetworkManager.sendJsonRequest(parkingAPI, "", "", "GET", true, onGetFavoriteExist, onLoginError);
        }

    }

    let onGetFavoriteExist = (dataObj: any) => {
        if (dataObj) {
            var restData = CommonUtil.parseData(dataObj);
            if (restData && restData.equipmentListingId) {
                setFavour(true);
            } else {
                setFavour(false);
            }
        } else {
            setFavour(false);
            //const [isFavourExit,setFavour] = useState("");
        }
    }

    let getOrganizationCloseNetworks = () => {
        var saasOrg = CommonUtil.getSaasOrgId();
        let avalAPI: string = 'api/organization/getOrganizationCloseNetworks?saasOrgId=' + saasOrg;//CommonUtil.getSelectedOrgId();
        NetworkManager.sendJsonRequest(avalAPI, '', '', 'GET', true, (dataObj: any) => {
            if (dataObj) {
                const restData: any = CommonUtil.parseData(dataObj);
                const ddData:any = restData?.organizationCloseNetworks.map((ele:any)=>{
                    let obj = {...ele,renterOrgName:ele.orgName,renterOrgId:ele.networkOrgId}
                    return obj;
                })
                setCloseNetworkList( ddData|| [])
            }
        }, onLoginError)
    }

    let conversionRateValue = 1;

    let onGetAvailabilityDetails = (dataObj: any) => {
        if (dataObj) {
            var restData = CommonUtil.parseData(dataObj);
            if (restData) {
                CommonUtil.EQUIP_AVAIL_TYPE = restData.listingType;
                setAVailType(restData.listingType);

                let aStDate = CommonUtil.getServerDate(restData.startDate);
                let aEdDate = CommonUtil.getServerDate(restData.endDate);
                //1243

                setTermsFile(restData.tncFile);
                setAgreementFile(restData.agreementFile);
                setIsTncConfig(restData.agreementConfig || restData.agreementCinfig);

                setStDate(restData.startDate);
                setEndDate(restData.endDate);

                setStDateOffer(restData.startDate);
                setEndDateOffer(restData.endDate);

                setStDate1(restData.startDate);
                setEndDate1(restData.endDate);

                // setConversionRate(restData.conversionRate);
                conversionRateValue = restData.conversionRate || 1;

                setDnuConditions(restData.dnuConditions ? restData.dnuConditions : "-");
                setDnuEqpConditions(restData.dnuEqpConditions ? restData.dnuEqpConditions : "-");
                setPlatformFeePaidBy(restData.platformFeePaidBy ? restData.platformFeePaidBy : "");

                if (aStDate && aEdDate) {
                    let searchDBFromDate = localStorage.getItem("startDate");
                    let searchDBEndDate = localStorage.getItem("endDate");
                    let sDate = null;
                    let eDate = null;
                    let isFromDateMax: boolean = false;
                    let isEndDateMax: boolean = false;
                    if (searchDBFromDate) {
                        sDate = CommonUtil.getServerDate(searchDBFromDate);
                        if (sDate && (sDate.getTime() >= aStDate.getTime())) {
                            isFromDateMax = true;

                        }
                    }
                    if (searchDBEndDate) {
                        eDate = CommonUtil.getServerDate(searchDBEndDate);
                        if (eDate && (eDate.getTime() <= aEdDate.getTime())) {
                            isEndDateMax = true;
                        }
                    }
                    if (stDateRef.current) {
                        if (isFromDateMax && sDate) {
                            setStDate(CommonUtil.getOfferDate(sDate));
                            setStDate1(CommonUtil.getOfferDate(sDate));

                            stDateRef.current.flatpickr.setDate(sDate);
                        } else {
                            stDateRef.current.flatpickr.setDate(aStDate);
                        }
                        //new Date(restData.startDate));
                        stDateRef.current.flatpickr.set("minDate", aStDate);//new Date(restData.startDate));
                        stDateRef.current.flatpickr.set("maxDate", aEdDate);//new Date(restData.endDate));
                    }
                    if (endDateRef.current) {
                        //endDateRef.current.flatpickr.setDate(aEdDate);//new Date(restData.endDate));
                        if (isEndDateMax && eDate) {
                            setEndDate(CommonUtil.getOfferDate(eDate));//.toString());
                            setEndDate1(CommonUtil.getOfferDate(eDate));//.toString());
                            endDateRef.current.flatpickr.setDate(eDate);
                        } else {
                            endDateRef.current.flatpickr.setDate(aEdDate);
                        }
                        endDateRef.current.flatpickr.set("minDate", aStDate);//new Date(restData.startDate));
                        endDateRef.current.flatpickr.set("maxDate", aEdDate);//new Date(restData.endDate));
                    }

                    if (stDateRef1.current) {
                        if (isFromDateMax && sDate) {
                            setStDate(CommonUtil.getOfferDate(sDate));//.toString());
                            setStDate1(CommonUtil.getOfferDate(sDate));//.toString());
                            stDateRef1.current.flatpickr.setDate(sDate);
                        } else {
                            stDateRef1.current.flatpickr.setDate(aStDate);
                        }//new Date(restData.startDate));
                        stDateRef1.current.flatpickr.set("minDate", aStDate);//new Date(restData.startDate));
                        stDateRef1.current.flatpickr.set("maxDate", aEdDate);//new Date(restData.endDate));
                    }
                    if (endDateRef1.current) {
                        if (isEndDateMax && eDate) {
                            setEndDate(CommonUtil.getOfferDate(eDate));//.toString());
                            setEndDate1(CommonUtil.getOfferDate(eDate));//.toString());
                            endDateRef1.current.flatpickr.setDate(eDate);
                        } else {
                            endDateRef1.current.flatpickr.setDate(aEdDate);
                        }
                        //endDateRef1.current.flatpickr.setDate(aEdDate);//new Date(restData.endDate));
                        endDateRef1.current.flatpickr.set("minDate", aStDate);//new Date(restData.startDate));
                        endDateRef1.current.flatpickr.set("maxDate", aEdDate);//new Date(restData.endDate));
                    }

                    if (stDateRef2.current) {
                        stDateRef2.current.flatpickr.setDate(aStDate);//new Date(restData.startDate));
                    }
                    if (edDateRef2.current) {
                        edDateRef2.current.flatpickr.setDate(aEdDate);//new Date(restData.endDate));
                    }

                    if (stDateAvlRepRef.current) {
                        stDateAvlRepRef.current.flatpickr.setDate(aStDate);//new Date(restData.startDate));
                    }
                    if (endDateAvlRepRef.current) {
                        endDateAvlRepRef.current.flatpickr.setDate(aEdDate);//new Date(restData.endDate));
                    }
                    if (stDateAvlRepRef1.current) {
                        stDateAvlRepRef1.current.flatpickr.setDate(aStDate);//new Date(restData.startDate));
                    }
                    if (endDateAvlRepRef1.current) {
                        endDateAvlRepRef1.current.flatpickr.setDate(aEdDate);//new Date(restData.endDate));
                    }

                }


                setArrival(restData.arrivalParkingLotAddress);
                setDeparture(restData.departureParkingLotAddress);

                setPolicyName(restData.policyName);
                setPerDayRate(restData.insurancePerDayAmount);

                setDateString(restData.startDate + " - " + restData.endDate);
                setDailyRate(restData.dailyRate);
                setWeeklyRate(restData.weeklyRate);
                setMonthlyRate(restData.monthlyRate);
                dailyPriceRate = Number(restData.dailyRate);
                setDistanceRate(restData.distanceRate);
                distancePriceRate = Number(restData.distanceRate);
                setQuantity(restData.quantity);
                setAllowOffer(restData.allowOffer);
                //updating from costimating setMovePrice(restData.movePrice);

                enginePrice = restData.engineRate;
                setEngineRate(restData.engineRate);

                setNoOfDays(restData.noOfDaysAllowed);
                setPaidPrice(restData.movePrice);

                setEDisRate(restData.estimatedDistance);
                setPriceKm(restData.pricePerKm);
                setAdditionalPrice(restData.dailyRate);
                setOrgName(restData.orgName);

                setIsOwner(false);
                if (CommonUtil.getSelectedOrgId() == restData.saasOrgId || CommonUtil.isRootUser()) {
                    setIsOwner(true);
                }

                liabilityRateId = restData.ratePlanIdLiability;
                physicalDamageRateId = restData.ratePlanIdPhysical;

                setSelLiability({ policyId: restData.policyIdLiability, policy: restData.policyNameLiability });
                setSelPhysicalDamage({ policyId: restData.policyIdPhysical, policy: restData.policyNamePhysical });

                if (isPublicSearchEnable) {
                    setInsuranceChecked(false);
                    if (restData.isInsurancePaidByOwnerLiability == "Y" || restData.isInsurancePaidByRenterLiability == "Y" || restData.isInsurancePaidByOwnerPhysical == "Y" || restData.isInsurancePaidByRenterPhysical == "Y") {
                        setInsuranceChecked(true);
                    }
                    if (restData.isInsurancePaidByRenterLiability == "Y" || restData.isInsurancePaidByRenterPhysical == "Y") {
                        setInsuranceRenterChecked(true)
                    }
                    setInsurStatus(true);
                    setInsurStatusPhy(true);
                    // isInuranceRenterChecked
                }


                setLiabilityPaidByOwner((restData.isInsurancePaidByOwnerLiability == "Y") ? true : false);
                setLiabilityPaidByRenter((restData.isInsurancePaidByRenterLiability == "Y") ? true : false);
                setPhysicalDamagePaidByOwner((restData.isInsurancePaidByOwnerPhysical == "Y") ? true : false);
                setPhysicalDamagePaidByRenter((restData.isInsurancePaidByRenterPhysical == "Y") ? true : false);

                isLiabilityPaidByRenter = false;
                isPhysicalDamagePaidByRenter = false;
                if (restData.isInsurancePaidByRenterLiability == "Y") {
                    isLiabilityPaidByRenter = true;
                }
                if (restData.isInsurancePaidByRenterPhysical == "Y") {
                    isPhysicalDamagePaidByRenter = true;
                }


                setLiabilityPlan(restData.policyNameLiability);
                setPhysicalDamagePlan(restData.policyNamePhysical);
                
                if(restData.defaultCurrency === "CAD"){
                    setLiabilityPlanPerDay(restData.liabilityRateAfterConversion);
                    setPhysicalDamagePlanPerDay(restData.physcialRateAfterConversion);
                    liabilityInsuracePerDayAmount = restData.liabilityRateAfterConversion;
                    physicalDamagePerDayAmount = restData.physcialRateAfterConversion;
                }else{
                    setLiabilityPlanPerDay(restData.insurancePerDayAmountLiability);
                    setPhysicalDamagePlanPerDay(restData.insurancePerDayAmountPhysical);
                    liabilityInsuracePerDayAmount = restData.insurancePerDayAmountLiability;
                    physicalDamagePerDayAmount = restData.insurancePerDayAmountPhysical;
                }                

                setDepartureCity(restData.departureCity);
                setDepartureState(restData.departureState);

                setArrivalCity(restData.arrivalCity);
                setArrivalState(restData.arrivalState);

                setArrival((restData.arrivalCity + ", " + restData.arrivalState));
                setDeparture((restData.departureCity + ", " + restData.departureState));


                if (chkLiability.current) {
                    chkLiability.current.checked = true;
                }
                if (chkLiabilityRep.current) {
                    chkLiabilityRep.current.checked = true;
                }

                if (chkPhysicalDamage.current) {
                    chkPhysicalDamage.current.checked = true;
                }

                if (chkPhysicalDamageRep.current) {
                    chkPhysicalDamageRep.current.checked = true;
                }

                if (chkLiabilityOffer.current) {
                    chkLiabilityOffer.current.checked = true;
                }
                if (chkPhysicalDamageOffer.current) {
                    chkPhysicalDamageOffer.current.checked = true;
                }


                /*let endDate = new Date(restData.endDate);
                endDate.setHours(23,59,59);
                let endMs = 0;
                if(endDate){
                    endMs = endDate.getTime();
                }
                let stDate = new Date(restData.startDate);
                stDate.setHours(0,0,0);
                let stMs = 0;
                if(stDate){
                    stMs = stDate.getTime();
                }*/
                diffDays = CommonUtil.getReservationDays(restData.startDate, restData.endDate);//endMs-stMs)/(24*3600*1000);
                //days = Math.ceil(days);
                setDays(diffDays);

                setTimeout(() => {
                    if (isInsuranceEnabled) {
                        calculatePrice();
                    }

                }, 1000);

                //let price = diffDays*Number(restData.dailyRate);
                //setDurationPrice(price);

                setDefPrice(restData.defaultCurrency);
                defaultCurrency = restData.defaultCurrency;
                // if(restData.listingType === RENTAL){
                //     defaultCurrency = String(restData.departureCountry).toUpperCase() === String(USA).toUpperCase() ? "USD" : String(restData.departureCountry).toUpperCase() === String(CANADA).toUpperCase() ? "CAD" : "";
                // }else {
                //     defaultCurrency = String(restData.arrivalCountry).toUpperCase() === String(USA).toUpperCase() ? "USD" : String(restData.arrivalCountry).toUpperCase() === String(CANADA).toUpperCase() ? "CAD" : "";
                // }

                if (restData.defaultCurrency == "CAD") {
                    $("#rdRentalCad").addClass("active");
                    $("#rdRepositionCad").addClass("active");
                } else {
                    $("#rdRentalUS").addClass("active");
                    $("#rdRepositionUS").addClass("active");
                }

                if (restData.listingStatus == CommonUtil.EQUIP_AVAILABILITY_STATUS_IN_PROGRESS || restData.listingStatus == CommonUtil.EQUIP_AVAILABILITY_STATUS_OPEN) {

                } else {
                    $("#btnConfirmReserve").hide();
                    $("#btnConfirmReserve1").hide();
                }

                // setCloseNetworkList(restData.specificNetworks)
                setAvailabilityViewType(restData.availabilityViewType)


            }
        }

        if (localStorage.getItem("offerPopup") && localStorage.getItem("offerPopup") == "true") {
            localStorage.setItem("offerPopup", "false");
            onClickMakeOffer();
        }

        setSelPolicyObj({ id: restData.policyId, policyId: getInsurPolicyById(restData.policyId) });

        setSelPolicyObjOffer({ id: restData.policyId, policyId: getInsurPolicyById(restData.policyId) });
        if (!isPublicSearchEnable) {
            getOwnerInsuranceDetails();

            if (!isInsuranceEnabled) {
                //setLiabilityPaidByOwner((restData.isInsurancePaidByOwnerLiability == "Y")?true:false);
                setLiabilityPaidByRenter(true);
                //setPhysicalDamagePaidByOwner((restData.isInsurancePaidByOwnerPhysical == "Y")?true:false);
                setPhysicalDamagePaidByRenter(true);
                let equipAllAPI: string = 'api/insurance/allPolicy';
                let obj: any = {};
                NetworkManager.sendJsonRequest(equipAllAPI, '', '', 'GET', true, (dataObj: any) => {
                    if (dataObj) {
                        let polacies = []
                        var restData = CommonUtil.parseData(dataObj)
                        if (restData) {
                            if (Array.isArray(restData)) {
                                polacies = restData
                            } else {
                                polacies.push(restData)
                            }
                        }

                        if (polacies.length > 0) {
                            polacies.forEach((insItem: any, idx: number) => {
                                if (insItem.isDefault == "Y") {
                                    if (insItem.insurancePlanType == CommonUtil.PHYSICAL_DAMAGE) {
                                        isPhysicalDamagePaidByRenter = true;
                                        console.log(insItem);
                                        
                                        physicalDamageRateId = insItem.id;
                                        // estimatedValue= insItem.estimatedValue;
                                        setPhysicalDamagePlan(insItem.policyId);
                                        // if(defaultCurrency === "CAD")
                                            physicalDamagePerDayAmount = parseFloat(Number(insItem.perDayInsuranceValue * conversionRateValue).toFixed(2));
                                        // else
                                        //     physicalDamagePerDayAmount = insItem.perDayInsuranceValue;
                                        
                                        setPhysicalDamagePlanPerDay(physicalDamagePerDayAmount);
                                    } else if (insItem.insurancePlanType == CommonUtil.LIABILITY) {
                                        isLiabilityPaidByRenter = true;
                                        liabilityRateId = insItem.id;
                                        liabilityInsuracePerDayAmount = insItem.perDayInsuranceValue;
                                        setLiabilityPlan(insItem.policyId);
                                        // if(defaultCurrency === "CAD")
                                            liabilityInsuracePerDayAmount = parseFloat(Number(insItem.perDayInsuranceValue * conversionRateValue).toFixed(2));
                                        // else
                                        //     liabilityInsuracePerDayAmount = insItem.perDayInsuranceValue;
                                        setLiabilityPlanPerDay(liabilityInsuracePerDayAmount);    
                                    }
                                }
                            });
                            // if(defaultCurrency === "CAD")
                                getDefaultRate()
                            // if(defaultCurrency === "CAD"){
                            //     let obj = {
                            //         liabilityInsurancePaidByRenter : isLiabilityPaidByRenter ? 'Y' : 'N',
                            //         physicalInsurancePaidByRenter : isPhysicalDamagePaidByRenter ? 'Y' : 'N',
                            //         liabilityRateAfterConversion: liabilityInsuracePerDayAmount,
                            //         physcialRateAfterConversion: physicalDamagePerDayAmount
                            //     }
                            //     setConversionObj(obj);
                            // }
                        }
                    }
                    if (!isInsuranceEnabled) {
                        calculatePrice();
                    }

                }, onLoginError);
            }
        }
    }

    let getOwnerInsuranceDetails = () => {
        let insurenceAPI: string = "api/organization/finance/getInsuranceDetails?saasOrgId=" + saagOrgId;
        NetworkManager.sendJsonRequest(insurenceAPI, "", "", "GET", true, onGetOwnerInsureceInfo, onLoginError);
    }
    let onGetOwnerInsureceInfo = (dataObj: any) => {
        if (dataObj) {
            var restData = CommonUtil.parseData(dataObj);
            if (restData) {
                setInsAssCoverOwner(restData.minCoverageNonOwnedAssets);
                setInsCivilOnwer(restData.minCoverageCivil);

                setCarrierCoverOwner(restData.carrierRequiredCoverage);
                setContinentCargoOwner(restData.contingentCargo);

                if (restData.carrierRequiredCoverage) {
                    ownerCoverageInsurace = Number(restData.carrierRequiredCoverage);
                }else{
                    ownerCoverageInsurace = Number(CommonUtil.MIN_OWNER_COVERAGE);                
                }

            }
        }

        getRenterInsuranceDetails();
    }

    let getRenterInsuranceDetails = () => {
        let insurenceAPI: string = "api/organization/finance/getInsuranceDetails?saasOrgId=" + CommonUtil.getSaasOrgId();
        NetworkManager.sendJsonRequest(insurenceAPI, "", "", "GET", true, onGetRenterInsureceInfo, onLoginError);
    }
    let onGetRenterInsureceInfo = (dataObj: any) => {
        if (dataObj) {
            var restData = CommonUtil.parseData(dataObj);
            if (restData) {
                setInsAssCoverRenter(restData.minCoverageNonOwnedAssets);
                setInsCivilRenter(restData.minCoverageCivil);

                setCarrierCoverRenter(restData.carrierRequiredCoverage);
                setContinentCargoRenter(restData.contingentCargo);

                if (restData.minCoverageNonOwnedAssets) {
                    renterCoverageInsurance = Number(restData.minCoverageNonOwnedAssets);
                }

            }
        }
console.log(renterCoverageInsurance," >= ",ownerCoverageInsurace);

        if (insuranceMandatoryOrg === true) {
            setInsurStatus(true);
            setInsurStatusPhy(true)
        //according to the 3941 point 6 non-asset owner field is lesser than the estimated value of the equipment the insurance must be mandatory
        } 
        
        if (renterCoverageInsurance >= estimatedValue) {
            setInsurStatusPhy(false)
        } else {
            setInsurStatusPhy(true)
        }

    
        if (restData.minCoverageCivil >= ownerCoverageInsurace){
            setInsurStatus(false);
        } else {
            setInsurStatus(true);
        }
        
    }

    let getInsurPolicyById = (pId: string) => {
        for (let i = 0; i < insurPolicies.length; i++) {
            if (insurPolicies[i].id == pId) {
                return insurPolicies[i].policyId;
            }
        }
        return "";
    }
    let calculatePrice = () => {

        var sDate = null;
        var eDate = null;

        if (CommonUtil.EQUIP_AVAIL_TYPE == CommonUtil.EQUIP_AVAIL_RENTAL_TYPE) {
            if (stDateRef.current) {
                sDate = stDateRef.current.flatpickr.selectedDates[0];
            }
            if (endDateRef.current) {
                eDate = endDateRef.current.flatpickr.selectedDates[0];
            }

        } else {
            if (stDateRef1.current) {
                sDate = stDateRef1.current.flatpickr.selectedDates[0];
            }
            if (endDateRef1.current) {
                eDate = endDateRef1.current.flatpickr.selectedDates[0];
            }
        }

        let rDays = CommonUtil.getDays(sDate, eDate);
        setRDays(rDays);
        var qParams: string = "availabilityId=" + CommonUtil.SELECT_EQUIP_AVAIL_ID;
        qParams = qParams + "&distancePerDay=" + dDays;
        if (eDate) {
            qParams = qParams + "&endDate=" + CommonUtil.getReservationDateString(eDate);
        }
        qParams = qParams + "&saasOrgId=" + CommonUtil.getSaasOrgId();
        if (sDate) {
            qParams = qParams + "&startDate=" + CommonUtil.getReservationDateString(sDate);
        }
        qParams = qParams + "&enginerHoursPerDay=" + eHours;

        var calculateAPI = "api/availability/costestimate?" + qParams;
        // +"&endDate=44&enginerHoursPerDay=44&saasOrgId=2&startDate=4";

        //let price1 = Number(diffDays)*Number(dailyPriceRate);
        // setDurationPrice(Number(price1.toFixed(2)));

        // let parkingAPI:string = "api/favorite/listing/"+CommonUtil.SELECT_EQUIP_AVAIL_ID+"?saasOrgId="+CommonUtil.getSaasOrgId();
        if (isPublicSearchEnable) {
            calculateAPI = "api/session/availability/costestimate?" + qParams;
        }
        setIsLiabilityVisible(false);
        setPhysicalDamageVisible(false);
        NetworkManager.sendJsonRequest(calculateAPI, "", "", "GET", true, (dataObj: any) => {
            if (dataObj) {
                var restData = CommonUtil.parseData(dataObj);
                if (restData) {
                    setDays(restData.noOfDays);
                    setMovePrice(restData.movePrice)
                    CommonUtil.setCostEstimate(restData);

                    //chkLiabilityRep.current.checked
                    if (CommonUtil.EQUIP_AVAIL_TYPE == CommonUtil.EQUIP_AVAIL_REPOSITION_TYPE) {
                        let totAmt = "";//
                        let repAmount = (Number(restData.movePrice) + Number(restData.durationPrice));
                        let insurAmount = 0;//
                        let insurPer = 0;
                        let newAdminfee: number = Number(String(defaultCurrency).toUpperCase().indexOf("USD") !== -1 ? usInsuranceRate : canadaInsuranceRate);
                        if (isLiabilityPaidByRenter && chkLiabilityRep.current && chkLiabilityRep.current.checked) {
                            setIsLiabilityVisible(true);
                            insurAmount = (rDays * Number(liabilityInsuracePerDayAmount));
                            if (insurAmount) {
                                // insurPer = (insurAmount)*(10/100);
                                insurPer = newAdminfee
                            }
                        }
                        if (isPhysicalDamagePaidByRenter && chkPhysicalDamageRep.current && chkPhysicalDamageRep.current.checked) {
                            setPhysicalDamageVisible(true);
                            insurAmount = insurAmount + (rDays * Number(physicalDamagePerDayAmount));
                            if (insurAmount) {
                                // insurPer = (insurAmount)*(10/100);
                                insurPer = newAdminfee
                            }
                        }


                        setInsurPercent(insurPer ? "-" + insurPer.toFixed(2) : "");
                        setTotalInsurAmount(insurAmount ? "-" + insurAmount.toFixed(2) : "");



                        repAmount = repAmount - (insurAmount + (insurPer));
                        if (repAmount) {
                            if (restData?.extraChargesTotal) {
                                repAmount += Number(restData?.extraChargesTotal)
                            }

                            totAmt = repAmount.toFixed(2);
                        }

                        totalRDurationPrice(restData.extraChargesTotal ? restData.extraChargesTotal : "0");

                        setPlatformFees(Number(restData?.platformFees));

                        totalExtraDays(restData.extraDays ? restData.extraDays : "0");
                        totalRepositionPrice(totAmt);
                    } else {
                        let insurAmount = 0;//
                        let insurPer = 0;
                        let newAdminfee: number = Number(String(defaultCurrency).toUpperCase().indexOf("USD") !== -1 ? usInsuranceRate : canadaInsuranceRate);
                        if (isLiabilityPaidByRenter && chkLiability.current && chkLiability.current.checked) {
                            setIsLiabilityVisible(true);
                            insurAmount = (rDays * Number(liabilityInsuracePerDayAmount));
                            if (insurAmount) {
                                // insurPer = (insurAmount)*(10/100);
                                insurPer = newAdminfee;
                            }
                        }
                        if (isPhysicalDamagePaidByRenter && chkPhysicalDamage.current && chkPhysicalDamage.current.checked) {
                            setPhysicalDamageVisible(true);
                            insurAmount = insurAmount + (rDays * Number(physicalDamagePerDayAmount));
                            if (insurAmount) {
                                // insurPer = (insurAmount)*(10/100);
                                insurPer = newAdminfee;
                            }
                        }
                        setInsurPercent(insurPer ? insurPer.toFixed(2) : "");
                        setTotalInsurAmount(insurAmount ? insurAmount.toFixed(2) : "");
                        
                        setDailyRatePlanType(restData.dailyRatePlanType || "Daily");
                        const durationPriceRate = restData.durationPrice

                        setDistancePrice(restData.distancePrice ? restData.distancePrice.toFixed(2) : "");
                        setDurationPrice(durationPriceRate !== "" ? durationPriceRate.toFixed(2) : "");
                        setServiceFreePrice(restData.ownerServiceFee ? restData.ownerServiceFee.toFixed(2) : "");
                        setEngineUnitUsagePrice(restData.enginePrice ? restData.enginePrice.toFixed(2) : "");

                        let totalPrice = Number(restData.distancePrice) + Number(durationPriceRate) + Number(restData.ownerServiceFee);
                        if (CommonUtil.SELECT_EQUIPMENT_TYPE == CommonUtil.REEFER_EQUIP_TYPE) {
                            if (restData.enginePrice) {
                                totalPrice = totalPrice + Number(restData.enginePrice);
                            }
                        }

                        totalPrice = totalPrice + insurAmount + insurPer;

                        setTotalAmount(totalPrice.toFixed(2));
                        totalRDurationPrice(durationPriceRate ? durationPriceRate.toFixed(2) : "");
                        totalExtraDays(restData.extraDays ? restData.extraDays : "");
                    }

                }
            }
        }, onLoginError);

        //console.log(diffDays,dDays,distancePriceRate)
        /*let price = Number(diffDays) * Number(dDays) * Number(distancePriceRate);
        let dPrice = Number(price).toFixed(2);
        setDistancePrice(Number(dPrice));

        //console.log(price1,price,(Number(servieFee)/100));
        let enPrice:Number = 0;
        if(CommonUtil.SELECT_EQUIPMENT_TYPE == CommonUtil.REEFER_EQUIP_TYPE){
            let engineHourPrice = Number(diffDays) * Number(eHours) * Number(enginePrice);
            enPrice = Number(Number(engineHourPrice).toFixed(2));
            setEngineUnitUsagePrice(Number(enPrice));
        }

        let sPrice = (Number(enPrice)+Number(price1)+Number(price))*(Number(servieFee)/100);
        //sPrice = sPrice*(Number(renter)/100);
        let sPriceNum = Number(sPrice).toFixed(2);
        let ownrSPrice:number = 0;
        if(Number(sPriceNum)>Number(ownerRepositioningMinimumCost)){
            ownrSPrice = Number(sPriceNum);
        }else{
            ownrSPrice = ownerRepositioningMinimumCost;
        }

        setServiceFreePrice(Number(ownrSPrice));



        let totPrice = (Number(enPrice)+Number(price1)+Number(price)+Number(ownrSPrice)).toFixed(2);
        setTotalAmount(Number(totPrice));*/
    }
    let onGetEquipmentDetails = (dataObj: any) => {
        if (dataObj) {
            var restData = CommonUtil.parseData(dataObj);
            if (restData) {
                saagOrgId = restData.saasOrgId;
                CommonUtil.IS_GENERIC_EQUIP = (restData.isGeneric == "Y" ? true : false);
                setRatingCount(restData.ratingCount ? restData.ratingCount : "");
                setOverAllRate(restData.overallRating ? restData.overallRating : "");
                setEquipmentData(restData);
                setEquipType(restData.equipmentType ? restData.equipmentType : "-");
                setEquipName(restData.genericEquipmentName ? restData.genericEquipmentName : "-");
                setTrackingType(restData.trackingType ? restData.trackingType : "-");


                setUnitNumber(restData.unitNumber ? restData.unitNumber : "-");
                setVinNumber(restData.VIN ? restData.VIN : "-");
                setYear(restData.year ? restData.year : "-");
                setModel(restData.model ? restData.model : "-");
                setBrand(restData.brand ? restData.brand : "-");
                setNumOfAxes(restData.numberOfAxles ? restData.numberOfAxles : "-");
                setTrailerLength(restData.lengthOfTrailer ? restData.lengthOfTrailer : "-");
                setELicense(restData.licensePlateNumber ? restData.licensePlateNumber : "-");
                setExpDate(restData.expDate ? restData.expDate : "-");
                setAssetType(restData.assetType ? restData.assetType : "-");
                setExpDate(restData.annualMechanicalInspExpDate ? restData.annualMechanicalInspExpDate : "-");

                setActivative(restData.activationInstructions ? restData.activationInstructions : "-");
                setDeActive(restData.deactivationInstructions ? restData.deactivationInstructions : "-");
                estimatedValue = (restData.estimatedValue ? restData.estimatedValue : 0);
                // setDnuConditions(restData.dnuConditions?restData.dnuConditions:"-");
                // setDnuEqpConditions(restData.dnuEqpConditions?restData.dnuEqpConditions:"-");

                setEmails(restData.additionalEmailIds ? restData.additionalEmailIds : "-");
                setDeposit(restData.securityDeposit ? restData.securityDeposit : "-");

                if (restData.equipmentType == "DRY VAN") {
                    setImagePath("../../images/dryVan.png");
                } else if (restData.equipmentType == "PLATE DRY VAN") {
                    setImagePath("../../images/plateDryVan1.png");
                }
                setImagePath(CommonUtil.getEquipemntImage(restData.equipmentType));
                // getEquipemntImage

                if (restData.specifications) {
                    if (restData.specifications.general) {
                        setAxelSpread(restData.specifications.general.axleSpread ? restData.specifications.general.axleSpread : "-");
                        setTireInflation(restData.specifications.general.tireInflationSystem ? restData.specifications.general.tireInflationSystem : "-");
                        setSkirts(restData.specifications.general.skirts ? restData.specifications.general.skirts : "-");
                        setPintleHook(restData.specifications.general.pintleHook ? restData.specifications.general.pintleHook : "-");
                        setHazmat(restData.specifications.general.hazmatPlacardHolder ? restData.specifications.general.hazmatPlacardHolder : "-");
                        setSuspension(restData.specifications.general.suspensionType ? restData.specifications.general.suspensionType : "-");
                    }
                    if (restData.specifications.trailer) {
                        setRearDoor(restData.specifications.trailer.rearDoor ? restData.specifications.trailer.rearDoor : "-");
                        setSideDoor(restData.specifications.trailer.sideDoor ? restData.specifications.trailer.sideDoor : "-");
                        setRoof(restData.specifications.trailer.roof ? restData.specifications.trailer.roof : "-");
                        setFloor(restData.specifications.trailer.floor ? restData.specifications.trailer.floor : "-");
                        setFloorType(restData.specifications.trailer.floorType ? restData.specifications.trailer.floorType : "-");
                        setInterior(restData.specifications.trailer.interiorFinish ? restData.specifications.trailer.interiorFinish : "-");
                        setLogistics(restData.specifications.trailer.logisticPostsDistance ? restData.specifications.trailer.logisticPostsDistance : "-");
                        setDeckBeams(restData.specifications.trailer.doubleDeckBeams ? restData.specifications.trailer.doubleDeckBeams : "-");
                    }
                    if (restData.specifications.lift) {
                        setLift(restData.specifications.lift.lift);
                        setLiftType(restData.specifications.lift.liftType);
                        setLiftCap(restData.specifications.lift.liftCapacity);
                    }

                    if (restData.specifications.reefer) {
                        setMultiTemp(restData.specifications.reefer.multiTemperature);
                        setUnitModal(restData.specifications.reefer.unitMake);
                        setUnitMake(restData.specifications.reefer.unitModel);
                        setUnitYear(restData.specifications.reefer.unitYear);
                    }
                }

                if (restData.attachments) {
                    attachmentList = []
                    if (Array.isArray(restData.attachments)) {
                        attachmentList = restData.attachments
                    } else {
                        attachmentList.push(restData.attachments)
                    }
                    if (attachmentList.length > 0) {
                        BusyLoader.showLoader()
                        showAttachments()
                    }
                }


            }
        }
        if (!isPublicSearchEnable) {
            if (saagOrgId) {
                getOrgInsurancePlanList();
            }
        } else {
            getPublicAvailabilityDetails();
        }

    }

    let showAttachments = () => {
        if (attachmentList.length > attchIdx) {
            let qParam: string = 'objectPath=' + attachmentList[attchIdx].fullFilePath
            NetworkManager.sendJsonRequest('api/session/downloadUrl', '', qParam, 'GET', false, (dataObj: any) => {
                let obj: any = {}
                obj.Type = attachmentList[attchIdx].documentType
                obj.Path = dataObj
                obj.attachementId = attachmentList[attchIdx].attachementId
                equiImages.push(obj)
                attchIdx = attchIdx + 1
                showAttachments()
            },
                onLoginError,
            )
        } else {
            BusyLoader.hideLoader()
            setEquipImagesPath(equiImages)
        }
    }

    let onClickConfirmReservation = () => {
        if(isOwner &&  (availabilityViewType=="SPECIFIC_VIEW"||availabilityViewType=="PRIVATE_VIEW") ){
            if(closeNetworkList?.length > 0 && selectedcloseNetwork?.renterOrgId == undefined){
                let strConfirm = "Please select Carrier";
                ToastManager.showToast(strConfirm, 'Error');
                return;
            }
        }
        if (!CommonUtil.getSaasOrgId()) {
            let strConfirm = "Please log in to proceed with the booking";
            ToastManager.showToast(strConfirm, 'Info');
        } else {
            let restObj: any = {};
            let sDate = null;
            let eDate = null;
            restObj.policyId = selPolicyObj ? selPolicyObj.id : "";
            restObj.dailyRate = dailyRate;
            restObj.weeklyRate = weeklyRate;
            restObj.monthlyRate = monthlyRate;
            restObj.distanceRate = distanceRate;
            restObj.distanceUnit = distanceUnit;
            restObj.engineRate = engineRate;
            restObj.defaultCurrency = defPrice;
            if(isTncConfig && terms && agreement){
                restObj.tncAccepted = true;
            }
            restObj.equipmentListingId = CommonUtil.SELECT_EQUIP_AVAIL_ID;
            restObj.estimatedDistancePerDay = distance;
            if (CommonUtil.SELECT_EQUIPMENT_TYPE == CommonUtil.REEFER_EQUIP_TYPE) {
                restObj.estimatedEngineHoursPerDay = engineUnitUsage;//eHours;
            }
            //if(CommonUtil.IS_GENERIC_EQUIP)

            if (CommonUtil.EQUIP_RESERVE_IS_GENERIC == "Y") {
                restObj.quantity = "1";//quantity;
            }

            restObj.serviceFeePercentage = renter;
            restObj.additionalPriceExcessDays = addtionalPrice;
            // restObj.additionalPriceExcessDays = addtionalPrice;
            restObj.movePrice = movePrice;
            restObj.noOfDaysAllowed = noofDays;
            restObj.serviceFeePercentage = renter;
            if (availType == CommonUtil.EQUIP_AVAIL_RENTAL_TYPE) {
                //CommonUtil.getReservationDateString(stDate)
                if (stDateRef.current) {
                    sDate = stDateRef.current.flatpickr.selectedDates[0];
                    if (sDate) {
                        restObj.startDate = CommonUtil.getReservationDateString(sDate);
                    }
                }
                if (endDateRef.current) {
                    eDate = endDateRef.current.flatpickr.selectedDates[0];
                    if (eDate) {
                        restObj.endDate = CommonUtil.getReservationDateString(eDate);
                    }
                }
                //restObj.endDate = CommonUtil.getDateString(endDate);
            } else {
                //if(stDateRef1.current){
                if (stDateRef1.current) {
                    sDate = stDateRef1.current.flatpickr.selectedDates[0];
                    if (sDate) {
                        restObj.startDate = CommonUtil.getReservationDateString(sDate);
                    }
                }
                if (endDateRef1.current) {
                    eDate = endDateRef1.current.flatpickr.selectedDates[0];
                    if (eDate) {
                        restObj.endDate = CommonUtil.getReservationDateString(eDate);
                    }
                }
            }

            if (addtionalPrice) {
                restObj.dailyRate = addtionalPrice;
            }
            if (priceKm) {
                restObj.distanceRate = priceKm;
            }

            let lRateId: string = "";
            let pDamageRateId: string = "";
            if (availType == CommonUtil.EQUIP_AVAIL_RENTAL_TYPE) {
                if (chkLiability && chkLiability.current && chkLiability.current.checked) {
                    lRateId = liabilityRateId;
                }
                if (chkPhysicalDamage && chkPhysicalDamage.current && chkPhysicalDamage.current.checked) {
                    pDamageRateId = physicalDamageRateId;
                }
            } else {
                if (chkLiabilityRep && chkLiabilityRep.current && chkLiabilityRep.current.checked) {
                    lRateId = liabilityRateId;
                }
                if (chkPhysicalDamageRep && chkPhysicalDamageRep.current && chkPhysicalDamageRep.current.checked) {
                    pDamageRateId = physicalDamageRateId;
                }
            }

            if (isInuranceChecked) {
                restObj.ratePlanIdLiability = lRateId;
                restObj.ratePlanIdPhysical = pDamageRateId;
            } else {
                restObj.ratePlanIdLiability = "";
                restObj.ratePlanIdPhysical = "";
            }
            if(conversioObj)
            restObj = {...restObj,...conversioObj}
            //Here update on click chekbox
            //Reposition
            //restObj.liabilityInsurancePaidByRenter = chkLiabilityRep?.current?.checked  ? 'Y' : 'N';
            //restObj.physicalInsurancePaidByRenter = chkPhysicalDamageRep?.current?.checked? 'Y' : 'N';
            //VH-3814 
            //Rental
            console.log(CommonUtil.EQUIP_AVAIL_TYPE, chkPhysicalDamage?.current?.checked,chkLiability?.current?.checked);
            console.log(CommonUtil.EQUIP_AVAIL_TYPE, chkLiabilityRep?.current?.checked,chkPhysicalDamageRep?.current?.checked);
            
            const libility = liabilityPaidByRenter ? (CommonUtil.EQUIP_AVAIL_TYPE === "Rental" ? chkLiability?.current?.checked : CommonUtil.EQUIP_AVAIL_TYPE === "Reposition" ? chkLiabilityRep?.current?.checked : null):false;

            const physical = physicalDamagePaidByRenter ? (CommonUtil.EQUIP_AVAIL_TYPE === "Rental" ? chkPhysicalDamage?.current?.checked : CommonUtil.EQUIP_AVAIL_TYPE === "Reposition" ? chkPhysicalDamageRep?.current?.checked : null):false;


            restObj.liabilityInsurancePaidByRenter =  libility ? 'Y' : 'N';
            restObj.physicalInsurancePaidByRenter = physical ? 'Y' : 'N';


            if (sDate && eDate) {
                let parkingAPI: string = "";
                let selectedRenterOrgId =  CommonUtil.getSaasOrgId();
                if(closeNetworkList?.length > 0){
                    if(selectedcloseNetwork?.renterOrgId){
                        selectedRenterOrgId = selectedcloseNetwork?.renterOrgId
                    }
                }
                if (isPublicSearchEnable) {
                    parkingAPI = "api/reservation/validatePublicAvailabilityAndCreateReservation?renterOrgId=" +selectedRenterOrgId;
                } else {
                    let isSearchClicked = localStorage.getItem("isSearchClicked");
                    // removed because public search is acting in the private search 08/05
                    // if (isSearchClicked == "false") {
                    //     parkingAPI = "api/reservation/validatePublicAvailabilityAndCreateReservation?renterOrgId=" +selectedRenterOrgId;
                    // } else {
                        parkingAPI = "api/reservation/?renterOrgId=" +selectedRenterOrgId;
                    // }

                }
                //let parkingAPI:string = "api/reservation/?renterOrgId="+CommonUtil.getSaasOrgId();
                //let parkingAPI:string = "api/reservation/validatePublicAvailabilityAndCreateReservation?renterOrgId="+CommonUtil.getSaasOrgId();
                NetworkManager.sendJsonRequest(parkingAPI, restObj, "", "POST", true, onSaveReservation, onLoginError);
            } else {
                let strConfirm = "Reservation Dates are required to confirm the reservation";
                ToastManager.showToast(strConfirm, 'Info');
            }

        }

    }

    let onChangeDistance = (e: any) => {
        dDays = e.currentTarget.value;
        setDistance(e.currentTarget.value);
        setTimeout(() => {
            calculatePrice();
        }, 1000);
    }

    let onChangeEngineUsage = (e: any) => {
        eHours = e.currentTarget.value;
        setEngineUnitUsage(e.currentTarget.value);
        setTimeout(() => {
            calculatePrice();
        }, 1000);
    }
    let onSaveReservation = (dataObj: any) => {
        if (dataObj) {
            var restData = CommonUtil.parseData(dataObj)
            if (restData) {
                let strConfirm = CommonUtil.getMessageText(Constants.RESERVATION_CONFIRM, "Reservation Confirmed Successfully");
                ToastManager.showToast(strConfirm, 'Info');
                CommonUtil.SELECT_RESERVATION_ID = restData.reservationId;//"5";
                CommonUtil.EQUIP_RESERVE_ASSET_TYPE = restData.assetType;
                CommonUtil.EQUIP_RESERVE_EQUIPMENT_TYPE = restData.equipmentType;

                publish(Events.EQUIPMENT_EVENT, { data: Events.CONFIRM_RESERVATION })
                navigate('viewreservation');

            }
        }
        Events.dispatchReservationEVent();
    }

    let onClickFavorities = () => {
        if (!isFavourExit) {
            let restObj: any = {};
            restObj.equipmentListingId = CommonUtil.SELECT_EQUIP_AVAIL_ID;
            let favorAPI: string = "api/favorite/?saasOrgId=" + CommonUtil.getSaasOrgId();
            NetworkManager.sendJsonRequest(favorAPI, restObj, "", "POST", true, onSaveFavorities, onLoginError);
        } else {
            let favorAPI: string = "api/favorite/listing/" + CommonUtil.SELECT_EQUIP_AVAIL_ID;
            NetworkManager.sendJsonRequest(favorAPI, '', "", "DELETE", true, oRemoveFavorities, onLoginError);
        }

    }

    let oRemoveFavorities = (dataObj: any) => {
        if (dataObj) {
            if (dataObj == "success") {
                let strRemoveFavor = CommonUtil.getMessageText(Constants.REMOVE_FAVOR_SUCCESS, "Removed Favorite successfully");
                ToastManager.showToast(strRemoveFavor, 'Info');
                setFavour(false);
            } else {
                var restData = CommonUtil.parseData(dataObj)
                if (restData) {
                    let strRemoveFavor = CommonUtil.getMessageText(Constants.REMOVE_FAVOR_SUCCESS, "Removed Favorite successfully");
                    ToastManager.showToast(strRemoveFavor, 'Info');
                    setFavour(false);
                }
            }
        }
        Events.dispatchFavoriteEVent();
    }

    let onSaveFavorities = (dataObj: any) => {
        if (dataObj) {
            var restData = CommonUtil.parseData(dataObj)
            if (restData) {
                let strAddFavor = CommonUtil.getMessageText(Constants.ADD_FAVOR_SUCCESS, "Added Equipment to the Favorite");
                ToastManager.showToast(strAddFavor, 'Info');
                setFavour(true);
            }
        }
        Events.dispatchFavoriteEVent();
    }

    let onChangeStDate = (dateValue: any) => {
        if (stDateRef.current) {
            let strDate: string = CommonUtil.getDate(dateValue);
            setStDate(strDate);
            onChangeDates();
        }
    }
    let onChangeEdDate = (dataVal: any) => {
        let strDate: string = CommonUtil.getDate(dataVal);
        setEndDate(strDate);
        onChangeDates();
    }

    let onChangeDates = () => {
        if (stDateRef.current && endDateRef.current) {
            var startDate1 = stDateRef.current.flatpickr.selectedDates[0];
            var endDate1 = endDateRef.current.flatpickr.selectedDates[0];

            if (endDate1) {
                endDate1 = new Date(endDate1);
                stDateRef.current.flatpickr.set("maxDate", endDate1);
                // stDtOptions.maxDate = new Date(endDate);
            } if (startDate1) {
                endDateRef.current.flatpickr.set("minDate", new Date(startDate1));
                //stDtOptions.minDate = new Date(startDate);
            }

            setTimeout(() => {
                //var startDate1 = stDateRef.current.flatpickr.selectedDates[0];
                // var endDate1 = endDateRef.current.flatpickr.selectedDates[0];

                diffDays = CommonUtil.getDays(startDate1, endDate1);//endMs-stMs)/(24*3600*1000);
                //days = Math.ceil(days);
                setDays(diffDays);

                calculatePrice();
            }, 1000);
        }
    }

    let onChangeStDate1 = (dateValue: any) => {
        if (stDateRef1.current) {
            let strDate: string = CommonUtil.getDate(dateValue);
            setStDate1(strDate);
            onChangeDates1();
        }
    }
    let onChangeEdDate1 = (dataVal: any) => {
        let strDate: string = CommonUtil.getDate(dataVal);
        setEndDate1(strDate);
        onChangeDates1();
    }

    let onChangeDates1 = () => {
        if (stDateRef1.current && endDateRef1.current) {
            var startDate1 = stDateRef1.current.flatpickr.selectedDates[0];
            var endDate1 = endDateRef1.current.flatpickr.selectedDates[0];

            if (endDate1) {
                endDate1 = new Date(endDate1);
                stDateRef1.current.flatpickr.set("maxDate", endDate1);
                // stDtOptions.maxDate = new Date(endDate);
            } if (startDate1) {
                endDateRef1.current.flatpickr.set("minDate", new Date(startDate1));
                //stDtOptions.minDate = new Date(startDate);
            }

            setTimeout(() => {
                //var startDate1 = stDateRef.current.flatpickr.selectedDates[0];
                // var endDate1 = endDateRef.current.flatpickr.selectedDates[0];

                let days = CommonUtil.getDays(startDate1, endDate1);//endMs-stMs)/(24*3600*1000);
                //days = Math.ceil(days);
                setDays(days);
                calculatePrice();
            }, 1000);
        }
    }

    let onChangeStDate2 = (dateValue: any) => {
        if (stDateRef1.current) {
            let strDate: string = CommonUtil.getDate(dateValue);
            setStDate2(strDate);
            setTimeout(() => {
                // updateEndDate();
                onChangeDates2();
            })
            try {
                const nDays = CommonUtil.getDays(strDate, endDate2);
                setOfferRightrateObj(getSelectedRangeRate(Number(nDays), Number(dailyPricePopup || dailyRate), Number(weeklyPricePopup || weeklyRate), Number(monthlyPricePopup || monthlyRate)));
            } catch (e) {
                setOfferRightrateObj({ type: Constants.DAY });
            }
        }
    }
    let onChangeEdDate2 = (dataVal: any) => {
        let strDate: string = CommonUtil.getDate(dataVal);
        setEndDate2(strDate);
        try {
            const nDays = CommonUtil.getDays(stDate2, strDate);
            setOfferRightrateObj(getSelectedRangeRate(Number(nDays), Number(dailyPricePopup || dailyRate), Number(weeklyPricePopup || weeklyRate), Number(monthlyPricePopup || monthlyRate)));
        } catch (e) {
            setOfferRightrateObj({ type: Constants.DAY });
        }
        // onChangeDates1();
    }
    let handleRepoNoOfDays = (e: any) => {
        setNumDayReposPopup(e.currentTarget.value);
        CommonUtil.isMandatory(numDayReposRef);

    }

    let updateEndDate = () => {
        if (stDateRef2.current && numDayReposRef.current) {
            var addDaysMillis = 2 * 24 * 60 * 60 * 1000;
            var newNofDaysMiilis = (Number(numDayReposRef.current.value)) * 24 * 60 * 60 * 1000;
            var startDate = stDateRef2.current.flatpickr.selectedDates[0];
            var endDate1 = new Date(new Date(startDate).getTime() + addDaysMillis + newNofDaysMiilis);
            if (edDateRef2.current) {
                var newEedDate: any;
                newEedDate = edDateRef2.current.flatpickr.setDate(new Date(endDate1));
            }
            //setEndDate2(new Date(endDate).toString());
            let newstrDate: string = CommonUtil.getAcceptDate(new Date(endDate1));
            setEndDate2(newstrDate);
            //let newstrDate:string = CommonUtil.getDate(endDate2);
            //setEndDate2(newstrDate);
        }
    }
    let onChangeDates2 = () => {
        if (stDateRef2.current && edDateRef2.current) {
            // stDateRef.current.flatpickr.set("minDate",new Date());
            //endDateRef.current.flatpickr.set("minDate",new Date());

            var startDate = stDateRef2.current.flatpickr.selectedDates[0];
            var endDate = edDateRef2.current.flatpickr.selectedDates[0];

            edDateRef2.current.flatpickr.set("minDate", new Date(startDate));
            stDateRef2.current.flatpickr.set("maxDate", new Date(endDate));

            if (startDate) {

                //stDtOptions.minDate = new Date(startDate);
            }
            if (endDate) {
                // endDate = new Date(endDate);

                // stDtOptions.maxDate = new Date(endDate);
            }
        }
    }

    let onClickMakeOffer = () => {
        BusyLoader.showLoader();
        setShowOfferPopup(true);
        setNumDayReposPopup("");
        setDailyPricePopup("");
        setWeeklyPricePopup("");
        setMonthlyPricePopup("");
        setDisPricePopup("");
        setPaidPricePopup("");
        setAdditionalPricePopup("");
        setEstimatedDistancePopup("");
        setPricePerMilePopup("");
        setEngineHoursPopup("");
        setNoOfDaysPopup("");
        setEndDate2(endDateOffer);
        setStDate2(stDateOffer);
        // stDateOffer,endDateOffer

        //getPartkingLots();

        setTimeout(() => {
            BusyLoader.hideLoader();
            showHideOfferFields();
        }, 1500);
    }
    useEffect(() => {
        if (showOfferPopup) {
            try {
                const nDays = CommonUtil.getDays(stDateOffer, endDateOffer);
                setOfferLeftrateObj(getSelectedRangeRate(Number(nDays), Number(dailyPricePopup || dailyRate), Number(weeklyPricePopup || weeklyRate), Number(monthlyPricePopup || monthlyRate)));
            } catch (e) {
                setOfferLeftrateObj({ type: Constants.DAY });
            }
            try {
                const nDays = CommonUtil.getDays(stDate2, endDate2);
                setOfferRightrateObj(getSelectedRangeRate(Number(nDays), Number(dailyPricePopup || dailyRate), Number(weeklyPricePopup || weeklyRate), Number(monthlyPricePopup || monthlyRate)));
            } catch (e) {
                setOfferRightrateObj({ type: Constants.DAY });
            }
            setNumDayReposPopup(noofDays);
        }
        try {
            if (stDateRef2?.current) {

                stDateRef2?.current?.flatpickr?.setDate(new Date(CommonUtil.getOfferDate(stDateOffer)));
            }
            if (edDateRef2?.current) {
                edDateRef2?.current?.flatpickr?.setDate(new Date(CommonUtil.getOfferDate(endDateOffer)));
            }
        }
        catch (e) { }

        setEndDate2(endDateOffer);
        setStDate2(stDateOffer);
    }, [stDateRef2?.current, edDateRef2?.current, showOfferPopup]);

    let showHideOfferFields = () => {
        let arr = ['divNumOfDays', 'divAvailEquips', 'divDailyRate', 'divWeeklyRate', 'divMonthlyRate', 'divDistanceRate', 'divPaidPrice', 'divAdditionalPrice', 'divEstimatedDistance', 'divPricePerMail',
            'divNumOfDays1', 'divAvailEquips1', 'divDailyRate1', 'divWeeklyRate1', 'divMonthlyRate1', 'divDistanceRate1', 'divPaidPrice1', 'divAdditionalPrice1', 'divEstimatedDistance1', 'divPricePerMail1', 'divEngineHours', 'divEngineHours1', 'divDistanceToTravel', 'divEngineHoursUnit'];
        arr.forEach((item: any, idx: number) => {
            $("#" + item).hide();
        });

        if (CommonUtil.EQUIP_RESERVE_IS_GENERIC == "Y") {
            if (CommonUtil.EQUIP_AVAIL_TYPE == CommonUtil.EQUIP_AVAIL_REPOSITION_TYPE) {
                $("#divAvailEquips").show();
                $("#divNumOfDays").show();
                $("#divPaidPrice").show();
                $("#divAdditionalPrice").show();
                $("#divAvailEquips1").show();
                $("#divNumOfDays1").show();
                $("#divPaidPrice1").show();
                $("#divAdditionalPrice1").show();
            } else if (CommonUtil.EQUIP_AVAIL_TYPE == CommonUtil.EQUIP_AVAIL_RENTAL_TYPE) {
                $("#divAvailEquips").show();
                $("#divDailyRate").show();
                $("#divWeeklyRate").show();
                $("#divMonthlyRate").show();
                $("#divDistanceRate").show();
                $("#divAvailEquips1").show();
                $("#divDailyRate1").show();
                $("#divWeeklyRate1").show();
                $("#divMonthlyRate1").show();
                $("#divDistanceRate1").show();
                $("#divDistanceToTravel").show();
                if (CommonUtil.SELECT_EQUIPMENT_TYPE == CommonUtil.REEFER_EQUIP_TYPE) {
                    $("#divEngineHours").show();
                    $("#divEngineHours1").show();
                    $("#divEngineHoursUnit").show();
                }
            }
        } else {
            if (CommonUtil.EQUIP_AVAIL_TYPE == CommonUtil.EQUIP_AVAIL_REPOSITION_TYPE) {
                $("#divNumOfDays").show();
                $("#divPaidPrice").show();
                $("#divAdditionalPrice").show();
                $("#divEstimatedDistance").show();
                $("#divPricePerMail").show();
                $("#divNumOfDays1").show();
                $("#divPaidPrice1").show();
                $("#divAdditionalPrice1").show();
                $("#divEstimatedDistance1").show();
                $("#divPricePerMail1").show();
            } else if (CommonUtil.EQUIP_AVAIL_TYPE == CommonUtil.EQUIP_AVAIL_RENTAL_TYPE) {
                $("#divDailyRate").show();
                $("#divWeeklyRate").show();
                $("#divMonthlyRate").show();
                $("#divDistanceRate").show();
                $("#divDailyRate1").show();
                $("#divWeeklyRate1").show();
                $("#divMonthlyRate1").show();
                $("#divDistanceRate1").show();
                $("#divDistanceToTravel").show();
                if (CommonUtil.SELECT_EQUIPMENT_TYPE == CommonUtil.REEFER_EQUIP_TYPE) {
                    $("#divEngineHours").show();
                    $("#divEngineHours1").show();
                    $("#divEngineHoursUnit").show();
                }
            }
        }

        if (chkLiabilityOffer.current) {
            chkLiabilityOffer.current.checked = true;
            setOfferInsLib(true);
        }
        if (chkPhysicalDamageOffer.current) {
            chkPhysicalDamageOffer.current.checked = true;
            setOfferInsPhy(true);
        }

    }
    let getPartkingLots = () => {
        let parkingAPI: string = "api/parkinglot/all?saasOrgId=" + CommonUtil.getSaasOrgId();
        NetworkManager.sendJsonRequest(parkingAPI, "", "", "GET", true, onGetParkingLots, onLoginError);
    }

    let onGetParkingLots = (dataObj: any) => {
        if (dataObj) {
            var restData = CommonUtil.parseData(dataObj);
            if (restData) {
                let parkingLots: any = [];
                if (Array.isArray(restData)) {
                    parkingLots = restData;
                } else {
                    parkingLots.push(restData);
                }
                setParkingLots(parkingLots);
            }
        }
    }

    let onChangeDepartureLot = (dataObj: any) => {
        setSelDepartureObj(dataObj);
    }

    let onChangeArrivalLot = (dataObj: any) => {
        setSelArrivalObj(dataObj);
    }


    let onDevOfferShowPopup = () => {
        setTimeout(function () {
            setPopupCenter('devOfferPopup', 'devOfferContentPopup');
        }, 100)
    }
    let setPopupCenter = (popId: string, cId: string) => {
        let windowHeight = window.innerHeight
        let windowWidth = window.innerWidth
        //windowHeight = windowHeight - 170

        if (cId) {
            let popupTop = windowHeight - 650;
            popupTop = popupTop / 2
            let popupLeft = windowWidth - 800;
            popupLeft = popupLeft / 2
            //if(popupTop<50){
            //popupTop = 10
            //}
            $('#' + popId).css({ margin: '0px', top: popupTop, left: popupLeft })
        }
    }
    let onCloseOfferPopup = () => {
        setShowOfferPopup(false);
    }

    let onClickAcceptOffer = (e: any) => {
        e.preventDefault();
        if (CommonUtil.EQUIP_AVAIL_TYPE == CommonUtil.EQUIP_AVAIL_REPOSITION_TYPE && paidPricePopup === "") {
            let msg = "Please enter Paid Price to Carrier for Repositioning";
            ToastManager.showToast(msg, 'Error')
            return
        }
        if (endDate2 && stDate2) {
            let restObj: any = {};
            restObj.policyId = selPolicyObjOffer ? selPolicyObjOffer.id : "";
            restObj.distanceUnit = "";
            restObj.endDate = endDate2;
            restObj.equipmentListingId = CommonUtil.SELECT_EQUIP_AVAIL_ID;
            restObj.estimatedDistancePerDay = distanceToTravelPopup;
            if (CommonUtil.SELECT_EQUIPMENT_TYPE == CommonUtil.REEFER_EQUIP_TYPE) {
                restObj.estimatedEngineHoursPerDay = engineHoursUnitPopup;
            }

            if (CommonUtil.EQUIP_RESERVE_IS_GENERIC == "Y") {
                restObj.quantity = "1";//quantity;
            }
            restObj.renterOrgId = CommonUtil.getSaasOrgId();
            restObj.serviceFeePercentage = renter;
            restObj.startDate = stDate2;
            restObj.movePrice = paidPricePopup;
            restObj.noOfDaysAllowed = numDayReposPopup;
            restObj.distanceRate = disPricePopup;
            restObj.estimatedDistance = estimatedDisPopup;
            restObj.engineRate = engineRatePopup;
            restObj.defaultCurrency = defPrice;


            if (CommonUtil.EQUIP_AVAIL_TYPE == CommonUtil.EQUIP_AVAIL_RENTAL_TYPE) {
                // if (dailyPricePopup) {
                restObj.dailyRate = dailyPricePopup || dailyRate;
                // } if (weeklyPricePopup) {
                restObj.weeklyRate = weeklyPricePopup || weeklyRate;
                // } if (monthlyPricePopup) {
                restObj.monthlyRate = monthlyPricePopup || monthlyRate;
                // }
            } else {
                if (addtionalPrice) {
                    restObj.dailyRate = addtionalPrice;
                }
            }

            restObj.ratePlanIdLiability = "";
            restObj.ratePlanIdPhysical = "";
            if (isInuranceChecked) {
                if (chkLiabilityOffer && chkLiabilityOffer.current && chkLiabilityOffer.current.checked) {
                    restObj.ratePlanIdLiability = liabilityRateId;
                }
                if (chkPhysicalDamageOffer && chkPhysicalDamageOffer.current && chkPhysicalDamageOffer.current.checked) {
                    restObj.ratePlanIdPhysical = physicalDamageRateId;
                }
            } else {
                restObj.ratePlanIdLiability = "";
                restObj.ratePlanIdPhysical = "";
            }

            // if(pricePerMilePopup){
            //     restObj.distanceRate = pricePerMilePopup;
            // }

            let flag: boolean = true;
            if (CommonUtil.EQUIP_AVAIL_TYPE == CommonUtil.EQUIP_AVAIL_REPOSITION_TYPE) {
                let days = CommonUtil.getDays(stDate2, endDate2);
                if (numDayReposPopup === "") {
                    flag = false;
                } else if (Number(numDayReposPopup) > days) {
                    flag = false;
                }
            }


            if (flag) {

                let parkingAPI: string = "api/offers/";//?saasOrgId="+CommonUtil.getSaasOrgId();
                NetworkManager.sendJsonRequest(parkingAPI, restObj, "", "POST", true, onOfferCreate, onLoginError);
            } else {
                if (numDayReposPopup === "") {
                    let msg = CommonUtil.getMessageText(Constants.ENTER_NUM_OF_DAYS, "Please enter number of days allowed for repositioning");
                    ToastManager.showToast(msg, 'Error')
                } else {
                    let msg = CommonUtil.getMessageText(Constants.NUM_OF_DAY_ALLOWED, "Number of days allowed for repositioning is less than or equal to between days");
                    ToastManager.showToast(msg, 'Error')
                }
            }
        }
    }

    let onOfferCreate = (dataObj: any) => {
        if (dataObj) {
            var restData = CommonUtil.parseData(dataObj);
            if (restData) {
                setShowOfferPopup(false);
                let strOffer = CommonUtil.getMessageText(Constants.OFFER_CREATE_SUCCESS, "Offer created Successfully");
                ToastManager.showToast(strOffer, 'Info');
                //Confirm.show('Success',restData.status ? restData.status : strOffer, [{label: 'Close', callback: ()=>console.log("here in ok")}])
            }
        }
        Events.dispatchOfferEVent();
    }

    let onClickCloseOffer = () => {

    }
    let onOpenStDate = () => {
        if (stDateRef2.current) {
            if (edDateRef2.current) {
                var endDate = edDateRef2.current.flatpickr.selectedDates[0];
                if (endDate) {
                    stDateRef2.current.flatpickr.set("maxDate", new Date(endDate));
                } else {
                    stDateRef2.current.flatpickr.set("minDate", new Date(stDate));
                }

            } else {
                stDateRef2.current.flatpickr.set("minDate", new Date(stDate));
            }

        }
    }
    let onOpenEDDate = () => {
        if (edDateRef2.current) {
            if (stDateRef2.current) {
                var startDate = stDateRef2.current.flatpickr.selectedDates[0];
                if (startDate) {
                    edDateRef2.current.flatpickr.set("minDate", new Date(startDate));
                } else {
                    edDateRef2.current.flatpickr.set("minDate", new Date(endDate));
                }

            } else {
                edDateRef2.current.flatpickr.set("minDate", new Date(endDate));
            }

        }
    }

    let onSelectPolicy = (data: any) => {
        setSelPolicyObj(data);
    }

    let onSelectPolicyOffer = (data: any) => {
        setSelPolicyObjOffer(data);
    }
    let onClickEquipImage = (mTile: string, images: any, imgPath: string, idx: number, catagory?: string) => {
        // item && item.Type == CommonUtil.EQUIP_DRIVER_SIDE
        // setGallary(images);
        if (images.length == 0) {
            setEquipImagePath(imgPath);
        } else {
            // setImageIdx(idx);
        }
        let equipImages: any = [];

        images.forEach((item: any, eIdx: number) => {
            if (item && item.Type == catagory) {
                equipImages.push(item)
            }
        });
        for (let i = 0; i < equipImages.length; i++) {
            if (equipImages[i].Path == imgPath) {
                idx = i;
                break;
            }
        }
        setImageIdx(idx);
        setModalTile(mTile);
        setGallary(equipImages);

        setImageMoalShow(true);
    }

    const getDefaultRate = ()=>{
        let getRatePlanAPI: string = "api/insurance/getDefaultRatePlanIds?equipmentId=" + CommonUtil.SELECT_EQUIP_ID;
       NetworkManager.sendJsonRequest(getRatePlanAPI, "", "", "GET", true, onGetDefaultRatePlan, onLoginError);
   }

   const onGetDefaultRatePlan = (dataObj:any)=>{
       if(dataObj ) {
           var restData = CommonUtil.parseData(dataObj);
           if (restData) {       
            
            liabilityInsuracePerDayAmount =  parseFloat(Number((restData?.liabilityInsurance?.perDayRate || restData?.liabilityInsurance?.masterInsurancePerDay) * conversionRateValue).toFixed(2));
            physicalDamagePerDayAmount = parseFloat(Number((restData?.physicalInsurance?.perDayRate || restData?.physicalInsurance?.masterInsurancePerDay) * conversionRateValue).toFixed(2));
            setPhysicalDamagePlanPerDay(physicalDamagePerDayAmount);
            setLiabilityPlanPerDay(liabilityInsuracePerDayAmount); 
            if(restData?.physicalInsurance?.ratePlanId){
                physicalDamageRateId = restData?.physicalInsurance?.ratePlanId
            }
            if(restData?.liabilityInsurance?.ratePlanId){
                liabilityRateId = restData?.liabilityInsurance?.ratePlanId;
            }

            // **************** IMPORTANT *****************
            // This is change "Y" always according to Ankit he will rewamp code. 
            
            let obj = {
                //liabilityInsurancePaidByRenter : restData?.liabilityInsurance?.perDayRate  ? 'Y' : 'N',
                //physicalInsurancePaidByRenter : restData?.physicalInsurance?.perDayRate? 'Y' : 'N',
                //liabilityInsurancePaidByRenter : chkLiabilityRep?.current?.checked  ? 'Y' : 'N',
                //physicalInsurancePaidByRenter : chkPhysicalDamageRep?.current?.checked? 'Y' : 'N',
                liabilityRateAfterConversion: liabilityInsuracePerDayAmount,
                physcialRateAfterConversion: physicalDamagePerDayAmount
            }
            setConversionObj(obj);            
            
           }
       }
   }

//    useEffect(() => {
//        if(CommonUtil.SELECT_EQUIP_ID && conversionRate && !isInsuranceEnabled){
//            getDefaultRate();
//        }
//    }, [conversionRate,isInsuranceEnabled]);

    let onCloseImagePopup = () => {
        setImageMoalShow(false);
    }

    let onLoginSuccess = () => {
        setIsLogin("false");
    }

    let onLogoutSuccess = () => {
        setIsLogin("true");
        setIsLogout("false");
    }


    let onLoginError = (err: any) => {
        console.log(err);
    }

    const checkforTnCAgreement = (e:any)=>{
        if(isTncConfig){
            if(terms && agreement){
                onClickConfirmReservation();
            }else{    
                Confirm.show("Accept Terms and Conditions", "This owner requires rental customers to accept their organization's Terms and Conditions and the Master Rental Agreement, which will apply to all business conducted involving this owner's assets. Transactions with other owners will continue to be governed by the standard vHub Terms and Conditions.", [
                    { label: "Close" },
                 ]);            
                // ToastManager.showToast("This owner requires rental customers to accept their organization's Terms and Conditions and the Master Rental Agreement, which will apply to all business conducted involving this owner's assets. Transactions with other owners will continue to be governed by the standard vHub Terms and Conditions.", 'Error');                
            }
            // Confirm.show("Confirm", "This owner requires rental customers to accept their organization's Terms and Conditions and the Master Rental Agreement, which will apply to all business conducted involving this owner's assets. Transactions with other owners will continue to be governed by the standard vHub Terms and Conditions.", [
            //    { label: "ok" },
            // ]);
        }else{
            onClickConfirmReservation();
        }        
    }

    let rateObj: any = {};
    try {
        rateObj =  getSelectedRangeRate(days, Number(dailyRate), Number(weeklyRate), Number(monthlyRate));
    } catch (e) {
        rateObj = {};
    }
    const nOfferDays = (stDate2 && endDate2) ? CommonUtil.getDays(stDate2, endDate2) : 0;
    const estimateData: any = {
        currency: {
            label: "currency",
            value: defPrice
        },
        totalDays: {
            label: "days",
            value: noofDays
        },
        movePrice: {
            label: CommonUtil.getMessageText(Constants.PAID_PRICE_CARRIER, "Paid price to carrier making the move"),
            value: movePrice
        },
        excessDays: {
            label: "excess days",
            value: addtionalPrice,
            days: rextradays,
        },
        excessDaysTotal: {
            label: CommonUtil.getMessageText(Constants.EXCESS_DAILY_TOTAL, "Excess daily total"),
            value: rdurationPrice
        },
        PhysicalDamageinsurance: {
            label: "physicalDamagePlanPerDay",
            value: physicalDamagePlanPerDay,
            flag: isPhysicalDamageVisible,
            days: rDays,
        },
        liabilityinsurance: {
            label: "liabilityPlanPerDay",
            value: liabilityPlanPerDay,
            flag: isLiabilityVisible,
            days: rDays,
        },
        insurance: {
            label: "Insurance Amount",
            value: insuranceAmount,
            physicalDamagePaidByRenter,
            liabilityPaidByRenter
        },
        AdministrationFee: {
            label: "Administration fee",
            value: String(defaultCurrency).toUpperCase().indexOf("USD") !== -1 ? usInsuranceRate : canadaInsuranceRate
        },
        platformFees: {
            label: "Platform fee",
            value: platformFees
        },
        platformFeePaidBy: {
            label: "platformFeePaidBy",
            value: platformFeePaidBy
        },
    }


    return (
        <>
            <Modal show={showOfferPopup} dialogAs={DraggableComponent} id="devOfferPopup" onShow={onDevOfferShowPopup}>
                <div className="modal-content" id="devOfferContentPopup" style={{ "width": "900px", "height": "650px" }}>
                    <div className="modal-header">
                        <h6 className="modal-title">{(CommonUtil.EQUIP_AVAIL_TYPE == CommonUtil.EQUIP_AVAIL_REPOSITION_TYPE) ? CommonUtil.getMessageText(Constants.OFFER_DETAILS_REPOSITION, "Offer Details - Reposition") : CommonUtil.getMessageText(Constants.OFFER_DETAILS_RENTAL, "Offer Details - Rental")}</h6>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" style={{ marginLeft: "0px" }} onClick={onCloseOfferPopup} >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body color-black" style={{ overflow: "auto" }}>
                        <div className="row">
                            <div className="col-md-12">
                                <p>{CommonUtil.getMessageText(Constants.OFFER_RECEIVED_FROM, "Offer received from")}: <strong>{CommonUtil.getSelectedOrgName()}</strong></p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6 border-right" style={{ paddingRight: "25px" }}>
                                <h6 className="text-center">{CommonUtil.getMessageText(Constants.AVAILABILITY_DETAILS, "Availability Details")}</h6>
                                <div className="row">

                                    <div className="col-md-12 pr-0" style={{ paddingLeft: "20px" }}>
                                        <div className="row pr-0">
                                            <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.DATES, "Dates")}</label>
                                        </div>
                                    </div>
                                    <div className="row col-md-12 pr-0" style={{ paddingLeft: "40px" }}>
                                        <div className="form-group col-sm-6 pr-0">
                                            <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.STARTS_AT, "Starts at")}:</label>
                                            <div className="col-sm-12 pr-0">
                                                <div className="input-icon">
                                                    <i className="fa fa-calendar" aria-hidden="true" style={{ display: "none" }}></i>
                                                    <input type="text" className="form-control" required value={stDateOffer} disabled={true} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group col-sm-6 pr-0">
                                            <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.ENDS_AT, "Ends at")}:</label>
                                            <div className="col-sm-12 pr-0">
                                                <div className="input-icon">
                                                    <i className="fa fa-calendar" aria-hidden="true" style={{ display: "none" }}></i>
                                                    <input type="text" className="form-control" required value={endDateOffer} disabled={true} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group pr-0" id={"divNumOfDays"}>
                                            <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.DAYS_ALLOWED, "Days allowed for repositioning")}</label>
                                            <div className="col-sm-12 pr-0">
                                                <input type="text" disabled={true} className="form-control" required value={noofDays} />
                                            </div>
                                        </div>
                                        <div className="form-group pr-0" id={"divAvailEquips"}>
                                            <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.AVAIL_EQUIP, "Available equipment for this period")}</label>
                                            <div className="col-sm-12">
                                                <input type="text" disabled={true} className="form-control" required value={quantity} />
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12" style={{ paddingLeft: "20px" }}>
                                        <div className="row">
                                            <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.LOCATION, "Location")}</label>
                                        </div>
                                    </div>
                                    <div className="col-md-12" style={{ paddingLeft: "40px" }}>
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.DEPARTRE_PARK_LOT, "Departure Parking Lot")}
                                            </label>
                                            <div className="col-sm-12">
                                                <div className="input-icon">
                                                    <i className="fa fa-bullseye d-none" aria-hidden="true"></i>
                                                    <input type="text" className="form-control" required
                                                        value={departure} disabled={true} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.ARRIVAL_PARK_LOT, "Arrival Parking Lot")}</label>
                                            <div className="col-sm-12">
                                                <div className="input-icon">
                                                    <i className="fa fa-map-marker" aria-hidden="true" style={{ display: "none" }}></i>
                                                    <input type="text" className="form-control" required value={arrival} disabled={true} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row" style={{ display: ((isInuranceChecked && isInuranceRenterChecked) ? "" : "none") }}>
                                    <div className="col-md-12" style={{ paddingLeft: "20px" }}>
                                        <div className="row" style={{ display: ((liabilityPaidByRenter || physicalDamagePaidByRenter) ? "" : "none") }}>
                                            <label className="col-sm-12 col-form-label">{"Insurance"}</label>
                                        </div>
                                    </div>
                                    <div className="row" style={{ paddingLeft: "30px", display: ((liabilityPaidByRenter) ? "" : "none") }}>
                                        <div className="col-sm-8 col-12">
                                            <div className="form-group row">
                                                <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.LIABILITY_INSURANCE_PLAN, "Liability Insurance Plan")}</label>
                                                <div className="col-sm-12 col-12">
                                                    <input type="text" className="form-control" value={liabilityPlan} disabled={true} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-4 col-12">
                                            <div className="form-group row">
                                                <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.RATE_PER_DAY, "Rate per day")}</label>
                                                <div className="col-sm-12 col-12">
                                                    <input type="text" className="form-control"
                                                        placeholder={CommonUtil.getMessageText(Constants.RATE_PER_DAY, 'Rate per day')} value={liabilityPlanPerDay}
                                                        maxLength={10} disabled={true}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row" style={{ paddingLeft: "30px", display: ((physicalDamagePaidByRenter) ? "" : "none") }}>

                                        <div className="col-sm-8 col-12">
                                            <div className="form-group row">
                                                <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.PHYSICAL_DAMAGE_INSURANCE_PLAN, "Physical Damage Insurance Plan")}</label>
                                                <div className="col-sm-12 col-12">
                                                    <input type="text" className="form-control" value={physicalDamagePlan} disabled={true} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-4 col-12">
                                            <div className="form-group row">
                                                <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.RATE_PER_DAY, "Rate per day")}</label>
                                                <div className="col-sm-12 col-12">
                                                    <input type="text" className="form-control"
                                                        placeholder={CommonUtil.getMessageText(Constants.RATE_PER_DAY, 'Rate per day')} value={physicalDamagePlanPerDay}
                                                        maxLength={10} disabled={true}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-12" style={{ paddingLeft: "20px" }}>
                                        <div className="row">
                                            <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.COST, "Cost")}:</label>
                                        </div>
                                    </div>
                                    <div className="col-md-12" style={{ paddingLeft: "40px" }}>
                                        <div className={`form-group row ${offerRightrateObj.type == Constants.DAY ? '' : 'd-none'}`} id={"divDailyRate"}>
                                            <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.DAILY_RATE, "Daily Rate")}:</label>
                                            <div className="col-sm-12">
                                                <div><div className="input-group mb-3">
                                                    <span className="input-group-text" style={{ height: "30px", fontSize: "14px", color: "#555" }}>{defPrice}</span>
                                                    <input type="text" className="form-control" required
                                                        value={dailyRate} disabled={true} />
                                                </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={`form-group row ${offerRightrateObj.type == Constants.WEEK ? '' : 'd-none'}`} id={"divWeeklyRate"}>
                                            <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.WEEKLY_RATE, "Weekly Rate")}:</label>
                                            <div className="col-sm-12">
                                                <div><div className="input-group mb-3">
                                                    <span className="input-group-text" style={{ height: "30px", fontSize: "14px", color: "#555" }}>{defPrice}</span>
                                                    <input type="text" className="form-control" required
                                                        value={weeklyRate} disabled={true} />
                                                </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={`form-group row ${offerRightrateObj.type == Constants.MONTH ? '' : 'd-none'}`} id={"divMonthlyRate"}>
                                            <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.MONTHLY_RATE, "Monthly Rate")}:</label>
                                            <div className="col-sm-12">
                                                <div><div className="input-group mb-3">
                                                    <span className="input-group-text" style={{ height: "30px", fontSize: "14px", color: "#555" }}>{defPrice}</span>
                                                    <input type="text" className="form-control" required
                                                        value={monthlyRate} disabled={true} />
                                                </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group row" id={"divDistanceRate"}>
                                            <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.DIST_RATE, "Distance Rate")}:</label>
                                            <div className="col-sm-12">
                                                <div><div className="input-group mb-3">
                                                    <span className="input-group-text" style={{ height: "30px", fontSize: "14px", color: "#555" }}>{defPrice}</span>
                                                    <input type="text" className="form-control" required
                                                        value={distanceRate} disabled={true} />
                                                </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12" style={{ paddingLeft: "40px" }}>
                                        <div className="form-group row" id={"divPaidPrice"}>
                                            <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.PAID_PRICE, "Paid price carrier for Repositioning")}:</label>
                                            <div className="col-sm-12">
                                                <div>
                                                    <div className="input-group mb-3">
                                                        <span className="input-group-text" style={{ height: "30px", fontSize: "14px", color: "#555" }}>{defPrice}</span>
                                                        <input type="text" className="form-control" required
                                                            value={paidPrice} disabled={true} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group row" id={"divAdditionalPrice"} style={{ visibility: "hidden" }}>
                                            <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.ADDITIONAL_PRICE, "Additional Price per Excess Days")}:</label>
                                            <div className="col-sm-12">
                                                <div><input type="text" className="form-control" required
                                                    value={addtionalPrice} readOnly />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group row" id={"divEstimatedDistance"} style={{ visibility: "hidden" }}>
                                            <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.EST_DISTANCE, "Estimated distance in miles")}:</label>
                                            <div className="col-sm-12">
                                                <div><input type="text" className="form-control" required
                                                    value={eDisRate} readOnly />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group row" id={"divPricePerMail"} style={{ visibility: "hidden" }}>
                                            <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.PRICE_PER_MILE, "Price per mile")}:</label>
                                            <div className="col-sm-12">
                                                <div><input type="text" className="form-control" required
                                                    value={priceKm} readOnly />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group row" id={"divEngineHours"}>
                                            <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.ENGINE_RATE, "Engine Hours")}:</label>
                                            <div className="col-sm-12">
                                                <div><input type="text" className="form-control" required
                                                    value={engineRate} readOnly />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <h6 className="text-center">{CommonUtil.getMessageText(Constants.OFFER_DETAILS, "Offer Details")}</h6>
                                <div className="row">
                                    <div className="col-md-12" style={{ paddingLeft: "20px", visibility: "hidden" }}>
                                        <div className="row">
                                            <label className="col-sm-12 col-form-label">Dates</label>
                                        </div>
                                    </div>
                                    <div className="row pr-0" style={{ paddingLeft: "30px" }}>

                                        <div className="form-group col-sm-6 input-highlighted">
                                            <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.STARTS_AT, "Starts at")}:</label>
                                            <div className="col-sm-12">
                                                <div className="input-icon">
                                                    <i className="fa fa-calendar" aria-hidden="true" style={{ display: "none" }}></i>
                                                    <Flatpickr placeholder="MM/DD/YYYY" ref={stDateRef2} onChange={onChangeStDate2}
                                                        options={{ dateFormat: 'Y-m-d' }} className="form-control flat-picker"
                                                        onOpen={onOpenStDate} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group col-sm-6 input-highlighted">
                                            <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.ENDS_AT, "Ends at")}:</label>
                                            <div className="col-sm-12">
                                                <div className="input-icon">
                                                    <i className="fa fa-calendar" aria-hidden="true" style={{ display: "none" }}></i>

                                                    <Flatpickr placeholder="MM/DD/YYYY" ref={edDateRef2} onChange={onChangeEdDate2} onOpen={onOpenEDDate}
                                                        options={{ dateFormat: 'Y-m-d' }} className="form-control flat-picker"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group input-highlighted" id={"divNumOfDays1"}>
                                            <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.DAYS_ALLOWED, "Number of days allowed for repositioning")}</label>
                                            <div className="col-sm-12">
                                                <input ref={numDayReposRef} type="number" id="startthree" name="trip-start"
                                                    className="form-control clsStandardText  clsFormInputControl" required value={numDayReposPopup}
                                                    onChange={handleRepoNoOfDays}
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group input-highlighted" id={"divAvailEquips1"}>
                                            <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.AVAIL_EQUIP, "Available equipment for this period")}</label>
                                            <div className="col-sm-12">
                                                <input ref={numDayRef} type="number"
                                                    className="form-control" required value={quantity ? 1 : noOfDaysPopup} min={0}
                                                    onChange={(e) => { setNoOfDaysPopup(e.currentTarget.value); CommonUtil.isMandatory(numDayRef); }}
                                                />
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12" style={{ paddingLeft: "20px", visibility: "hidden" }}>
                                        <div className="row">
                                            <label className="col-sm-12 col-form-label">Location</label>
                                        </div>
                                    </div>
                                    <div className="col-md-12" style={{ paddingLeft: "30px" }}>
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.DEPARTRE_PARK_LOT, "Departure Parking Lot")}</label>
                                            <div className="col-sm-12">
                                                <div className="input-icon">
                                                    <i className="fa fa-bullseye" aria-hidden="true" style={{ display: "none" }}></i>
                                                    <input type="text" className="form-control" required
                                                        value={departure} disabled={true} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.ARRIVAL_PARK_LOT, "Arrival Parking Lot")}</label>
                                            <div className="col-sm-12">
                                                <div className="input-icon">
                                                    <i className="fa fa-map-marker" aria-hidden="true" style={{ display: "none" }}></i>
                                                    <input type="text" className="form-control" required value={arrival} disabled={true} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row" style={{ display: ((isInuranceChecked && isInuranceRenterChecked) ? "" : "none") }}>
                                    <div className="row" style={{ display: ((liabilityPaidByRenter) ? "" : "none") }}>
                                        <div className="col-sm-1 col-12">
                                            <div className="form-group row" style={{ paddingTop: "30px" }}>
                                                <div className="col-sm-12 col-12">
                                                    <input ref={chkLiabilityOffer} type="checkbox" className="checkBox" style={{ width: "25px", height: "25px" }}
                                                        disabled={insurStatus ? true : false} onChange={(e) => {
                                                            setOfferInsLib(e.target.checked);
                                                        }} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-7 col-12">
                                            <div className="form-group row">
                                                <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.LIABILITY_INSURANCE_PLAN, "Liability Insurance Plan")}</label>
                                                <div className="col-sm-12 col-12">
                                                    <input type="text" className="form-control" value={liabilityPlan} disabled={true} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-4 col-12">
                                            <div className="form-group row">
                                                <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.RATE_PER_DAY, "Rate per day")}</label>
                                                <div className="col-sm-12 col-12">
                                                    <input type="text" className="form-control"
                                                        placeholder={CommonUtil.getMessageText(Constants.RATE_PER_DAY, 'Rate per day')} value={liabilityPlanPerDay}
                                                        maxLength={10} disabled={true}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row" style={{ display: ((physicalDamagePaidByRenter) ? "" : "none") }}>ee
                                        <div className="col-sm-1 col-12">
                                            <div className="form-group row" style={{ paddingTop: "30px" }}>
                                                <div className="col-sm-12 col-12">
                                                    <input ref={chkPhysicalDamageOffer} type="checkbox" className="checkBox" style={{ width: "25px", height: "25px" }}
                                                        disabled={insurStatusPhy ? true : false} onChange={(e) => {
                                                            setOfferInsPhy(e.target.checked);
                                                        }} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-7 col-12">
                                            <div className="form-group row">
                                                <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.PHYSICAL_DAMAGE_INSURANCE_PLAN, "Physical Damage Insurance Plan")}</label>
                                                <div className="col-sm-12 col-12">
                                                    <input type="text" className="form-control" value={physicalDamagePlan} disabled={true} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-4 col-12">
                                            <div className="form-group row">
                                                <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.RATE_PER_DAY, "Rate per day")}</label>
                                                <div className="col-sm-12 col-12">
                                                    <input type="text" className="form-control"
                                                        placeholder={CommonUtil.getMessageText(Constants.RATE_PER_DAY, 'Rate per day')} value={physicalDamagePlanPerDay}
                                                        maxLength={10} disabled={true}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12" style={{ paddingLeft: "20px", visibility: "hidden", minHeight: "45px" }}>
                                        <div className="row">
                                            <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.COST, "Cost")}</label>
                                        </div>
                                    </div>
                                    <div className="col-md-12" style={{ paddingLeft: "30px" }}>
                                        <div className={`form-group row input-highlighted ${offerRightrateObj.type == Constants.DAY ? '' : 'd-none'}`} id={"divDailyRate1"}>
                                            <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.DAILY_RATE, "Daily Rate")}:</label>
                                            <div className="col-sm-12">
                                                <div><div className="input-group mb-3">
                                                    <span className="input-group-text" style={{ height: "30px", fontSize: "14px", color: "#555" }}>{defPrice}</span>
                                                    <input ref={dailyRateRef} type="text"
                                                        className="form-control" required value={dailyPricePopup} min={0}
                                                        onKeyPress={CommonUtil.allowNumbersDecimal} onChange={(e) => { CommonUtil.handleNumbersDecimalInput({ event: e, ref: dailyRateRef, setState: setDailyPricePopup }) }} />
                                                </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={`form-group row input-highlighted ${offerRightrateObj.type == Constants.WEEK ? '' : 'd-none'}`} id={"divWeeklyRate1"}>
                                            <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.WEEKLY_RATE, "Weekly Rate")}:</label>
                                            <div className="col-sm-12">
                                                <div><div className="input-group mb-3">
                                                    <span className="input-group-text" style={{ height: "30px", fontSize: "14px", color: "#555" }}>{defPrice}</span>
                                                    <input ref={weeklyRateRef} type="text"
                                                        className="form-control" required value={weeklyPricePopup} min={0}
                                                        onKeyPress={CommonUtil.allowNumbersDecimal} onChange={(e) => { CommonUtil.handleNumbersDecimalInput({ event: e, ref: weeklyRateRef, setState: setWeeklyPricePopup }) }} />
                                                </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={`form-group row input-highlighted ${offerRightrateObj.type == Constants.MONTH ? '' : 'd-none'}`} id={"divMonthlyRate1"}>
                                            <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.MONTHLY_RATE, "Monthly Rate")}:</label>
                                            <div className="col-sm-12">
                                                <div><div className="input-group mb-3">
                                                    <span className="input-group-text" style={{ height: "30px", fontSize: "14px", color: "#555" }}>{defPrice}</span>
                                                    <input ref={monthlyRateRef} type="text"
                                                        className="form-control" required value={monthlyPricePopup} min={0}
                                                        onKeyPress={CommonUtil.allowNumbersDecimal} onChange={(e) => { CommonUtil.handleNumbersDecimalInput({ event: e, ref: monthlyRateRef, setState: setMonthlyPricePopup }) }} />
                                                </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group row input-highlighted" id={"divDistanceRate1"}>
                                            <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.DIST_RATE, "Distance Rate")}:</label>
                                            <div className="col-sm-12">
                                                <div><div className="input-group mb-3">
                                                    <span className="input-group-text" style={{ height: "30px", fontSize: "14px", color: "#555" }}>{defPrice}</span>
                                                    <input ref={distanceRef} type="text" min={0}
                                                        className="form-control" required value={disPricePopup}
                                                        onKeyPress={CommonUtil.allowNumbersDecimal} onChange={(e) => { CommonUtil.handleNumbersDecimalInput({ event: e, ref: distanceRef, setState: setDisPricePopup }) }} />
                                                </div>
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                    <div className="col-md-12" style={{ paddingLeft: "30px" }}>
                                        <div className="form-group row" id={"divPaidPrice1"}>
                                            <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.PAID_PRICE, "Paid price carrier for Repositioning")}:</label>
                                            <div className="col-sm-12">
                                                {/* <div><input ref={paidPricePopupRef} type="text" className="form-control" required min={0}
                                                                value={paidPricePopup} onKeyPress={CommonUtil.allowNumbersDecimal} onChange={(e)=>{CommonUtil.handleNumbersDecimalInput({event:e,ref:paidPricePopupRef,setState:setPaidPricePopup})}} />
                                                    </div> */}
                                                <div><div className="input-group mb-3">
                                                    <span className="input-group-text" style={{ height: "30px", fontSize: "14px", color: "#555" }}>{defPrice}</span>
                                                    <input ref={paidPricePopupRef} type="text" className="form-control" required min={0}
                                                        value={paidPricePopup} onKeyPress={CommonUtil.allowNumbersDecimal} onBlur={(e: any) => {
                                                            if (Number(paidPricePopup) < 80) {
                                                                setPaidPricePopup("80");
                                                            }
                                                        }} onChange={(e) => { CommonUtil.handleNumbersDecimalInput({ event: e, ref: paidPricePopupRef, setState: setPaidPricePopup }) }} />
                                                </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-12 mt-3">
                                                {CommonUtil.EQUIP_AVAIL_TYPE == CommonUtil.EQUIP_AVAIL_REPOSITION_TYPE && paidPricePopup ? <CostEstimate data={estimateData} excessDays={numDayReposPopup || noofDays} nDays={nOfferDays} saagOrgId={saagOrgId} enginerHoursPerDay={engineUnitUsage} isPhysicalDamageVisible={offerInsPhy} isLiabilityVisible={offerInsLib} distancePerDay={distance} movePrice={paidPricePopup} startDate={stDate2} endDate={endDate2} /> : null}
                                            </div>
                                        </div>
                                        <div className="form-group row" id={"divAdditionalPrice1"} style={{ visibility: "hidden" }}>
                                            <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.ADDITIONAL_PRICE, "Additional Price per Excess Days")}:</label>
                                            <div className="col-sm-12">
                                                <div><input ref={additionalPricePopupRef} type="text" className="form-control" required min={0}
                                                    value={additionalPricePopup} onKeyPress={CommonUtil.allowNumbersDecimal} onChange={(e) => { CommonUtil.handleNumbersDecimalInput({ event: e, ref: additionalPricePopupRef, setState: setAdditionalPricePopup }) }} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group row" id={"divEstimatedDistance1"} style={{ visibility: "hidden" }}>
                                            <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.EST_DISTANCE, "Estimated distance in miles")}:</label>
                                            <div className="col-sm-12">
                                                <div><input type="text" ref={estimatedDisPopupRef} className="form-control" required min={0}
                                                    value={estimatedDisPopup} onKeyPress={CommonUtil.allowNumbersDecimal} onChange={(e) => { CommonUtil.handleNumbersDecimalInput({ event: e, ref: estimatedDisPopupRef, setState: setEstimatedDistancePopup }) }} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group row" id={"divPricePerMail1"} style={{ visibility: "hidden" }}>
                                            <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.PRICE_PER_MILE, "Price per mile")}:</label>
                                            <div className="col-sm-12">
                                                <div><input type="text" ref={pricePerMilePopupRef} className="form-control" required min={0}
                                                    value={pricePerMilePopup} onKeyPress={CommonUtil.allowNumbersDecimal} onChange={(e) => { CommonUtil.handleNumbersDecimalInput({ event: e, ref: pricePerMilePopupRef, setState: setPricePerMilePopup }) }} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group row" id={"divEngineHours1"}>
                                            <label className="col-sm-12 col-form-label" style={{ paddingLeft: "5px" }}>{CommonUtil.getMessageText(Constants.ENGINE_RATE, "Engine Hours")}:</label>
                                            <div className="col-sm-12" style={{ paddingLeft: "5px" }}>
                                                <div><input ref={engineRatePopupRef} type="text" className="form-control" required min={0}
                                                    value={engineRatePopup} onKeyPress={CommonUtil.allowNumbersDecimal} onChange={(e) => { CommonUtil.handleNumbersDecimalInput({ event: e, ref: engineRatePopupRef, setState: setEngineHoursPopup }) }} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group row" id={"divDistanceToTravel"} style={{ paddingLeft: "10px" }}>
                                            <label className="col-sm-12 col-form-label" style={{ paddingLeft: "5px" }}>{CommonUtil.getMessageText(Constants.DISTANCE_TO_TRAVEL, "Distance to travel")}:</label>
                                            <div className="col-sm-12" style={{ paddingLeft: "6px" }}>
                                                <div className={"flex"} style={{ alignItems: "center" }}>
                                                    <input ref={distanceToTravelRef} type="text" className="form-control" required min={0}
                                                        value={distanceToTravelPopup} onKeyPress={CommonUtil.allowNumbersDecimal} onChange={(e) => { CommonUtil.handleNumbersDecimalInput({ event: e, ref: distanceToTravelRef, setState: setDistanceToTravelPopup }) }} /> &nbsp;&nbsp;<span>{mesurement}/{CommonUtil.getMessageText(Constants.DAY, "DAY")}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group row" id={"divEngineHoursUnit"}>
                                            <label className="col-sm-12 col-form-label" style={{ paddingLeft: "5px" }}>{CommonUtil.getMessageText(Constants.ENGINE_UNIT_USAGE, "Engine unit usage")}:</label>
                                            <div className="col-sm-12 flex" style={{ paddingLeft: "5px" }}>
                                                <div className={"flex"}><input ref={engineHoursUnitRef} type="text" className="form-control" required min={0}
                                                    value={engineHoursUnitPopup} onKeyPress={CommonUtil.allowNumbersDecimal} onChange={(e) => { CommonUtil.handleNumbersDecimalInput({ event: e, ref: engineHoursUnitRef, setState: setEngineHoursUnitPopup }) }} style={{ width: "60%", display: "inline" }} />  &nbsp;&nbsp; {CommonUtil.getMessageText(Constants.HOURS_DAY, "hours/day")}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-outline-dark" data-dismiss="modal" onClick={onCloseOfferPopup}>{CommonUtil.getMessageText(Constants.CLOSE, "Close")}</button>
                        {(CommonUtil.ORGANIZATION_TYPE == CommonUtil.ORG_TYPE_RENTER || CommonUtil.ORGANIZATION_TYPE == CommonUtil.ORG_TYPE_OWNER_RENTER) ?
                            (<button type="button" className="btn btn-success" data-dismiss="modal" onClick={onClickAcceptOffer}>{CommonUtil.getMessageText(Constants.SAVE, "Save")}</button>) : ""}
                    </div>
                </div>
            </Modal>

            <EquipGalleryModal modalTile={modalTitle} modalShow={imageModalShow} onCloseImagePopup={onCloseImagePopup} images={gallary} idx={imageIdx}
                imagePath={imageEquipPath} />

            <div className="page-content-wrapper">
                <style>
                    {`
                        .highlight-border-date-fields input{
                            border-color:green;
                        }
                    `}
                </style>
                <div className="container-fluid" style={{ paddingLeft: "15px", paddingRight: "15px" }}>

                    <br />
                    <div className="row ml-sm-4 mr-sm-4" style={{ paddingTop: "40px", display: (isPublicSearch ? "" : "none") }}>
                        <EquipmentHeader isPLogin={isLogin} isSearchDB={"false"} isSignUp={"false"} isLogout={isLogout}
                            onLoginSuccess={onLoginSuccess} onLogoutSuccess={onLogoutSuccess} />
                    </div>
                    <div className="row ml-sm-4 mr-sm-4">
                        <div className="col-lg-7">
                            <div className="mr-sm-3">
                                <section>
                                    <div className="row">
                                        <div className="col-sm-3 col-md-2 text-center">
                                            <img className="img-fluid" src={imagePath} alt="" />
                                        </div>
                                        <StarRating value={Math.round(Number(overAllRate))} />{overAllRate} | {ratingCount} Ratings
                                        <div className="col-sm-9 col-md-10" style={{ display: "none" }}>
                                            <h6 className="mt-4">Name of the company will be shown after the reservation is confirmed.</h6>
                                        </div>
                                    </div>
                                    <hr className="mt-2" />
                                    <div className="row">
                                        <div className="col-sm-8">
                                            <div className="row">
                                                <div className="col-sm-4 col-6 mb-3"><span
                                                    className="details-head">{CommonUtil.getMessageText(Constants.VIN_NO, "VIN Number")}</span><label>{vinNumber}</label>
                                                </div>
                                                <div className="col-sm-4 col-6  mb-3"><span
                                                    className="details-head">{CommonUtil.getMessageText(Constants.BRAND, "Brand")}</span><label>{brand}</label>
                                                </div>
                                                <div className="col-sm-4 col-6  mb-3"><span
                                                    className="details-head">{CommonUtil.getMessageText(Constants.YEAR, "Year")}</span><label>{year}</label>
                                                </div>
                                                <div className="col-sm-4 col-6 mb-3"><span
                                                    className="details-head">{CommonUtil.getMessageText(Constants.UNIT_NUMBER, "Unit Number")}</span><label>{unitNumber}</label>
                                                </div>
                                                <div className="col-sm-4 col-6  mb-3"><span
                                                    className="details-head">{CommonUtil.getMessageText(Constants.MODEL, "Model")}</span><label>{model}</label>
                                                </div>
                                                <div className="col-sm-4 col-6  mb-3"><span
                                                    className="details-head">{CommonUtil.getMessageText(Constants.LENGTH_OF_TRAILER, "Length")}</span><label>{trailerLength}</label>
                                                </div>
                                                <div className="col-sm-4 col-6  mb-3"><span
                                                    className="details-head">{CommonUtil.getMessageText(Constants.EQUIP_NAME, "Equipment Name")}</span><label>{equipName}</label>
                                                </div>
                                                <div className="col-sm-4 col-6  mb-3"><span
                                                    className="details-head">{CommonUtil.getMessageText(Constants.TRACK_TYPE, "Tracking Type")}</span><label>{trackingType}</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-4" style={{ display: (CommonUtil.ORGANIZATION_TYPE == CommonUtil.ORG_TYPE_RENTER ? "none" : "") }}>
                                            <img className="img-fluid" src={imagePath} alt="" style={{ border: "1px solid #999", borderRadius: "50%" }} />
                                        </div>
                                    </div>
                                </section>
                                <section style={{ display: (CommonUtil.ORGANIZATION_TYPE == CommonUtil.ORG_TYPE_RENTER ? "none" : "") }}>
                                    <h5>{CommonUtil.getMessageText(Constants.EQUIP_PHOTO, "Equipment Photos")}</h5>
                                    <hr />
                                    <div style={{ display: 'flex', overflow: "auto" }}>
                                        {equipImagesPath.map((item: any, idx: number) => {
                                            if (item && item.Type == CommonUtil.EQUIP_IMAGES) {
                                                var list = (
                                                    <div className={'upload-image-item'} key={idx}>
                                                        <img src={item.Path} className="upload-thumbnail m-3" style={{ margin: "0px", cursor: "pointer" }}
                                                            onClick={() => {
                                                                onClickEquipImage(CommonUtil.getMessageText(Constants.EQUIP_PHOTO, "Equipment Photos"), equipImagesPath, item.Path, idx, CommonUtil.EQUIP_IMAGES);
                                                            }} />
                                                    </div>
                                                )
                                                return list
                                            }
                                        })}

                                    </div>
                                </section>
                                <section style={{ display: (CommonUtil.ORGANIZATION_TYPE == CommonUtil.ORG_TYPE_RENTER ? "none" : "") }}>
                                    <h5>{CommonUtil.getMessageText(Constants.DAMAGES, "Known Damages")}</h5>
                                    <hr />
                                    <div style={{ display: 'flex', overflow: "auto" }}>
                                        {equipImagesPath.map((item: any, idx: number) => {
                                            if (item && item.Type == CommonUtil.EQUIP_DAMAGES) {
                                                var list = (
                                                    <div className={'upload-image-item'} key={idx}>
                                                        <img src={item.Path} className="upload-thumbnail m-3" style={{ margin: "0px", cursor: "pointer" }}
                                                            onClick={() => {
                                                                onClickEquipImage(CommonUtil.getMessageText(Constants.DAMAGES, "Equipment Photos"), equipImagesPath, item.Path, idx, CommonUtil.EQUIP_DAMAGES);
                                                            }} />
                                                    </div>
                                                )
                                                return list
                                            }
                                        })}

                                    </div>
                                </section>
                                <section>
                                    <h5>{CommonUtil.getMessageText(Constants.GEN_INFO, "General Information")}</h5>
                                    <hr />
                                    <div className="row">
                                        <div className="col-sm-2 col-6"><span className="details-head">{CommonUtil.getMessageText(Constants.ASSET_TYPE, "Asset Type")}</span><label>{assetType}</label>
                                        </div>
                                        <div className="col-sm-2 col-6"><span className="details-head">{CommonUtil.getMessageText(Constants.UNIT_NUMBER, "Unit Number")}</span><label>{unitNumber}</label>
                                        </div>
                                        <div className="col-sm-2 col-6"><span className="details-head">{CommonUtil.getMessageText(Constants.VIN_NO, "VIN Number")}</span><label>{vinNumber}</label>
                                        </div>
                                        <div className="col-sm-2 col-6"><span
                                            className="details-head">{CommonUtil.getMessageText(Constants.BRAND, "Brand")}</span><label>{brand}</label></div>
                                        <div className="col-sm-2 col-6"><span
                                            className="details-head">{CommonUtil.getMessageText(Constants.MODEL, "Model")}</span><label>{model}</label></div>
                                        <div className="col-sm-2 col-6"><span
                                            className="details-head">{CommonUtil.getMessageText(Constants.YEAR, "Year")}</span><label>{year}</label></div>
                                        <div className="col-sm-2 col-6"><span className="details-head">{CommonUtil.getMessageText(Constants.NO_OF_AXELS, "Number of Axles")}</span><label>{numOfAxes}</label>
                                        </div>
                                        <div className="col-sm-2 col-6"><span className="details-head">{CommonUtil.getMessageText(Constants.LENGTH_OF_TRAILER, "Length of Trailer")}</span><label>{trailerLength}</label>
                                        </div>
                                        <div className="col-sm-4 col-6"><span className="details-head">{CommonUtil.getMessageText(Constants.LICENSE_PLATE_NO, "License Plate Number")}</span><label>{license}</label></div>
                                        <div className="col-sm-4 col-6"><span className="details-head">{CommonUtil.getMessageText(Constants.ANN_MECH_EXPIRE_DATE, "Annual Mechanical Expiration Date")}</span><label>{expDate}</label></div>
                                    </div>
                                </section>
                                <section>
                                    <h5>{CommonUtil.getMessageText(Constants.SPECIFICATIONS, "Specifications")}</h5>
                                    <hr />
                                    <h6>{CommonUtil.getMessageText(Constants.SPECIFICATIONS, "Specifications")}</h6>
                                    <div className="row">
                                        <div className="col-sm-2 col-6"><span className="details-head">{CommonUtil.getMessageText(Constants.AXLE_SPREAD, "Axle Spread")}</span><label>{axelSpread}</label>
                                        </div>
                                        <div className="col-sm-2 col-6"><span className="details-head">{CommonUtil.getMessageText(Constants.TIRE_INF_SYS, "Tire Inflation System")}</span><label>{tireInflation}</label>
                                        </div>
                                        <div className="col-sm-2 col-6"><span
                                            className="details-head">{CommonUtil.getMessageText(Constants.SKIRTS, "Skirts")}</span><label>-</label></div>
                                        <div className="col-sm-2 col-6"><span className="details-head">{CommonUtil.getMessageText(Constants.PINTLE_HOOK, "Pintle Hook")}</span><label>{pintleHook}</label>
                                        </div>
                                        <div className="col-sm-2 col-6"><span className="details-head">{CommonUtil.getMessageText(Constants.HAZMAT_PLACARD, "Hazmat Placard")}</span><label>{hazmat}</label>
                                        </div>
                                        <div className="col-sm-2 col-6"><span
                                            className="details-head">{CommonUtil.getMessageText(Constants.SUSPENSION, "Suspension")}</span><label>{suspension}</label></div>
                                    </div>
                                    <h6>{CommonUtil.getMessageText(Constants.TRAILERS, "Trailers")}</h6>
                                    <div className="row">
                                        <div className="col-sm-2 col-6"><span
                                            className="details-head">{CommonUtil.getMessageText(Constants.REAR_DOOR, "Rear Door")}</span><label>{rearDoor}</label></div>
                                        <div className="col-sm-2 col-6"><span
                                            className="details-head">{CommonUtil.getMessageText(Constants.SIDE_DOOR, "Side Door")}</span><label>{sideDoor}</label></div>
                                        <div className="col-sm-2 col-6"><span
                                            className="details-head">{CommonUtil.getMessageText(Constants.ROOF, "Roof")}</span><label>{roof}</label></div>
                                        <div className="col-sm-2 col-6"><span
                                            className="details-head">{CommonUtil.getMessageText(Constants.FLOOR, "Floor")}</span><label>{floor}</label></div>
                                        <div className="col-sm-2 col-6"><span className="details-head">{CommonUtil.getMessageText(Constants.FLOOR_TYPE, "Floor Type")}</span><label>{floorType}</label>
                                        </div>
                                        <div className="col-sm-2 col-6"><span className="details-head">{CommonUtil.getMessageText(Constants.INTERIOR_FINISH, "Interior Finish")}</span><label>{interior}</label>
                                        </div>
                                        <div className="col-sm-4 col-6"><span className="details-head">{CommonUtil.getMessageText(Constants.LOGIS_POST_DIST, "Logistics Posts Distance")}</span><label>{logistics}</label>
                                        </div>
                                        <div className="col-sm-4 col-6"><span className="details-head">{CommonUtil.getMessageText(Constants.DOUBLE_DECK_BEAMS, "Double Deck Beams")}</span><label>{deckBeams}</label>
                                        </div>
                                    </div>
                                    <h6>{CommonUtil.getMessageText(Constants.LIFT, "Lift")}</h6>
                                    <div className="row">
                                        <div className="col-sm-2 col-6"><span
                                            className="details-head">{CommonUtil.getMessageText(Constants.LIFT, "Lift")}</span><label>{lift}</label></div>
                                        <div className="col-sm-2 col-6"><span
                                            className="details-head">{CommonUtil.getMessageText(Constants.LIFT_TYPE, "Lift Type")}</span><label>{liftType}</label></div>
                                        <div className="col-sm-2 col-6"><span className="details-head">{CommonUtil.getMessageText(Constants.UNIT_CAPACITY, "Unit Capacity")}</span><label>{unitCapacity}</label>
                                        </div>
                                        <div className="col-sm-2 col-6"><span
                                            className="details-head">{CommonUtil.getMessageText(Constants.FLOOR, "Floor")}</span><label>{floor}</label></div>
                                    </div>
                                    <h6>{CommonUtil.getMessageText(Constants.TEMP_CONTROL, "Temperature Control")}</h6>
                                    <div className="row">
                                        <div className="col-sm-2 col-6"><span
                                            className="details-head">{CommonUtil.getMessageText(Constants.UNIT_MAKE, "Unit Make")}</span><label>{unitMake}</label></div>
                                        <div className="col-sm-2 col-6"><span className="details-head">{CommonUtil.getMessageText(Constants.UNIT_MODEL, "Unit Model")}</span><label>{unitModal}</label>
                                        </div>
                                        <div className="col-sm-2 col-6"><span
                                            className="details-head">{CommonUtil.getMessageText(Constants.UNIT_YEAR, "Unit Year")}</span><label>{unitYear}</label></div>
                                        <div className="col-sm-2 col-6"><span className="details-head">{CommonUtil.getMessageText(Constants.MULTI_TEMP, "Multi Temperature")}</span><label>{multiTemp}</label>
                                        </div>
                                    </div>
                                </section>
                                <section>
                                    <h5>{CommonUtil.getMessageText(Constants.RESERVATION_CONDITION, "Reservation Conditions")}</h5>
                                    <hr />
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <p style={{ marginBottom: "7px" }}><strong>{CommonUtil.getMessageText(Constants.CONDITIONS_RESTRICTIONS, "Conditions & Restrictions")}</strong><br />
                                                {dnuConditions}
                                            </p>
                                            <p><strong>{CommonUtil.getMessageText(Constants.ACTIVATION, "Activation")}</strong><br />
                                                {activeInstruction}
                                            </p>
                                            <p><strong>{CommonUtil.getMessageText(Constants.SECURE_DEPOSIT, "Security Deposit")}</strong><br />
                                                {deposit}
                                            </p>
                                            <p>{CommonUtil.getMessageText(Constants.PLEASE_REFER_TO, "Please refer to")} <a href="https://vhubapp.com/master-agreement-home/" target="_blank"><u>{CommonUtil.getMessageText(Constants.MASTER_LEASE_AGREEMENT, "Master Lease Agreement")}</u></a>
                                                {CommonUtil.getMessageText(Constants.RENTAL_TRANSACTIONS, "for the conditions applicable to this rental transaction.")}</p>
                                        </div>
                                        <div className="col-sm-6">
                                            <p style={{ marginBottom: "7px" }}><strong>{CommonUtil.getMessageText(Constants.EQUIP_GEO_RESTICTIONS, "Equipment Geo Restrictions")}</strong><br />
                                                {dnuEqpConditions}
                                            </p>
                                            <p><strong>{CommonUtil.getMessageText(Constants.DEACTIVATION, "Deactivation")}</strong><br />
                                                {deActiveInstruction}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-12 col-12">
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                        <div className="col-12 d-block d-md-none pb-5"></div>
                        <div className="col-12 d-block d-md-none fixed-bottom bg-white pt-1 pb-2">
                            <div className="row">
                                <div className="col-12 text-center p-2" style={{ display: "none" }}>9/27/2022 - 9/28/2022</div>
                                <div className="col-12 text-center">
                                    <button type="submit" data-toggle="modal"
                                        data-target=".bs-example-modal-center-reservation-information"
                                        className="btn btn-primary waves-effect waves-light w-100 pt-2 pb-2">{CommonUtil.getMessageText(Constants.RESERVATION, "Reservation")}
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-5 d-none d-md-block">
                            <button type="submit" className="btn btn-primary waves-effect waves-light w-100 pt-2 pb-2" style={{ display: (!isPublicSearch ? "" : "none") }} onClick={onClickFavorities}>
                                <i className="fa fa-heart"></i> &nbsp;{isFavourExit ? "Remove Favorities" : "Add to Favorites"}
                            </button>
                            <div className="ml-sm-3 equip-public-favor" style={{ paddingTop: "10px" }}>
                                <section>
                                    {(isOwner) ? <div><span>Owner Name:</span> <label>{orgName}</label></div> : ""}

                                    <h5 style={{ paddingTop: "15px" }}>{CommonUtil.getMessageText(Constants.RESERVATION_INFO, "Reservation Information")}</h5>
                                    <hr />

                                    {isOwner && closeNetworkList?.length > 0 ? <div className="row">
                                        <div className="col-sm-12">
                                            <div className="form-group">
                                                <label>Carrier Name</label>
                                              
                                                <SingleSelectComp width={"100%"}  
                                                selectedValue={selectedcloseNetwork}
                                                dataSource={closeNetworkList} 
                                                handleChange={onChangeCloseNetwork} 
                                                value={"renterOrgId"} label={"renterOrgName"}
                                                disabled={availabilityViewType == "PUBLIC_VIEW" ? true : false}
                                                />
                                            </div>
                                        </div>
                                    </div>:""}

                                    <div className="form-group row">
                                        <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.AVAILABILITY_TYPE, "Available type")}</label>
                                        <div className="col-sm-12 slection-drop">
                                            <input type="text" disabled={true} value={availType} className="form-control" name="start" />
                                            <div id="hidden_divi2" style={{ display: (availType == CommonUtil.EQUIP_AVAIL_REPOSITION_TYPE) ? "" : "none" }}>
                                                <div className="row">
                                                    <div className="col-sm-12">
                                                        <div className="form-group row mt-3 Availabilities-table font-15">
                                                            <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.DEP_ARRIVAL, "Departure - Arrival")}</label>
                                                            <div className="col-1">
                                                                <i className="fa fa-map-marker" style={{ display: "block" }}></i>
                                                                <i className="fa fa-map-marker"></i>
                                                            </div>
                                                            <div className="col-11 p-0">
                                                                <span>{departure}</span>
                                                                <br /> <br />
                                                                <span>{arrival}</span></div>
                                                            <div className="col-12"><br /><small>{CommonUtil.getMessageText(Constants.FULL_ADDR_RECEIVE, "Full Address received after reservation")}</small></div>
                                                        </div>


                                                        <div className="row">
                                                            <div className="col-sm-12">
                                                                <div className="form-group">
                                                                    <label>Owner's Availability Dates</label>
                                                                    <div style={{ display: "flex", pointerEvents: "none" }}>
                                                                        <div className="col-sm-6" style={{ paddingLeft: "0px" }}>
                                                                            <Flatpickr placeholder="Start Date" ref={stDateAvlRepRef} options={CommonUtil.DATE_FORMAT} className="form-control flat-picker" />
                                                                        </div>
                                                                        &nbsp;
                                                                        <div className="col-sm-6" style={{ paddingLeft: "0px", paddingRight: "4px" }}>
                                                                            <Flatpickr placeholder="End Date" ref={endDateAvlRepRef} options={CommonUtil.DATE_FORMAT} className="form-control flat-picker" />
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-sm-12">
                                                                <div className="form-group">
                                                                    <label>Reservation Dates</label>
                                                                    <div style={{ display: "flex" }}>
                                                                        <div className="col-sm-6 highlight-border-date-fields" style={{ paddingLeft: "0px" }}>
                                                                            <Flatpickr placeholder="Start Date" ref={stDateRef1} onChange={onChangeStDate1} options={CommonUtil.DATE_FORMAT} className="form-control flat-picker" />
                                                                        </div>
                                                                        &nbsp;
                                                                        <div className="col-sm-6 highlight-border-date-fields" style={{ paddingLeft: "0px", paddingRight: "4px" }}>
                                                                            <Flatpickr placeholder="End Date" ref={endDateRef1} onChange={onChangeEdDate1} options={CommonUtil.DATE_FORMAT} className="form-control flat-picker" />
                                                                        </div>
                                                                        <div className="input-daterange input-group" id="date-range" style={{ display: "none" }}>
                                                                            <input type="text" className="form-control" value={dateString} disabled={true} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row" style={{ display: ((isInuranceChecked && isInuranceRenterChecked) ? "" : "none") }}>
                                                            <div className="row" style={{ display: ((liabilityPaidByRenter) ? "" : "none") }}>
                                                                <div className="col-sm-1 col-12">
                                                                    <div className="form-group row" style={{ paddingTop: "30px" }}>
                                                                        <div className="col-sm-12 col-12">
                                                                            <input ref={chkLiabilityRep} type="checkbox" className="checkBox" style={{ width: "25px", height: "25px" }}
                                                                                disabled={insurStatus ? true : false} onChange={() => { calculatePrice() }} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-sm-6 col-12">
                                                                    <div className="form-group row">
                                                                        <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.LIABILITY_INSURANCE_PLAN, "Liability Insurance Plan")}</label>
                                                                        <div className="col-sm-12 col-12">
                                                                            <input type="text" className="form-control" value={liabilityPlan} disabled={true} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-sm-4 col-12">
                                                                    <div className="form-group row">
                                                                        <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.RATE_PER_DAY, "Rate per day")}</label>
                                                                        <div className="col-sm-12 col-12">
                                                                            <input type="text" className="form-control"
                                                                                placeholder={CommonUtil.getMessageText(Constants.RATE_PER_DAY, 'Rate per day')} value={liabilityPlanPerDay}
                                                                                maxLength={10} disabled={true}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row" style={{ display: ((physicalDamagePaidByRenter) ? "" : "none") }}>
                                                                <div className="col-sm-1 col-12">
                                                                    <div className="form-group row" style={{ paddingTop: "30px" }}>
                                                                        <div className="col-sm-12 col-12">
                                                                            <input ref={chkPhysicalDamageRep} type="checkbox" className="checkBox" style={{ width: "25px", height: "25px" }}
                                                                                disabled={insurStatus ? true : false} onChange={() => { calculatePrice() }} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-sm-6 col-12">
                                                                    <div className="form-group row">
                                                                        <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.PHYSICAL_DAMAGE_INSURANCE_PLAN, "Physical Damage Insurance Plan")}</label>
                                                                        <div className="col-sm-12 col-12">
                                                                            <input type="text" className="form-control" value={physicalDamagePlan} disabled={true} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-sm-4 col-12">
                                                                    <div className="form-group row">
                                                                        <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.RATE_PER_DAY, "Rate per day")}</label>
                                                                        <div className="col-sm-12 col-12">
                                                                            <input type="text" className="form-control"
                                                                                placeholder={CommonUtil.getMessageText(Constants.RATE_PER_DAY, 'Rate per day')} value={physicalDamagePlanPerDay}
                                                                                maxLength={10} disabled={true}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="btn-group w-100" data-toggle="buttons" style={{ display: "none" }}>
                                                            <label className="btn btn-default  m-0  p-1" style={{ border: "0px" }} id={"rdRepositionCad"}>
                                                                <input type="radio" name="colour" id="cad"
                                                                    value="cad" checked={true} style={{ display: "none" }} /> CAD
                                                            </label>
                                                            <label className="btn btn-default m-0 p-1" style={{ border: "0px" }} id={"rdRepositionUS"}>
                                                                <input type="radio" name="colour" id="usd" value="usd" style={{ display: "none" }} /> USD
                                                            </label>
                                                        </div>
                                                        {
                                                            (availabilityViewType=="SPECIFIC_VIEW"||availabilityViewType=="PRIVATE_VIEW"|| isPublicSearch || CommonUtil.ORGANIZATION_TYPE == CommonUtil.ORG_TYPE_RENTER || CommonUtil.ORGANIZATION_TYPE == CommonUtil.ORG_TYPE_OWNER_RENTER) ?
                                                                (<div>
                                                                    <div className={`row mb-3 ${isTncConfig ? "":"d-none"}`}>{/* tnc and agreement checkbox */}
                                                                        <div className="col-sm-6 col-6">
                                                                            <div className="d-flex align-items-center m-0 p-0" style={{ border: "0px" }} >
                                                                                <div className="pr-3"><input type="checkbox" className="checkBox" style={{ width: "25px", height: "25px" }} checked={terms} onChange={(e)=>{setTerms(e.target.checked)}}/></div>
                                                                                <span className="px-3" style={{fontWeight:600}}>{` Accept ${orgName}'s `}<a style={{color:"#0d6efd",textDecoration:"underline"}} onClick={()=>onDownloadDoc(termsFile)}>{CommonUtil.getMessageText(Constants.TERMS_AND_CONDITIONS, "Terms and Conditions")}</a></span>
                                                                            </div> 
                                                                        </div>
                                                                        <div className="col-sm-6 col-6">
                                                                            <div className="d-flex align-items-center m-0 p-0" style={{ border: "0px" }} >
                                                                                <div className="pr-3"><input type="checkbox" className="checkBox" style={{ width: "25px", height: "25px" }} checked={agreement} onChange={(e)=>{setAgreement(e.target.checked)}}/></div>
                                                                                <span className="px-3" style={{fontWeight:600}}>{` Accept ${orgName}'s `}<a style={{color:"#0d6efd",textDecoration:"underline"}} onClick={()=>onDownloadDoc(agreementFile)}>{CommonUtil.getMessageText(Constants.MASTER_RENTAL_AGREEMENT, "Master Rental Agreement")}</a></span>
                                                                            </div> 
                                                                        </div>
                                                                        
                                                                    </div>
                                                                    <button type="submit" className="btn btn-warning waves-effect waves-light w-100 pt-2 pb-2"
                                                                        onClick={onClickMakeOffer} style={{ display: (allowOffer == "Y" ? "" : "none") }}>
                                                                        {CommonUtil.getMessageText(Constants.MAKE_AN_OFFER, "Make an Offer")}
                                                                    </button>
                                                                    <button type="submit" id="btnConfirmReserve" className="btn btn-primary waves-effect waves-light w-100 mt-3 pt-2 pb-2"
                                                                        onClick={onClickConfirmReservation}>
                                                                        {CommonUtil.getMessageText(Constants.CONFIRM_RESERVATION, "Confirm Reservation")}
                                                                    </button>
                                                                </div>)
                                                                : ""}

                                                    </div>
                                                </div>
                                                <div className="row mt-3 font-15">
                                                    <div className="col-sm-12 ">
                                                        <h6>Estimate</h6>
                                                        <div className="row estimate  mb-2">
                                                            <div className="col-9 pr-0">
                                                                <i className="fa fa-usd"></i>
                                                                <p style={{ paddingLeft: "10px", fontSize: "15px" }}>{CommonUtil.getMessageText(Constants.PAID_PRICE_CARRIER, "Paid price to carrier making the move")}<small>{noofDays}
                                                                    &nbsp;{CommonUtil.getMessageText(Constants.DAYS_REPOSITION, "day(s) for repositioning")}</small></p>
                                                            </div>
                                                            <div className="col-3 pl-0 text-right">{defPrice}&nbsp;{Number(movePrice).toFixed(2)}
                                                            </div>
                                                        </div>
                                                        <div className="row estimate mb-2">
                                                            <div className="col-9 pr-0">
                                                                <i className="fa fa-exclamation"></i>
                                                                <p style={{ paddingLeft: "10px", fontSize: "15px" }}>{CommonUtil.getMessageText(Constants.EXCESS_DAILY_TOTAL, "Excess daily total")} <small>{rextradays} {CommonUtil.getMessageText(Constants.DAYS, "day(s)")} X
                                                                    &nbsp;{defPrice}{addtionalPrice}/{CommonUtil.getMessageText(Constants.DAY, "day")}</small></p>
                                                            </div>
                                                            <div className="col-3 pl-0 text-right">{defPrice} {rdurationPrice} </div>
                                                        </div>
                                                        <div className="row estimate mb-2" style={{ display: ((physicalDamagePaidByRenter || liabilityPaidByRenter) && (chkLiabilityRep?.current?.checked || chkPhysicalDamageRep?.current?.checked) ? "" : "none") }}>
                                                            <div className="col-9 pr-0">
                                                                <i className="fa fa-exclamation"></i>
                                                                <p style={{ paddingLeft: "10px", fontSize: "15px" }}>{"Insurance amount"}
                                                                    <small style={{ display: "flex" }}><small style={{ display: isLiabilityVisible ? "" : "none" }}>({rDays} {CommonUtil.getMessageText(Constants.DAYS, "day(s)")} X
                                                                        &nbsp;{liabilityPlanPerDay})</small>  <small style={{ display: isPhysicalDamageVisible ? "" : "none" }}>+({rDays} {CommonUtil.getMessageText(Constants.DAYS, "day(s)")} X {physicalDamagePlanPerDay})</small>
                                                                    </small></p>
                                                            </div>
                                                            <div className="col-3 pl-0 text-right">{defPrice} {insuranceAmount} </div>
                                                        </div>
                                                        <div className="row estimate mb-2" style={{ display: ((physicalDamagePaidByRenter || liabilityPaidByRenter) && (chkLiabilityRep?.current?.checked || chkPhysicalDamageRep?.current?.checked) ? "" : "none") }}>
                                                            <div className="col-9 pr-0">
                                                                <i className="fa fa-exclamation"></i>
                                                                <p style={{ paddingLeft: "10px", fontSize: "15px" }}>{"Administration fee"}
                                                                    {/* <small>10% of the insurance amount </small> */}
                                                                    <small>{`flat rate of ${String(defaultCurrency).toUpperCase().indexOf("USD") !== -1 ? usInsuranceRate : canadaInsuranceRate}`}</small>
                                                                </p>
                                                            </div>
                                                            {/* <div className="col-3 pl-0 text-right">{defPrice} {insurancePercent} </div> */}
                                                            <div className="col-3 pl-0 text-right">{` ${defPrice} -${String(defaultCurrency).toUpperCase().indexOf("USD") !== -1 ? usInsuranceRate : canadaInsuranceRate}`} </div>
                                                        </div>
                                                        {/* <div className="row estimate mb-2">
                                                                <div className="col-9 pr-0">
                                                                    <i className="fa fa-exclamation"></i>
                                                                    <p style={{paddingLeft:"10px",fontSize:"15px"}}>{"Platform fee"}</p>                                                                 
                                                                </div>
                                                                <div className="col-3 pl-0 text-right">{` ${defPrice} ${platformFees}`} </div>
                                                            </div> */}
                                                        <div className="row  mt-3 mb-2">
                                                            <div className="col-8 p-3 bg-light">
                                                                <h6>{CommonUtil.getMessageText(Constants.YOU_WILL_EARN, "You will earn")}:</h6>
                                                            </div>
                                                            <div className="col-4 text-right p-3 bg-light">
                                                                <h6>{defPrice} {((repositionPrice))}</h6>
                                                            </div>
                                                        </div>
                                                        <p>The estimates shown do not include taxes and should be
                                                            used for reference only as it is subject to adjustment
                                                            in accordance with the Payment Terms. Please refer to <a
                                                                href="https://vhubapp.com/payment-terms/" target="_blank"><u>Payment Terms</u></a> for full details.
                                                        </p>
                                                        <div className="col-sm-12 shadow-sm p-2 border border-danger mt-4">{CommonUtil.getMessageText(Constants.WILL_BE_CHARGED, "You will be charged for each excess day that the trailer is late.")}</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div id="hidden_div" style={{ display: (availType == CommonUtil.EQUIP_AVAIL_RENTAL_TYPE) ? "" : "none" }}>
                                                <div className="row">
                                                    <div className="col-sm-12">
                                                        <div className="form-group row  mt-3 ">
                                                            <div className="col-sm-12">
                                                                <label className="col-form-label">{CommonUtil.getMessageText(Constants.DEPARTURE, "Departure")}</label>
                                                                <input type="text" disabled={true} value={departure} className="form-control" />

                                                            </div>
                                                            <div className="col-sm-12">
                                                                <label className="col-form-label">{CommonUtil.getMessageText(Constants.ARRIVAL, "Arrival")}</label>
                                                                <input type="text" disabled={true} value={arrival} className="form-control" />
                                                            </div>
                                                            <small style={{ paddingTop: "10px" }}>{CommonUtil.getMessageText(Constants.FULL_ADDRESS, "Full Address received after reservation")}</small>
                                                        </div>

                                                        <div className=" row">
                                                            <div className="col-sm-12">
                                                                <div className="form-group">
                                                                    <label>Owner's Availability Dates</label>
                                                                    <div style={{ display: "flex", pointerEvents: "none" }}>
                                                                        <div className="col-sm-6" style={{ paddingLeft: "0px" }}>
                                                                            <Flatpickr placeholder="Start Date" ref={stDateAvlRepRef1} options={CommonUtil.DATE_FORMAT} className="form-control flat-picker" />
                                                                        </div>
                                                                        &nbsp;
                                                                        <div className="col-sm-6" style={{ paddingLeft: "0px", paddingRight: "4px" }}>
                                                                            <Flatpickr placeholder="End Date" ref={endDateAvlRepRef1} options={CommonUtil.DATE_FORMAT} className="form-control flat-picker" />
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className=" row">
                                                            <div className="col-sm-12">
                                                                <div className="form-group">
                                                                    <label>Reservation Dates</label>
                                                                    <div style={{ display: "flex" }}>
                                                                        <div className="col-sm-6 highlight-border-date-fields" style={{ paddingLeft: "0px" }}>
                                                                            <Flatpickr placeholder="Start Date" ref={stDateRef} onChange={onChangeStDate} options={CommonUtil.DATE_FORMAT} className="form-control flat-picker" />
                                                                        </div>
                                                                        &nbsp;
                                                                        <div className="col-sm-6 highlight-border-date-fields" style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                                                                            <Flatpickr placeholder="End Date" ref={endDateRef} onChange={onChangeEdDate} options={CommonUtil.DATE_FORMAT} className="form-control flat-picker" />
                                                                        </div>
                                                                        <div className="input-daterange input-group" id="date-range" style={{ display: "none" }}>
                                                                            <input type="text" disabled={true} className="form-control" value={dateString} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row" style={{ display: ((isInuranceChecked && isInuranceRenterChecked) ? "" : "none") }}>
                                                            <div className="row" style={{ display: ((liabilityPaidByRenter) ? "" : "none") }}>
                                                                <div className="col-sm-1 col-12">
                                                                    <div className="form-group row" style={{ paddingTop: "30px" }}>
                                                                        <div className="col-sm-12 col-12">
                                                                            <input ref={chkLiability} type="checkbox" className="checkBox" style={{ width: "25px", height: "25px" }}
                                                                                disabled={insurStatus ? true : false} onChange={() => { calculatePrice() }} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-sm-6 col-12">
                                                                    <div className="form-group row">
                                                                        <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.LIABILITY_INSURANCE_PLAN, "Liability Insurance Plan")}</label>
                                                                        <div className="col-sm-12 col-12">
                                                                            <input type="text" className="form-control" value={liabilityPlan} disabled={true} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-sm-4 col-12">
                                                                    <div className="form-group row">
                                                                        <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.RATE_PER_DAY, "Rate per day")}</label>
                                                                        <div className="col-sm-12 col-12">
                                                                            <input type="text" className="form-control"
                                                                                placeholder={CommonUtil.getMessageText(Constants.RATE_PER_DAY, 'Rate per day')} value={liabilityPlanPerDay}
                                                                                maxLength={10} disabled={true}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row" style={{ display: ((physicalDamagePaidByRenter) ? "" : "none") }}>
                                                                <div className="col-sm-1 col-12">
                                                                    <div className="form-group row" style={{ paddingTop: "30px" }}>
                                                                        <div className="col-sm-12 col-12">
                                                                            <input ref={chkPhysicalDamage} type="checkbox" className="checkBox" style={{ width: "25px", height: "25px" }}
                                                                                disabled={insurStatusPhy ? true : false} onChange={() => { calculatePrice() }} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-sm-6 col-12">
                                                                    <div className="form-group row">
                                                                        <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.PHYSICAL_DAMAGE_INSURANCE_PLAN, "Physical Damage Insurance Plan")}</label>
                                                                        <div className="col-sm-12 col-12">
                                                                            <input type="text" className="form-control" value={physicalDamagePlan} disabled={true} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-sm-4 col-12">
                                                                    <div className="form-group row">
                                                                        <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.RATE_PER_DAY, "Rate per day")}</label>
                                                                        <div className="col-sm-12 col-12">
                                                                            <input type="text" className="form-control"
                                                                                placeholder={CommonUtil.getMessageText(Constants.RATE_PER_DAY, 'Rate per day')} value={physicalDamagePlanPerDay}
                                                                                maxLength={10} disabled={true}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="row">
                                                            <div className="col-sm-12">
                                                                <div className="form-group">
                                                                    <label>{CommonUtil.getMessageText(Constants.DISTANCE_TO_TRAVEL, "Distance to travel")}</label>
                                                                    <div>
                                                                        <div className="row">
                                                                            <div className="input input-group" id="date-range1" style={{ width: "95%" }}>
                                                                                <div className="col-9" style={{ display: "flex", alignItems: "center" }}>
                                                                                    <i className="fa fa fa-map-signs"></i> &nbsp;&nbsp;
                                                                                    <input type="number" className="form-control" value={distance} onChange={(e) => { onChangeDistance(e) }} />
                                                                                </div>
                                                                                <div className="col-3 text-left" style={{ paddingLeft: "5px", paddingTop: "5px" }}>{mesurement}/{CommonUtil.getMessageText(Constants.DAY, "DAY")}</div>
                                                                            </div>
                                                                            {minInvoiceAmount && <div className="col-12 mx-2 mt-1"><small>{`The estimated distance charge will be a minimum of $${minInvoiceAmount} for $${distance} ${mesurement}/${CommonUtil.getMessageText(Constants.DAY, "DAY")} upon extension of the reservation.`}</small></div>}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row" style={{ display: (CommonUtil.SELECT_EQUIPMENT_TYPE == CommonUtil.REEFER_EQUIP_TYPE ? "" : "none") }}>
                                                            <div className="col-sm-12">
                                                                <div className="form-group">
                                                                    <label>{CommonUtil.getMessageText(Constants.ENGINE_UNIT_USAGE, "Engine unit usage")}</label>
                                                                    <div>
                                                                        <div className="row">
                                                                            <div className="input input-group" id="date-range1" style={{ width: "95%" }}>
                                                                                <div className="col-9" style={{ display: "flex", alignItems: "center" }}>
                                                                                    <i className="fa fa fa-map-signs"></i> &nbsp;&nbsp;
                                                                                    <input type="number" className="form-control" value={engineUnitUsage} onChange={(e) => { onChangeEngineUsage(e) }} />
                                                                                </div>
                                                                                <div className="col-3 text-left" style={{ paddingLeft: "0px", paddingTop: "5px" }}>&nbsp;{CommonUtil.getMessageText(Constants.H_DAY, "h/day")}</div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="btn-group w-100" data-toggle="buttons" style={{ display: "none" }}>
                                                            <label style={{ border: "0px" }} className="btn btn-default  m-0  p-1" id={"rdRentalCad"}>
                                                                <input type="radio" name="colour" id="cad" value="cad" checked={true} style={{ display: "none" }} /> CAD
                                                            </label>
                                                            <label className="btn btn-default m-0 p-1" style={{ border: "0px" }} id={"rdRentalUS"}>
                                                                <input type="radio" name="colour" id="usd" value="usd" style={{ display: "none" }} /> USD
                                                            </label>
                                                        </div>
                                                        {
                                                            (availabilityViewType=="SPECIFIC_VIEW"||availabilityViewType=="PRIVATE_VIEW" || isPublicSearch || CommonUtil.ORGANIZATION_TYPE == CommonUtil.ORG_TYPE_RENTER || CommonUtil.ORGANIZATION_TYPE == CommonUtil.ORG_TYPE_OWNER_RENTER) ?
                                                                (<div>
                                                                    <div className={`row mb-3 ${isTncConfig ? "":"d-none"}`}>{/* tnc and agreement checkbox */}
                                                                        <div className="col-sm-6 col-6">
                                                                            <div className="d-flex align-items-center m-0 p-0" style={{ border: "0px" }} >
                                                                                <div className="pr-3"><input type="checkbox" className="checkBox" style={{ width: "25px", height: "25px" }} checked={terms} onChange={(e)=>{setTerms(e.target.checked)}}/></div>
                                                                                <span className="px-3" style={{fontWeight:600}}>{` Accept ${orgName}'s `}<a style={{color:"#0d6efd",textDecoration:"underline"}} onClick={()=>onDownloadDoc(termsFile)}>{CommonUtil.getMessageText(Constants.TERMS_AND_CONDITIONS, "Terms and Conditions")}</a></span>
                                                                            </div> 
                                                                        </div>
                                                                        <div className="col-sm-6 col-6">
                                                                            <div className="d-flex align-items-center m-0 p-0" style={{ border: "0px" }} >
                                                                                <div className="pr-3"><input type="checkbox" className="checkBox" style={{ width: "25px", height: "25px" }} checked={agreement} onChange={(e)=>{setAgreement(e.target.checked)}}/></div>
                                                                                <span className="px-3" style={{fontWeight:600}}>{` Accept ${orgName}'s `}<a style={{color:"#0d6efd",textDecoration:"underline"}} onClick={()=>onDownloadDoc(agreementFile)}>{CommonUtil.getMessageText(Constants.MASTER_RENTAL_AGREEMENT, "Master Rental Agreement")}</a></span>
                                                                            </div> 
                                                                        </div>
                                                                    </div>

                                                                    <button type="submit" className="btn btn-warning waves-effect waves-light w-100 pt-2 pb-2"
                                                                        style={{ display: (allowOffer == "Y" ? "" : "none") }} onClick={onClickMakeOffer}>
                                                                        {CommonUtil.getMessageText(Constants.MAKE_AN_OFFER, "Make an Offer")}
                                                                    </button>
                                                                    &nbsp;
                                                                    <button type="submit" id="btnConfirmReserve1" className="btn btn-primary waves-effect waves-light w-100  pt-2 pb-2" onClick={checkforTnCAgreement}>
                                                                        {CommonUtil.getMessageText(Constants.CONFIRM_RESERVATION, "Confirm Reservation")}
                                                                    </button>
                                                                </div>) : ""
                                                        }
                                                    </div>
                                                </div>
                                                <div className="row mt-4">
                                                    <div className="col-sm-12">
                                                        <h6>Rates</h6>
                                                        <div className="row">
                                                            <div className="col-sm-4 col-6">{CommonUtil.getMessageText(Constants.DAILY_RATES, "Daily Rates")}
                                                                <label>{defPrice} {dailyRate}/{CommonUtil.getMessageText(Constants.DAY, "DAY")}</label>
                                                            </div>
                                                            <div className="col-sm-4 col-6">{CommonUtil.getMessageText(Constants.WEEKLY_RATES, "Weekly Rates")}
                                                                <label>{defPrice} {weeklyRate}/{CommonUtil.getMessageText(Constants.WEEK, "WEEK")}</label>
                                                            </div>
                                                            <div className="col-sm-4 col-6">{CommonUtil.getMessageText(Constants.MONTHLY_RATES, "Monthly Rates")}
                                                                <label>{defPrice} {monthlyRate}/{CommonUtil.getMessageText(Constants.MONTH, "MONTH")}</label>
                                                            </div>
                                                            <div className="col-sm-8 col-6 mt-2">{CommonUtil.getMessageText(Constants.DISTANCE_RATES, "Distance Rates")}
                                                                <label>{defPrice} {distanceRate}/{mesurement}</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row mt-2 font-15">
                                                    <div className="col-sm-12 ">
                                                        <h6>Estimate </h6>
                                                        <div className="row estimate mb-2">
                                                            <div className="col-8">
                                                                <i className="fa fa-calendar"></i>
                                                                {/* <p className={"paragraph"}>{CommonUtil.getMessageText(Constants.DURATION, "Duration")}<small>{rateObj.type === Constants.MONTH ? rateObj.NoofMonths:rateObj.type === Constants.WEEK ? rateObj.NoofWeeks:rateObj.Noofdays } 
                                                                    {`${String(rateObj.type).toLowerCase()}(s)`} * {defPrice} {rateObj.rate} / {rateObj.type}</small></p> */}
                                                                <p className={"paragraph"}>{CommonUtil.getMessageText(Constants.DURATION, "Duration")}<small>{days} {CommonUtil.getMessageText(Constants.DAYS, "Days")} * {defPrice} {(rateObj.ratePerDay.toFixed(2))} / {CommonUtil.getMessageText(Constants.DAYS, "Days")}</small></p>
                                                            </div>
                                                            <div className="col-4 text-right">{defPrice} {durationPrice}
                                                            </div>
                                                        </div>
                                                        <div className="row estimate mb-2">
                                                            <div className="col-8">
                                                                <i className="fa fa fa-map-signs"></i>
                                                                <p className={"paragraph"}>{CommonUtil.getMessageText(Constants.DISTANCE, "Distance")} <small>{days} {CommonUtil.getMessageText(Constants.DAYS, "Days")} * {distance} {mesurement}/{CommonUtil.getMessageText(Constants.DAY, "DAY")} * {defPrice} {distanceRate} /{mesurement}</small></p>
                                                            </div>
                                                            <div className="col-4 text-right">{defPrice} {distancePrice}
                                                            </div>
                                                        </div>
                                                        <div className="row estimate mb-2" style={{ display: (CommonUtil.SELECT_EQUIPMENT_TYPE == CommonUtil.REEFER_EQUIP_TYPE ? "" : "none") }}>
                                                            <div className="col-8">
                                                                <i className="fa fa fa-map-signs"></i>
                                                                <p className={"paragraph"}>{CommonUtil.getMessageText(Constants.ENGINE_UNIT_USAGE, "Engine usage unit")} <small>{days} {CommonUtil.getMessageText(Constants.DAYS, "Days")} * {engineUnitUsage} {CommonUtil.getMessageText(Constants.HOURS, "Hours/Day")} * {defPrice} {engineRate} /{CommonUtil.getMessageText(Constants.HOUR, "hour")}</small></p>
                                                            </div>
                                                            <div className="col-4 text-right">{defPrice} {engineUnitUsagePrice}
                                                            </div>
                                                        </div>
                                                        <div className="row estimate mb-2" style={{ display: ((physicalDamagePaidByRenter || liabilityPaidByRenter) && (chkLiability?.current?.checked || chkPhysicalDamage?.current?.checked) ? "" : "none") }}>
                                                            <div className="col-9 pr-0">
                                                                <i className="fa fa-exclamation"></i>
                                                                <p style={{ paddingLeft: "10px", fontSize: "15px" }}>{"Insurance amount"}
                                                                    <small style={{ display: "flex" }}><small style={{ display: isLiabilityVisible ? "" : "none" }}>({rDays} {CommonUtil.getMessageText(Constants.DAYS, "day(s)")} X
                                                                        &nbsp;{liabilityPlanPerDay})</small>  <small style={{ display: isPhysicalDamageVisible ? "" : "none" }}>+({rDays} {CommonUtil.getMessageText(Constants.DAYS, "day(s)")} X {physicalDamagePlanPerDay})</small></small></p>
                                                            </div>
                                                            <div className="col-3 pl-0 text-right">{defPrice} {insuranceAmount} </div>
                                                        </div>
                                                        <div className="row estimate mb-2" style={{ display: ((physicalDamagePaidByRenter || liabilityPaidByRenter) && (chkLiability?.current?.checked || chkPhysicalDamage?.current?.checked) ? "" : "none") }}>
                                                            <div className="col-9 pr-0">
                                                                <i className="fa fa-exclamation"></i>
                                                                <p style={{ paddingLeft: "10px", fontSize: "15px" }}>{"Administration fee"}
                                                                    {/* <small>10% of the insurance amount </small> 2087 */}
                                                                    <small>{`flat rate of ${String(defaultCurrency).toUpperCase().indexOf("USD") !== -1 ? usInsuranceRate : canadaInsuranceRate}`}</small>
                                                                </p>
                                                            </div>
                                                            <div className="col-3 pl-0 text-right">{defPrice} {insurancePercent} </div>
                                                        </div>
                                                        <div className="row estimate mb-2" style={{display:serviceFreePrice ? "": "none"}}>
                                                            <div className="col-8">
                                                                <i className="fa fa-cogs"></i>
                                                                <p className={"paragraph"}>{CommonUtil.getMessageText(Constants.SERVICE_FEE, "Service Fee")}<small>{renter}%</small></p>
                                                            </div>
                                                            <div className="col-4 text-right">{defPrice} {serviceFreePrice}
                                                            </div>
                                                        </div>
                                                        <div className="row  mt-3 mb-2">
                                                            <div className="col-8 p-3 bg-light">
                                                                <h6>{CommonUtil.getMessageText(Constants.TOTAL_BEFORE_TAXES, "Total Before Taxes")}:</h6>
                                                            </div>
                                                            <div className="col-4 text-right p-3 bg-light">
                                                                <h6>{defPrice} {totalAmout}</h6>
                                                            </div>
                                                        </div>
                                                        <p style={{ display: "none" }}>The estimates shown do not include taxes and should be
                                                            used for reference only as it is subject to adjustment
                                                            in accordance with the Payment Terms. Please refer to <a
                                                                href="https://vhubapp.com/payment-terms/" target="_blank"><u>Payment Terms</u></a> for full details.
                                                        </p>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default EquipPublicView;
