import React, { useState } from "react";

const OrgUserRenderer = (props: any) => {
  const [hover, setHover] = useState(false);

  const onClickUser = () => {
    if (props.context) {
      props.context.onClickUserDetails(props.data);
    }
  };

  const onClickDeleteUser = () => {
    if (props.context) {
      props.context.onClickUserRemove(props.data);
    }
  };

  return (
    <div
      className="cellRenderHeader"
      onMouseOver={() => setHover(true)}
      onMouseOut={() => setHover(false)}
    >
      <a>
        <span onClick={onClickUser} className="cellRender">
          {props.value}
        </span>
      </a>
      {props?.data?.status === "Invited" && (
        <span
          className="ms-2 p-1"
          style={{
            color: "red",
            visibility: hover ? "visible" : "hidden",
            cursor: "pointer",
          }}
          onClick={onClickDeleteUser}
        >
          <svg id="fi_6861362" height="16" viewBox="0 0 512 512" width="16" xmlns="http://www.w3.org/2000/svg" data-name="Layer 1"><path d="m170.8 14.221a14.21 14.21 0 0 1 14.2-14.207l141.991-.008a14.233 14.233 0 0 1 14.2 14.223v35.117h-170.391zm233.461 477.443a21.75 21.75 0 0 1 -21.856 20.33h-254.451a21.968 21.968 0 0 1 -21.854-20.416l-21.774-318.518h343.174l-23.234 318.6zm56.568-347.452h-409.658v-33a33.035 33.035 0 0 1 33.005-33.012l343.644-.011a33.051 33.051 0 0 1 33 33.02v33zm-270.79 291.851a14.422 14.422 0 1 0 28.844 0v-202.247a14.42 14.42 0 0 0 -28.839-.01v202.257zm102.9 0a14.424 14.424 0 1 0 28.848 0v-202.247a14.422 14.422 0 0 0 -28.843-.01z" fill="#fc0005" fill-rule="evenodd"></path></svg>
        </span>
      )}
    </div>
  );
};

export default OrgUserRenderer;
