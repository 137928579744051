import React, {useEffect, useRef, useState} from "react";
import orgUserRenderer from "../Organization/OrgUserRenderer";
import InspectionSelect from "../InspectionGrid/InspectionSelect";
import useStateWithCallback from "use-state-with-callback";
import SingleSelectComp from "../../Components/SingleSelectComp";
import {IAGrid} from "../../Components/IInterfaces";
import {Modal} from "react-bootstrap";
import DraggableComponent from "../../Components/DraggableComponent";
import InspectionRenderer from "../InspectionGrid/InspectionRenderer";
import {CommonUtil} from "../../utils/CommonUtil";
import {NetworkManager} from "../../Components/NetworkManager";
import ToastManager from "../../Components/ToastManager";
import {StatusCodeUtil} from "../../utils/StatusCodeUtil";
import orgUserStatucRenderer from "../Organization/OrgUserStatucRenderer";
import Confirm from "../../Components/confirm";
import $ from "jquery";
import {Constants} from "../Languages/Constants";
import InspectionStatusRenderer from "../InspectionGrid/InspectionStatusRenderer";
import PhoneInput from "react-phone-input-2";
import {useNavigate} from "react-router-dom";
import InspectionGrid from "../InspectionGrid/InspectionGrid";
import '../InspectionCompany/Inspection.css'

var userMode:string = "";
let userObj:any = null;
const UserDetails = ()=>{

    const [users,setUsers] = useStateWithCallback([],()=>{
        if(userGridRef1.current){
            userGridRef1.current.columnFittoSize();
        }
    });
    const [showUserPopup,setShowUserPopup] = useState(false);
    const [showUserStatusPopup,setShowUerStatusPopup] = useState(false);

    const userGridRef1 = useRef<IAGrid>(null);
    const divRef = useRef<HTMLDivElement>(null);
    const fnRef = useRef<HTMLInputElement>(null);
    const lnRef = useRef<HTMLInputElement>(null);
    const emailRef = useRef<HTMLInputElement>(null);

    const navigate = useNavigate();
    
    const [email,setEmailId] = useState("");
    const [inspectorFirstName,SetInspectorFirstName] = useState('');
    const [inspectorLastName,setInspectorLastName] = useState("");
    const [phoneNumber,setPhoneNumber] = useState("");
    const [locationAssigned,setLocationAssigned] = useState("")

    const [status,setStatues] = useState(CommonUtil.inspectorStatus);
    const [statusObj,setStatusObj]  = useState<any>(null);
    const [inspectorType,setInspectorType] = useState(CommonUtil.userType);
    const [inspectorTypeObj,setInspectorTypeObj]  = useState<any>(null);

    const [inspectorTypeClass,setInspectorTypeClass] = useState("mandatory");
    const [statusClass,setStatusClass] = useState("mandatory");
    const [phoneClass, setPhoneClass] = useState('mandatory');

    const [typeDiable,setTypeDisable] = useState(true);
    const [resend,setResend] = useState(false);



    const [userStatus,setUserStatus] = useState("");
    const [userColumns] = useState([

        { field: 'email', headerName:CommonUtil.getMessageText(Constants.EMAIL,'Email'),sortable:true,cellStyle: { 'fontWeight': '400','letterSpacing':'0px','fontSize':'13px','fontFamily':'poppins'},headerClass: 'header-white',cellRenderer: InspectionRenderer},
        { field: 'inspectorFirstName', headerName:CommonUtil.getMessageText(Constants.INSPECTOR_FIRST_NAME,'First Name'),sortable:true,cellStyle: { 'fontWeight': '400','letterSpacing':'0px','fontSize':'13px','fontFamily':'poppins' },headerClass: 'header-white'},
        { field: 'inspectorLastName', headerName:CommonUtil.getMessageText(Constants.INSPECTOR_LAST_NAME,'Last Name'),sortable:true,cellStyle: { 'fontWeight': '400','letterSpacing':'0px','fontSize':'13px','fontFamily':'poppins' },headerClass: 'header-white'},
        { field: 'phoneNumber', headerName:CommonUtil.getMessageText(Constants.PHONE_NUMBER,'Phone Number'),sortable:true,cellStyle: { 'fontWeight': '400','letterSpacing':'0px','fontSize':'13px','fontFamily':'poppins' },headerClass: 'header-white'},
        { field: 'inspectorType', headerName:CommonUtil.getMessageText(Constants.INSPECTOR_TYPE,'User Type'),sortable:true,cellStyle: { 'fontWeight': '400','letterSpacing':'0px','fontSize':'13px','fontFamily':'poppins' },headerClass: 'header-white'},
        { field: 'status', headerName:CommonUtil.getMessageText(Constants.STATUS,'Status'),sortable:true,cellStyle: { 'fontWeight': '400','letterSpacing':'0px','fontSize':'13px','fontFamily':'poppins' },headerClass: 'header-white',cellRenderer:InspectionStatusRenderer}
      ]);

    let onClickUserDetails = (data: any)=>{
      localStorage.setItem("inspectorId", data.inspectorId);
      
        userMode = CommonUtil.UPDATE_MODE;
        userObj = data;
       
        console.log(data.inspectionOrgId)
        setEmailId(data.email);
        SetInspectorFirstName(data.inspectorFirstName);
        setInspectorLastName(data.inspectorLastName);
        setPhoneNumber(data.phoneNumber);
        setLocationAssigned(data.locationAssigned);
        setInspectorTypeObj({Key:data.inspectorType,Value:data.inspectorType});
        setStatusObj({Key:data.status,Value:data.status});
        setShowUserPopup(true);
        setLocationAssigned(data.locationAssigned);
        setTypeDisable(true);

       
    }

    let onClickActiveUser = (userData:any)=>{
        if(userData.status == CommonUtil.ACTIVE || userData.status == CommonUtil.IN_ACTIVE){
            userObj = userData;
            setUserStatus(userObj.status);
            setTimeout(()=>{
                setShowUerStatusPopup(true);
            })
        }
    }


    let onCloseUserStausPopup = ()=>{
        setShowUerStatusPopup(false);
    }

    let onClickAddUser = ()=>{
        clearUserData();
        userMode = CommonUtil.CREATE_MODE;
        setShowUserPopup(true);
        setTypeDisable(false);
    }
    let onCloseUserPopup = ()=>{
        setShowUserPopup(false);
    }

   
    let onStatusChange = (data:any)=>{
        setStatusObj(data);
        (data)? setStatusClass("select-box-valid"): setStatusClass("select-box-invalid");
    }

    let onUserTypeChange = (data:any)=>{
      setInspectorTypeObj(data);
        (data)? setInspectorTypeClass("select-box-valid"): setInspectorTypeClass("select-box-invalid");
    }

    let onCreateUser = ()=>{
        var isFn:boolean = CommonUtil.isMandatory(fnRef);
        var isLN:boolean = CommonUtil.isMandatory(lnRef);
        var isMail:boolean = CommonUtil.isEmailMandatory(emailRef);
        var isContact:boolean = true;
        (inspectorTypeObj)?setInspectorTypeClass("select-box-valid"): setInspectorTypeClass("select-box-invalid");
        (statusObj)?setStatusClass("select-box-valid"): setStatusClass("select-box-invalid");

        if (!phoneNumber.trim() || phoneNumber.length < 11) {
            isContact = false
            setPhoneClass('is-invalid')
        }

        if(inspectorTypeObj && isLN && isMail && isFn && isContact && statusObj){
            var restObj:any = {};
            restObj.email = email;
            restObj.inspectorFirstName = inspectorFirstName;
            restObj.inspectorLastName = inspectorLastName;
            restObj.phoneNumber = phoneNumber;
            restObj.inspectorType = inspectorTypeObj?inspectorTypeObj.Key:"";
            restObj.status = statusObj?statusObj.Key:"";
            restObj.saasOrgId = CommonUtil.getInspectionSaasOrgId();
           

            if(userMode == CommonUtil.UPDATE_MODE){
                if(userObj){
                    restObj.inspectorId = userObj.inspectorId;
                }
            }
            var userUrl = "";
            var method = "POST";
            if(userMode == CommonUtil.CREATE_MODE){
                userUrl = "api/inspector/";
            }else{
                method = "PUT";
                userUrl = "api/inspector/updateInspector?inspectorId="+CommonUtil.getInspectorId();
            }

            NetworkManager.sendJsonRequest(userUrl,restObj,"",method,true,onUserCreate,onLoginError);
        }
    }

    let onUserCreate = (data:any)=>{
        if (data) {
            var restData = CommonUtil.parseData(data);
            if (restData) {
                onCloseUserPopup();
                let strSuccess = CommonUtil.getMessageText(Constants.USER_CREATED_SUCCESS,"User Created Successfully");
                ToastManager.showToast(strSuccess, "Info");
                getAllUsers();
            }
        }
    }

    let onClickResend = (userData:any)=>{
        localStorage.setItem("inspectorId", userData.inspectorId);
        userObj = userData;
        let strConfirm = CommonUtil.getMessageText(Constants.CONFIRM,"Confirm");
        let resendInvitation = CommonUtil.getMessageText(Constants.RESEND_INVITATION,"Do you want to Resend Invitation?");
        Confirm.show(strConfirm, resendInvitation, [{label:"Yes", callback:function(){
                //console.log(userData);
                var restObj:any = {};
            restObj.email = userData.email;
            restObj.loginId = userData.email;
            restObj.userId = userData.inspectorId;
            restObj.inspectorFirstName = userData.inspectorFirstName;
            restObj.inspectorLastName = userData.inspectorLastName;
            restObj.phoneNumber = userData.phoneNumber;
            restObj.inspectorType = userData.inspectorType
            restObj.status = userData.status 
            restObj.saasOrgId = CommonUtil.getInspectionSaasOrgId();

                let resendAPI:string = "api/organization/resendInvitation?saasOrgId="+CommonUtil.getInspectionSaasOrgId();
                NetworkManager.sendJsonRequest(resendAPI,restObj,"","POST",true,onReInviteUser,onLoginError);

            }}, {label:"No"}]);

    }

    let onReInviteUser = (dataObj:any)=>{
        if(dataObj) {
            var restData = CommonUtil.parseData(dataObj);
            if (restData) {
                let resendInvitation = CommonUtil.getMessageText(Constants.RESEND_SUCCESS,"Resend Invitation Successfully");
                ToastManager.showToast(resendInvitation,"Info");
            }
        }
    }


    let getAllUsers = ()=>{
        setUsers([]);
        var qParam = CommonUtil.getInspectionSaasOrgId();
        var orgUrl = "api/inspector/getInspectors?saasOrgId="+qParam;
        NetworkManager.sendJsonRequest(orgUrl,"","","GET",true,onOrgGetAllUsers,onLoginError);
    }

    useEffect(()=>{
        getAllUsers();
    },[])

    let onOrgGetAllUsers = (dataObj:any)=>{
        console.log(dataObj);
        if(dataObj) {
            var restData = JSON.parse(dataObj);
            console.log(restData)
            if(restData){
                restData.map((org:any)=>{
                    console.log(org.inspectionOrgId)
                })
            }
            setUsers(restData)
            if(restData){
                var users = [];
                if(Array.isArray(restData)){
                    users = restData;
                }else{
                    users.push(restData);
                    
                }
                setUsers(restData)    
                console.log(users)    
            }

}
        

    }

    let clearUserData = ()=>{
     
        setEmailId("");
        setInspectorLastName("")
        SetInspectorFirstName("")
        setPhoneNumber("");
        setLocationAssigned("");
        // setInspectorTypeObj(null)
        // setStatusObj(null);
    }

    let onLoginError = (err:any)=>{
        console.log(err);
    }


    return(
        <>
            <Modal  show={showUserPopup} dialogAs={DraggableComponent}>
                <div className="modal-content" style={{"width": "700px","height":"400px"}}>
                <div className="modal-header">
                        <h6 className="modal-title darkgrey-text fw-600">{CommonUtil.getMessageText(Constants.CREATE_USER,"Create User")}</h6>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={onCloseUserPopup} >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <div className="col-md-6 col-12">
                                <div className="form-group row">
                                <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.FIRST_NAME,"First Name")} <span
                                        className="text-danger"></span>
                                </label>
                                    <div className="col-sm-12">
                                    <div className="inputf">
                                        <input ref={fnRef} type="text"
                                               className="form-control clsStandardText  clsFormInputControl"
                                               placeholder="Enter First Name" aria-label="First Name" value={inspectorFirstName}
                                               onChange={(e)=>{SetInspectorFirstName(e.currentTarget.value);CommonUtil.isMandatory(fnRef);}} />
                                    </div>
                                    </div>
                            </div>
                            </div>

                            <div className="col-md-6 col-12">
                                <div className="form-group row">
                                <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.LAST_NAME,"Last Name")} <span
                                        className="text-danger"></span>
                                </label>
                                    <div className="col-sm-12">
                                    <div className="inputf">
                                        <input ref={lnRef} type="text"
                                               className="form-control clsStandardText  clsFormInputControl"
                                               placeholder="Enter Last Name" aria-label="Last Name" value={inspectorLastName}
                                               onChange={(e)=>{setInspectorLastName(e.currentTarget.value);CommonUtil.isMandatory(lnRef);}} />
                                    </div>
                                    </div>
                            </div>
                            </div>

                            <div className="col-md-6">
                                <div className="form-group row">
                                <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.EMAIL_ID,"Email ID")} <span
                                        className="text-danger"></span>
                                </label>
                                    <div className="col-sm-12">
                                    <div className="inputf">
                                        <input ref={emailRef} type="email"
                                               className="form-control clsStandardText  clsFormInputControl"
                                               placeholder="Enter Email" aria-label="Email" value={email}
                                               onChange={(e)=>{setEmailId(e.currentTarget.value);CommonUtil.isEmailMandatory(emailRef);}} />
                                    </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="form-group row">
                                <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.USER_TYPE,"User Type")} <span
                                        className="text-danger"></span>
                                </label>
                                    <div className="col-sm-12">
                                    <SingleSelectComp disabled={typeDiable} dataSource={inspectorType} selectedValue={inspectorTypeObj} handleChange={onUserTypeChange} value={"Key"} label={"Value"}
                                                           className={inspectorTypeClass} width={"100%"} placeholder="Enter User Type"/>
                                    </div>
                                </div>
                            </div>
                        
                             <div className="col-md-6">
                                <div className="form-group row">
                                <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.PHONE_NUMBER,"Phone Number")} <span
                                        className="text-danger"></span>
                                </label>
                                    <div className="col-sm-12">
                                    <div className="inputf">
                                    <PhoneInput
                                  country={'us'}
                                  countryCodeEditable={true}
                                  containerClass={phoneClass}
                                  value={phoneNumber}
                                  placeholder="Enter Phone Number"
                                  inputClass={`input-phone ${phoneNumber.toString().length === 11 ? '' : 'inputMandatory'}`}
                                  onChange={(e) => {
                                    //console.log(e)
                                    if (phoneNumber == '') {
                                      e = '' + e
                                    }
                                    setPhoneNumber(e)
                                  }}
                                  disableCountryGuess={false}
                                  disableDropdown={true}
                                  disableSearchIcon={true}
                              />
                                    </div>
                                </div>
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="form-group row">
                                <label className="col-sm-12 col-form-label">{CommonUtil.getMessageText(Constants.STATUS,"Status")} <span
                                        className="text-danger"></span>
                                </label>
                                    <div className="col-sm-12">
                                    <SingleSelectComp  dataSource={status} selectedValue={statusObj} handleChange={onStatusChange} value={"Key"} label={"Value"}
                                                           className={statusClass} width={"100%"} placeholder="Select Status"/>
                                    </div>
                                </div>
                            </div>

                           
                            <div className="row justify-content-end mt-2">
                            <div className="col-md-6 col-12" style={{textAlign:'right'}}>
                            <button type="button" className="btn btn-success" onClick={onCreateUser}>{CommonUtil.getMessageText(Constants.SAVE,"Save")}</button>&nbsp;&nbsp;
                            <button type="button" className="btn btn-danger"  onClick={onCloseUserPopup}>{CommonUtil.getMessageText(Constants.CANCEL,"Cancel")}</button>

                            </div>
                    </div>
                            
                    </div>
                   
                </div>
                </div>
            </Modal>
            <div className="container-fluid">
                <div className="row fs-7 pt-1">
                        <div className="ag-theme-customeTheme gridHeight" style={{ height: "calc(100vh - 200px)", width: "100%",paddingLeft:"8px",paddingRight:"5px" }}>
                            <InspectionGrid id="CustOrgUserGrid"  cols={userColumns} rows={users} pageSize={100} addNew={true} tooltip={"Add User"} name={"Add User"}
                                    headerHeight={CommonUtil.GRID_HEADER_HEIGHT} showButton={true} rowHeight={CommonUtil.GRID_ROW_HEIGHT} ref={userGridRef1} method={{onClickUserDetails,onClickActiveUser,onClickResend}} addNewRecord={onClickAddUser} />
                        </div>
                </div>
            </div>
            
        </>

    );
}

export default UserDetails;