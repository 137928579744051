import React, { useEffect, useRef, useState } from "react";
import AGGrid from "../../Components/AGGrid";
import { CommonUtil } from "../../utils/CommonUtil";
import { IAGrid } from "../../Components/IInterfaces";
import CompanyStatusRenderer from "../Organization/CompanyStatusRenderer";
import { NetworkManager } from "../../Components/NetworkManager";
import DetectDevice from "../../utils/DetectDevice";
import MGrid from "../../Components/MGrid";
import { useNavigate } from "react-router-dom";
import EquipAvailTypeRenderer from "../Organization/EquipAvailTypeRenderer";
import EquipCellRenderer from "../Organization/EquipCellRenderer";
import { countIncreaser } from "../../utils/Counter";
import {Constants} from "../Languages/Constants";
import {Events} from "../Languages/Events";
import InvoiceFilter from "./InvoiceFilter";

var DAYS = "7";
const DashBoard360 = () => {
    const navigate = useNavigate();

    const IncompleteCellRenderer = (propData: any) => {
        const onClickLink = () => {
            localStorage.setItem("saasorgId", propData.data.orgId);
            localStorage.setItem("CustomerViewTab", "true");
            let siteOrigin: string = window.location.origin;
            console.log(siteOrigin, 'siteOrigin', window.location)
            siteOrigin = siteOrigin + "/?requestor=custprofiles";
            var newTab = document.createElement('a');
            newTab.href = siteOrigin;
            newTab.setAttribute('target', '_blank');
            newTab.click();
        }
        return (
            <div className={"cellRenderHeader"}>
                <a><span onClick={onClickLink} className={"cellRender"}>{propData.data.orgName}</span></a>
            </div>

        )
    }

    const CreationDateCellRenderer = (propData: any) => {
        return (
            <div>
                <a><span>{propData.data.creationDate}</span></a>
            </div>

        )
    }

    const UpdationDateCellRenderer = (propData: any) => {
        return (
            <div>
                <a><span>{propData.data.lastUpdateDate}</span></a>
            </div>

        )
    }

    const dueCellRenderer = (propData: any) => {
        const onClickLink = () => {
            localStorage.setItem("ReservationTab", "true");
            let equipId: string = propData.data.equipmentId;
            let reservationId: string = propData.data.reservationId;
            let reservationAssetType: string = propData.data.assetType;
            let isGeneric: string = propData.data.isGeneric;
            let equipType: string = propData.data.equipmentType;
            let availId: string = propData.data.equipmentListingId;

            let siteOrigin: string = window.location.origin;
            siteOrigin = siteOrigin + "/?requestor=reservationview&equipid=" + equipId + "&token=" + localStorage.getItem("token");
            siteOrigin = siteOrigin + "&reservationId=" + reservationId;
            siteOrigin = siteOrigin + "&assetType=" + reservationAssetType;
            siteOrigin = siteOrigin + "&equipType=" + equipType;
            siteOrigin = siteOrigin + "&generic=" + isGeneric;
            siteOrigin = siteOrigin + "&availId=" + availId;

            var newTab = document.createElement('a');
            newTab.href = siteOrigin;
            newTab.setAttribute('target', '_blank');
            newTab.click();

        }
        return (
            <div className={"cellRenderHeader"}>
                <a><span onClick={onClickLink} className={"cellRender"}>{propData.data.reservationCode}</span></a>
            </div>
        )
    }

    const billButtonRender = (propData: any) => {

        let onClickBill = () => {
            localStorage.setItem("BillingTab","true");
            CommonUtil.SELECT_RESERVATION_ID = propData.data.reservationId;
            CommonUtil.SELECT_EQUIP_ID = propData.data.equipmentId;
            CommonUtil.EQUIP_RESERVE_ASSET_TYPE = propData.data.assetType;
            CommonUtil.EQUIP_RESERVE_EQUIPMENT_TYPE = propData.data.equipmentType;
            CommonUtil.EQUIP_RESERVE_IS_GENERIC = propData.data.isGeneric;
            CommonUtil.SELECT_EQUIP_AVAIL_ID = propData.data.equipmentListingId;
            localStorage.setItem('REPROCESS_PREXTRA', 'N');
            try{
                if(propData.colDef.fromReprocess){
                    localStorage.setItem('REPROCESS_PREXTRA', 'Y');
                }
            }catch(e){
                localStorage.setItem('REPROCESS_PREXTRA', 'N');
            }

            console.log(propData);
            //navigate("invoice");

            let siteOrigin:string = window.location.origin;
            siteOrigin = siteOrigin+"/?requestor=billingdoc&equipid="+CommonUtil.SELECT_EQUIP_ID;//+"&token="+ localStorage.getItem("token");
            siteOrigin = siteOrigin+"&reservationId="+ CommonUtil.SELECT_RESERVATION_ID;
            siteOrigin = siteOrigin+"&assetType="+ CommonUtil.EQUIP_RESERVE_ASSET_TYPE;
            siteOrigin = siteOrigin+"&equipType="+CommonUtil.EQUIP_RESERVE_EQUIPMENT_TYPE;
            siteOrigin = siteOrigin+"&generic="+CommonUtil.EQUIP_RESERVE_IS_GENERIC;
            siteOrigin = siteOrigin+"&availId="+CommonUtil.SELECT_EQUIP_AVAIL_ID;

            var newTab = document.createElement('a');
            newTab.href = siteOrigin;
            newTab.setAttribute('target', '_blank');
            newTab.click();
        }
        return (
            <>
                <button className="btn btn-success" onClick={onClickBill}>Bill</button>
            </>
        )
    }

    let onClickLink = (data: any) => {
        navigate('equippublic');
    }

    let inCompleteColumnDefs = [
        { field: 'orgName', headerName: CommonUtil.getMessageText(Constants.ORG_NAME,"Organization Name"), resizable: true, flex: 1, width: 200, sortable: true, filter: 'agTextColumnFilter', cellRenderer: IncompleteCellRenderer },
        { field: 'usageType', headerName: CommonUtil.getMessageText(Constants.USAGE_TYPE,"Usage Type"), resizable: true, flex: 1, width: 200, sortable: true, filter: 'agTextColumnFilter' },
        { field: 'trCommissionNumber', headerName: CommonUtil.getMessageText(Constants.DOT_NUMBER,"DOT Number"), resizable: true, flex: 1, width: 200, sortable: true, filter: 'agTextColumnFilter' },
        { field: 'ownerStatus', headerName: CommonUtil.getMessageText(Constants.OWNER_STATUS,"Owner Status"), resizable: true, flex: 1, width: 200, sortable: true, filter: 'agTextColumnFilter' },
        { field: 'renterStatus', headerName: CommonUtil.getMessageText(Constants.RENTER_STATUS,"Renter Status"), resizable: true, flex: 1, width: 200, sortable: false, filter: 'agTextColumnFilter' },
        { field: 'lastUpdatedBy', headerName: CommonUtil.getMessageText(Constants.UPDATED_BY,"Updated by"), resizable: true, flex: 1, width: 200, sortable: true, filter: 'agTextColumnFilter' },
        { field: 'creationDateinMilliSeconds', headerName: CommonUtil.getMessageText(Constants.CREATION_DATE,"Creation Date"), resizable: true, flex: 1, width: 200, sortable: true, filter: 'agTextColumnFilter', cellRenderer: CreationDateCellRenderer },
        { field: 'lastUpdateDateinMilliSeconds', headerName: CommonUtil.getMessageText(Constants.RECENT_UPDATE,"Recent update"), resizable: true, flex: 1, width: 200, sortable: true, filter: 'agTextColumnFilter',cellRenderer: UpdationDateCellRenderer },        
    ]

    let activationColumnDefs = [
        { field: 'reservationCode', headerName: CommonUtil.getMessageText(Constants.RESERVATION_CODE,"Reservation Code"), resizable: true, width: 150, flex: 1, sortable: true, filter: 'agNumberColumnFilter', cellRenderer: dueCellRenderer },
        { field: 'ownerName', headerName: CommonUtil.getMessageText(Constants.OWNER_ORGANIZATION,"Owner Organization"), resizable: true, width: 150, flex: 1, sortable: true, filter: 'agNumberColumnFilter' },
        { field: 'renterName', headerName: CommonUtil.getMessageText(Constants.RENTER_ORGANIZATION,"Renter Organization"), resizable: true, width: 250, flex: 1, sortable: true, filter: 'agNumberColumnFilter' },
        { field: 'vin', headerName: CommonUtil.getMessageText(Constants.VIN_NUMBER,"VIN Number"), resizable: true, width: 200, flex: 1, sortable: true, filter: 'agTextColumnFilter' },
        { field: 'unitNumber', headerName: CommonUtil.getMessageText(Constants.UNIT_NUMBER,"Unit Number"), resizable: true, width: 200, flex: 1, sortable: true, filter: 'agTextColumnFilter' },
        { field: 'equipmentType', headerName: CommonUtil.getMessageText(Constants.EQUIP_TYPE,"Equipment Type"), resizable: true, width: 200, flex: 1, sortable: true, filter: 'agTextColumnFilter' },
        { field: 'scheduledStartDate', headerName: CommonUtil.getMessageText(Constants.START_DATE,"Start Date"), resizable: true, flex: 1, width: 200, sortable: true, filter: 'agNumberColumnFilter' },
        { field: 'scheduledEndDate', headerName: CommonUtil.getMessageText(Constants.END_DATE,"End Date"), resizable: true, width: 150, flex: 1, sortable: true, filter: 'agNumberColumnFilter' },
        { field: 'reservationType', headerName: CommonUtil.getMessageText(Constants.RESERVATION_TYPE,"Reservation Type"), resizable: true, width: 200, flex: 1, sortable: true, filter: 'agNumberColumnFilter' },
        { field: 'reservationStatus', headerName: CommonUtil.getMessageText(Constants.RESERVATION_TYPE,"Reservation Status"), resizable: true, width: 200, flex: 1, sortable: true, filter: 'agNumberColumnFilter' },
    ]

    let deactivationColumnDefs = [
        { field: 'reservationCode', headerName: CommonUtil.getMessageText(Constants.RESERVATION_CODE,"Reservation Code"), resizable: true, width: 150, flex: 1, sortable: true, filter: 'agNumberColumnFilter', cellRenderer: dueCellRenderer },
        { field: 'ownerName', headerName: CommonUtil.getMessageText(Constants.OWNER_ORGANIZATION,"Owner Organization"), resizable: true, width: 150, flex: 1, sortable: true, filter: 'agNumberColumnFilter' },
        { field: 'renterName', headerName: CommonUtil.getMessageText(Constants.RENTER_ORGANIZATION,"Renter Organization"), resizable: true, width: 250, flex: 1, sortable: true, filter: 'agNumberColumnFilter' },
        { field: 'vin', headerName: CommonUtil.getMessageText(Constants.VIN_NUMBER,"VIN Number"), resizable: true, width: 200, flex: 1, sortable: true, filter: 'agTextColumnFilter' },
        { field: 'unitNumber', headerName: CommonUtil.getMessageText(Constants.UNIT_NUMBER,"Unit Number"), resizable: true, width: 200, flex: 1, sortable: true, filter: 'agTextColumnFilter' },
        { field: 'equipmentType', headerName: CommonUtil.getMessageText(Constants.EQUIP_TYPE,"Equipment Type"), resizable: true, width: 200, flex: 1, sortable: true, filter: 'agTextColumnFilter' },
        { field: 'scheduledStartDate', headerName: CommonUtil.getMessageText(Constants.START_DATE,"Start Date"), resizable: true, flex: 1, width: 200, sortable: true, filter: 'agNumberColumnFilter' },
        { field: 'scheduledEndDate', headerName: CommonUtil.getMessageText(Constants.END_DATE,"End Date"), resizable: true, width: 150, flex: 1, sortable: true, filter: 'agNumberColumnFilter' },
        { field: 'reservationType', headerName: CommonUtil.getMessageText(Constants.RESERVATION_TYPE,"Reservation Type"), resizable: true, width: 200, flex: 1, sortable: true, filter: 'agNumberColumnFilter' },
        { field: 'reservationStatus', headerName: CommonUtil.getMessageText(Constants.RESERVATION_STATUS,"Reservation Status"), resizable: true, width: 200, flex: 1, sortable: true, filter: 'agNumberColumnFilter' },
    ]

    let repositionColumnDefs = [
        { field: 'button', headerName: CommonUtil.getMessageText(Constants.BILLS,"Bills"), resizable: true, width: 150, sortable: true, filter: 'agNumberColumnFilter', cellRenderer: billButtonRender },
        { field: 'reservationCode', headerName: CommonUtil.getMessageText(Constants.RESERVATION_CODE,"Reservation Code"), resizable: true, width: 200, sortable: true, filter: 'agNumberColumnFilter' },
        { field: 'ownerName', headerName: CommonUtil.getMessageText(Constants.OWNER_ORGANIZATION,"Owner Organization"), resizable: true, width: 200, sortable: true, filter: 'agNumberColumnFilter' },
        { field: 'renterName', headerName: CommonUtil.getMessageText(Constants.RENTER_ORGANIZATION,"Renter Organization"), resizable: true, width: 250, sortable: true, filter: 'agNumberColumnFilter' },
        { field: 'vin', headerName: CommonUtil.getMessageText(Constants.VIN_NUMBER,"VIN Number"), resizable: true, width: 200, sortable: true, filter: 'agTextColumnFilter' },
        { field: 'unitNumber', headerName: CommonUtil.getMessageText(Constants.UNIT_NUMBER,"Unit Number"), resizable: true, width: 200, sortable: true, filter: 'agTextColumnFilter' },
        { field: 'equipmentType', headerName: CommonUtil.getMessageText(Constants.EQUIP_TYPE,"Equipment Type"), resizable: true, width: 200, sortable: true, filter: 'agTextColumnFilter' },
        { field: 'scheduledStartDate', headerName: CommonUtil.getMessageText(Constants.RESERVATION_START_DATE,"Reservation Start Date"), resizable: true, width: 200, sortable: true, filter: 'agNumberColumnFilter' },
        { field: 'actualStartDate', headerName: CommonUtil.getMessageText(Constants.ACTIVATION_DATE,"Activation Date"), resizable: true, width: 200, sortable: true, filter: 'agNumberColumnFilter' },
        { field: 'scheduledEndDate', headerName: CommonUtil.getMessageText(Constants.RESERVATION_END_DATE,"Reservation End Date"), resizable: true, width: 150, sortable: true, filter: 'agNumberColumnFilter' },
        { field: 'actualEndDate', headerName: CommonUtil.getMessageText(Constants.DE_ACTIVATION_DATE,"Deactivation Date"), resizable: true, width: 200, sortable: true, filter: 'agNumberColumnFilter' },
        { field: 'reservationStatus', headerName: 'Reservation Status', resizable: true, width: 200, sortable: true, filter: 'agNumberColumnFilter' },
    ]

    let renterColumnDefs = [
        { field: 'button', headerName: CommonUtil.getMessageText(Constants.BILLS,"Bills"), resizable: true, width: 100, sortable: true, filter: 'agNumberColumnFilter', cellRenderer: billButtonRender },
        { field: 'reservationCode', headerName: CommonUtil.getMessageText(Constants.RESERVATION_CODE,"Reservation Code"), resizable: true, width: 200, sortable: true, filter: 'agNumberColumnFilter' },
        { field: 'ownerName', headerName: CommonUtil.getMessageText(Constants.OWNER_ORGANIZATION,"Owner Organization"), resizable: true, width: 200, sortable: true, filter: 'agNumberColumnFilter' },
        { field: 'renterName', headerName: CommonUtil.getMessageText(Constants.RENTER_ORGANIZATION,"Renter Organization"), resizable: true, width: 250, sortable: true, filter: 'agNumberColumnFilter' },
        { field: 'vin', headerName: CommonUtil.getMessageText(Constants.VIN_NUMBER,"VIN Number"), resizable: true, width: 200, sortable: true, filter: 'agTextColumnFilter' },
        { field: 'unitNumber', headerName: CommonUtil.getMessageText(Constants.UNIT_NUMBER,"Unit Number"), resizable: true, width: 200, sortable: true, filter: 'agTextColumnFilter' },
        { field: 'equipmentType', headerName:  CommonUtil.getMessageText(Constants.EQUIP_TYPE,"Equipment Type"), resizable: true, width: 200, sortable: true, filter: 'agTextColumnFilter' },
        { field: 'scheduledStartDate', headerName:  CommonUtil.getMessageText(Constants.RESERVATION_START_DATE,"Reservation Start Date"), resizable: true, width: 200, sortable: true, filter: 'agNumberColumnFilter' },
        { field: 'actualStartDate', headerName: CommonUtil.getMessageText(Constants.ACTIVATION_DATE,"Activation Date"), resizable: true, width: 200, sortable: true, filter: 'agNumberColumnFilter' },
        { field: 'scheduledEndDate', headerName: CommonUtil.getMessageText(Constants.RESERVATION_END_DATE,"Reservation End Date"), resizable: true, width: 150, sortable: true, filter: 'agNumberColumnFilter' },
        { field: 'actualEndDate', headerName: CommonUtil.getMessageText(Constants.DE_ACTIVATION_DATE,"Deactivation Date"), resizable: true, width: 200, sortable: true, filter: 'agNumberColumnFilter' },
        { field: 'reservationStatus', headerName: CommonUtil.getMessageText(Constants.RESERVATION_STATUS,"Reservation Status"), resizable: true, width: 200, sortable: true, filter: 'agNumberColumnFilter' },
    ]

    let reprocessColumnDefs = [
        { field: 'button', headerName: CommonUtil.getMessageText(Constants.BILLS,"Bills"), resizable: true, width: 100, sortable: true, filter: 'agNumberColumnFilter',fromReprocess:true, cellRenderer: billButtonRender },
        { field: 'reservationCode', headerName: CommonUtil.getMessageText(Constants.RESERVATION_CODE,"Reservation Code"), resizable: true, width: 200, sortable: true, filter: 'agTextColumnFilter' },
        { field: 'reservationType', headerName: CommonUtil.getMessageText(Constants.RESERVATION_TYPE,"Reservation Type"), resizable: true, width: 200, sortable: true, filter: 'agTextColumnFilter' },
        { field: 'ownerName', headerName: CommonUtil.getMessageText(Constants.OWNER_ORGANIZATION,"Owner Organization"), resizable: true, width: 200, sortable: true, filter: 'agNumberColumnFilter' },
        { field: 'renterName', headerName: CommonUtil.getMessageText(Constants.RENTER_ORGANIZATION,"Renter Organization"), resizable: true, width: 250, sortable: true, filter: 'agNumberColumnFilter' },
        { field: 'vin', headerName: CommonUtil.getMessageText(Constants.VIN_NUMBER,"VIN Number"), resizable: true, width: 200, sortable: true, filter: 'agTextColumnFilter' },
        { field: 'unitNumber', headerName: CommonUtil.getMessageText(Constants.UNIT_NUMBER,"Unit Number"), resizable: true, width: 200, sortable: true, filter: 'agTextColumnFilter' },
        { field: 'equipmentType', headerName:  CommonUtil.getMessageText(Constants.EQUIP_TYPE,"Equipment Type"), resizable: true, width: 200, sortable: true, filter: 'agTextColumnFilter' },
        { field: 'scheduledStartDate', headerName:  CommonUtil.getMessageText(Constants.RESERVATION_START_DATE,"Reservation Start Date"), resizable: true, width: 200, sortable: true, filter: 'agNumberColumnFilter' },
        { field: 'actualStartDate', headerName: CommonUtil.getMessageText(Constants.ACTIVATION_DATE,"Activation Date"), resizable: true, width: 200, sortable: true, filter: 'agNumberColumnFilter' },
        { field: 'scheduledEndDate', headerName: CommonUtil.getMessageText(Constants.RESERVATION_END_DATE,"Reservation End Date"), resizable: true, width: 150, sortable: true, filter: 'agNumberColumnFilter' },
        { field: 'actualEndDate', headerName: CommonUtil.getMessageText(Constants.DE_ACTIVATION_DATE,"Deactivation Date"), resizable: true, width: 200, sortable: true, filter: 'agNumberColumnFilter' },
        { field: 'reservationStatus', headerName: CommonUtil.getMessageText(Constants.RESERVATION_STATUS,"Reservation Status"), resizable: true, width: 200, sortable: true, filter: 'agNumberColumnFilter' },
    ]

    const departureRender = (propData: any) => {
        return (
            <div>
                <p><span >{`${propData.data.departureCity},${propData.data.departureState}`}</span></p>
            </div>
        )
    }

    const arrivalRender = (propData: any) => {
        return (
            <div>
                <p><span>{`${propData.data.arrivalCity},${propData.data.arrivalState}`}</span></p>
            </div>
        )
    }

    const [availColumnDefs] = useState([
        { field: 'damagesCount', headerName: CommonUtil.getMessageText(Constants.AVAILABILITY_DETAILS,"Availability Details"), resizable: true, width: 200, sortable: true, filter: 'agNumberColumnFilter', cellRenderer: EquipAvailTypeRenderer },
        { field: 'listingType', headerName: CommonUtil.getMessageText(Constants.AVAILABILITY_TYPE,"Availability Type"), resizable: true, width: 150, sortable: true, filter: 'agNumberColumnFilter' },
        { field: 'UnitEquipName', headerName: CommonUtil.getMessageText(Constants.EQUIP_NO_NAME,"Equipment Number / Name"), resizable: true, width: 250, sortable: true, cellRenderer: EquipCellRenderer, filter: 'agTextColumnFilter' },
        { field: 'assetType', headerName: CommonUtil.getMessageText(Constants.ASSET_TYPE,"Asset Type"), resizable: true, width: 150, sortable: true, hide: true, filter: 'agTextColumnFilter' },
        { field: 'equipmentType', headerName: CommonUtil.getMessageText(Constants.EQUIP_TYPE,"Equipment Type"), resizable: true, width: 150, sortable: true, filter: 'agNumberColumnFilter' },
        { field: 'model', headerName: CommonUtil.getMessageText(Constants.MODEL,"Model"), resizable: true, width: 150, sortable: true, filter: 'agNumberColumnFilter' },
        { field: 'isGeneric', headerName: CommonUtil.getMessageText(Constants.IS_GENERIC,"Is Generic"), resizable: true, width: 150, sortable: true, hide: true, filter: 'agNumberColumnFilter' },
        { field: 'brand', headerName: CommonUtil.getMessageText(Constants.BRAND,"Brand"), resizable: true, width: 150, sortable: true, hide: true, filter: 'agNumberColumnFilter' },
        { field: 'startDate', headerName: CommonUtil.getMessageText(Constants.AVAILABILITY_FROM_DATE,"Availability From Date"), resizable: true, width: 200, sortable: true, filter: 'agNumberColumnFilter' },
        { field: 'endDate', headerName: CommonUtil.getMessageText(Constants.AVAILABILITY_TO_DATE,"Availability To Date"), resizable: true, width: 200, sortable: true, filter: 'agNumberColumnFilter' },
        { field: 'departureParkingLotAddress', headerName: CommonUtil.getMessageText(Constants.DEPARTURE_ADDR,"Departure Address"), resizable: true, width: 200, sortable: true, filter: 'agTextColumnFilter', cellRenderer: departureRender },
        { field: 'arrivalParkingLotAddress', headerName: CommonUtil.getMessageText(Constants.ARRIVAL_ADDRESS,"Arrival Address"), resizable: true, width: 200, sortable: true, filter: 'agTextColumnFilter', cellRenderer: arrivalRender },
        { field: 'listingStatus', headerName: CommonUtil.getMessageText(Constants.STATUS,"Status"), resizable: true, width: 100, sortable: true, filter: 'agNumberColumnFilter' },
    ])
    const [reservationColumnDefs] = useState([
        { field: 'reservationCode', headerName: CommonUtil.getMessageText(Constants.RESERVATION_CODE,"Reservation Code"), resizable: true, width: 200, sortable: true, filter: 'agNumberColumnFilter',cellRenderer: dueCellRenderer },
        { field: 'ownerName', headerName: CommonUtil.getMessageText(Constants.OWNER_ORGANIZATION,"Owner Organization"), resizable: true, width: 200, sortable: true, filter: 'agNumberColumnFilter' },
        { field: 'renterName', headerName: CommonUtil.getMessageText(Constants.RENTER_ORGANIZATION,"Renter Organization"), resizable: true, width: 250, sortable: true, filter: 'agNumberColumnFilter' },
        { field: 'genericEquipmentName', headerName: CommonUtil.getMessageText(Constants.EQUIP_NAME,"Equipment Name"), resizable: true, width: 200, sortable: true, filter: 'agTextColumnFilter' },
        { field: 'equipmentType', headerName:  CommonUtil.getMessageText(Constants.EQUIP_TYPE,"Equipment Type"), resizable: true, width: 200, sortable: true, filter: 'agTextColumnFilter' },
        { field: 'scheduledStartDate', headerName:  CommonUtil.getMessageText(Constants.RESERVATION_START_DATE,"Reservation Start Date"), resizable: true, width: 200, sortable: true, filter: 'agNumberColumnFilter' },
        { field: 'actualStartDate', headerName: CommonUtil.getMessageText(Constants.ACTIVATION_DATE,"Activation Date"), resizable: true, width: 200, sortable: true, filter: 'agNumberColumnFilter' },
        { field: 'scheduledEndDate', headerName: CommonUtil.getMessageText(Constants.RESERVATION_END_DATE,"Reservation End Date"), resizable: true, width: 150, sortable: true, filter: 'agNumberColumnFilter' },
        { field: 'reservationStatus', headerName: CommonUtil.getMessageText(Constants.RESERVATION_STATUS,"Reservation Status"), resizable: true, width: 200, sortable: true, filter: 'agNumberColumnFilter' },
    ])

    const [activeRepositionColumnDefs] = useState([
        { field: 'vin', headerName: CommonUtil.getMessageText(Constants.VIN_NUMBER,"VIN Number"), resizable: true, width: 200, sortable: true, filter: 'agNumberColumnFilter' },
        { field: 'reservationCode', headerName: CommonUtil.getMessageText(Constants.RESERVATION_CODE,"Reservation Code"), resizable: true, width: 200, sortable: true, filter: 'agNumberColumnFilter',cellRenderer: dueCellRenderer},
        { field: 'ownerName', headerName: CommonUtil.getMessageText(Constants.OWNER_ORGANIZATION,"Owner Organization"), resizable: true, width: 200, sortable: true, filter: 'agNumberColumnFilter' },
        { field: 'renterName', headerName: CommonUtil.getMessageText(Constants.RENTER_ORGANIZATION,"Renter Organization"), resizable: true, width: 250, sortable: true, filter: 'agNumberColumnFilter' },
        { field: 'genericEquipmentName', headerName: CommonUtil.getMessageText(Constants.EQUIP_NAME,"Equipment Name"), resizable: true, width: 200, sortable: true, filter: 'agTextColumnFilter' },
        { field: 'equipmentType', headerName:  CommonUtil.getMessageText(Constants.EQUIP_TYPE,"Equipment Type"), resizable: true, width: 200, sortable: true, filter: 'agTextColumnFilter' },
        { field: 'assetType', headerName:  CommonUtil.getMessageText(Constants.ASSET_TYPE,"Asset Type"), resizable: true, width: 200, sortable: true, filter: 'agTextColumnFilter' },
        { field: 'scheduledStartDate', headerName:  CommonUtil.getMessageText(Constants.RESERVATIONS_START_DATE,"Reservation Start Date"), resizable: true, width: 200, sortable: true, filter: 'agNumberColumnFilter' },
        { field: 'scheduledEndDate', headerName:  CommonUtil.getMessageText(Constants.RESERVATIONS_END_DATE,"Reservation End Date"), resizable: true, width: 200, sortable: true, filter: 'agNumberColumnFilter' },        
        // { field: 'actualStartDate', headerName: CommonUtil.getMessageText(Constants.ACTUAL_START_DATE,"Actual Start Date"), resizable: true, width: 150, sortable: true, filter: 'agNumberColumnFilter' },
        { field: 'actualStartDate', headerName: CommonUtil.getMessageText(Constants.ACTIVATION_DATE,"Activation Date"), resizable: true, width: 200, sortable: true, filter: 'agNumberColumnFilter' },
        { field: 'reservationStatus', headerName: CommonUtil.getMessageText(Constants.RESERVATION_STATUS,"Reservation Status"), resizable: true, width: 200, sortable: true, filter: 'agNumberColumnFilter' },
    ])
    const [activeRentalColumnDefs] = useState([
        { field: 'vin', headerName: CommonUtil.getMessageText(Constants.VIN_NUMBER,"VIN Number"), resizable: true, width: 200, sortable: true, filter: 'agNumberColumnFilter'},
        { field: 'reservationCode', headerName: CommonUtil.getMessageText(Constants.RESERVATION_CODE,"Reservation Code"), resizable: true, width: 200, sortable: true, filter: 'agNumberColumnFilter',cellRenderer: dueCellRenderer },
        { field: 'ownerName', headerName: CommonUtil.getMessageText(Constants.OWNER_ORGANIZATION,"Owner Organization"), resizable: true, width: 200, sortable: true, filter: 'agNumberColumnFilter' },
        { field: 'renterName', headerName: CommonUtil.getMessageText(Constants.RENTER_ORGANIZATION,"Renter Organization"), resizable: true, width: 250, sortable: true, filter: 'agNumberColumnFilter' },
        { field: 'genericEquipmentName', headerName: CommonUtil.getMessageText(Constants.EQUIP_NAME,"Equipment Name"), resizable: true, width: 200, sortable: true, filter: 'agTextColumnFilter' },
        { field: 'equipmentType', headerName:  CommonUtil.getMessageText(Constants.EQUIP_TYPE,"Equipment Type"), resizable: true, width: 200, sortable: true, filter: 'agTextColumnFilter' },
        { field: 'assetType', headerName:  CommonUtil.getMessageText(Constants.ASSET_TYPE,"Asset Type"), resizable: true, width: 200, sortable: true, filter: 'agTextColumnFilter' },
        { field: 'scheduledStartDate', headerName:  CommonUtil.getMessageText(Constants.RESERVATIONS_START_DATE,"Reservation Start Date"), resizable: true, width: 200, sortable: true, filter: 'agNumberColumnFilter' },
        { field: 'scheduledEndDate', headerName:  CommonUtil.getMessageText(Constants.RESERVATIONS_END_DATE,"Reservation End Date"), resizable: true, width: 200, sortable: true, filter: 'agNumberColumnFilter' },        
        // { field: 'actualStartDate', headerName: CommonUtil.getMessageText(Constants.ACTUAL_START_DATE,"Actual Start Date"), resizable: true, width: 150, sortable: true, filter: 'agNumberColumnFilter' },
        { field: 'actualStartDate', headerName: CommonUtil.getMessageText(Constants.ACTIVATION_DATE,"Activation Date"), resizable: true, width: 200, sortable: true, filter: 'agNumberColumnFilter' },
        { field: 'reservationStatus', headerName: CommonUtil.getMessageText(Constants.RESERVATION_STATUS,"Reservation Status"), resizable: true, width: 200, sortable: true, filter: 'agNumberColumnFilter' },
    ])
    const [inoivesColumnDefs] = useState([        
        { field: 'reservationCode', headerName: CommonUtil.getMessageText(Constants.RESERVATION_CODE,"Reservation Code"), resizable: true, width: 200, sortable: true, filter: 'agNumberColumnFilter' },
        { field: 'orgName', headerName: CommonUtil.getMessageText(Constants.ORGANISATION,"Organization"), resizable: true, width: 200, sortable: true, filter: 'agNumberColumnFilter' },
        { field: 'renterName', headerName: CommonUtil.getMessageText(Constants.RENTER_ORGANIZATION,"Renter Organization"), resizable: true, width: 250, sortable: true, filter: 'agNumberColumnFilter' },
        { field: 'invoiceCode', headerName: CommonUtil.getMessageText(Constants.INVOICE_CODE,"Invoice code"), resizable: true, width: 200, sortable: true, filter: 'agTextColumnFilter' },        
        { field: 'creationDate', headerName: CommonUtil.getMessageText(Constants.CREATED_DATE,"Date of creation"), resizable: true, width: 200, sortable: true, filter: 'agNumberColumnFilter' },
        { field: 'invoiceStatus', headerName: CommonUtil.getMessageText(Constants.STATUS,"Status"), resizable: true, width: 200, sortable: true, filter: 'agNumberColumnFilter' },
    ])
    // cellRenderer:CompanyStatusRenderer
    const [incomplete, setIncomplete] = useState<any>({})
    const [activation, setActivation] = useState<any>({})
    const [deactivation, setDeactivation] = useState<any>({})
    const [rentalPending, setRentalPending] = useState<any>({})
    const [repositionPending, setRepositionPending] = useState<any>({})
    const [availabilities, setAvailabilities] = useState<any>({})
    const [genReservation, setGenReservation] = useState<any>({})
    const [activeReposition, setActiveReposition] = useState<any>({"propName":"RepositionsCount","numValue":0})
    const [activeRental, setActiveRental] = useState<any>({"propName":"ActiveRentalsCount","numValue":0})
    const [invoiceReport, setInvoiceReport] = useState<any>({"propName":"invoices","numValue":0})
    const [soiReport, setsoiReport] = useState<any>({"propName":"SOI","numValue":0})
    const [reprocessSubmitted, setReprocessSubmitted] = useState<any>({"propName":"reprocessSubmitted","numValue":0})
    const [last7days, setLast7Days] = useState<any>({})
    const [rowsData, setRowsData] = useState<any>([]);
    const [page, setPage] = useState(0);
    const [selectedTab, setSelectedTab] = useState('')
    const [reservationCode, setReservationCode] = useState('')
    const { isMobile } = DetectDevice();
    const gridRef = useRef<IAGrid>(null);


    const onError = (data: any) => {
        console.log(data, 'error')
    }

    const getIncompleteCount = () => {
        NetworkManager.sendJsonRequest('api/dashboard/incompletprofilescount?saasOrgId='+CommonUtil.getSelectedOrgId()+'&days='+DAYS+'&exclude=N', '', '', 'GET', true, (data: any) => {
            if(data){
                let restData = CommonUtil.parseData(data);
                //var restData = CommonUtil.parseData(dataObj)
                if(restData){
                    console.log(restData);
                    setIncomplete(restData)
                    // setSelectedTab(restData?.propName)
                    getData('incompletprofiles');
                    setTimeout(() => {
                       // countIncreaser('.incomplete-counter');
                    }, 1000)
                }

            }

        }, onError)
    }

    const getLast7DaysIncometeCount = ()=>{
        NetworkManager.sendJsonRequest('api/dashboard/incompletprofilescount?saasOrgId='+CommonUtil.getSelectedOrgId()+'&days='+DAYS+'&exclude=Y', '', '', 'GET', true, (data: any) => {
            if(data){
                let restData = CommonUtil.parseData(data);
                //var restData = CommonUtil.parseData(dataObj)
                if(restData){
                    console.log(restData);
                    let obj:any = {};
                    obj.numValue = restData.numValue;
                    obj.propName = "last7days"
                    setLast7Days(obj);
                    // setSelectedTab(restData?.propName)
                    //getData('incompletprofiles');
                    setTimeout(() => {
                        // countIncreaser('.incomplete-counter');
                    }, 1000)
                }

            }

        }, onError)
    }

    const getAvailabilitiesCount = () => {
        let availabilitiesAPI = "api/dashboard/openavailabilitiescount?saasOrgId="+CommonUtil.getSelectedOrgId();
        NetworkManager.sendJsonRequest(availabilitiesAPI, '', '', 'GET', true, (data: any) => {
            if(data){
                let restData = CommonUtil.parseData(data);
                if(restData){
                    console.log(restData);
                    //setSelectedTab(restData?.propName)
                    setAvailabilities(restData)
                    getData('openavailabilities');
                    setTimeout(() => {
                        //countIncreaser('.availability-counter');
                    }, 1000)
                }

            }

        }, onError)
    }

    let getGenericReservationCount = ()=>{
        let restAPI = 'api/dashboard/vinAssignmentPendingReservationsCount?saasOrgId='+CommonUtil.getSelectedOrgId();
        NetworkManager.sendJsonRequest(restAPI, '', '', 'GET', true, (data: any) => {
            if(data){
                let restData = CommonUtil.parseData(data);
                if(restData){
                    console.log(restData);
                    setGenReservation(restData);
                    /*setActivation(restData)
                    if (!CommonUtil.isRootUser() && CommonUtil.ORGANIZATION_TYPE !== CommonUtil.ORG_TYPE_OWNER && CommonUtil.ORGANIZATION_TYPE !== CommonUtil.ORG_TYPE_OWNER_RENTER) {
                        // setSelectedTab(restData?.propName)
                        getData('activationdues');
                    }
                    setTimeout(() => {
                        // countIncreaser('.activation-counter');
                    }, 1000)*/
                }
            }


        }, onError)
    }
    const getActivationCount = () => {
        let restAPI = 'api/dashboard/activationduecount?saasOrgId='+CommonUtil.getSelectedOrgId();
        NetworkManager.sendJsonRequest(restAPI, '', '', 'GET', true, (data: any) => {
            if(data){
                let restData = CommonUtil.parseData(data);
                if(restData){
                    console.log(restData);
                    setActivation(restData)
                    if (!CommonUtil.isRootUser() && CommonUtil.ORGANIZATION_TYPE !== CommonUtil.ORG_TYPE_OWNER && CommonUtil.ORGANIZATION_TYPE !== CommonUtil.ORG_TYPE_OWNER_RENTER) {
                        // setSelectedTab(restData?.propName)
                        getData('activationdues');
                    }
                    setTimeout(() => {
                       // countIncreaser('.activation-counter');
                    }, 1000)
                }
            }


        }, onError)
    }
    const getDeactivationCount = () => {
        let restAPI = 'api/dashboard/deactivationduecount?saasOrgId='+CommonUtil.getSelectedOrgId();
        NetworkManager.sendJsonRequest(restAPI, '', '', 'GET', true, (data: any) => {
            if(data){
                let restData = CommonUtil.parseData(data);
                if(restData){
                    console.log(restData);
                    setDeactivation(restData)
                    setTimeout(() => {
                         //countIncreaser('.deactivation-counter');
                    }, 1000)
                }
            }

        }, onError)
    }
    const getInvoiceCount = () => {
        let restAPI = 'api/dashboard/deactivationduecount?saasOrgId='+CommonUtil.getSelectedOrgId();
        NetworkManager.sendJsonRequest(restAPI, '', '', 'GET', true, (data: any) => {
            if(data){
                let restData = CommonUtil.parseData(data);
                if(restData){
                    console.log(restData);
                    setInvoiceReport({"propName":"invoice","numValue":0});                    
                }
            }

        }, onError)
    }
    const getSOICount = () => {
        let restAPI = 'api/dashboard/deactivationduecount?saasOrgId='+CommonUtil.getSelectedOrgId();
        NetworkManager.sendJsonRequest(restAPI, '', '', 'GET', true, (data: any) => {
            if(data){
                let restData = CommonUtil.parseData(data);
                if(restData){
                    console.log(restData);
                    setsoiReport({"propName":"SOI","numValue":0});                    
                }
            }

        }, onError)
    }

    const getPendingRenterCount = () => {
        NetworkManager.sendJsonRequest('api/dashboard/pendingbillingcount?listingType=Rental', '', '', 'GET', true, (data: any) => {
            if(data){
                let restData = CommonUtil.parseData(data);
                if(restData){
                    console.log(restData);
                    restData.propName = 'PendingBillingCountRental'
                    setRentalPending(restData)
                    setTimeout(() => {
                         //countIncreaser('.pending-billing')
                    }, 1000)
                }

            }

        }, onError)
    }
    const getPendingRepositionCount = () => {
        NetworkManager.sendJsonRequest('api/dashboard/pendingbillingcount?listingType=Reposition', '', '', 'GET', true, (data: any) => {
            if(data){
                let restData = CommonUtil.parseData(data);
                if(restData){
                    console.log(restData);
                    restData.propName = 'PendingBillingCountReposition'
                    setRepositionPending(restData)
                    setTimeout(() => {
                        //countIncreaser('.pending-reposition')
                    }, 1000)
                }
                }


        }, onError)
    }
// -----------1245----------------
    const getActiveRentalsCount = () => {
        NetworkManager.sendJsonRequest('api/dashboard/activeRentalsCount?saasOrgId='+CommonUtil.getSelectedOrgId(), '', '', 'GET', true, (data: any) => {
            if(data){
                let restData = CommonUtil.parseData(data);
                if(restData){
                    console.log(restData);
                    if(!restData.propName)
                        restData.propName = 'ActiveRentalsCount'
                    setActiveRental(restData)
                }

            }

        }, onError)
    }
    const getActiveReposotionsCount = () => {
        NetworkManager.sendJsonRequest('api/dashboard/activeRepositionsCount?saasOrgId='+CommonUtil.getSelectedOrgId(), '', '', 'GET', true, (data: any) => {
            if(data){
                let restData = CommonUtil.parseData(data);
                if(restData){
                    console.log(restData);
                    if(!restData.propName)
                        restData.propName = 'RepositionsCount'
                    setActiveReposition(restData)
                }

            }

        }, onError)
    }
// -----------1245----------------

    const getData = (path: any) => {//incompletprofiles
        let restAPI = "";
        if(path == "last7days"){
            restAPI = 'api/dashboard/incompletprofiles?saasOrgId='+CommonUtil.getSelectedOrgId()+"&days="+DAYS+"&exclude=Y";
        }else if(path == "incompletprofiles"){
            restAPI = 'api/dashboard/incompletprofiles?saasOrgId='+CommonUtil.getSelectedOrgId()+"&days="+DAYS+"&exclude=N";
        }else{
            restAPI = 'api/dashboard/' + path+"?saasOrgId="+CommonUtil.getSelectedOrgId();
        }

        NetworkManager.sendJsonRequest(restAPI, '', '', 'GET', true, (data: any) => {
            if(data){
                let restData = CommonUtil.parseData(data);
                if(restData){
                    setRowsData(restData)
                    setPage(Math.random()*10000);
                }
            }


        }, onError)
    }
    const getPendingBillsData = (path: any, propName: any) => {
        let query = propName === 'PendingBillingCountReposition' ? 'Reposition' : 'Rental'
        NetworkManager.sendJsonRequest('api/dashboard/' + path + '?listingType=' + query, '', '', 'GET', true, (data: any) => {
            if(data){
                let restData = CommonUtil.parseData(data);
                if(restData){
                    setRowsData(restData);
                }

            }

        }, onError)
    }

    const getActiveRepositions = (path: any, propName: any) => {
        NetworkManager.sendJsonRequest('api/dashboard/activeRepositions?saasOrgId='+CommonUtil.getSelectedOrgId(), '', '', 'GET', true, (data: any) => {
            if(data){
                let restData = CommonUtil.parseData(data);
                if(restData){
                    setRowsData(restData);
                }

            }

        }, onError)
    }

    const getActiveRentals = (path: any, propName: any) => {
        NetworkManager.sendJsonRequest('api/dashboard/activeRentals?saasOrgId='+CommonUtil.getSelectedOrgId(), '', '', 'GET', true, (data: any) => {
            if(data){
                let restData = CommonUtil.parseData(data);
                if(restData){
                    setRowsData(restData);
                }

            }

        }, onError)
    }

    const getIncoiceReport = (path: any, propName: any) => {
        // http://localhost:8080/api/invoiceAndBilling/getAllInvoicesAndSOIs?saasOrgId=1&pageNumber=5&pageSize=5
        NetworkManager.sendJsonRequest('api/invoiceAndBilling/getAllInvoicesAndSOIs?saasOrgId='+CommonUtil.getSelectedOrgId()+'&pageNumber=0&pageSize=1000', '', '', 'GET', true, (data: any) => {
            if(data){
                let restData = CommonUtil.parseData(data);
                if(restData){
                    setRowsData(restData);
                }
            }

        }, onError)
    }
    const getSOIReport = (path: any, propName: any) => {
        // http://localhost:8080/api/invoiceAndBilling/getAllInvoicesAndSOIs?saasOrgId=1&pageNumber=5&pageSize=5
        NetworkManager.sendJsonRequest('api/invoiceAndBilling/getAllInvoicesAndSOIs?saasOrgId='+CommonUtil.getSelectedOrgId()+'&pageNumber=0&pageSize=1000', '', '', 'GET', true, (data: any) => {
            if(data){
                let restData = CommonUtil.parseData(data);
                if(restData){
                    setRowsData(restData);
                }
            }

        }, onError)
    }



    useEffect(() => {
        //console.log(incomplete.propName);
        if (CommonUtil.isRootUser()) {
            setSelectedTab("IncompleteProfilesCount");
            getIncompleteCount();
            getLast7DaysIncometeCount();
            getPendingRenterCount();
            getPendingRepositionCount();
            getGenericReservationCount();
            getActivationCount();
            getDeactivationCount();

            getActiveRentalsCount();
            getActiveReposotionsCount();
            //getAvailabilitiesCount();
        } else {
            if (CommonUtil.ORGANIZATION_TYPE == CommonUtil.ORG_TYPE_OWNER || CommonUtil.ORGANIZATION_TYPE == CommonUtil.ORG_TYPE_OWNER_RENTER) {
                setSelectedTab("OpenAvailabilitiesCount");
                getAvailabilitiesCount();
                getGenericReservationCount();
                getActivationCount();
                getDeactivationCount();
            }else{
                setSelectedTab("ActivationDueCount");
                getActivationCount();
                getDeactivationCount();
            }
            getInvoiceCount();
            getActiveRentalsCount();
            getActiveReposotionsCount();
        }

        document.addEventListener(Events.SWITCH_CUSTOMER_EVENT, onSwitchCustomer);
        return () => {
            document.removeEventListener(Events.SWITCH_CUSTOMER_EVENT, onSwitchCustomer);
        }
    }, [])

    let onSwitchCustomer = (event: any)=>{
        //console.log(divRef, 'divRef');
        if(CommonUtil.isRootUser()){
            setSelectedTab("IncompleteProfilesCount");
            getIncompleteCount();
            getPendingRenterCount();
            getPendingRepositionCount();
            getGenericReservationCount();
            getActivationCount();
            getDeactivationCount();
            getActiveRentalsCount();
            getActiveReposotionsCount();
        }else {
            if (CommonUtil.ORGANIZATION_TYPE == CommonUtil.ORG_TYPE_OWNER || CommonUtil.ORGANIZATION_TYPE == CommonUtil.ORG_TYPE_OWNER_RENTER) {
                setSelectedTab("OpenAvailabilitiesCount");
                getAvailabilitiesCount();
                getGenericReservationCount();
                getActivationCount();
                getDeactivationCount();                
            }else{
                setSelectedTab("ActivationDueCount");
                getActivationCount();
                getDeactivationCount();
            }
            getInvoiceCount();
            getActiveRentalsCount();
            getActiveReposotionsCount();
        }

        //getEquipmentList();
        Events.dispatchBreadCrumbEvent();
        setPage(Math.random()*10000);
    }

    const changeTab = (propName: any, path: any) => {
        if (selectedTab === propName) return
        setRowsData([]);
        console.log(propName);
        setSelectedTab(propName)
        if (path === 'pendingbillingdetails') {
            getPendingBillsData(path, propName)
        } else if(path === 'activeRentals'){            
            getActiveRentals(path, propName)
        } else if(path === 'activeRepositions'){
            getActiveRepositions(path, propName)
        } else if(path === 'reprocessSubmitted'){
            // getActiveRepositions(path, propName)
            console.log(path);            
        } else if(path === 'invoices'){
            getIncoiceReport(path, propName)
            console.log(path);
        } else if(path === 'SOI'){
            getSOIReport(path, propName)
            console.log(path);
            
        } else {
            getData(path);
        }
    }

    const getSearchReservation= (path: any) => {
        setRowsData([]);
        // let query = propName === 'PendingBillingCountReposition' ? 'Reposition' : 'Rental'
        NetworkManager.sendJsonRequest('api/dashboard/searchReservation?reservationCode=' + path, '', '', 'GET', true, (data: any) => {
            if(data){
                // console.log(data);
                const result = JSON.parse(data);
                if(!result.errorMessage){
                    setRowsData([result]);
                }               
                
            }
        }, onError)
    }
    console.log(rowsData)

    return (
        <>
            <div className="page-content-wrapper">
                <div className="container-fluid" style={{ paddingLeft: "15px", paddingRight: "15px" }}>
                    <br />
                    <div className="row five">
                        {CommonUtil.isRootUser() ? (
                            <div className="col-md-6 col-lg-4 col-xl-2 col-xxl-2">
                                <div className="counter-box" onClick={() => { changeTab(incomplete.propName, 'incompletprofiles') }} style={incomplete.propName === selectedTab ? { backgroundColor: '#DAF4EE' } : {}} >
                                    <div className="d-flex justify-content-middle">
                                        <div className="col- counter1 text-center " style={{ width: "auto" }}>
                                            <img src="../../images/incompleteCustomerProfile.png" width={"60px"}/>
                                        </div>
                                        <div className="col- counter-indi-box " style={{ width: "auto" }}>                                            
                                            <span className="incomplete-counter counter" data-value={incomplete?.numValue} >{incomplete?.numValue}</span>
                                            <p className="m-0">{CommonUtil.getMessageText(Constants.INCOMPLETE_CUSTOMER_PROFILE,"Incomplete Customer Profile")}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        ) : null}

                        {CommonUtil.isRootUser()  ? (
                            <div className="col-md-6 col-lg-4 col-xl-2 col-xxl-2">
                                <div className="counter-box" onClick={() => { changeTab(last7days.propName, 'last7days') }} style={last7days.propName === selectedTab ? { backgroundColor: '#DAF4EE',paddingBottom:"3px"} : {paddingBottom:"3px"}} >
                                    <div className="d-flex justify-content-middle">
                                        <div className="col- counter1 text-center" style={{ width: "auto" }}>
                                            <img src="../../images/newCustomersIncompleteProfiles.png"  width={"60px"}/>
                                        </div>
                                        <div className="col- counter-indi-box " style={{ width: "auto" }}>
                                            <span className="availability-counter counter" data-value={last7days?.numValue}>{last7days?.numValue}</span>
                                            <p className="m-0">{CommonUtil.getMessageText(Constants.INCOMPLETE_7DAYS_PROFILE,"New Customers Incomplete Profiles")}</p>
                                            <p className="m-0" style={{fontSize:"8px",color:"#6B6B6B"}}>{"(7 days)"}</p>
                                            </div>
                                    </div>
                                </div>
                            </div>
                        ) : null}



                        {CommonUtil.ORGANIZATION_TYPE == CommonUtil.ORG_TYPE_OWNER || CommonUtil.ORGANIZATION_TYPE == CommonUtil.ORG_TYPE_OWNER_RENTER ? (
                            <div className="col-md-6 col-lg-4 col-xl-2 col-xxl-2">
                                <div className="counter-box" onClick={() => { changeTab(availabilities.propName, 'openavailabilities') }} style={availabilities.propName === selectedTab ? { backgroundColor: '#DAF4EE' } : {}} >
                                    <div className="d-flex justify-content-middle">
                                        <div className="col- counter1 text-center " style={{ width: "auto" }}>
                                            <img src="../../images/ActivationDue.png" width={"60px"}/>
                                        </div>
                                        <div className="col- counter-indi-box " style={{ width: "auto" }}>
                                            <span className="availability-counter counter" data-value={availabilities?.numValue}>{availabilities?.numValue}</span>
                                            <p className="m-0">{CommonUtil.getMessageText(Constants.AVAILABILITIES,"Availabilities")}</p>
                                            </div>
                                    </div>
                                </div>
                            </div>
                        ) : null}

                        <div className="col-md-6 col-lg-4 col-xl-2 col-xxl-2">
                            <div className="counter-box" onClick={() => { changeTab(activation.propName, 'activationdues') }} style={activation.propName === selectedTab ? { backgroundColor: '#DAF4EE' } : {}}>
                                <div className="d-flex justify-content-middle">
                                    <div className="col- counter1 text-center " style={{ width: "auto" }}>
                                        <img src="../../images/ActivationDue.png"  width={"60px"}/>
                                    </div>
                                    <div className="col- counter-indi-box " style={{ width: "auto" }}>
                                        <span className="activation-counter counter" data-value={activation?.numValue} >{activation?.numValue}</span>
                                        <p className="m-0">{CommonUtil.getMessageText(Constants.ACTIVATION_DUE,"Activation Due")}</p>
                                        </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 col-lg-4 col-xl-2 col-xxl-2">
                            <div className="counter-box" onClick={() => { changeTab(deactivation.propName, 'deactivationdues') }} style={deactivation.propName === selectedTab ? { backgroundColor: '#DAF4EE' } : {}}>
                                <div className="d-flex justify-content-middle">
                                    <div className="col- counter1 text-center " style={{ width: "auto" }}>
                                        <img src="../../images/DeactivationDue.png" width={"60px"}/>
                                    </div>
                                    <div className="col- counter-indi-box " style={{ width: "auto" }}>
                                        <span className="deactivation-counter counter" data-value={deactivation?.numValue} >{deactivation?.numValue}</span>
                                        <p className="m-0">{CommonUtil.getMessageText(Constants.DE_ACTIVATION_DUE,"Deactivation Due")}</p>
                                        </div>
                                </div>
                            </div>
                        </div>
                        {CommonUtil.isRootUser() ? (
                            <div className="col-md-6 col-lg-4 col-xl-2 col-xxl-2">
                                <div className="counter-box" onClick={() => { changeTab(repositionPending.propName, 'pendingbillingdetails') }} style={repositionPending.propName === selectedTab ? { backgroundColor: '#DAF4EE' } : {}}>
                                    <div className="d-flex justify-content-middle">
                                        <div className="col- counter1 text-center" style={{ width: "auto" }}>
                                            <img src="../../images/pendingBillsRepositions.png"  width={"60px"}/>
                                        </div>
                                        <div className="col- counter-indi-box " style={{ width: "auto" }}>
                                            <span className="pending-reposition counter" data-value={repositionPending?.numValue}>{repositionPending?.numValue}</span>
                                            <p className="m-0">{CommonUtil.getMessageText(Constants.PENDING_BILLING_REPOSITIONS,"Pending Billing - Repositions")}</p>
                                            </div>
                                    </div>
                                </div>
                            </div>
                        ) : null}
                        {CommonUtil.isRootUser() ? (
                            <div className="col-md-6 col-lg-4 col-xl-2 col-xxl-2">
                                <div className="counter-box" onClick={() => { changeTab(rentalPending.propName, 'pendingbillingdetails') }} style={rentalPending.propName === selectedTab ? { backgroundColor: '#DAF4EE' } : {}}>
                                    <div className="d-flex justify-content-middle">
                                        <div className="col- counter1 text-center" style={{ width: "auto" }}>
                                            <img src="../../images/PendingBillsRentals.png"  width={"60px"}/>
                                        </div>
                                        <div className="col- counter-indi-box " style={{ width: "auto" }}>
                                            <span className="pending-billing counter" data-value={rentalPending?.numValue} >{rentalPending?.numValue}</span>
                                            <p className="m-0">{CommonUtil.getMessageText(Constants.PENDING_BILLING_RENTALS,"Pending Billing - Rentals")}</p>
                                            </div>
                                    </div>
                                </div>
                            </div>
                        //        <div className="col-md-6 col-lg-4 col-xl-3 col-xxl-3">
                        //        <div className="counter-box" onClick={() => { changeTab(rentalPending.propName, 'pendingbillingdetails') }} style={rentalPending.propName === selectedTab ? { backgroundColor: '#DAF4EE' } : {}}>
                        //            <div className="row">
                        //                <div className="col- counter1 text-center pt-4 pb-4 pl-1 pr-1" style={{ width: "auto" }}>
                        //                    <i className="fa fa-wpforms"></i>
                        //                </div>
                        //                <div className="col- counter-indi-box pt-4 pb-4 pl-1 pr-1" style={{ width: "auto" }}>
                        //                    <p className="m-0">{CommonUtil.getMessageText(Constants.PENDING_BILLING_RENTALS,"Pending Billing - Rentals")}</p>
                        //                    <span className="pending-billing counter" data-value={rentalPending?.numValue} >{rentalPending?.numValue}</span></div>
                        //            </div>
                        //        </div>
                        //    </div>
                        ) : null}

                        {CommonUtil.isRootUser() || CommonUtil.ORGANIZATION_TYPE == CommonUtil.ORG_TYPE_OWNER || CommonUtil.ORGANIZATION_TYPE == CommonUtil.ORG_TYPE_OWNER_RENTER ? (
                            <div className="col-md-6 col-lg-4 col-xl-2 col-xxl-2">
                                <div className="counter-box" onClick={() => { changeTab(genReservation.propName, 'vinAssignmentPendingReservations') }} style={genReservation.propName === selectedTab ? { backgroundColor: '#DAF4EE' } : {}} >
                                    <div className="d-flex justify-content-middle">
                                        <div className="col- counter1 text-center " style={{ width: "auto" }}>
                                            <img src="../../images/vinAssgnmentPending.png"  width={"60px"}/>
                                        </div>
                                        <div className="col- counter-indi-box " style={{ width: "auto" }}>
                                            <span className="availability-counter counter" data-value={genReservation?.numValue}>{genReservation?.numValue}</span>
                                            <p className="m-0">{CommonUtil.getMessageText(Constants.GENERIC_RESERVATIONS,"VIN Assignment Pending")}</p>
                                            </div>
                                    </div>
                                </div>
                            </div>
                        ) : null}

{/* --------1245---------------- */}
                        {CommonUtil.isRootUser() || CommonUtil.ORGANIZATION_TYPE == CommonUtil.ORG_TYPE_OWNER || CommonUtil.ORGANIZATION_TYPE == CommonUtil.ORG_TYPE_OWNER_RENTER || CommonUtil.ORGANIZATION_TYPE == CommonUtil.ORG_TYPE_RENTER ? (
                            <div className="col-md-6 col-lg-4 col-xl-2 col-xxl-2">
                                <div className="counter-box" onClick={() => { changeTab(activeReposition.propName, 'activeRepositions') }} style={activeReposition.propName === selectedTab ? { backgroundColor: '#DAF4EE' } : {}} >
                                    <div className="d-flex justify-content-middle">
                                        <div className="col- counter1 text-center " style={{ width: "auto" }}>
                                            <img src="../../images/ActiveRepositions.png"  width={"60px"}/>
                                        </div>
                                        <div className="col- counter-indi-box " style={{ width: "auto" }}>
                                            <span className="availability-counter counter" data-value={activeReposition?.numValue}>{activeReposition?.numValue}</span>
                                            <p className="m-0">{CommonUtil.getMessageText(Constants.ACTIVE_REPOSITIONS,"Active Repositions")}</p>
                                            </div>
                                    </div>
                                </div>
                            </div>
                        ) : null}

                        {CommonUtil.isRootUser() || CommonUtil.ORGANIZATION_TYPE == CommonUtil.ORG_TYPE_OWNER || CommonUtil.ORGANIZATION_TYPE == CommonUtil.ORG_TYPE_OWNER_RENTER || CommonUtil.ORGANIZATION_TYPE == CommonUtil.ORG_TYPE_RENTER? (
                            <div className="col-md-6 col-lg-4 col-xl-2 col-xxl-2">
                                <div className="counter-box" onClick={() => { changeTab(activeRental.propName, 'activeRentals') }} style={activeRental.propName === selectedTab ? { backgroundColor: '#DAF4EE' } : {}} >
                                    <div className="d-flex justify-content-middle">
                                        <div className="col- counter1 text-center " style={{ width: "auto" }}>
                                            <img src="../../images/ActiveRentals.png"  width={"60px"}/>
                                        </div>
                                        <div className="col- counter-indi-box " style={{ width: "auto" }}>
                                            <span className="availability-counter counter" data-value={activeRental?.numValue}>{activeRental?.numValue}</span>
                                            <p className="m-0">{CommonUtil.getMessageText(Constants.ACTIVE_RENTALS,"Active Rentals")}</p>
                                            </div>
                                    </div>
                                </div>
                            </div>
                        ) : null}
                        {CommonUtil.isRootUser() ? (
                            <div className="col-md-6 col-lg-4 col-xl-2 col-xxl-2">
                                <div className="counter-box" onClick={() => { changeTab(reprocessSubmitted.propName, 'reprocessSubmitted') }} style={"reprocessSubmitted" === selectedTab ? { backgroundColor: '#DAF4EE' } : {}} >
                                    <div className="d-flex justify-content-middle">
                                        <div className="col- counter1 text-center " style={{ width: "auto" }}>
                                            <img src="../../images/Reprocess-Submitted-Reservations.svg"  width={"60px"}/>
                                        </div>
                                        <div className="col- counter-indi-box " style={{ width: "auto" }}>                                            
                                            <p className="m-0">{CommonUtil.getMessageText(Constants.REPROCESS_SUBMITTED_RESERVATIONS,"Reprocess Submitted Reservations")}</p>
                                            </div>
                                    </div>
                                </div>
                            </div>
                        ) : null}
{/* 2708         */}
                        {CommonUtil.isRootUser() || CommonUtil.ORGANIZATION_TYPE == CommonUtil.ORG_TYPE_OWNER || CommonUtil.ORGANIZATION_TYPE == CommonUtil.ORG_TYPE_OWNER_RENTER || CommonUtil.ORGANIZATION_TYPE == CommonUtil.ORG_TYPE_RENTER? (
                            <div className="col-md-6 col-lg-4 col-xl-2 col-xxl-2 d-none">
                                <div className="counter-box" onClick={() => { changeTab(invoiceReport.propName, 'invoices') }} style={invoiceReport.propName === selectedTab ? { backgroundColor: '#DAF4EE' } : {}} >
                                    <div className="d-flex justify-content-middle">
                                        <div className="col- counter1 text-center " style={{ width: "auto" }}>
                                            <img src="../../images/invoice.png" height={"50px"}/>
                                        </div>
                                        <div className="col- counter-indi-box " style={{ width: "auto" }}>
                                            <span className="availability-counter counter" data-value={invoiceReport?.numValue}>{invoiceReport?.numValue}</span>
                                            <p className="m-0">{CommonUtil.getMessageText(Constants.INVOICES,"Invoices")}</p>
                                            </div>
                                    </div>
                                </div>
                            </div>
                        ) : null}
                        {CommonUtil.isRootUser() || CommonUtil.ORGANIZATION_TYPE == CommonUtil.ORG_TYPE_OWNER || CommonUtil.ORGANIZATION_TYPE == CommonUtil.ORG_TYPE_OWNER_RENTER || CommonUtil.ORGANIZATION_TYPE == CommonUtil.ORG_TYPE_RENTER? (
                            <div className="col-md-6 col-lg-4 col-xl-2 col-xxl-2 d-none">
                                <div className="counter-box" onClick={() => { changeTab(soiReport.propName, 'SOI') }} style={soiReport.propName === selectedTab ? { backgroundColor: '#DAF4EE' } : {}} >
                                    <div className="d-flex justify-content-middle">
                                        <div className="col- counter1 text-center " style={{ width: "auto" }}>
                                            <img src="../../images/soi.png" height={"50px"}/>
                                        </div>
                                        <div className="col- counter-indi-box " style={{ width: "auto" }}>
                                            <span className="availability-counter counter" data-value={soiReport?.numValue}>{soiReport?.numValue}</span>
                                            <p className="m-0">{CommonUtil.getMessageText(Constants.SOI,"SOI")}</p>
                                            </div>
                                    </div>
                                </div>
                            </div>
                        ) : null}



                    </div>
                    {isMobile ? (
                            <>
                                {
                                    rowsData && rowsData.length > 0 ? (<>
                                        {incomplete.propName === selectedTab ? (<MGrid id="OrgEquipSearchGrid" cols={inCompleteColumnDefs}
                                                                                       rows={rowsData} pageSize={10} headerHeight={CommonUtil.GRID_HEADER_HEIGHT} rowHeight={CommonUtil.GRID_ROW_HEIGHT}
                                                                                       ref={gridRef} addNew={false} />) : null}
                                        {last7days.propName === selectedTab ? (<MGrid id="OrgEquipSearchGrid" cols={inCompleteColumnDefs}
                                                                                       rows={rowsData} pageSize={10} headerHeight={CommonUtil.GRID_HEADER_HEIGHT} rowHeight={CommonUtil.GRID_ROW_HEIGHT}
                                                                                       ref={gridRef} addNew={false} />) : null}
                                        {activation.propName === selectedTab ? (<MGrid id="OrgEquipSearchGrid" cols={activationColumnDefs}
                                                                                       rows={rowsData} pageSize={10} headerHeight={CommonUtil.GRID_HEADER_HEIGHT} rowHeight={CommonUtil.GRID_ROW_HEIGHT}
                                                                                       ref={gridRef} addNew={false} />) : null}
                                        {deactivation.propName === selectedTab ? (<MGrid id="OrgEquipSearchGrid" cols={deactivationColumnDefs}
                                                                                         rows={rowsData} pageSize={10} headerHeight={CommonUtil.GRID_HEADER_HEIGHT} rowHeight={CommonUtil.GRID_ROW_HEIGHT}
                                                                                         ref={gridRef} addNew={false} />) : null}
                                        {repositionPending.propName === selectedTab ? (<MGrid id="OrgEquipSearchGrid" cols={repositionColumnDefs}
                                                                                              rows={rowsData} pageSize={10} headerHeight={CommonUtil.GRID_HEADER_HEIGHT} rowHeight={CommonUtil.GRID_ROW_HEIGHT}
                                                                                              ref={gridRef} addNew={false} />) : null}
                                        {rentalPending.propName === selectedTab ? (<MGrid id="OrgEquipSearchGrid" cols={renterColumnDefs}
                                                                                          rows={rowsData} pageSize={10} headerHeight={CommonUtil.GRID_HEADER_HEIGHT} rowHeight={CommonUtil.GRID_ROW_HEIGHT}
                                                                                          ref={gridRef} addNew={false} />) : null}
                                        {availabilities.propName === selectedTab ? (<MGrid id="OrgEquipSearchGrid" cols={availColumnDefs} method={{ onClickLink }}
                                                                                           rows={rowsData} pageSize={10} headerHeight={CommonUtil.GRID_HEADER_HEIGHT} rowHeight={CommonUtil.GRID_ROW_HEIGHT}
                                                                                           ref={gridRef} addNew={false} />) : null}
                                        {genReservation.propName === selectedTab ? (<MGrid id="OrgEquipSearchGrid" cols={availColumnDefs} method={{ onClickLink }}
                                                                                           rows={rowsData} pageSize={10} headerHeight={CommonUtil.GRID_HEADER_HEIGHT} rowHeight={CommonUtil.GRID_ROW_HEIGHT}
                                                                                           ref={gridRef} addNew={false} />) : null}
{/* --------1245---------------- */}
                                        {activeReposition.propName === selectedTab ? (<MGrid id="OrgEquipSearchGrid" cols={activeRepositionColumnDefs}
                                                                                          rows={rowsData} pageSize={10} headerHeight={CommonUtil.GRID_HEADER_HEIGHT} rowHeight={CommonUtil.GRID_ROW_HEIGHT}
                                                                                          ref={gridRef} addNew={false} />) : null}
                                        {activeRental.propName === selectedTab ? (<MGrid id="OrgEquipSearchGrid" cols={activeRentalColumnDefs}
                                                                                          rows={rowsData} pageSize={10} headerHeight={CommonUtil.GRID_HEADER_HEIGHT} rowHeight={CommonUtil.GRID_ROW_HEIGHT}
                                                                                          ref={gridRef} addNew={false} />) : null}
{/* --------1034---------------- */}
                                        {reprocessSubmitted.propName === selectedTab ? (<MGrid id="OrgEquipSearchGrid" cols={repositionColumnDefs}
                                                                                              rows={rowsData} pageSize={10} headerHeight={CommonUtil.GRID_HEADER_HEIGHT} rowHeight={CommonUtil.GRID_ROW_HEIGHT}
                                                                                              ref={gridRef} addNew={false} />) : null}
                                        {invoiceReport.propName === selectedTab ? (<MGrid id="OrgEquipSearchGrid" cols={inoivesColumnDefs}
                                                                                            rows={rowsData} pageSize={10} headerHeight={CommonUtil.GRID_HEADER_HEIGHT} rowHeight={CommonUtil.GRID_ROW_HEIGHT}
                                                                                            ref={gridRef} addNew={false} />) : null}
                                        {soiReport.propName === selectedTab ? (<MGrid id="OrgEquipSearchGrid" cols={inoivesColumnDefs}
                                                                                            rows={rowsData} pageSize={10} headerHeight={CommonUtil.GRID_HEADER_HEIGHT} rowHeight={CommonUtil.GRID_ROW_HEIGHT}
                                                                                            ref={gridRef} addNew={false} />) : null}
                                    </>) : null
                                }
                            </>
                        ) :
                        (<>
                            <div className="gray my-2" style={{ paddingLeft: "5px" }}>
                                <div className="row">
                                {reprocessSubmitted.propName === selectedTab ?
                                <div className="row pt-2 pb-3" >
                                                <div className="col-md-2 col-lg-6 pt-2" >
                                                    <input  type="text" className="form-control "
                                                        required={true} placeholder="Reservation Code"
                                                        value={reservationCode}
                                                        onChange={(e)=>{setReservationCode(e.target.value)}}
                                                        />
                                                </div>
                                                <div className="col-md-2 col-lg-6 pt-2" >
                                                    <button type="button" className="btn btn-warning cus-btn btn-sm button-height"
                                                        onClick={(e:any)=>getSearchReservation(reservationCode)}
                                                         >
                                                        {CommonUtil.getMessageText(Constants.SEARCH,"Search")}
                                                    </button>
                                                </div>
                                                
                                            </div>:null}
                                            {invoiceReport.propName === selectedTab &&
                                                <InvoiceFilter />
                                            }
                                    <div className="ag-theme-alpine gridHeight" style={{ height: (CommonUtil.isRootUser()?(reprocessSubmitted.propName === selectedTab ? 'calc(100vh - 400px)': 'calc(100vh - 350px)'):'calc(100vh - 250px)'), width: '100%' }} >
                                        {incomplete.propName === selectedTab ? (<AGGrid id="OrgEquipSearchGrid1" cols={inCompleteColumnDefs}
                                                                                        rows={rowsData} pageSize={500} headerHeight={CommonUtil.GRID_HEADER_HEIGHT} rowHeight={CommonUtil.GRID_ROW_HEIGHT}
                                                                                        ref={gridRef} addNew={false} />) : null}
                                        {last7days.propName === selectedTab ? (<AGGrid id="OrgEquipSearchGrid2" cols={inCompleteColumnDefs}
                                                                                        rows={rowsData} pageSize={500} headerHeight={CommonUtil.GRID_HEADER_HEIGHT} rowHeight={CommonUtil.GRID_ROW_HEIGHT}
                                                                                        ref={gridRef} addNew={false} />) : null}
                                        {activation.propName === selectedTab ? (<AGGrid id="OrgEquipSearchGrid3" cols={activationColumnDefs}
                                                                                        rows={rowsData} pageSize={500} headerHeight={CommonUtil.GRID_HEADER_HEIGHT} rowHeight={CommonUtil.GRID_ROW_HEIGHT}
                                                                                        ref={gridRef} addNew={false} />) : null}
                                        {deactivation.propName === selectedTab ? (<AGGrid id="OrgEquipSearchGrid4" cols={deactivationColumnDefs}
                                                                                          rows={rowsData} pageSize={500} headerHeight={CommonUtil.GRID_HEADER_HEIGHT} rowHeight={CommonUtil.GRID_ROW_HEIGHT}
                                                                                          ref={gridRef} addNew={false} />) : null}
                                        {repositionPending.propName === selectedTab ? (<AGGrid id="OrgEquipSearchGrid5" cols={repositionColumnDefs}
                                                                                               rows={rowsData} pageSize={500} headerHeight={CommonUtil.GRID_HEADER_HEIGHT} rowHeight={CommonUtil.GRID_ROW_HEIGHT}
                                                                                               ref={gridRef} addNew={false} />) : null}
                                        {rentalPending.propName === selectedTab ? (<AGGrid id="OrgEquipSearchGrid6" cols={renterColumnDefs}
                                                                                           rows={rowsData} pageSize={500} headerHeight={CommonUtil.GRID_HEADER_HEIGHT} rowHeight={CommonUtil.GRID_ROW_HEIGHT}
                                                                                           ref={gridRef} addNew={false} />) : null}
                                        {availabilities.propName === selectedTab ? (<AGGrid id="OrgEquipSearchGrid7" cols={availColumnDefs} method={{ onClickLink }}
                                                                                            rows={rowsData} pageSize={500} headerHeight={CommonUtil.GRID_HEADER_HEIGHT} rowHeight={CommonUtil.GRID_ROW_HEIGHT}
                                                                                            ref={gridRef} addNew={false} />) : null}
                                        {genReservation.propName === selectedTab ? (<AGGrid id="OrgEquipReseveGrid8" cols={reservationColumnDefs} method={{ onClickLink }}
                                                                                            rows={rowsData} pageSize={500} headerHeight={CommonUtil.GRID_HEADER_HEIGHT} rowHeight={CommonUtil.GRID_ROW_HEIGHT}
                                                                                            ref={gridRef} addNew={false} />) : null}
{/* --------1245---------------- */}

                                        {activeReposition.propName === selectedTab ? (<AGGrid id="OrgEquipSearchGrid9" cols={activeRepositionColumnDefs}
                                                                                           rows={rowsData} pageSize={500} headerHeight={CommonUtil.GRID_HEADER_HEIGHT} rowHeight={CommonUtil.GRID_ROW_HEIGHT}
                                                                                           ref={gridRef} addNew={false} />) : null}
                                        {activeRental.propName === selectedTab ? (<AGGrid id="OrgEquipSearchGrid10" cols={activeRentalColumnDefs}
                                                                                           rows={rowsData} pageSize={500} headerHeight={CommonUtil.GRID_HEADER_HEIGHT} rowHeight={CommonUtil.GRID_ROW_HEIGHT}
                                                                                           ref={gridRef} addNew={false} />) : null}                                                                                            
{/* --------V20-1034---------------- */}                                        
                                        {reprocessSubmitted.propName === selectedTab ? (<AGGrid id="OrgEquipSearchGrid11" cols={reprocessColumnDefs}
                                                rows={rowsData} pageSize={500} headerHeight={CommonUtil.GRID_HEADER_HEIGHT} rowHeight={CommonUtil.GRID_ROW_HEIGHT}
                                                ref={gridRef} addNew={false} />) : null}                                             
                                            
                                        {invoiceReport.propName === selectedTab ? (<AGGrid id="OrgEquipSearchGrid11" cols={inoivesColumnDefs}
                                                rows={rowsData} pageSize={500} headerHeight={CommonUtil.GRID_HEADER_HEIGHT} rowHeight={CommonUtil.GRID_ROW_HEIGHT}
                                                ref={gridRef} addNew={false} />) : null}                                            
                                            
                                        {soiReport.propName === selectedTab ? (<AGGrid id="OrgEquipSearchGrid11" cols={inoivesColumnDefs}
                                                rows={rowsData} pageSize={500} headerHeight={CommonUtil.GRID_HEADER_HEIGHT} rowHeight={CommonUtil.GRID_ROW_HEIGHT}
                                                ref={gridRef} addNew={false} />) : null}
                                             
                                            
                                                                                                                                  
                                    </div>
                                </div>
                            </div>
                        </>)
                    }
                </div>
            </div>
        </>
    );
}
export default DashBoard360;
