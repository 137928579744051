import React, { useEffect, useRef, useState } from "react";
import { CommonUtil } from "../utils/CommonUtil";
import { Constants } from "../Pages/Languages/Constants";
import { NetworkManager } from "./NetworkManager";
import BusyLoader from "./BusyLoader";
import ToastManager from "./ToastManager";
import { StatusCodeUtil } from "../utils/StatusCodeUtil";
import Confirm from "./confirm";
import AGGrid from "./AGGrid";
import { DragNDrop } from "../utils/DragDrop";
var fileType: string = "";
var fullPath: string = "";
let prevdocs:any = {};
const Terms = () => {
  const fileref = useRef<any>();
  const droptnc = useRef<any>();
  const dropagree = useRef<any>();
  const [fileName, setFileName] = useState("");
  const [attachDocs, setAttachDocs] = useState<any>({});
  const [rows, setRows] = useState([]);
  const [rows2, setRows2] = useState([]);
  const [fromEdit, setFromEdit] = useState(false);
  
  const columnDefOwner =[
    // { field: 'ownerName', headerName: "Owner Name", resizable: true, width: 100, flex: 1, sortable: true, },
    { field: 'renterName', headerName: "Renter Name", resizable: true, width: 100, flex: 1, sortable: true, },
    { field: 'renterUser', headerName: "Accepted by", resizable: true, width: 100, flex: 1, sortable: true, },
    { field: 'acceptedOn', headerName: "Accepted on", resizable: true, width: 100, flex: 1, sortable: true, },
  ]

  const columnDefRenter =[
    { field: 'ownerName', headerName: "Owner Name", resizable: true, width: 100, flex: 1, sortable: true, },
    // { field: 'renterName', headerName: "Renter Name", resizable: true, width: 100, flex: 1, sortable: true, },
    { field: 'renterUser', headerName: "Accepted by", resizable: true, width: 100, flex: 1, sortable: true, },
    { field: 'acceptedOn', headerName: "Accepted on", resizable: true, width: 100, flex: 1, sortable: true, },
  ]


  let onRemoveDoc = (fType: string) => {
    Confirm.show("Confirm", "Do you want remove document?", [
      {
        label: "Yes",
        callback: function () {
          const docs = { ...attachDocs };
          if (fType == "tnc") {
            docs.tncRequired = false;
            docs.tncFile = "";
            docs.tncFileName = "";
          } else {
            docs.agreementRequired = false;
            docs.agreementFile = "";
            docs.agreementFileName = "";
          }
          setAttachDocs(docs);
        },
      },
      { label: "No" },
    ]);
  };

   useEffect(() => {
    prevdocs = {...attachDocs};
   },[attachDocs]);

  useEffect(() => {
    getTncDocs();
    DragNDrop({ drop: droptnc, handleDragOver, handleDrop:(e:any)=>{
      fileType = "tnc";  
      handleDrop(e);
    }, handleDragEnter, handleDragLeave });
    DragNDrop({ drop: dropagree, handleDragOver, handleDrop:(e:any)=>{
      fileType = "agree";  
      handleDrop(e);
    }, handleDragEnter, handleDragLeave });
  }, [CommonUtil.ORGANIZATION_TYPE]);

  const handleDrop = (e: any) => {
      e.preventDefault();
      e.stopPropagation();          
      const { files } = e.dataTransfer;
      if (files && files.length) {
        fileref.current.files = files;
          onSelectFiles({target:{files:files}});
      }
  }
  const handleDragEnter = (e: any) => {
    e.preventDefault()
    e.stopPropagation()
  }
  const handleDragLeave = (e: any) => {
    e.preventDefault()
    e.stopPropagation()
  }

  const handleDragOver = (e: any) => {
    e.preventDefault()
    e.stopPropagation()
  }

  let uploadFinanceDocuments = (fType: string) => {
    fileType = fType;
    onFPGSTFormUpload();
  };
  let onFPGSTFormUpload = () => {
    if (fileref.current) {
      fileref.current.value = "";
      if (document.getElementById("inputGST")) {
        // document.getElementById("inputGST").click();
      }

      // $("#inputGST").click();
      try {
        fileref.current.click();
      } catch (e) {
        console.log(e);
      }
    }
    setFileName("");
  };

  let onLoginError = (err: any) => {
    BusyLoader.hideLoader();
    console.log(err);
  };

  let onSelectFiles = (e: any) => {
    //console.log(e);
    if (e && e.target && e.target.files[0]) {
      let fileName = e.target.files[0].name;
      setFileName(fileName);
      BusyLoader.showLoader();
      fullPath =
        "objectPath=tnc/organizations/" +
        CommonUtil.getSelectedOrgId() +
        "/" +
        fileName;
      var qParam: string = fullPath;
      NetworkManager.sendJsonRequest(
        "api/session/uploadUrl",
        "",
        qParam,
        "GET",
        false,
        onUploadSuccess,
        onLoginError
      );
    }
  };
  let onUploadSuccess = (dataObj: any) => {
    if (dataObj) {
      if (dataObj.statusCode) {
        BusyLoader.hideLoader();
        var errMsg = StatusCodeUtil.getCodeMessage(dataObj.statusCode);
        ToastManager.showToast(errMsg, "Error");
      } else {
        var strUploadPath = dataObj;
        if (fileref.current && fileref.current.files) {
          const formData = new FormData();
          var myHeaders = new Headers();
          var file = fileref.current.files[0];
          var requestOptions: any = {
            method: "PUT",
            headers: myHeaders,
            body: file,
            redirect: "follow",
          };
          fetch(strUploadPath, requestOptions)
            .then((response) => response.text())
            .then((result) => {
              BusyLoader.hideLoader();
              //   uploadDocument();
              const docs = { ...prevdocs };
              if (fileType == "tnc") {
                docs.tncRequired = true;
                docs.tncFile =
                  "tnc/organizations/" +
                  CommonUtil.getSelectedOrgId() +
                  "/" +
                  fileref.current.files[0].name;
                docs.tncFileName = fileref.current.files[0].name;
              } else {
                docs.agreementRequired = true;
                docs.agreementFile =
                  "tnc/organizations/" +
                  CommonUtil.getSelectedOrgId() +
                  "/" +
                  fileref.current.files[0].name;
                docs.agreementFileName = fileref.current.files[0].name;
              }
              setAttachDocs(docs);
            })
            .catch((error) => {
              BusyLoader.hideLoader();
              console.log("error", error);
            });
        }
      }
    }
  };

  let uploadDocument = () => {
    let restObj: any = { ...attachDocs };
    if(!attachDocs?.tncFile || !attachDocs?.agreementFile){
      let strConfirm = "Please upload both the documents";
      ToastManager.showToast(strConfirm, 'Error');
      return;
    }
    let method = "POST";
    let uploadUrl: string =
      "api/organization/tnc/?saasOrgId=" + CommonUtil.getSaasOrgId();
    if (fromEdit) {
      method = "PUT";
      uploadUrl = "api/organization/tnc/" + CommonUtil.getSaasOrgId();
    }
    NetworkManager.sendJsonRequest(
      uploadUrl,
      restObj,
      "",
      method,
      false,
      onUpdateDocument,
      onLoginError
    );
  };

  let onUpdateDocument = (dataObj: any) => {
    if (dataObj) {
      var restData = JSON.parse(dataObj);
      if (restData.statusCode) {
        BusyLoader.hideLoader();
        var errMsg = StatusCodeUtil.getCodeMessage(restData.statusCode);
        ToastManager.showToast(errMsg, "Error");
      } else {
        ToastManager.showToast("Documents uploaded successfully", "Info");
      }
    }
    getTncDocs();
    BusyLoader.hideLoader();
  };
  const getTncDocs = () => {
    let financeAPI: string =
      "api/organization/tnc/" + CommonUtil.getSaasOrgId();
    if(CommonUtil.ORGANIZATION_TYPE === CommonUtil.ORG_TYPE_RENTER){
      financeAPI =
     `api/organization/tnc/1?renterOrgId=${CommonUtil.getSaasOrgId()}`;
    }
    if(CommonUtil.ORGANIZATION_TYPE === CommonUtil.ORG_TYPE_OWNER_RENTER){
      financeAPI =
     `api/organization/tnc/${CommonUtil.getSaasOrgId()}?renterOrgId=${CommonUtil.getSaasOrgId()}`;
    }
    NetworkManager.sendJsonRequest(
      financeAPI,
      "",
      "",
      "GET",
      true,
      onGetTncDocs,
      onLoginError
    );
  };
  const onGetTncDocs = (dataObj: any) => {
    if (dataObj) {
      var restData = JSON.parse(dataObj);
      if (restData) {
        const {agreementConfig,
          agreementFile,
          agreementFileName,
          agreementRequired,
          renterUser,
          saasOrgId,
          tncFile,
          tncFileName,
          tncRequired }  = restData;
        setAttachDocs( {agreementConfig,
          agreementFile,
          agreementFileName,
          agreementRequired,
          renterUser,
          saasOrgId,
          tncFile,
          tncFileName,
          tncRequired });
          setFromEdit((restData?.agreementFile && restData?.tncFile) ? true : false );
          
            setRows2(restData?.tncAcceptedOwners || []);
          
            setRows(restData?.tncAcceptedRenters || []);
          
        
      }
    }
  };
  const notforRenter =  CommonUtil.SELECT_ORG_TYPE === CommonUtil.ORG_TYPE_RENTER ? "d-none" : "";
  // let newColumns:any = [...columnDef];
  //   if(CommonUtil.SELECT_ORG_TYPE === CommonUtil.ORG_TYPE_OWNER){
  //     newColumns = columnDef.filter((ele:any)=>ele.field != "ownerName")
  //   }else if(CommonUtil.SELECT_ORG_TYPE === CommonUtil.ORG_TYPE_RENTER){
  //     newColumns = columnDef.filter((ele:any)=>ele.field != "renterName")
  //   }
  

  return (
    <>
      <input
        type="file"
        ref={fileref}
        className="custom-file-input"
        multiple={false}
        id="inputGST"
        accept="application/pdf"
        style={{ display: "none" }}
        onChange={(e) => {
          onSelectFiles(e);
        }}
      ></input>
      <div>
        <div className="row">
          <div className="col-12">
            <h6>Owner's Customer T&C and the Master Rental Agreements Details</h6>

          <div className={notforRenter}>
            <div className="mt-4 mb-4 d-flex ">
              <div>
                {" "}
                <b>
                  Enable the option for the Renters to accept the Owner's T&C
                  and the Customer Agreement
                </b>{" "}
              </div>
              <div className="form-check form-switch ms-4">
                <input
                  className="form-check-input"
                  type="checkbox"
                  role="switch"
                  id="flexSwitchCheckChecked"
                  onChange={(e) =>
                    setAttachDocs({
                      ...attachDocs,
                      agreementConfig: e.target.checked,
                    })
                  }
                  checked={attachDocs?.agreementConfig}
                />
                {/* <label className="form-check-label" htmlFor="flexSwitchCheckChecked">Checked switch checkbox input</label> */}
              </div>
            </div>
            <div className="row">
              <div className="col-md-4 col-12">
                <div className="form-group row">
                  <label className="col-sm-12 col-form-label">
                    Terms & Conditions Document
                  </label>
                </div>
              </div>
              <div className="col-md-4 col-12" ref={droptnc}>
                <div
                  className="col-sm-12 cus-input-file-text financial-browse-button"
                  onClick={() => {
                    uploadFinanceDocuments("tnc");
                  }}
                >
                  {CommonUtil.getMessageText(
                    Constants.DRAG_DRAP_FILES,
                    "Drag and Drop files here Or"
                  )}{" "}
                  &nbsp;
                  <button
                    type="button"
                    className="btn btn-outline-primary financial-button"
                  >
                    {CommonUtil.getMessageText(
                      Constants.BROWSE_FILE,
                      "Browse File"
                    )}
                  </button>
                </div>
                <div className="col-sm-12 cus-input-file p-0"></div>
              </div>
              {attachDocs?.tncFileName && (
                <div className="col-md-4 col-12 mt-2">
                  <div className="form-group row">
                    <div className="col-sm-12">
                      <div className="gallery flex documents-gallary">
                        <div className="img-fluid  document-box">
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <img
                              src="../../images/document.png"
                              alt=""
                              className={"financial-download-image"}
                              onClick={(e: any) => {
                                onDownloadDoc(attachDocs?.tncFile);
                              }}
                            />
                            <span
                              style={{
                                paddingLeft: "10px",
                                paddingRight: "10px",
                              }}
                            >
                              <a>
                                <i
                                  onClick={() => {
                                    onRemoveDoc("tnc");
                                  }}
                                  className=" remove-btn fa fa-remove"
                                  style={{ color: "red", cursor: "pointer" }}
                                ></i>
                              </a>
                            </span>
                          </div>
                          <label className="financial-download-label">
                            {attachDocs?.tncFileName || ""}
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="row">
              <div className="col-md-4 col-12">
                <div className="form-group row">
                  <label className="col-sm-12 col-form-label">
                    Master Rental Agreement Document
                  </label>
                </div>
              </div>
              <div className="col-md-4 col-12" ref={dropagree}>
                <div
                  className="col-sm-12 cus-input-file-text financial-browse-button"
                  onClick={() => {
                    uploadFinanceDocuments("agree");
                  }}
                >
                  {CommonUtil.getMessageText(
                    Constants.DRAG_DRAP_FILES,
                    "Drag and Drop files here Or"
                  )}{" "}
                  &nbsp;
                  <button
                    type="button"
                    className="btn btn-outline-primary financial-button"
                  >
                    {CommonUtil.getMessageText(
                      Constants.BROWSE_FILE,
                      "Browse File"
                    )}
                  </button>
                </div>
                <div className="col-sm-12 cus-input-file p-0"></div>
              </div>
              {attachDocs?.agreementFileName && (
                <div className="col-md-4 col-12 mt-2">
                  <div className="form-group row">
                    <div className="col-sm-12">
                      <div className="gallery flex documents-gallary">
                        <div className="img-fluid  document-box">
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <img
                              src="../../images/document.png"
                              alt=""
                              className={"financial-download-image"}
                              onClick={(e: any) => {
                                onDownloadDoc(attachDocs?.agreementFile);
                              }}
                            />
                            <span
                              style={{
                                paddingLeft: "10px",
                                paddingRight: "10px",
                              }}
                            >
                              <a>
                                <i
                                  onClick={() => {
                                    onRemoveDoc("agree");
                                  }}
                                  className=" remove-btn fa fa-remove"
                                  style={{ color: "red", cursor: "pointer" }}
                                ></i>
                              </a>
                            </span>
                          </div>
                          <label className="financial-download-label">
                            {attachDocs?.agreementFileName || ""}
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>

            <div className="mt-4">
              <div className="row">
                {CommonUtil.SELECT_ORG_TYPE !== CommonUtil.ORG_TYPE_RENTER && <div className="col-12 mb-3">
                  <div className="ag-theme-alpine gridHeight"
                        style={{ height: '200px', width: '100%', paddingLeft: '8px', paddingRight: '5px' }} >
                        <AGGrid id={`acceptedGrid_owner' : ''}`} cols={columnDefOwner} hideToolbar={true}
                            rows={rows} pageSize={500} headerHeight={30} rowHeight={40}
                        />
                    </div>
                </div>}
                {CommonUtil.SELECT_ORG_TYPE !== CommonUtil.ORG_TYPE_OWNER && <div className="col-12 mb-3">
                  <div className="ag-theme-alpine gridHeight"
                        style={{ height: '200px', width: '100%', paddingLeft: '8px', paddingRight: '5px' }} >
                        <AGGrid id={`acceptedGrid_renter' : ''}`} cols={columnDefRenter} hideToolbar={true}
                            rows={rows2} pageSize={500} headerHeight={30} rowHeight={40}
                        />
                    </div>
                </div>}
              </div>
            </div>

          </div>

              <div className={notforRenter}>
          <div className="col-md-12 col-12 text-right pt-0 pb-2">
            <button
              type="button"
              style={{ display: "none" }}
              className="btn btn-outline-dark"
              data-dismiss="modal"
            >
              {CommonUtil.getMessageText(
                Constants.DISCARD_CHANGES,
                "Discard Changes"
              )}
            </button>
            &nbsp;&nbsp;
            <button
              type="submit"
              // style={{ display: CommonUtil.isRootUser() ? "" : "none" }}
              className="btn btn-success"
              onClick={(e) => {
                uploadDocument();
                //   onClickSave("section");
              }}
            >
              {CommonUtil.getMessageText(Constants.SAVE, "Save")}
            </button>
          </div>
          </div>

        </div>
      </div>
    </>
  );
};

export const onDownloadDoc = (filePath: string)=>{
  console.log("---------------");  
  const onDownloadSuccess = (dataObj: any) => {
    //console.log(dataObj);
    let link = document.createElement("a");
    link.download = "download";
    link.href = dataObj;
    link.target = "_blank";
    link.click();
  };
  const onLoginError = (err: any) => {
    BusyLoader.hideLoader();
    console.log(err);
  };
  
  if (filePath) {
    var qParam: string = "objectPath=" + filePath;
    NetworkManager.sendJsonRequest(
      "api/session/downloadUrl",
      "",
      qParam,
      "GET",
      true,
      onDownloadSuccess,
      onLoginError
    );
  }
  
}

export default Terms;
