import React, { useEffect, useRef, useState } from "react";
import { CheckPriceValue, CommonUtil, getSelectedRangeRate } from "../../utils/CommonUtil";
import { NetworkManager } from "../../Components/NetworkManager";
import ToastManager from "../../Components/ToastManager";
import { StatusCodeUtil } from "../../utils/StatusCodeUtil";
import Flatpickr from "react-flatpickr";
import DraggableComponent from "../../Components/DraggableComponent";
import SingleSelectComp from "../../Components/SingleSelectComp";
import { Modal } from "react-bootstrap";
import BusyLoader from "../../Components/BusyLoader";
import $ from "jquery";
import { Constants } from "../Languages/Constants";
import useStateWithCallback from "use-state-with-callback";
import AGGrid from "../../Components/AGGrid";
import EquipCellRenderer from "../Organization/EquipCellRenderer";
import {IAGrid} from "../../Components/IInterfaces";
import EquipAvailTypeRenderer from "../Organization/EquipAvailTypeRenderer";
import {DragNDrop} from "../../utils/DragDrop";
import Confirm from "../../Components/confirm";
import Costestimatehistory from "../../Components/Costestimatehistory";
import MonthlyInvoices from "./monthlyInvoices";
import { onDownloadDoc } from "../../Components/Terms";


var ownerMinRepositionPrice = 0;
let totalPrice = 0;

var renterOrgId: string = "";
var ownerOrgId: string = "";

var liabilityPolicyId:string = "";
var physicalDamagePolicyId:string = "";
var ownerSaasOrgId:string = "";
let isInsuranceEnabled:boolean = false;

var insurPolicies:any = [];

var tLiabilityPolicyId = "";
var tLiabilityPolicyType = "";

var tPhysicalPolicyId = "";
var tPhysicalPolicyType = "";
var reservationCode:string  ="";
let fullPath:string = "";
let fileType:string = "";

let attchIdx = 0;
let equiImages = [];
let fileName = "";
let totalDays:number = 0;
let liabilityInsuracePerDayAmount = 0;
let physicalDamagePerDayAmount = 0;

let isGeneric:string = "";
let isAnyAvailable:string = "";

let parkingLotArray:any = [];

let reserveArrivalpartkingLotId:string = "";
let reserveDepartureParkingLotId:string = "";

let isLiabilityPaidByRenter:boolean = false;
let isPhysicalDamagePaidByRenter:boolean = false;
let liabilityPaidByOwner:boolean = false;
let physicalDamagePaidByOwner:boolean = false;
let avoidMultiple:string[] = [];

const usInsuranceRate = '3.75';
const canadaInsuranceRate = '4.75';
let defaultCurrency = '';
let reservationStatus = '';
let uploadFrom = "";
let liabilityRateAfterConversion = 0
let physcialRateAfterConversion = 0;
let conversionRateValue = 1;
let availabilityListingType = "";
let signforServiceFee="";


const invoiceAction = (props:any) => {
    let onClickLink = ()=>{
        BusyLoader.showLoader();
        var myHeaders = new Headers()
        myHeaders.append('Content-Type', 'application/json')
        if (localStorage.getItem('token')) {
            var token = 'Bearer ' + localStorage.getItem('token')
            myHeaders.append('Authorization', token);
        }
         fetch(NetworkManager.serverPath + "api/invoiceAndBilling/reservationInvoicePrextra?reservationCode="+reservationCode+"&invoiceNumber=" + props.data.invoiceNumber+"&orgId="+CommonUtil.getSaasOrgId(), {method:'GET', headers:myHeaders })
            .then((response:any) => {
                response.blob().then((blob:any) => {
                    BusyLoader.hideLoader();
                    let url = window.URL.createObjectURL(blob);
                    let a = document.createElement('a');
                    a.href = url;
                    a.download = `invoice_${props.data.invoiceNumber}.pdf`;
                    a.click();
                });

            }).catch((error)=>{ BusyLoader.hideLoader();})
    }
    return(
        <>

        <div className={"cellRenderHeader"}>
            <a><span onClick={onClickLink} className={"cellRender"}>{props.data.invoiceNumber}</span></a>
        </div>
        </>
    )
}
const ReservationDetails = (props: any) => {
    const REPOSITION = "Reposition";
    const RENTAL = "Rental";
    const USA = "United States";
    const CANADA = "Canada";

    const dropInsurance = React.useRef(null);
    const dropFileObj = React.useRef<any>(null);
    const [startDate, setStartDate] = useState("");
    const [activationDate, setActivationDate] = useState("");
    const [activationNumber, setActivationNumber] = useState("");
    const [activationName, setActivationName] = useState("");
    const [dactivationNumber, setDActivationNumber] = useState("");
    const [dactivationName, setDActivationName] = useState("");
    const [deActivationDate, setDeActivationDate] = useState("");
    const [companyName, setCompanyName] = useState('');
    const [ownercompanyName, setOwnerCompanyName] = useState('');
    const [custPo, setCustPo] = useState("")
    const [renterPo, setRenterPo] = useState("")
    const [isVin, setVin] = useState<any>("")
    const [dailyRatePlanType, setDailyRatePlanType] = useState<any>("")//Daily,Monthly,Weekly


    const [ctq, setCTQ] = useState('');
    const [ownerctq, setOwnerCTQ] = useState('');

    const [attachDocs, setAttachDocs] = useState<any>([]);
    const [insurencId, setInsurenceId] = useState("");
    const [ownerinsurencId, setOwnerInsurencId] = useState("");

    const fromRef = useRef<Flatpickr>(null);
    const toRef = useRef<Flatpickr>(null);
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [dateOptions, setDateOptions] = useState(CommonUtil.DATE_FORMAT);
    const [days, setDays] = useState(0);
    const [dailyRate, setDailyRate] = useState("");
    const[monthlyRate, setMonthlyRate]= useState("");
    const[weeklyRate, setWeeklyRate]= useState("");
    const [distanceRate, setDistanceRate] = useState("");
    const [distanceUnit, setDistanceUnit] = useState("km");
    const [engineRate, setEngineRate] = useState("");
    const [quantity, setQuantity] = useState("");
    const [serviceFee, setServiceFee] = useState("");
    const [eDistance, setEstimatedDistance] = useState("");
    const [estimatedEngineHours, setEstimatedEngineHours] = useState("");

    const [fileNmae, setFileName] = useState("");
    const [owner, setOwner] = useState("");
    const [renter, setRenter] = useState("");
    const [cad, setCAD] = useState("");
    const [percent, setPercent] = useState("");
    const [claims, setClaims] = useState("");

    const [phoneNum, setPhoneNum] = useState("");
    const [ownerphoneNum, setOwnerPhoneNum] = useState("");
    const [contactName, setContactName] = useState("");
    const [ownercontactName, setOwnerContactName] = useState("");
    const [mail, setMail] = useState("");
    const [ownermail, setOwnerMail] = useState("");
    const [imageSrc, setImageSrc] = useState("");
    const [ownerimageSrc, setOwnerImageSrc] = useState("");

    const [availType, setAVailType] = useState("");
    const [defPrice, setDefPrice] = useState("");

    const [movePrice, setMovePrice] = useState("");
    const [noOfDays, setNoOfDays] = useState("");
    const [addtionalPrice, setAdditionalPrice] = useState("");

    const [deActiveInstruction, setDeActive] = useState("");
    const [activeInstruction, setActivative] = useState("");

    const [departure, setDeparture] = useState("");
    const [arrival, setArrival] = useState("");

    const [departureIns, setDepartureIns] = useState("");
    const [arrivalIns, setArrivalIns] = useState("");

    const [departTwicRequire, setDepartTwinRequire] = useState(false);
    const [arrivalTwicRequire, setArrivalTwinRequire] = useState(false);

    const [isLiabilityPaidByOwner, setIsLiabilityPaidByOwner] = useState("No");
    const [isPhysicalPaidByOwner, setIsPhysicalPaidByOwner] = useState("No");

    const [departureMap, setDepartureMap] = useState("");
    const [arrivalMap, setArrivalMap] = useState("");
    const [equipType, setEquipType] = useState("");

    const [totalRepositionPrice, setRepositionPrice] = useState("");
    const [platformFees, setPlatformFees] = useState<any>("");
    const [rdurationPrice,totalRDurationPrice] = useState(0);
    const [insuranceAmount, setInsuranceAmount] = useState("");
    const [insuranceAdminFee, setInsuranceAdminFee] = useState("");
    const [rextradays,totalExtraDays] = useState(0);
    const [tottalRentalPrice, setRentalPrice] = useState("");
    const [enginePrice, setEnginePrice] = useState("");
    const [distancePrice, setDistancePrice] = useState("");
    const [durationPrice, setDurationPrice] = useState("");
    const [cServiceFee, setServiceFreePrice] = useState("");
    const [cServiceFeeText, setServiceFreePriceText] = useState("");

    const [activationRating, setActivationRating] = useState("");
    const [deActivationRating, setDeActivationRating] = useState("");
    const [reservationData, setReservationData] = useState<any>({});
    const [departureAddr, setDepartureAddr] = useState("");
    const [arrivalAddr, setArrivalAddr] = useState("");

    const [reservationStartDate, setReservationStartDate] = useState("");
    const [reservationEndDate, setReservationEndDate] = useState("");

    const [policyPerDayAmount, setPolicyPerdayAmount] = useState("");
    const [policyName, setPolicyName] = useState("");

    const [insurPolicyId, setInsurPolicyId] = useState("");
    const [insurPolicyName, setInsurPolicyName] = useState("");
    const [deductible, setDeductible] = useState("");
    const [limit, setLimit] = useState("");
    const [description, setDescription] = useState("");

    const [lPolicyName,setLPolicyName] = useState("");
    const [lPolicyType,setLPolicyType] = useState("");
    const [lPolicyCompanyName,setLPolicyCompanyName] = useState("");
    const [lPolicyRatePerDay,setLPolicyRatePerDay] = useState<any>("");
    const [lPolicyDeductiable,setLPolicyDeductable] = useState<any>("");
    const [lPolicyLimit,setLPolicyLimit] = useState<any>("");
    const [lPolicyLowerValue,setLPolicyLowerValue] = useState<any>("");
    const [lPolicyUpperValue,setLPolicyUpperValue] = useState<any>("");

    const [phyPolicyName,setPhyPolicyName] = useState("");
    const [phyPolicyType,setPhyPolicyType] = useState("");
    const [phyPolicyCompanyName,setPhyPolicyCompanyName] = useState("");
    const [phyPolicyRatePerDay,setPhyPolicyRatePerDay] = useState<any>("");
    const [phyPolicyDeductiable,setPhyPolicyDeductable] = useState<any>("");
    const [phyPolicyLimit,setPhyPolicyLimit] = useState<any>("");
    const [phyPolicyLowerValue,setPhyPolicyLowerValue] = useState<any>("");
    const [phyPolicyUpperValue,setPhyPolicyUpperValue] = useState<any>("");

    const [liabilityDownload,setLiabilityDownload] = useState("");
    const [physicalDownload,setPhysicalDownload] = useState("");

    const [engineHours,setEngineHours] = useState("");
    const [exEngineHours,setExEngineHours] = useState("");
    const [enteredHubodometer,setEnteredHubodometer] = useState("");
    const [distancePerDay,setDistancePerDay] = useState("");
    const [etype,setEtype] = useState(false);

    const [insurPlans, setInsurPlans] = useState<any>([]);

    const [equipDocs, setEquipData] = useState<any>([]);

    const [listingType,setListingType] = useState("");

   

    const [mesurement,setMesurment] = useState("");
    const [isDateChanged,setDatesChanges] = useState(false);
    const [isInvoice,setIsInvoice] = useState(false);
    const [isStatementOfIncome,setStatementOfIncome] = useState(false);
    const [fullPathResp, setFullPathResp] = useState({name:"", path:""})

    const fileref = useRef<HTMLInputElement>(null);

    const [isDepVisible,setDepVisible] = useState(false);

    const [selDepartureObj,setSelDepartureObj] = useState<any>(null);
    const [selArrivalObj,setSelArrivalObj] = useState<any>(null);
    const [parkingLots,setParkingLots] = useState<any>([]);
    const [show,setShow] = useState(false);
    const [emailInvoice,setEmailInvoice] = useState("");
    const [invoiceAndBillingId,setInvoiceAndBillingId] = useState("");
    const [sentEmail,setSentEmail] = useState("")
    const [conversionRate,setConversionRate] = useState<any>(null)
    const [contractNumber,setContactNumber] = useState("");

    const [activationOdometer,setActivationOdometer] = useState('');
    const [activationEngineHours,setActivationEngineHours] = useState('');
    const [lastHubodometer,setLastHubodometer] = useState('');
    const [lastEngineHour,setLastEngineHour] = useState('');
    const [modalOpen,setModalOpen] = useState(false);
    const [update,setUpdate] = useState(0);

    const [termsFile,setTermsFile] = useState("");
    const [agreementFile,setAgreementFile] = useState("");

    const onClickCancel = ()=>{
        setShow(false)
    }

    const onClickSave = () => {
        const restData = {
            "custPo": custPo,
            "renterPo": renterPo,
            reservationCode:reservationCode,
            saasOrgId: CommonUtil.getSaasOrgId(),
            attachmentFilePath:fullPathResp.path,
            documentName:fullPathResp.name,
            reservationId:0
        }
        NetworkManager.sendJsonRequest(`api/reservation/saveCustomerContractDetails?reservationId=${CommonUtil.SELECT_RESERVATION_ID}`,restData,"","POST",true,(response:any)=>{
            if(response){
                setShow(false);
                ToastManager.showToast(CommonUtil.getMessageText(Constants.SAVED_SUCCESSFULLY,"Saved Successfully"), "Info");
                setFullPathResp({name:"", path:""})
            }

        },onLoginError);
    }

    const onClickSendEmailSave = ()=>{
        const restData = {
            "invoiceNumber" : emailInvoice,
            "invoiceAndBillingId":invoiceAndBillingId,
            "emails" : sentEmail.split(',')
        }
        NetworkManager.sendJsonRequest("api/invoiceAndBilling/resendInvoiceEmail",restData,"","POST",true,(response:any)=>{
            if(response){
                setShow(false);
                ToastManager.showToast(response, "Info");
            }

        },onLoginError);
    }

    const onSentEmailsChange = (event:any)=>{
        setSentEmail(event.target.value)
    }

    const emailsSendModal = (
      <Modal show={true} dialogAs={DraggableComponent}>
        <div className="modal-content" style={{ width: "600px", height: "200px" }}>
          <div className="modal-header">
            Send Emails
          </div>
          <div className="modal-body">
          <textarea className="form-control" 
            style={{ height: "50px" }}
            placeholder="Enter emails"
            value={sentEmail}
            onChange={onSentEmailsChange}
            />
          </div>
          <div className="modal-footer">
         
                        <button type="button" className="btn btn-outline-dark" onClick={onClickCancel}>{CommonUtil.getMessageText(Constants.CLOSE,"Cancel")}</button>
                        <button type="button" className="btn btn-success" onClick = {onClickSendEmailSave}>{CommonUtil.getMessageText(Constants.SAVE,"Save")}</button>
                  
          </div>
        </div>
      </Modal>
    );

    let onemailLink = (params:any) => {
      setShow(true);
      setEmailInvoice(params.data.invoiceNumber)
      setInvoiceAndBillingId(params.data.invoiceAndBillingId)
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      if (localStorage.getItem("token")) {
        var token = "Bearer " + localStorage.getItem("token");
        myHeaders.append("Authorization", token);
      }
      NetworkManager.sendJsonRequest(
        `api/invoiceAndBilling/getRecipient?reservationCode=${params.data.reservationCode}&billingType=${params.data.billingType}`, 
        "",
        "",
        "GET",
        true,
        (data: any) => {
          if (data) {
            setSentEmail(data);
          }
        },
        onLoginError
      );
    };
    let onNewEmailLink = (params:any) => {
        setShow(true);
        setEmailInvoice(params.data.invoiceNumber)
        setInvoiceAndBillingId(params.data.invoiceAndBillingId)
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        if (localStorage.getItem("token")) {
            var token = "Bearer " + localStorage.getItem("token");
            myHeaders.append("Authorization", token);
        }
        const url:any = `api/download/resendInvoiceEmail?invoiceId=${params.data.invoiceAndBillingId}`
        NetworkManager.sendJsonRequest(
            url, 
            "",
            "",
            "GET",
            true,
            (data: any) => {
            if (data) {
                setSentEmail(data);
            }
            },
            onLoginError
        );
    };

    const emailAction = (props: any) => (
      <>
        <div className={"cellRenderHeader"}>
          <button
            className="btn btn-success active-status"
            onClick={(e)=>onemailLink(props)}
          >
            {props.data.isInvoiceEmailSent === "Y" ? "Resend" : "Send"}
          </button>
        </div>
      </>
    );
    const newEmailAction = (props: any) => (
        <>
          <div className={"cellRenderHeader"}>
            <button
              className="btn btn-success active-status"
              onClick={(e)=>onNewEmailLink(props)}
            >
              {props.data.isInvoiceEmailSent === "Y" ? "Resend" : "Send"}
            </button>
          </div>
        </>
      );
    
    let invoiceColumnDefs = [
        { field: 'invoiceDate', headerName: CommonUtil.getMessageText(Constants.DATE, "Date"), resizable: true, width: 300, sortable: true, filter: 'agNumberColumnFilter',  },
        { field: 'invoiceNumber', headerName: CommonUtil.getMessageText(Constants.INVOICE_NUM, "Invoice Number"), resizable: true, width: 300, sortable: true, filter: 'agNumberColumnFilter',cellRenderer: invoiceAction},
        { field: 'totalAmount', headerName: CommonUtil.getMessageText(Constants.AMOUNT, "Amount"), resizable: true, width:200, sortable: true,  filter: 'agTextColumnFilter' },
        { field: 'billingType', headerName: CommonUtil.getMessageText(Constants.STATUS, "Status"), resizable: true, width: 250, sortable: true, filter: 'agNumberColumnFilter' },
        { field: 'isInvoiceEmailSent', headerName: CommonUtil.getMessageText(Constants.EMAIL_STATUS, "Email Status"), resizable: true, width: 250, sortable: true, filter: 'agTextColumnFilter' },
        { field: 'emailSentAt', headerName: CommonUtil.getMessageText(Constants.EMAIL_SENT_AT, "Email Sent at"), resizable: true, width: 250, sortable: true, filter: 'agDateColumnFilter' },
        // { field: 'email', headerName: CommonUtil.getMessageText(Constants.EMAIL, "Email"), resizable: true, width: 250, sortable: true, filter: 'agDateColumnFilter' ,cellRenderer: emailAction},
    ]
    if(CommonUtil.isRootUser()){
        invoiceColumnDefs.push({ field: 'email', headerName: CommonUtil.getMessageText(Constants.EMAIL, "Email"), resizable: true, width: 250, sortable: true, filter: 'agDateColumnFilter' ,cellRenderer: newEmailAction})
    }

    let invoiceStatementColumnDefs = [
        { field: 'invoiceDate', headerName: CommonUtil.getMessageText(Constants.DATE, "Date"), resizable: true, width: 200, sortable: true, filter: 'agNumberColumnFilter',  },
        { field: 'invoiceNumber', headerName: CommonUtil.getMessageText(Constants.INVOICE_NUM, "Invoice Number"), resizable: true, width: 300, sortable: true, filter: 'agNumberColumnFilter',cellRenderer: invoiceAction  },
        { field: 'totalAmount', headerName: CommonUtil.getMessageText(Constants.AMOUNT, "Amount"), resizable: true, width:200, sortable: true,  filter: 'agTextColumnFilter' },
        { field: 'billingType', headerName: CommonUtil.getMessageText(Constants.STATUS, "Status"), resizable: true, width: 200, sortable: true, filter: 'agNumberColumnFilter' },
        { field: 'isInvoiceEmailSent', headerName: CommonUtil.getMessageText(Constants.EMAIL_STATUS, "Email Status"), resizable: true, width: 250, sortable: true, filter: 'agTextColumnFilter' },
        { field: 'emailSentAt', headerName: CommonUtil.getMessageText(Constants.EMAIL_SENT_AT, "Email Sent at"), resizable: true, width: 250, sortable: true, filter: 'agDateColumnFilter' },
    ]

    if(CommonUtil.isRootUser()){
        invoiceStatementColumnDefs.push({ field: 'email', headerName: CommonUtil.getMessageText(Constants.EMAIL, "Email"), resizable: true, width: 250, sortable: true, filter: 'agDateColumnFilter',cellRenderer: newEmailAction },
        )
    }

    const gridRef = useRef<IAGrid>(null)

    const [invoiceData, setInvoiceData] = useStateWithCallback<any>([], () => {
        setTimeout(()=>{
            if(gridRef.current){
                //gridRef.current.columnFittoSize();
            }
        },1000);
    })

    const [invoiceStatementData, setInvoiceStatementData] = useStateWithCallback<any>([], () => {
        setTimeout(()=>{
            if(gridRef.current){
                gridRef.current.columnFittoSize();
            }
        },1000);
    })

    const [driverName,setDriverName] = useState("");
    const [driverPhoneNumber,setDriverPhoneNumber] = useState("");
    const [truckNumber,setTruckNumber] = useState("");
    const [carrierName,setCarrierName] = useState("");
    const [usdot,setUSDOT] = useState("");
    const [driverEmailId,setDriverEmailId] = useState("");


    //const [equipType,setEquipType] = useState("");
    useEffect(() => {

        liabilityPolicyId = "";
        isInsuranceEnabled = false;
        physicalDamagePolicyId = "";
        ownerSaasOrgId = "";
        reservationCode = "";
         attchIdx = 0;
        equiImages = [];
        totalDays = 0;
        isLiabilityPaidByRenter = false;
        isPhysicalDamagePaidByRenter = false;
        liabilityPaidByOwner=false;
        physicalDamagePaidByOwner=false;
        setAttachDocs([]);

        if(localStorage.getItem("reservationview") == "true"){
            if(CommonUtil.getSelectedOrgId()){
                getPublicReservationDetails();
            }
        }
        }, [props.isLogin]);

    useEffect(() => {
        /*getOrganizationInfo();
        getOrgServiceFees();
        getFinanceInfo();
        getUserDetails();*/

        //setInvoiceData([{}]);

        liabilityPolicyId = "";
        physicalDamagePolicyId = "";
        ownerSaasOrgId = "";
        reservationCode = "";
        fileType = CommonUtil.POD_DOC_TYPE;

        if(localStorage.getItem("reservationview") == "true"){

            if(CommonUtil.getSelectedOrgId()){
                getPublicReservationDetails();
            }

        }else{
            getEquipmentDetails();
            getReservationDetails();
        }
        //localStorage.setItem("reservationview","");

        DragNDrop({ drop: dropInsurance, handleDragOver, handleDrop: handleDropInsurance, handleDragEnter, handleDragLeave })


    }, [props.driverName]);

    let getEquipmentDetails = () => {
        if (CommonUtil.SELECT_EQUIP_ID) {
            let equipmentAPI: string = "api/equipment/" + CommonUtil.SELECT_EQUIP_ID;
            let obj: any = {};
            NetworkManager.sendJsonRequest(equipmentAPI, "", "", "GET", true, onGetEquipmentDetails, onLoginError);
        }
    }

    let onGetEquipmentDetails = (dataObj: any) => {
        if (dataObj) {
            var restData = CommonUtil.parseData(dataObj);
            if (restData) {
                setActivative(restData.activationInstructions ? restData.activationInstructions : "-");
                setDeActive(restData.deactivationInstructions ? restData.deactivationInstructions : "-");
                setEquipType(restData.equipmentType ? restData.equipmentType : "-");
            }
        }

    }

    let getCustomerContractDetails = () => {
        let customerAPI: string = `api/reservation/getCustomerContractDetails?reservationId=${CommonUtil.SELECT_RESERVATION_ID}`
        NetworkManager.sendJsonRequest(customerAPI, "", "", "GET", true, onGetCustomerContractDetails, onLoginError);
    }
    let onGetCustomerContractDetails = (data: any) => {
        var restData = CommonUtil.parseData(data);
        setCustPo(restData.custPo);
        setRenterPo(restData.renterPo)
    }
  

    let getAvailabilityDetails = () => {
        if (CommonUtil.SELECT_EQUIP_AVAIL_ID) {
            let parkingAPI: string = "api/availability/" + CommonUtil.SELECT_EQUIP_AVAIL_ID + "?saasOrgId=" + CommonUtil.getSaasOrgId();
            NetworkManager.sendJsonRequest(parkingAPI, "", "", "GET", true, onGetAvailabilityDetails, onLoginError);
        }
    }
    let onGetAvailabilityDetails = (dataObj: any) => {
        if (dataObj) {
            var restData = CommonUtil.parseData(dataObj);
            setReservationData(restData);
            if (restData) {
                /*let arrivalAddr: string = restData.arrivalCity + "," + restData.arrivalState;
                let departureAddr: string = restData.departureCity + "," + restData.departureState;
                setDepartureAddr(departureAddr);
                setArrivalAddr(arrivalAddr);*/
                setAVailType(restData.listingType);
                availabilityListingType = restData.listingType;
                setDefPrice(restData.defaultCurrency);
                // if(restData.listingType === RENTAL){
                //     defaultCurrency = String(restData.departureCountry).toUpperCase() === String(USA).toUpperCase() ? "USD" : String(restData.departureCountry).toUpperCase() === String(CANADA).toUpperCase() ? "CAD" : "";
                // }else {
                //     defaultCurrency = String(restData.arrivalCountry).toUpperCase() === String(USA).toUpperCase() ? "USD" : String(restData.arrivalCountry).toUpperCase() === String(CANADA).toUpperCase() ? "CAD" : "";
                // }
                defaultCurrency = restData.defaultCurrency;
                if(restData.equipmentType === "REEFER VAN"){
                    setEtype(true)
                }else{
                    setEtype(false)
                }
                //setDeparture(restData.departureParkingLotAddress);
              //  setArrival(restData.arrivalParkingLotAddress);
               // setArrivalIns(restData.arrivalParkingLotInstructions);
                //setDepartureIns(restData.departureParkingLotInstructions);

                //setArrivalTwinRequire((restData.arrivalParkingTwicAccessRequired === "Y"?true:false));
                //setDepartTwinRequire((restData.departureParkingTwicAccessRequired === "Y"?true:false));

                setConversionRate(restData.conversionRate ? restData.conversionRate : 1);

                conversionRateValue = restData.conversionRate ? restData.conversionRate : 1;

                setIsLiabilityPaidByOwner((restData.isInsurancePaidByOwnerLiability === "Y"?"Yes":"No"));
                setIsPhysicalPaidByOwner((restData.isInsurancePaidByOwnerPhysical === "Y"?"Yes":"No"));


               // let rDays = CommonUtil.getDays(sDate,eDate);
                let aStDate = CommonUtil.getServerDate(restData.startDate);
                let aEdDate = CommonUtil.getServerDate(restData.endDate);

                //totalDays = CommonUtil.getReservationDays(restData.startDate,restData.endDate);






                //setMovePrice(restData.movePrice);
                //setAdditionalPrice(restData.additionalPriceExcessDays);
                // let dMap:string = "https://maps.google.com/maps?width=100%&amp;height=450&amp;hl=en&amp;coord=52.70967533219885, -8.020019531250002&amp;q="+restData.departureParkingLotAddress+"&amp;z=14&amp;iwloc=B&amp;output=embed";
                //console.log(dMap);
                // setDepartureMap(dMap);
                //$("#iFrame").attr("src",dMap);
                //

               let dParkObj:any = CommonUtil.getDropdownObject(parkingLotArray,reserveDepartureParkingLotId,"parkingLotId","parkingLotName");
                let aParkObj:any = CommonUtil.getDropdownObject(parkingLotArray,reserveArrivalpartkingLotId,"parkingLotId","parkingLotName");

                setSelDepartureObj({parkingLotId:reserveDepartureParkingLotId,parkingLotName:dParkObj.parkingLotName,address:dParkObj.address,poiCapture:dParkObj.poiCapture,rawAddress:dParkObj.rawAddress,city:dParkObj.city,country:dParkObj.country,latitude:dParkObj.latitude,longitude:dParkObj.longitude,postalCode:dParkObj.postalCode,state:dParkObj.state,isDummy:dParkObj.isDummy});
                setSelArrivalObj({parkingLotId:reserveArrivalpartkingLotId,parkingLotName:aParkObj.parkingLotName,address:aParkObj.address,poiCapture:aParkObj.poiCapture,rawAddress:aParkObj.rawAddress,city:aParkObj.city,country:aParkObj.country,latitude:aParkObj.latitude,longitude:aParkObj.longitude,postalCode:aParkObj.postalCode,state:aParkObj.state,isDummy:aParkObj.isDummy});

       
                setContactNumber(restData?.contractNumber);

                // Important this will check in reservation Details As per ankit moved to reservation details

                // isLiabilityPaidByRenter = false;
                // isPhysicalDamagePaidByRenter = false;
                // if(restData.isInsurancePaidByRenterLiability == "Y"){
                //     isLiabilityPaidByRenter = true;
                // }
                // if(restData.isInsurancePaidByRenterPhysical == "Y"){
                //     isPhysicalDamagePaidByRenter = true;
                // }

                // if(!isInsuranceEnabled){
                //     isLiabilityPaidByRenter = true;
                //     isPhysicalDamagePaidByRenter = true;
                // }


                /*let aMap: string = `https://maps.google.com/maps?q=${restData.arrivalParkingLotLatitude},${restData.arrivalParkingLotLongitude}&hl=en&z=14&amp&output=embed`;
                setDepartureMap(aMap);
                let idIframe = document.getElementById('iFRAME') as HTMLIFrameElement;
                //console.log(aMap);
                idIframe.setAttribute('src', aMap);

                let dMap: string = `https://maps.google.com/maps?q=${restData.departureParkingLotLatitude},${restData.departureParkingLotLongitude}&hl=en&z=14&amp&output=embed`;
                let dIframe = document.getElementById('depatureIframe') as HTMLIFrameElement;
                dIframe.setAttribute('src', dMap);*/
                //console.log(dMap);
            }
        }
        setTimeout(()=>{           
          getReservationPrices();
        })
        

    }

    //getDefaultLiabilityDetails();
    //getDefaultPhysicalDamageDetails();

    const updateDefaultLiaInsurence = (restData:any)=>{
        const low = restData.insurEstLow ?  Number(restData.insurEstLow*restData.conversionRate).toFixed(2) : '';
        const high = restData.insurEstLow ?  Number(restData.insurEstHigh*restData.conversionRate).toFixed(2) : '';
        const limit = restData.insurLimit ?  Number(restData.insurLimit*restData.conversionRate).toFixed(2) : '';
        const deductible = restData.insurDuductible ?  Number(restData.insurDuductible*restData.conversionRate).toFixed(2) : '';
        setLPolicyName(restData.insurName);
        setLPolicyType(restData.insurPlanType);
        setLPolicyCompanyName(restData.insurCompany);
        setLPolicyRatePerDay(restData.insurRateperDay);
        setLPolicyDeductable(deductible);
        setLPolicyLimit(limit);
        setLPolicyLowerValue(low);
        setLPolicyUpperValue(high);
    }

    const updateDefaultPhyInsurence = (restData:any)=>{
        const low = restData.insurEstLow ?  Number(restData.insurEstLow*restData.conversionRate).toFixed(2) : '';
        const high = restData.insurEstLow ?  Number(restData.insurEstHigh*restData.conversionRate).toFixed(2) : '';
        const limit = restData.insurLimit ?  Number(restData.insurLimit*restData.conversionRate).toFixed(2) : '';
        const deductible = restData.insurDuductible ?  Number(restData.insurDuductible*restData.conversionRate).toFixed(2) : '';
        setPhyPolicyName(restData.insurName);
        setPhyPolicyType(restData.insurPlanType);
        setPhyPolicyCompanyName(restData.insurCompany);
        setPhyPolicyRatePerDay(restData.insurRateperDay);
        setPhyPolicyDeductable(limit);
        setPhyPolicyLimit(deductible);
        setPhyPolicyLowerValue(low);
        setPhyPolicyUpperValue(high);
    }

    let getDefaultLiabilityDetails = ()=>{
        if(liabilityPolicyId){
            let polacyAPI: string = 'api/insurance/?policyId='+liabilityPolicyId;
            NetworkManager.sendJsonRequest(polacyAPI, '', '', 'GET', true, onGetDefPolicyLiabilityDetails, onLoginError);
        }else{
            getDefaultPhysicalDamageDetails();
        }
    }

    let onGetDefPolicyLiabilityDetails = (dataObj:any)=> {
        if (dataObj) {
            
            var restData = CommonUtil.parseData(dataObj)
            if (restData) {
                liabilityInsuracePerDayAmount =   liabilityInsuracePerDayAmount || applyConvertionRate(restData.perDayInsuranceValue);
                setLPolicyName(restData.policyId);
                setLPolicyType(restData.insurancePlanType);
                setLPolicyCompanyName(restData.insuranceCompanyName);
                setLPolicyRatePerDay(liabilityInsuracePerDayAmount ? liabilityInsuracePerDayAmount :applyConvertionRate(restData.perDayInsuranceValue));
                setLPolicyDeductable(applyConvertionRate(restData.deductible));
                setLPolicyLimit(applyConvertionRate(restData.limit));
                setLPolicyLowerValue("");
                setLPolicyUpperValue(applyConvertionRate(restData.estimatedValue));
            }
        }
        getDefaultPhysicalDamageDetails();
    }

    let getLiabilityDetails = ()=>{
        if(liabilityPolicyId){
            let polacyAPI: string = 'api/insurance/getRatePlan?insuranceRateId='+liabilityPolicyId;
            NetworkManager.sendJsonRequest(polacyAPI, '', '', 'GET', true, onGetPolicyLiabilityDetails, onLoginError);
        }
    }


    const applyConvertionRate = (value:any) =>{
        return Number(Number(value ? value  *  conversionRateValue : 0).toFixed(2));
    }

    let onGetPolicyLiabilityDetails = (dataObj:any)=>{
        if (dataObj) {
            var restData = CommonUtil.parseData(dataObj);
            if (restData) {
                setLPolicyName(restData.policy );
                setLPolicyType(restData.insurancePlanType);
                setLPolicyCompanyName(restData.insuranceCompanyName);   
                setLPolicyRatePerDay(liabilityInsuracePerDayAmount ? liabilityInsuracePerDayAmount : applyConvertionRate(restData.ratePerDay));
                setLPolicyDeductable(applyConvertionRate(restData.deductible));
                setLPolicyLimit(applyConvertionRate(restData.limit));
                setLPolicyLowerValue(applyConvertionRate(restData.estimatedValueLower));
                setLPolicyUpperValue(applyConvertionRate(restData.estimatedValueUpper));

                tLiabilityPolicyId = restData.policyId;
                tLiabilityPolicyType = restData.insurancePlanType;
            }
        }
        if(physicalDamagePolicyId){
            getPhysicalDamageDetails();
        }else{
            getOwnerOrgInsurancePolicies();
        }

    }

    let getDefaultPhysicalDamageDetails = ()=>{
        if(physicalDamagePolicyId){
            let polacyAPI: string = 'api/insurance/?policyId='+physicalDamagePolicyId;
            NetworkManager.sendJsonRequest(polacyAPI, '', '', 'GET', true, (dataObj:any)=>{
                if (dataObj) {
                    var restData = CommonUtil.parseData(dataObj);
                    if (restData) {
                        physicalDamagePerDayAmount = physicalDamagePerDayAmount || applyConvertionRate(restData.perDayInsuranceValue) ;
                        setPhyPolicyName(restData.policyId);
                        setPhyPolicyType(restData.insurancePlanType);
                        setPhyPolicyCompanyName(restData.insuranceCompanyName);
                        setPhyPolicyRatePerDay(physicalDamagePerDayAmount || applyConvertionRate(restData.perDayInsuranceValue));
                        setPhyPolicyDeductable(applyConvertionRate(restData.deductible));
                        setPhyPolicyLimit(applyConvertionRate(restData.limit));
                        setPhyPolicyLowerValue("");
                        setPhyPolicyUpperValue(applyConvertionRate(restData.estimatedValue));


                    }
                }
            }, onLoginError);
        }
        getReservationPrices();
    }

    let getPhysicalDamageDetails = ()=>{
        if(physicalDamagePolicyId){
            let polacyAPI: string = 'api/insurance/getRatePlan?insuranceRateId='+physicalDamagePolicyId;
            NetworkManager.sendJsonRequest(polacyAPI, '', '', 'GET', true, onGetPolicyPhysicalDamageDetails, onLoginError);
        }
    }

    let onGetPolicyPhysicalDamageDetails = (dataObj:any)=>{
        if (dataObj) {
            var restData = CommonUtil.parseData(dataObj);
            if (restData) {
                setPhyPolicyName(restData.policy);
                setPhyPolicyType(restData.insurancePlanType);
                setPhyPolicyCompanyName(restData.insuranceCompanyName);
                setPhyPolicyRatePerDay(physicalDamagePerDayAmount ? physicalDamagePerDayAmount :applyConvertionRate(restData.ratePerDay));
                setPhyPolicyDeductable(applyConvertionRate(restData.deductible));
                setPhyPolicyLimit(applyConvertionRate(restData.limit));
                setPhyPolicyLowerValue(applyConvertionRate(restData.estimatedValueLower));
                setPhyPolicyUpperValue(applyConvertionRate(restData.estimatedValueUpper));

                tPhysicalPolicyId = restData.policyId;
                tPhysicalPolicyType = restData.insurancePlanType;
            }
        }
        getOwnerOrgInsurancePolicies();
    }

    const genTempAttach = () => {
        if(CommonUtil.SELECT_RESERVATION_ID){
            const doc:any = {
                documentType:"Rental_insurance",
                documentName:"Insurance_doc",
                fullFilePath:""
            }
            setAttachDocs([doc]);
        }
    }
    
    const getRentalAttachment = () => {
        if(CommonUtil.SELECT_RESERVATION_ID){
            var myHeaders = new Headers();
            var token = 'Bearer ' + localStorage.getItem('token');
            myHeaders.append("Authorization", token);

            var requestOptions:any = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
            };
            fetch(`${NetworkManager.serverPath}api/report/insurance?output=pdf&reservationId=${CommonUtil.SELECT_RESERVATION_ID}`, requestOptions)
            .then(response => response.blob()) 
            .catch(error => {
                ToastManager.showToast("Error while dowloading file", "Error");
            })
            .then((result:any) => {                
                const pdfUrl = URL.createObjectURL(result);
                let link:any = document.createElement("a");
                link.download = "download";
                link.href = pdfUrl;
                link.target = "_blank"
                link.click();
            })
            .catch(error => {
                ToastManager.showToast("Error while dowloading file", "Error");
            });
        }

    }
     const onDownloadPDF = (dataObj:any)=>{
        fetch(dataObj.blob()).then((blob:any)=>{
        let link = document.createElement("a");
        link.download = "download";
        link.href = blob;
        link.target = "_blank"
        link.click();
        });
     }


    let getFinanceInfo = () => {
        if(renterOrgId){
            let financeAPI: string = "api/organization/finance/getFinanceDetails?saasOrgId=" + renterOrgId;
            NetworkManager.sendJsonRequest(financeAPI, "", "", "GET", true, onGetFinanceInfo, onLoginError);
        }

    }

    let onGetFinanceInfo = (data: any) => {
        if (data) {
            var restData = CommonUtil.parseData(data);
            if (restData) {
                let docs = [];
                if (restData.documents) {
                    if (Array.isArray(restData.documents)) {
                        docs = restData.documents;
                    } else {
                        docs.push(restData.documents);
                    }
                    setAttachDocs(docs);
                    for (let idx: number = 0; idx < docs.length; idx++) {
                        if (docs[idx].documentType == CommonUtil.INSUR_DOC_TYPE) {
                            //ƒ(docs[idx].fullFilePath);
                            

                            //break;
                        }
                    }
                }
            }
        }
    }

    let getOwnerFinanceInfo = () => {
        if(ownerOrgId){
            let financeAPI: string = "api/organization/finance/getFinanceDetails?saasOrgId=" + ownerOrgId;
            NetworkManager.sendJsonRequest(financeAPI, "", "", "GET", true, onGetOwnerFinanceInfo, onLoginError);
        }
    }

    let onGetOwnerFinanceInfo = (data: any) => {
        if (data) {
            var restData = CommonUtil.parseData(data);
            if (restData) {
                let docs = [];
                if (restData.documents) {
                    if (Array.isArray(restData.documents)) {
                        docs = restData.documents;
                    } else {
                        docs.push(restData.documents);
                    }
                    for (let idx: number = 0; idx < docs.length; idx++) {
                        if (docs[idx].documentType == CommonUtil.INSUR_DOC_TYPE) {
                            setOwnerInsurencId(docs[idx].fullFilePath);
                            break;
                        }
                    }
                }
            }
        }
    }


    let getOrgServiceFees = () => {
        let listAPI: string = "api/organization/getServiceFees?saasOrgId=" + ownerOrgId;
        NetworkManager.sendJsonRequest(listAPI, "", "", "GET", true, onGetEquipmentTypePricing, onLoginError);
    }

    let onGetEquipmentTypePricing = (dataObj: any) => {
        if (dataObj) {
            var restData = CommonUtil.parseData(dataObj);
            if (restData) {
                setOwner(restData.ownerRentalPercentage);
                setRenter(restData.renterRentalPercentage);
                ownerMinRepositionPrice = restData.ownerRepositioningMinimumCost;
                setCAD(restData.ownerRepositioningMinimumCost);
                setPercent(restData.ownerRepositioningPercentage);
                setClaims(restData.claimFeePercentage);
            }
        }
    }

    let getPublicReservationDetails = ()=>{
        let revId = localStorage.getItem("listId");
        let reservationAPI:string = "api/session/reservation/"+revId+ "&saasOrgId=" + CommonUtil.getSaasOrgId();//CommonUtil.SELECT_RESERVATION_ID;
        NetworkManager.sendJsonRequest(reservationAPI,"","","GET",true,(dataObj: any)=>{
            if (dataObj) {
                var restData = CommonUtil.parseData(dataObj);
                if (restData) {
                    CommonUtil.SELECT_RESERVATION_ID = restData.reservationId;
                    CommonUtil.SELECT_EQUIP_AVAIL_ID = restData.equipmentListingId;
                    CommonUtil.SELECT_EQUIP_ID = restData.equipmentId;
                    if(CommonUtil.getSelectedOrgId()){
                        getEquipmentDetails();
                        onGetReservationDetails(dataObj);
                    }

                }
            }
        },onLoginError);
    }

    let getReservationDetails = () => {

        let reservationAPI: string = "api/reservation/" + CommonUtil.SELECT_RESERVATION_ID;
        NetworkManager.sendJsonRequest(reservationAPI, "", "", "GET", true, onGetReservationDetails, onLoginError);
    }

   // const [reservationCode,setReservationCode] = useState("");
    
    let onGetReservationDetails = (dataObj: any) => {
        let isRenterInsuranceDoc = false;
        
        getCustomerContractDetails();
        let defaultDataforPhyInsurence:any = {
            insurPolicyId:"",
            insurName:"",
            insurCompany:"",
            insurLimit:"",
            insurPlanType:"",
            insurRateperDay:"",
            insurDuductible:"",
            insurEstLow:"",
            insurEstHigh:"",
        }
        let defaultDataforLiaInsurence:any = {
            insurPolicyId:"",
            insurName:"",
            insurCompany:"",
            insurLimit:"",
            insurPlanType:"",
            insurRateperDay:"",
            insurDuductible:"",
            insurEstLow:"",
            insurEstHigh:"",
        }

        if (dataObj) {
            var restData = CommonUtil.parseData(dataObj);
            if (restData) {
                renterOrgId = restData.renterOrgId;
                ownerOrgId = restData.saasOrgId;
                //1243
                setTermsFile(restData.tncFile);
                setAgreementFile(restData.agreementFile);

                reserveArrivalpartkingLotId = restData.arrivalParkingLotId;
                reserveDepartureParkingLotId = restData.departureParkingLotId;
                setEngineHours(restData.estimatedEngineHoursPerDay) ;
                setDistancePerDay(restData.estimatedDistancePerDay);
                isGeneric = restData.isEquipmentGeneric;
                isAnyAvailable = restData.isEnableAnyAvailble;
                setVin(restData.vin);
                CommonUtil.SELECT_EQUIP_AVAIL_ID = restData.equipmentListingId;

                let arrivalAddr: string = restData.arrivalCity + "," + restData.arrivalState;
                let departureAddr: string = restData.departureCity + "," + restData.departureState;
                setDepartureAddr(departureAddr);
                setArrivalAddr(arrivalAddr);

                setDeparture(restData.departureParkingLotAddress);
                setDepartureIns(restData.departureParkingLotInstructions);
                
                setDActivationName(restData.arrivalParkingLotContactName);
                setDActivationNumber(restData.arrivalParkingLotContactNumber);

                setActivationName(restData.departureParkingLotContactName);
                setActivationNumber(restData.departureParkingLotContactNumber);

                setArrival(restData.arrivalParkingLotAddress);
                setArrivalIns(restData.arrivalParkingLotInstructions);

                setActivationOdometer(restData.activationOdometer);
                setActivationEngineHours(restData.activationEngineHours);

                setArrivalTwinRequire((restData.arrivalParkingTwicAccessRequired === "Y"?true:false));
                setDepartTwinRequire((restData.departureParkingTwicAccessRequired === "Y"?true:false));

                let aMap: string = `https://maps.google.com/maps?q=${restData.arrivalParkingLotLatitude},${restData.arrivalParkingLotLongitude}&hl=en&z=14&amp&output=embed`;
                setDepartureMap(aMap);
                let idIframe = document.getElementById('iFRAME') as HTMLIFrameElement;

                idIframe.setAttribute('src', aMap);

                let dMap: string = `https://maps.google.com/maps?q=${restData.departureParkingLotLatitude},${restData.departureParkingLotLongitude}&hl=en&z=14&amp&output=embed`;
                let dIframe = document.getElementById('depatureIframe') as HTMLIFrameElement;
                dIframe.setAttribute('src', dMap);

                if(restData.driver){
                    setDriverName(restData.driver.driverName);
                    setCarrierName(restData.driver.carrierName);
                    setUSDOT(restData.driver.usdot);
                    setTruckNumber(restData.driver.truckNumber);
                    setDriverPhoneNumber(restData.driver.driverPhoneNumber);
                    setDriverEmailId(restData.driver.driverEmail);
                }



                setIsInvoice(false);
                setStatementOfIncome(false);
                if(ownerOrgId == CommonUtil.getSelectedOrgId()) {
                    if(restData.listingType == "Reposition"){
                        setIsInvoice(true);
                    }else{
                        setStatementOfIncome(true);
                    }
                }else{
                   // setIsRenter(false);
                    if(restData.listingType == "Reposition"){
                        setStatementOfIncome(true);
                    }else{
                        setIsInvoice(true);
                    }
                }

                if(CommonUtil.isRootUser() || (localStorage.switchCustomer && localStorage.switchCustomer == "true")){
                    setIsInvoice(true);
                    setStatementOfIncome(true);
                }

                setStartDate(CommonUtil.getReservationDate(restData.creationDate) + " (UTC)");
                setActivationDate(CommonUtil.getReservationDate(restData.activationDate));
                setDeActivationDate(CommonUtil.getReservationDate(restData.actualEndDate?restData.actualEndDate:restData.deactivationDate));

                setListingType(restData.listingType?restData.listingType:"");
                //setReservationCode(restData.reservationCode?restData.reservationCode:"-");
                reservationCode = restData.reservationCode;


                let endDate = new Date(restData.endDate);
                let stDate = new Date(restData.startDate);
                let days = CommonUtil.getDays(stDate, endDate);
                totalDays = days;

                // if(restData.defaultCurrency === "CAD"){
                    liabilityInsuracePerDayAmount = restData.liabilityRateAfterConversion || restData.insurancePerDayAmountLiability;
                    physicalDamagePerDayAmount = restData.physcialRateAfterConversion || restData.insurancePerDayAmountPhysical;
                    if(restData.liabilityRateAfterConversion || restData.physcialRateAfterConversion){
                        liabilityRateAfterConversion= restData.liabilityRateAfterConversion;
                        physcialRateAfterConversion= restData.physcialRateAfterConversion;
                    }
                    if(restData.renterOrgId == CommonUtil.getSelectedOrgId()) { 
                        if(restData.isInsurancePaidByOwnerLiability === 'Y'){
                            liabilityInsuracePerDayAmount= 0;
                        }
                        if(restData.isInsurancePaidByOwnerPhysical === 'Y'){                            
                            physicalDamagePerDayAmount= 0;
                        }
                    }
                   
                // }else{   
                //     liabilityInsuracePerDayAmount = restData.liabilityRateAfterConversion || restData.insurancePerDayAmountLiability;
                //     physicalDamagePerDayAmount = restData.physcialRateAfterConversion || restData.insurancePerDayAmountPhysical;
                // }     

                if(restData.isInsurancePaidByRenter === 'Y' && (restData.isInsurancePaidByRenterLiability === 'Y' || restData.isInsurancePaidByRenterPhysical === 'Y' )){
                    isRenterInsuranceDoc = true;
                }

                if(fromRef.current && restData.startDate){
                    let stDate = CommonUtil.getServerDate(restData.startDate);
                    if(stDate){
                        fromRef.current.flatpickr.setDate(stDate);
                    }
                }
                if(toRef.current && restData.endDate){
                    let edDate = CommonUtil.getServerDate(restData.endDate);
                    if(edDate){
                        toRef.current.flatpickr.setDate(edDate);
                    }
                }

                reservationStatus = restData.reservationStatus

                if(reservationStatus == CommonUtil.RESERVATION_DELETED || reservationStatus == CommonUtil.RESERVATION_COMPLETED){


                }else{
                   // if(CommonUtil.isRootUser() || (localStorage.switchCustomer && localStorage.switchCustomer == "true") || ){
                        setDatesChanges(true);
                   // }
                }
        
                setDays(days);
                setDistanceRate(restData.distanceRate);
                setDailyRate(restData.dailyRate?restData.dailyRate:restData.additionalPriceExcessDays);
                setWeeklyRate(restData.weeklyRate?restData.weeklyRate : "");
                setMonthlyRate(restData.monthlyRate?restData.monthlyRate : "")
                //const getNewRate = getSelectedRangeRate()                
                setEngineRate(restData.engineRate);
                setMovePrice(restData.movePrice);
                setNoOfDays(restData.noOfDaysAllowed);
                setAdditionalPrice(restData.additionalPriceExcessDays);
                setServiceFee(restData.serviceFeePercentage);
                setEstimatedDistance(restData.estimatedDistancePerDay);
                setEstimatedEngineHours(restData.estimatedEngineHoursPerDay);
                setActivationRating(restData.activationRating);
                setDeActivationRating(restData.deactivationRating);

                setReservationStartDate(restData.startDate);
                setReservationEndDate(restData.endDate);


                setPolicyName(restData.policyName ? restData.policyName : "-");
                setPolicyPerdayAmount(restData.insurancePerDayAmount ? restData.insurancePerDayAmount : "-");

                liabilityPolicyId = restData.ratePlanIdLiability;
                physicalDamagePolicyId = restData.ratePlanIdPhysical;
                isInsuranceEnabled = (restData.isInsuranceEnabled == "N"?false:true);

                if(restData.isInsuranceEnabled == "N"){
                    if(restData.isLiabilityEnabled == "Y"){
                        //liabilityPolicyId = restData.policyIdLiability;
                    }
                    if(restData.isPhysicalDamageEnabled == "Y"){
                        //physicalDamagePolicyId = restData.policyIdPhysical;
                    }                   
                }

                setUpdate((prev:number)=>prev+1);

                 //V20-3814

                isLiabilityPaidByRenter = false;
                isPhysicalDamagePaidByRenter = false;
                if(restData.isInsurancePaidByRenterLiability == "Y"){
                    isLiabilityPaidByRenter = true;
                }
                if(restData.isInsurancePaidByRenterPhysical == "Y"){
                    isPhysicalDamagePaidByRenter = true;
                }
                liabilityPaidByOwner = false;
                physicalDamagePaidByOwner = false;
                if(restData.isInsurancePaidByOwnerLiability == "Y"){
                    liabilityPaidByOwner = true;
                }
                if(restData.isInsurancePaidByOwnerPhysical == "Y"){
                    physicalDamagePaidByOwner = true;
                }

               // This is change done by srini verify
                // if(!isInsuranceEnabled){
                //     isLiabilityPaidByRenter = true;
                //     isPhysicalDamagePaidByRenter = true;
                // }

                //V20-3814
                defaultDataforLiaInsurence = {...defaultDataforLiaInsurence,
                    insurPolicyId:restData.policyIdLiability,
                    insurName:restData.policyNameLiability,
                    insurCompany:restData.insuranceCompanyNameLiability,
                    insurLimit:restData.insurLiaLimit,
                    insurPlanType:restData.insurancePlanTypeLiability,
                    insurRateperDay:restData.liabilityRateAfterConversion,
                    insurDuductible:restData.insurLiaDuductible,
                    insurEstLow:restData.estimatedValueLowerLiability,
                    insurEstHigh:restData.estimatedValueUpperLiability,
                    conversionRate:restData.conversionRate ? restData.conversionRate : 1,
                    enabled :restData.isLiabilityEnabled == "Y" ? true : false
                }

                defaultDataforPhyInsurence = {...defaultDataforPhyInsurence,
                    insurPolicyId:restData.policyIdPhysical,
                    insurName:restData.policyNamePhysical,
                    insurCompany:restData.insuranceCompanyNamePhysical,
                    insurLimit:restData.insurPhyLimit,
                    insurPlanType:restData.insurancePlanTypePhysical,
                    insurRateperDay:restData.physcialRateAfterConversion,
                    insurDuductible:restData.insurPhyDuductible,
                    insurEstLow:restData.estimatedValueLowerPhysical,
                    insurEstHigh:restData.estimatedValueUpperPhysical,
                    conversionRate:restData.conversionRate ? restData.conversionRate : 1,
                    enabled :restData.isPhysicalDamageEnabled == "Y" ? true : false
                }

                //V20-3814
                
                let equipAllAPI: string = 'api/insurance/orgPolicies?orgId='+ownerOrgId;//CommonUtil.getSelectedOrgId();
                let obj: any = {};
                NetworkManager.sendJsonRequest(equipAllAPI, '', '', 'GET', true,(dataObj:any)=>{
                    if (dataObj) {
                        var restData = CommonUtil.parseData(dataObj);
                        if (restData) {
                            //setInsuranceChecked((restData.isInsuranceEnabled == "Y" ? true : false));
                            if(restData.isInsuranceEnabled == "Y"){
                                if(liabilityPolicyId){
                                    getLiabilityDetails();
                                }else if(physicalDamagePolicyId){
                                    getPhysicalDamageDetails();
                                }else{
                                    getOwnerOrgInsurancePolicies();
                                }
                            }else{
                                                      
                                if(!liabilityRateAfterConversion && !physcialRateAfterConversion){
                                   getDefaultLiabilityDetails(); 
                                }else if(!defaultDataforLiaInsurence?.insurPolicyId || !defaultDataforPhyInsurence?.insurPolicyId){
                                    getDefaultLiabilityDetails(); 
                                }else{
                                    if(liabilityRateAfterConversion && defaultDataforLiaInsurence.enabled)                                        
                                    updateDefaultLiaInsurence(defaultDataforLiaInsurence);
                                    if(physcialRateAfterConversion && defaultDataforPhyInsurence.enabled)
                                    updateDefaultPhyInsurence(defaultDataforPhyInsurence);                                   

                                }                                
                                

                            }
                        }
                    }
                },onLoginError);


            }
        }

        getRenterOrganizationInfo();
        getRenterUserDetails();
        getOwnerOrganizationInfo();
        getOwnerUserDetails();
        //getReservationPrices(); 
              
        try{
            if(isRenterInsuranceDoc){
                genTempAttach();
            }else{
                getFinanceInfo();
            }
        }catch(e){
            getFinanceInfo();
        }        
        getOwnerFinanceInfo();
        getOrgServiceFees();
        getInsurancePolicyDetails();

        showEquipmentDetails();
        if(reservationCode){
            getInvoiceDetails();
            getInvoiceStatements();
        }
        if(isGeneric == "Y" && isAnyAvailable == "N" && (CommonUtil.ORGANIZATION_TYPE == CommonUtil.ORG_TYPE_OWNER || CommonUtil.ORGANIZATION_TYPE == CommonUtil.ORG_TYPE_OWNER_RENTER)){
            setDepVisible(true);
            getAllParkingLots();
        }else{
            getAvailabilityDetails();
            setDepVisible(false);
        }
    }

    let getAllParkingLots = ()=>{
        if(ownerOrgId == CommonUtil.getSelectedOrgId()) {
            let parkingAPI:string = "api/parkinglot/all?saasOrgId="+ownerOrgId;//CommonUtil.getSaasOrgId();
            NetworkManager.sendJsonRequest(parkingAPI,"","","GET",true,onGetParkingLots,onLoginError);
        }

    }

    let onGetParkingLots = (dataObj:any)=>{
        if(dataObj) {
            parkingLotArray = [];
            let tempLotArray:any = [];
            var restData = CommonUtil.parseData(dataObj);
            if (restData) {
                if(Array.isArray(restData.parkingLots)){
                    tempLotArray = restData.parkingLots;
                }else{
                    tempLotArray.push(restData.parkingLots);
                }
                tempLotArray.forEach((item:any,idx:number)=>{
                   // if(item.isDummy == "N"){
                        parkingLotArray.push(item);
                   // }
                })
                setParkingLots(parkingLotArray);
            }
        }

        getAvailabilityDetails();
    }

    let getInvoiceDetails = ()=>{
        if(reservationCode){
            let equipAllAPI: string = 'api/invoiceAndBilling/getInvoicesAndBillings?reservationCode='+reservationCode;
            let obj: any = {};
            NetworkManager.sendJsonRequest(equipAllAPI, '', '', 'GET', true,onGetInvoiceDetails,onLoginError);
        }
    }

    let onGetInvoiceDetails = (dataObj:any)=>{
        var invoices = [];
        if(dataObj){
            var restData = CommonUtil.parseData(dataObj);
            if (restData) {
                if(Array.isArray(restData)){
                    invoices = restData;
                }else{
                    invoices.push(restData);
                }
            }
        }
        setInvoiceData(invoices);
    }

    let getInvoiceStatements = ()=>{
        if(reservationCode){
            let equipAllAPI: string = 'api/invoiceAndBilling/getInvoicesAndBillingsStatement?reservationCode='+reservationCode;
            let obj: any = {};
            NetworkManager.sendJsonRequest(equipAllAPI, '', '', 'GET', true,onGetInvoiceStatementDetails,onLoginError);
        }
    }

    let onGetInvoiceStatementDetails = (dataObj:any)=>{
        var invoices = [];
        if(dataObj){
            var restData = CommonUtil.parseData(dataObj);
            if (restData) {
                if(Array.isArray(restData)){
                    invoices = restData;
                }else{
                    invoices.push(restData);
                }
            }
        }
        setInvoiceStatementData(invoices);
    }

    let getInsurancePolicyDetails = () => {

    }

    let getOwnerOrgInsurancePolicies = ()=>{
        if(ownerOrgId){
            let equipAllAPI: string = 'api/insurance/orgPolicies?orgId='+ownerOrgId;
            let obj: any = {};
            NetworkManager.sendJsonRequest(equipAllAPI, '', '', 'GET', true,onGetOrgInsurancePolicy,onLoginError);
        }
    }
    let onGetOrgInsurancePolicy = (dataObj:any)=>{
        insurPolicies = [];
        setLiabilityDownload("");
        setPhysicalDownload("");
        if (dataObj) {
            var restData = CommonUtil.parseData(dataObj);
            if (restData) {
                if(restData.organizationInsuranceAssociation){
                    let arr = [];
                    if(Array.isArray(restData.organizationInsuranceAssociation)){
                        arr = restData.organizationInsuranceAssociation;
                    }else{
                        arr.push(restData.organizationInsuranceAssociation);
                    }
                    arr.forEach((item:any,idx:number)=>{
                        if(item.id == tLiabilityPolicyId && item.insurancePlanType == tLiabilityPolicyType){
                            setLiabilityDownload(item.fullPath);

                        }else if(item.id == tPhysicalPolicyId && item.insurancePlanType == tPhysicalPolicyType){
                            setPhysicalDownload(item.fullPath);
                        }
                        //item.policyName = item.policyId+"("+item.insurancePlanType+")";
                        //insurPolicies.push(item);
                    })
                }
            }
        }

        setInsurPlans(insurPolicies);
    }




    let onGetPolicyDetails = (dataObj:any)=>{
        if (dataObj) {
            var restData = CommonUtil.parseData(dataObj)
            if (restData) {
                setInsurPolicyId(restData.policyId);
                setInsurPolicyName(restData.nameOfInsurance);
                setDeductible(restData.deductible);
                setDescription(restData.detailedDesciption);
                setLimit(restData.limit);
            }
        }
    }

    let getReservationPrices = () => {
        let reservationAPI: string = "api/reservation/costestimate/?reservationId=" + CommonUtil.SELECT_RESERVATION_ID + "&saasOrgId=" + CommonUtil.getSaasOrgId();
        NetworkManager.sendJsonRequest(reservationAPI, "", "", "GET", true, onGetReservationPrices, onLoginError);
    }

    let onGetReservationPrices = (dataObj: any) => {
        if (dataObj) {
            var restData = CommonUtil.parseData(dataObj);
            if (restData) {       
                totalDays = restData.noOfDays || totalDays;                
                setDays(restData.noOfDays);              
                CommonUtil.setCostEstimate(restData);
                setDistancePrice(
                    restData.distancePrice && CheckPriceValue(restData.distancePrice)
                  );
                  setDurationPrice(
                    restData.durationPrice && CheckPriceValue(restData.durationPrice)
                  );
                  setServiceFreePrice(
                    restData.ownerServiceFee && CheckPriceValue(restData.ownerServiceFee)
                  );
                  setEnginePrice(
                    restData.enginePrice && CheckPriceValue(restData.enginePrice));
                 
                  setServiceFreePrice(
                    restData.ownerServiceFee && CheckPriceValue(restData.ownerServiceFee)
                  );
                  //2206 setServiceFreePriceText(restData.ownerServiceFeeText);
                  setServiceFreePriceText("");
                  setMovePrice(restData.movePrice && CheckPriceValue(restData.movePrice));           
           
                  totalRDurationPrice(
                    restData.durationPrice && CheckPriceValue(restData.durationPrice));
                totalExtraDays(restData.extraDays?restData.extraDays:"");
                // if(!restData.durationPrice && (restData.dailyRate && restData.noOfDays)){
                //     setDurationPrice(
                //         CheckPriceValue(restData.dailyRate * restData.noOfDays)
                //       );
                // }


                let totAmt = "";//
                let insurAmount = 0;//
                let insurPer = 0;

                if(CommonUtil.ORGANIZATION_TYPE === CommonUtil.ORG_TYPE_RENTER){
                    if(isLiabilityPaidByRenter){
                        insurAmount =  (totalDays*Number(liabilityInsuracePerDayAmount));
                    }
                    if(isPhysicalDamagePaidByRenter){
                        insurAmount =  insurAmount+(totalDays*Number(physicalDamagePerDayAmount));
                    }
                    // 2820 insurance paid by renter is made to 0 when viewed by ownner
                    if(isInsuranceEnabled && insurAmount && (isLiabilityPaidByRenter || isPhysicalDamagePaidByRenter  )  && ownerOrgId == CommonUtil.getSelectedOrgId()){
                        insurAmount = 0;
                        setInsuranceAmount("0");   
                    }
                }else{
                    if(renterOrgId == CommonUtil.getSelectedOrgId()) { 
                        if(!liabilityPaidByOwner){
                            insurAmount =  (totalDays*Number(liabilityInsuracePerDayAmount));
                        }
                        if(!physicalDamagePaidByOwner){
                            insurAmount =  insurAmount+(totalDays*Number(physicalDamagePerDayAmount));
                        }
                    }else{
                        if(liabilityPaidByOwner){
                            insurAmount =  (totalDays*Number(liabilityInsuracePerDayAmount));
                        }
                        if(physicalDamagePaidByOwner){
                            insurAmount =  insurAmount+(totalDays*Number(physicalDamagePerDayAmount));
                        }
                    }
                }

                console.log(isInsuranceEnabled , insurAmount , isLiabilityPaidByRenter , isPhysicalDamagePaidByRenter , ownerOrgId , CommonUtil.getSelectedOrgId())

                // if(isLiabilityPaidByRenter){
                //     insurAmount =  (totalDays*Number(liabilityInsuracePerDayAmount));
                // }
                // if(isPhysicalDamagePaidByRenter){
                //     insurAmount =  insurAmount+(totalDays*Number(physicalDamagePerDayAmount));
                // }

                if(insurAmount){
                    // insurPer = (insurAmount)*(10/100);
                    if(String(defaultCurrency).toUpperCase().indexOf("USD") !==-1){
                        insurPer = Number(usInsuranceRate);
                    }else{
                        insurPer = Number(canadaInsuranceRate);
                    }
                }
                                    
                setInsuranceAmount( insurAmount?insurAmount.toFixed(2):"");
                                
                // setInsuranceAdminFee( insurPer?insurPer.toFixed(2):""); V20-2087
                setInsuranceAdminFee( String(defaultCurrency).toUpperCase().indexOf("USD") !==-1 ?usInsuranceRate:canadaInsuranceRate);

                setPlatformFees(Number(restData?.platformFees));
                let durationPrice:number = restData.durationPrice;
                // if(!durationPrice){
                //     durationPrice = Number(restData.dailyRate) * Number(restData.noOfDays);
                // }
                let totPrice = (Number(restData.movePrice)+Number(durationPrice));
                let adminFee = 0;
                if(Number(insurAmount) > 0)
                    adminFee = Number(String(defaultCurrency).toUpperCase().indexOf("USD") !==-1 ?usInsuranceRate:canadaInsuranceRate);
                // totPrice = totPrice-(Number(insurAmount)+(adminFee));
                console.log(CommonUtil.ORGANIZATION_TYPE , CommonUtil.ORG_TYPE_RENTER );
                
                if((availabilityListingType == CommonUtil.EQUIP_AVAIL_REPOSITION_TYPE) && (liabilityPaidByOwner || physicalDamagePaidByOwner)){
                    if(CommonUtil.ORGANIZATION_TYPE === CommonUtil.ORG_TYPE_RENTER){
                        totPrice = totPrice-(Number(insurAmount)+(adminFee));
                    }else{
                        totPrice = totPrice+((adminFee));
                        totPrice = totPrice+(Number(insurAmount));  
                    }                
                }else {
                    totPrice = totPrice-(Number(insurAmount)+(adminFee));
                }

              
                // totPrice += Number(restData?.platformFees)
                setRepositionPrice( totPrice?totPrice.toFixed(2):"");
                // =  ?usInsuranceRate:canadaInsuranceRate

                
                setDailyRatePlanType(restData.dailyRatePlanType || "Daily");
                let totalPrice = Number(restData.distancePrice) + Number(durationPrice);
                //service fee for owner is to be minus
                if(ownerOrgId == CommonUtil.getSelectedOrgId() && availabilityListingType == CommonUtil.EQUIP_AVAIL_RENTAL_TYPE){ 
                     totalPrice = totalPrice-Number(restData.ownerServiceFee);
                     signforServiceFee = "-";
                }else{
                    totalPrice = totalPrice+Number(restData.ownerServiceFee);
                    signforServiceFee = "";
                }

                totalPrice = totalPrice+Number(insurAmount)+Number(insurPer);
                if (restData.enginePrice) {
                    totalPrice = totalPrice + Number(restData.enginePrice);
                }
                setRentalPrice( totalPrice?totalPrice.toFixed(2):"");
                

            }
        }
    }
    let getRenterOrganizationInfo = () => {
        var qParam = renterOrgId;//CommonUtil.getSaasOrgId();
        var orgUrl = 'api/organization/' + qParam;
        NetworkManager.sendJsonRequest(orgUrl, '', '', 'GET', true, onOrgDetailsSuccess, onLoginError)
    }
    let onOrgDetailsSuccess = (dataObj: any) => {
        if (dataObj) {
            var restData = CommonUtil.parseData(dataObj);
            if (restData) {
                setCompanyName(restData.orgName);
                setCTQ(restData.transportCommissionNumber ? restData.transportCommissionNumber : "--");
                //setPhoneNum(restData.phoneNumber);

                if (restData.logoPath) {
                    var qParam: string = "objectPath=" + restData.logoPath;
                    NetworkManager.sendJsonRequest("api/session/downloadUrl", "", qParam, "GET", true, onDownloadSuccess, onLoginError);
                }
            }
        }
    }
    let getOwnerOrganizationInfo = () => {
        var qParam = ownerOrgId;//CommonUtil.getSaasOrgId();
        var orgUrl = 'api/organization/' + qParam;
        NetworkManager.sendJsonRequest(orgUrl, '', '', 'GET', true, onOwnerOrgDetailsSuccess, onLoginError)
    }
    let onOwnerOrgDetailsSuccess = (dataObj: any) => {
        if (dataObj) {
            var restData = CommonUtil.parseData(dataObj);
            if (restData) {
                let mesure = CommonUtil.getMesurementByCountryName(restData.country);
                setMesurment(mesure);
                setOwnerCompanyName(restData.orgName);
                setOwnerCTQ(restData.transportCommissionNumber ? restData.transportCommissionNumber : "--");
                //setOwnerPhoneNum(restData.phoneNumber);

                if (restData.logoPath) {
                    var qParam: string = "objectPath=" + restData.logoPath;
                    NetworkManager.sendJsonRequest("api/session/downloadUrl", "", qParam, "GET", true, onOwnerOrgDownloadSuccess, onLoginError);
                }
            }
        }
    }

    let onOwnerOrgDownloadSuccess = (dataObj: any) => {
        setOwnerImageSrc(dataObj);
    }

   

    let getRenterUserDetails = () => {
        let getUserAPI: string = 'api/organization/' + renterOrgId + '/defaultuser';// + CommonUtil.getUserId() + '?saasOrgId=' + CommonUtil.getSaasOrgId();
        NetworkManager.sendJsonRequest(getUserAPI, '', '', 'GET', true, onGetUserDetails, onLoginError)
    }
    let onGetUserDetails = (dataObj: any) => {
        BusyLoader.hideLoader();
        if (dataObj) {
            var restData = CommonUtil.parseData(dataObj);
            if (restData) {
                setContactName(restData.firstName + " " + restData.lastName);
                setMail(restData.loginId ? restData.loginId : "--");
                setPhoneNum(restData.phone ? restData.phone : "--");
                //setLastName(restData.lastName);
                // setEmailId(restData.loginId);
            }
        }
    }
    let getOwnerUserDetails = () => {
        let getUserAPI: string = 'api/organization/' + ownerOrgId + '/defaultuser';// + CommonUtil.getUserId() + '?saasOrgId=' + CommonUtil.getSaasOrgId();
        NetworkManager.sendJsonRequest(getUserAPI, '', '', 'GET', true, onGetOwnerUserDetails, onLoginError)
    }
    let onGetOwnerUserDetails = (dataObj: any) => {
        BusyLoader.hideLoader();
        if (dataObj) {
            var restData = CommonUtil.parseData(dataObj);
            if (restData) {
                setOwnerContactName(restData.firstName + " " + restData.lastName);
                setOwnerMail(restData.loginId);
                setOwnerPhoneNum(restData.phone);
                //setLastName(restData.lastName);
                // setEmailId(restData.loginId);
            }
        }
    }
    let onDownloadSuccess = (data: any) => {
        setImageSrc(data);
    }

    let onChangeFromDate = (dateValue: any) => {
        let strDate: string = CommonUtil.getDate(dateValue)
        setFromDate(strDate);
        onChangeDates();
    }

    let onChangeToDate = (dateValue: any) => {
        let strDate: string = CommonUtil.getDate(dateValue)
        setToDate(strDate);
        onChangeDates();
    }

    let onChangeDates = () => {
        if (fromRef.current && toRef.current) {
            var startDate = fromRef.current.flatpickr.selectedDates[0];
            var endDate = toRef.current.flatpickr.selectedDates[0];

            if (endDate) {
                endDate = new Date(endDate);
                fromRef.current.flatpickr.set("maxDate", endDate);
                // stDtOptions.maxDate = new Date(endDate);
            } else if (startDate) {
                toRef.current.flatpickr.set("minDate", new Date(startDate));
                //stDtOptions.minDate = new Date(startDate);
            }
        }

    }
    useEffect(()=>{
        if(modalOpen){
            setTimeout(()=>{
                onChangeDates();
            },200)            
        }
    },[modalOpen])

    let onDownloadInsurence = (filePath: string) => {

        if (filePath) {
            var qParam: string = "objectPath=" + filePath;
            NetworkManager.sendJsonRequest("api/session/downloadUrl", "", qParam, "GET", true, onDownloadFP2506Success, onLoginError);
        }
    }
    let onDownloadFP2506Success = (dataObj: any) => {
        //console.log(dataObj);
        let link = document.createElement("a");
        link.download = "download";
        link.href = dataObj;
        link.target = "_blank"
        link.click();
    }



    let onClickSaveDriver = () => {

    }

    let totalServiceFree = () => {
        totalPrice = ((days * Number(dailyRate)) + (days * Number(eDistance) * Number(distanceRate)));//*(Number(serviceFee)/100);
        if (equipType == CommonUtil.REEFER_EQUIP_TYPE) {
            totalPrice = totalPrice + (days * Number(estimatedEngineHours) * Number(engineRate));
        }
        totalPrice = totalPrice * (Number(serviceFee) / 100);
        totalPrice = Number(totalPrice.toFixed(2));
        if (totalPrice > ownerMinRepositionPrice) {
            totalPrice = totalPrice;
        } else {
            totalPrice = ownerMinRepositionPrice;
        }
        return totalPrice;
    }
    let totalAmount = () => {
        let duration = (days * Number(dailyRate));
        let distance = days * Number(eDistance) * Number(distanceRate);
        let serviceFree = distance + duration + Number(totalPrice);//((days*Number(dailyRate))+(days*Number(eDistance)*Number(distanceRate)))*(Number(serviceFee)/100);
        if (equipType == CommonUtil.REEFER_EQUIP_TYPE) {
            serviceFree = serviceFree + (days * Number(estimatedEngineHours) * Number(engineRate));
        }
        return serviceFree.toFixed(2);
    }

    let onClickLiabilityDownload = ()=>{
        downloadInsuranceFile(liabilityDownload);
    }
    let onClickPhysicalDamage = ()=>{
        downloadInsuranceFile(physicalDownload);
    }

    let downloadInsuranceFile = (fullPath:string)=>{
        if (fullPath) {
            var qParam: string = "objectPath=" + fullPath;
            NetworkManager.sendJsonRequest("api/session/downloadUrl", "", qParam, "GET", true, onDownloadFP2506Success, onLoginError);
        }
    }

    let onClickEdit = ()=>{
        // if(reservationStatus === CommonUtil.RESERVATION_INPROGRESS || reservationStatus === CommonUtil.RESERVATION_DE_ACTIVATION_DUE || reservationStatus === CommonUtil.RESERVATION_LATE_ACTIVATION){
            setModalOpen(true);
        // }
        // getReservationDetails();
        // getAvailabilityDetails();
    }

    let onClickClose = ()=>{
        setModalOpen(false)
    }

    const updateLastHubodometerReading = (lastReading:any)=>{
        setLastHubodometer(lastReading||activationOdometer)
    }

    const updateLastEngineHourReading = (lastReading:any)=>{
        setLastEngineHour(lastReading||activationEngineHours)
    }

    let onClickReservationUpdate = ()=>{
        let stDate = null;
        let edDate = null;
        if(fromRef.current){
            stDate = fromRef.current.flatpickr.selectedDates[0];
        }
        if(toRef.current){
            edDate = toRef.current.flatpickr.selectedDates[0];
        }
        if(activationOdometer){
            if(!enteredHubodometer){
                ToastManager.showToast("Please Enter Hubodometer value", "Error");
                return;
            }
        }
        if(etype && activationEngineHours){
            if(!exEngineHours){
                ToastManager.showToast("Please Enter Engine Hours", "Error");
                return;
            }
            if(lastEngineHour > exEngineHours){
                ToastManager.showToast("Enter Engine Hours reading value should be greater the the older Engine Hours reading ", "Error");
                return;
            }
        }
        if(lastHubodometer > enteredHubodometer){
            ToastManager.showToast("Enter Hubodometer reading value should be greater the the older Hubodometer reading ", "Error");
            return;
        }

        if(stDate && edDate){
            //CommonUtil.SELECT_RESERVATION_ID
            let apiPath: string = "api/reservation/updateDates/" + CommonUtil.SELECT_RESERVATION_ID;
            let restObj:any = {};
            restObj.startDate = CommonUtil.getReservationDateString(stDate);
            restObj.endDate = CommonUtil.getReservationDateString(edDate);
            if(activationOdometer){
                if(etype)
                    restObj.reeferEngineHours = exEngineHours
                // restObj.Hubodometer = distancePerDay
                restObj.hubometer = enteredHubodometer
            }
           
            NetworkManager.sendJsonRequest(apiPath, restObj, "", "PUT", true, onReservationUpdateDates, onLoginError);
        }else{
           let msg =  CommonUtil.getMessageText(Constants.DATES_ARE_REQUIRED, "From Date and To Dates are required to update the Reservation");
            ToastManager.showToast(msg, "Error");
        }
    }

    let onReservationUpdateDates = (dataObj:any)=>{
        if (dataObj) {
            var restData = CommonUtil.parseData(dataObj);
            if (restData) {
                let msg =  CommonUtil.getMessageText(Constants.RESERVATION_DATES_UPDATED, "Reservation Dates are successfully updated");
                ToastManager.showToast(msg, "Info");
                setReservationStartDate(restData.startDate);
                setReservationEndDate(restData.endDate);
                setModalOpen(false)
                getReservationDetails();
                getAvailabilityDetails();
            }
        }
    }

    let onDownloadFP2506Doc = (filePath: string) => {
        // selFileType = fType;
        // let filePath = getAttachmentFilePath(fType);
        if (filePath) {
            var qParam: string = "objectPath=" + filePath;
            NetworkManager.sendJsonRequest("api/session/downloadUrl", "", qParam, "GET", true, onDownloadInsFormSuccess, onLoginError);
        }
    }

    let onDownloadInsFormSuccess = (dataObj: any) => {
        //console.log(dataObj);
        let link = document.createElement("a");
        link.download = "download";
        link.href = dataObj;
        link.target = "_blank"
        link.click();
    }

    const handleDragOver = (e: any) => {
        e.preventDefault()
        e.stopPropagation()
    }
    const handleDragEnter = (e: any) => {
        e.preventDefault()
        e.stopPropagation()
    }
    const handleDragLeave = (e: any) => {
        e.preventDefault()
        e.stopPropagation()
    }
    const handleDropInsurance = (e: any) => {
        e.preventDefault()
        e.stopPropagation();
        e.stopImmediatePropagation();
        //fileType = CommonUtil.INSUR_DOC_TYPE;//'Insurence';
        const { files } = e.dataTransfer;       
        if (files && files.length && avoidMultiple.indexOf(files[0].name) ===-1 ) {
            setFileName("");
            dropFileObj.current = files[0];
            onSelectDrop(files[0]);
            avoidMultiple.push(files[0].name);
        }
    }

    let uploadFinanceDocuments = () => {
        // fileType = fType;
        fileType = CommonUtil.POD_DOC_TYPE;
        uploadFrom = "POD";
        onFPGSTFormUpload();
    }
    let uploadContactDetails = () => {
        // fileType = fType;
        fileType = CommonUtil.CONTACT_DETAILS_DOC;
        uploadFrom = "CDetails";
        onFPGSTFormUpload();
    }
    let onFPGSTFormUpload = () => {
        if (fileref.current) {
            fileref.current.value = "";
            if (document.getElementById("inputGST")) {
                // document.getElementById("inputGST").click();
            }

            $("#inputGST").click();
        }
        setFileName("");
    }

    let onSelectDrop = (e: any) => {
        //console.log(e);
        if (e && e.name) {
            fileName = e.name;
            setFileName(fileName);
            BusyLoader.showLoader();
            if(uploadFrom === "CDetails")
                fullPath = "documents/equipment/contactDetails/" + ownerOrgId + "/" + fileName + "-" + new Date().getTime();
            else
                fullPath = "documents/equipment/POD/"+ownerOrgId+"/"+fileName+"-"+new Date().getTime();
            var qParam: string = 'objectPath='+fullPath;
            NetworkManager.sendJsonRequest("api/session/uploadUrl", "", qParam, "GET", false, onUploadSuccessDrop, onLoginError);
        }
    }
    let onSelectFiles = (e: any) => {
        //console.log(e);
        if (e && e.target && e.target.files[0]) {
            fileName = e.target.files[0].name;
            dropFileObj.current = e.target.files[0];
            setFileName(fileName);
            BusyLoader.showLoader();
            if(uploadFrom === "CDetails")
                fullPath = "documents/equipment/contactDetails/" + ownerOrgId + "/" + fileName + "-" + new Date().getTime();
            else
                fullPath = 'documents/equipment/POD/' + ownerOrgId + '/' + fileName + '-' + new Date().getTime();
            var qParam: string = 'objectPath='+fullPath;
            NetworkManager.sendJsonRequest("api/session/uploadUrl", "", qParam, "GET", false, onUploadSuccessDrop, onLoginError);
        }
    }

    let onUploadSuccessDrop = (dataObj: any) => {
        if (dataObj.statusCode) {
            BusyLoader.hideLoader();
            let errMsg = (StatusCodeUtil.getCodeMessage(dataObj.statusCode));
            ToastManager.showToast(errMsg, "Error");
        } else {
            let myHeaders = new Headers();
            var file:File = dropFileObj.current;
            let requestOptions: any = { method: 'PUT', headers: myHeaders, body: file, redirect: 'follow' };
            fetch(dataObj, requestOptions)
                .then(response => response.text())
                .then(result => {
                    let reqObj:any = {};
                    let obj:any = {};
                    obj.documentName = fileName;
                    obj.documentType = fileType;
                    obj.visibility = "public";
                    obj.fullFilePath = fullPath;
                    obj.saasOrgId = CommonUtil.getSaasOrgId();
                    obj.saasOrgId = CommonUtil.getSelectedOrgId();
                    obj.entityId = CommonUtil.SELECT_EQUIP_ID;
                    obj.reservationCode = CommonUtil.SELECT_RESERVATION_CODE;
                    obj.reservationId = CommonUtil.SELECT_RESERVATION_ID;
                    obj.attachmentCategory = CommonUtil.RESERVATION_CATEGORY;//CommonUtil.LICENSE_IMAGES;
                    obj.attachmentDescription = description;


                    reqObj = obj;

                    //reqObj.attachments = [obj];

                    let attachmentUrl:string = "api/equipment/attachment?saasOrgId="+ownerOrgId;

                    // var qParam:string = "logoPath=images/organizations/"+fileref.current.files[0].name+"&saasOrgId="+localStorage.getItem("saasorgId");
                    NetworkManager.sendJsonRequest(attachmentUrl,reqObj,"","PUT",false,onUpdateEquipImageSuccess,onLoginError);
                })
                .catch(error => {
                    BusyLoader.hideLoader();
                    
                });
        }
    }
    let onUpdateEquipImageSuccess = (dataObj:any)=>{
        BusyLoader.hideLoader();

        if (dataObj) {
            var restData = CommonUtil.parseData(dataObj);
            if(restData){
                ToastManager.showToast(CommonUtil.getMessageText(Constants.DOCS_UPLOAD_SUCCESS,"Document Uploaded Successfully"),"Info");
                setFullPathResp({path: restData.fullFilePath, name:restData.documentName})
                showEquipmentDetails();
            }
        }
        //console.log(dataObj);
        //let qParam:string = "objectPath="+imagePath;
        // NetworkManager.sendJsonRequest("api/session/downloadUrl","",qParam,"GET",false,onDownloadSuccess,onLoginError)
    }
    let showEquipmentDetails = ()=>{
        attchIdx = 0;
        equiImages = [];
        if (CommonUtil.SELECT_EQUIP_ID) {
            let equipmentAPI: string = 'api/equipment/' + CommonUtil.SELECT_EQUIP_ID;
            let obj: any = {}
            NetworkManager.sendJsonRequest(equipmentAPI, '', '', 'GET', true, onGetEquipmentAttachments, onLoginError);
        }
    }

    let onGetEquipmentAttachments = (dataObj:any)=>{
        if (dataObj) {
            var restData = CommonUtil.parseData(dataObj);
            if (restData) {
                setEquipData([]);
                if(restData && restData.attachments){
                    let attchments = [];
                    if(Array.isArray(restData.attachments)){
                        attchments = restData.attachments;
                    }else{
                        attchments.push(restData.attachments);
                    }

                    if(attchments.length>0){
                        let docs:any = [];
                        attchments.forEach((docItem:any,idx:number)=>{
                            if((docItem.documentType == CommonUtil.POD_DOC_TYPE || docItem.documentType == CommonUtil.CONTACT_DETAILS_DOC) && CommonUtil.SELECT_RESERVATION_ID == docItem.reservationId){
                                docs.push(docItem);
                            }
                        });
                        setEquipData(docs);
                    }

                }
            }
        }


    }

    let onRemoveFP2506Doc = (attachId: string) => {
        // Confirm.show("dosos",["yes","No"])
        // selFileType = fType;
        Confirm.show("Confirm", "Do you want remove document?", [{
            label: "Yes", callback: function () {
                //let attachId = getAttachmentId(fType);
                // BusyLoader.showLoader();
                if (attachId) {
                    let removeAPI:string = "api/equipment/attachment/"+attachId+"?saasOrgId="+CommonUtil.getSaasOrgId();
                    try{
                        const fileName:string = equipDocs.filter((item:any)=>item.attachementId === attachId)[0].documentName;
                        avoidMultiple.splice(avoidMultiple.indexOf(fileName),1);
                    }
                    catch(e){
                       avoidMultiple = []; 
                    }                   
                    
                    
                    NetworkManager.sendJsonRequest(removeAPI, "", "", "DELETE", true, onDeleteFinanceForms, onLoginError);
                }
            }
        }, { label: "No" }]);

    }
    let onDeleteFinanceForms = (dataObj: any) => {
        let flag: boolean = true;
        if (dataObj) {
            //  var restData = JSON.parse(dataObj);
            if (dataObj.statusCode) {
                flag = false;
                BusyLoader.hideLoader();
                var errMsg = (StatusCodeUtil.getCodeMessage(dataObj.statusCode));
                ToastManager.showToast(errMsg, "Error");
            } else {

            }
        }
        if (flag) {
            ToastManager.showToast("File Removed Successfully", "Info");
           showEquipmentDetails();
        }

    }

    let onChangeDepartureLot = (dataObj:any)=>{
        setSelDepartureObj(dataObj);

    }

    let onChangeArrivalLot = (dataObj:any)=>{
        setSelArrivalObj(dataObj);

    }

    let onClickUpdateAddr = ()=>{
        if((selDepartureObj && selDepartureObj.isDummy == "Y")){
            let strSuccess = "Please select original parking lot(s)";//CommonUtil.getMessageText(Constants.USER_CREATED_SUCCESS,"User Created Successfully");
            ToastManager.showToast(strSuccess, "Error");
            return;
        }else{
            onClickUpdateDepAddr();
        }
    }

    let onClickUpdateDepAddr = ()=>{
       
        let reqObj:any = {};
        reqObj.departueParkingDetails = {};
        reqObj.arrivalParkingDetails = {};


        if((selDepartureObj && selDepartureObj.isDummy == "Y" && selArrivalObj &&  selArrivalObj.isDummy == "Y")){
            let strSuccess = "Please select original parking lot(s)";//CommonUtil.getMessageText(Constants.USER_CREATED_SUCCESS,"User Created Successfully");
            ToastManager.showToast(strSuccess, "Error");
            return;
        }
        if(selDepartureObj && selDepartureObj.isDummy == "N" ) {
            reqObj.departueParkingDetails.parkingLotId = selDepartureObj.parkingLotId;
            reqObj.departueParkingDetails.address = selDepartureObj.address;
            reqObj.departueParkingDetails.poiCapture = selDepartureObj.poiCapture;
            reqObj.departueParkingDetails.rawAddress = selDepartureObj.rawAddress;
            reqObj.departueParkingDetails.city = selDepartureObj.city;
            reqObj.departueParkingDetails.country = selDepartureObj.country;
            reqObj.departueParkingDetails.latitude = selDepartureObj.latitude;
            reqObj.departueParkingDetails.longitude = selDepartureObj.longitude;
            reqObj.departueParkingDetails.postalCode = selDepartureObj.postalCode;
            reqObj.departueParkingDetails.state = selDepartureObj.state;
        }
        if(selArrivalObj &&  selArrivalObj.isDummy == "N") {
            reqObj.arrivalParkingDetails.parkingLotId = selArrivalObj.parkingLotId;
            reqObj.arrivalParkingDetails.address = selArrivalObj.address;
            reqObj.arrivalParkingDetails.poiCapture = selArrivalObj.poiCapture;
            reqObj.arrivalParkingDetails.rawAddress = selArrivalObj.rawAddress;
            reqObj.arrivalParkingDetails.city = selArrivalObj.city;
            reqObj.arrivalParkingDetails.country = selArrivalObj.country;
            reqObj.arrivalParkingDetails.latitude = selArrivalObj.latitude;
            reqObj.arrivalParkingDetails.longitude = selArrivalObj.longitude;
            reqObj.arrivalParkingDetails.postalCode = selArrivalObj.postalCode;
            reqObj.arrivalParkingDetails.state = selArrivalObj.state;
        }

            let availCraateApi:string = "";
            let method:string = "PUT";
            availCraateApi = "api/availability/updateParkingLotsForReservation?reservationId="+CommonUtil.SELECT_RESERVATION_ID;

            NetworkManager.sendJsonRequest(availCraateApi,reqObj,"",method,true,onUpdateAvailable,onLoginError);
        }



    let onUpdateAvailable = (dataObj:any)=>{
        if(dataObj) {
            var restData = CommonUtil.parseData(dataObj);
            if (restData) {
                ToastManager.showToast("Departure Address Updated Successfully","Info");
                getUpdatedReservationDetails();

            }
        }
    }

    let getUpdatedReservationDetails = () => {
        let reservationAPI: string = "api/reservation/" + CommonUtil.SELECT_RESERVATION_ID;
        NetworkManager.sendJsonRequest(reservationAPI, "", "", "GET", true, onGetUpdatedReservationDetails, onLoginError);
    }

    // const [reservationCode,setReservationCode] = useState("");

    let onGetUpdatedReservationDetails = (dataObj: any) => {
  
        if (dataObj) {
            var restData = CommonUtil.parseData(dataObj);
            if (restData) {
               // renterOrgId = restData.renterOrgId;
                //ownerOrgId = restData.saasOrgId;

                //reserveArrivalpartkingLotId = restData.arrivalParkingLotId;
               // reserveDepartureParkingLotId = restData.departureParkingLotId;

               // isGeneric = restData.isEquipmentGeneric;
               // isAnyAvailable = restData.isEnableAnyAvailble;
                //CommonUtil.SELECT_EQUIP_AVAIL_ID = restData.equipmentListingId;

                let arrivalAddr: string = restData.arrivalCity + "," + restData.arrivalState;
                let departureAddr: string = restData.departureCity + "," + restData.departureState;
                setDepartureAddr(departureAddr);
                setArrivalAddr(arrivalAddr);

               /* let arrivalAddr: string = restData.arrivalCity + "," + restData.arrivalState;
                let departureAddr: string = restData.departureCity + "," + restData.departureState;
                setDepartureAddr(departureAddr);
                setArrivalAddr(arrivalAddr);*/

                setDeparture(restData.departureParkingLotAddress);
                setDepartureIns(restData.departureParkingLotInstructions);

                setArrival(restData.arrivalParkingLotAddress);
                setArrivalIns(restData.arrivalParkingLotInstructions);

                setArrivalTwinRequire((restData.arrivalParkingTwicAccessRequired === "Y"?true:false));
                setDepartTwinRequire((restData.departureParkingTwicAccessRequired === "Y"?true:false));

                let aMap: string = `https://maps.google.com/maps?q=${restData.arrivalParkingLotLatitude},${restData.arrivalParkingLotLongitude}&hl=en&z=14&amp&output=embed`;
                setDepartureMap(aMap);
                let idIframe = document.getElementById('iFRAME') as HTMLIFrameElement;
                //console.log(aMap);
                idIframe.setAttribute('src', aMap);

                let dMap: string = `https://maps.google.com/maps?q=${restData.departureParkingLotLatitude},${restData.departureParkingLotLongitude}&hl=en&z=14&amp&output=embed`;
                let dIframe = document.getElementById('depatureIframe') as HTMLIFrameElement;
                dIframe.setAttribute('src', dMap);
            }
        }
    }


        let onClickUpdateArrivalAddr = ()=>{
            if((selArrivalObj && selArrivalObj.isDummy == "Y")){
                let strSuccess = "Please select original parking lot(s)";//CommonUtil.getMessageText(Constants.USER_CREATED_SUCCESS,"User Created Successfully");
                ToastManager.showToast(strSuccess, "Error");
                return;
            }else{
                onClickUpdateDepAddr();
            }

         }

    let onUpdateArivalAvailable = (dataObj:any)=>{
        if(dataObj) {
            var restData = CommonUtil.parseData(dataObj);
            if (restData) {
                ToastManager.showToast("Arrival Address Updated Successfully","Info");
            }
        }
    }
   

    let onLoginError = (err: any) => {
        console.log(err);
    }
  

    // useEffect(() => {
    //     const isValid = validate();
    //     setValid(isValid)
    // },[custPo,renterPo]);
    

    let rateObj:any = {};
    try{     
        rateObj = getSelectedRangeRate(days, Number(dailyRate), Number(weeklyRate), Number(monthlyRate));
    }catch(e){
        rateObj = {};
    }

    
    return (
        <>
         <Modal show={modalOpen} dialogAs={DraggableComponent}>
        <div className="modal-content" style={{ width: "600px", height: "400px" }}>
          <div className="modal-header">
            <h6>Extend Reservation</h6>
          </div>
          <div className="modal-body">
          <div className="row">
                    <div className="col-sm-12">
                        <div className="row" style={{paddingTop:"10px"}}>
                            <div className="col-6 col-sm-6 col-md-6 col-lg-6">
                                <div className="form-group row">

                                    <label className="col-sm-12 col-form-label m-0">{CommonUtil.getMessageText(Constants.FROM_DATE, "From Date")}</label>
                                    <div className="col-sm-12">
                                        <div className="date-i">
                                        <Flatpickr placeholder="From Date" ref={fromRef} options={dateOptions} onChange={onChangeFromDate}                                               
                                                defaultValue={CommonUtil.getDateFormatForDatePicker(reservationStartDate)}
                                                className="form-control" 
                                                disabled={activationDate? true : isDateChanged?CommonUtil.ORGANIZATION_TYPE == CommonUtil.ORG_TYPE_RENTER ? true : false :true} 
                                                // disabled={isDateChanged?false:true} 
                                                />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-6 col-sm-6 col-md-6 col-lg-6">
                                <div className="form-group row">
                                    <label className="col-sm-12 col-form-label m-0">{CommonUtil.getMessageText(Constants.TO_DATE, "To Date")}</label>
                                    <div className="col-sm-12">
                                        <div className="date-i">
                                            <Flatpickr placeholder="To Date" ref={toRef} options={dateOptions} onChange={onChangeToDate}
                                                 defaultValue={CommonUtil.getDateFormatForDatePicker(reservationEndDate)}
                                                className="form-control" disabled={isDateChanged?false:true} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {etype && activationEngineHours?(<>
                            <div className="col-6 col-sm-6 col-md-6 col-lg-6">
                                    <div className="form-group row">
                                        <label className="col-sm-12 col-form-label m-0">Last Engine hours</label>
                                        <input className="form-control"
                                                type='text'
                                                value={lastEngineHour|| activationEngineHours}
                                                disabled={true}
                                            />
                                    </div>
                                </div>        
                            
                            <div className="col-6 col-sm-6 col-md-6 col-lg-6">
                                <div className="form-group row">
                                    <label className="col-sm-12 col-form-label m-0">Engine Hours</label>
                                    <div className="col-sm-12">
                                        <div className="date-i">
                                        <input className="form-control"
                                               placeholder="Engine Hours"
                                               type='text'
                                               value={exEngineHours}
                                               onChange={(e:any)=>{setExEngineHours(e.currentTarget.value)}} 
                                        />
                                        </div>
                                    </div>
                                </div>
                            </div></>):""}
                            {
                                activationOdometer ?<>
                                <div className="col-6 col-sm-6 col-md-6 col-lg-6">
                                    <div className="form-group row">
                                        <label className="col-sm-12 col-form-label m-0">Last Hubodometer</label>
                                        <input className="form-control"
                                                type='text'
                                                value={lastHubodometer || activationOdometer}
                                                disabled={true}
                                            />
                                    </div>
                                </div>                                                         
                                <div className="col-6 col-sm-6 col-md-6 col-lg-6">
                                    <div className="form-group row">
                                        <label className="col-sm-12 col-form-label m-0">Enter Actual Hubodometer</label>
                                        <div className="col-sm-12">
                                            <div className="date-i">
                                            <input className="form-control"
                                                    placeholder="Hubodometer value"
                                                    type='text' 
                                                    value={enteredHubodometer}
                                                    onChange={(e:any)=>{setEnteredHubodometer(e.currentTarget.value)}}
                                            />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                </>
                            :""}
                        </div>
                    </div>
                </div>
          </div>
          <div className="modal-footer">
         
                        <button type="button" className="btn btn-outline-dark" onClick={onClickClose}>{CommonUtil.getMessageText(Constants.CLOSE,"Cancel")}</button>
                        <button type="button" className="btn btn-success" onClick = {onClickReservationUpdate}>{CommonUtil.getMessageText(Constants.SAVE,"Save")}</button>
                  
          </div>
        </div>
      </Modal>
      
            {show ? emailsSendModal : null}
            <input type="file" ref={fileref} className="custom-file-input" multiple={false} id="inputGST"
                   accept="application/pdf"
                   style={{ "display": "none" }} onChange={(e) => {
                onSelectFiles(e)
            }}></input>
            <div className="container-fluid">
                <div className="row d-flex mt-3 mb-5" >
                    <div className="col-md-6 col-lg-3 my-2" >
                        <p className="my-0">{CommonUtil.getMessageText(Constants.RESERVATION_TYPE, "Reservation Type")}:</p>
                        <strong>{reservationData?.listingType}</strong>
                    </div>
                    <div className="col-md-6 col-lg-3 my-2" style={{display:(reservationData?.listingType == CommonUtil.EQUIP_AVAIL_REPOSITION_TYPE)?"":"none"}}>
                        <p className="my-0">{CommonUtil.getMessageText(Constants.LOAD_TYPE, "Load Type")}:</p>
                        <strong>{reservationData?.loadType ? reservationData?.loadType : "-"}</strong>
                    </div>
                    <div className="col-md-6 col-lg-3 my-2" >
                        <p className="my-0">{CommonUtil.getMessageText(Constants.EQUIP_TYPE, "Equipment Type")}:</p>
                        <strong>{equipType}</strong>
                    </div>
                    <div className="col-md-6 col-lg-3 my-2" style={{display:(reservationData?.listingType == CommonUtil.EQUIP_AVAIL_REPOSITION_TYPE)?"":"none"}} >
                        <p className="my-0">{CommonUtil.getMessageText(Constants.REVENUE, "Revenue")}:</p>
                        <strong>{`$${movePrice ? movePrice : "-"}`}</strong>
                    </div>
                    <div className="col-md-6 col-lg-3 my-2" style={{display:(reservationData?.listingType == CommonUtil.EQUIP_AVAIL_REPOSITION_TYPE)?"":"none"}}>
                        <p className="my-0">{CommonUtil.getMessageText(Constants.DAYS_ALLOWED_REPOSITION, "Days allowed for repositioning")}:</p>
                        <strong>{`${noOfDays ? noOfDays : "-"} Days`}</strong>
                    </div>
                    <div className="col-md-6 col-lg-3 my-2" >
                        <p className="my-0">{CommonUtil.getMessageText(Constants.RESERVATION_START_DATE, "Reservation Start Date")}:</p>
                        <strong>{CommonUtil.getDBDate(reservationStartDate)}</strong>
                    </div>
                    <div className="col-md-6 col-lg-3 my-2" >
                        <p className="my-0">{CommonUtil.getMessageText(Constants.DEPARTURE_ADDR, "Departure Address")}:</p>
                        <strong>{departureAddr}</strong>
                    </div>
                    <div className="col-md-6 col-lg-3 my-2" >
                        <p className="my-0">{CommonUtil.getMessageText(Constants.ARRIVAL_ADDRESS, "Arrival Address")}:</p>
                        <strong>{arrivalAddr}</strong>
                    </div>
                    <div className="col-md-6 col-lg-3 my-2" >
                        <p className="my-0">{CommonUtil.getMessageText(Constants.RESERVATION_END_DATE, "Reservation End Date")}:</p>
                        <strong>{CommonUtil.getDBDate(reservationEndDate)}</strong>
                    </div>
                    <div className="col-md-6 col-lg-3 my-2" >
                        <p className="my-0">{"Contract Number"}:</p>
                        <strong>{contractNumber}</strong>
                    </div>

                </div>
                <div className="row">
                    <div className="col-lg-1"></div>
                    <div className="col-md-12 col-lg-10">
                        <div className="hori-timeline row" dir="ltr">
                            <div className="col-md-12 col-lg-10 m-auto">
                                <ul className="list-inline events">
                                    <li style={{ width: "32%" }} className="p-0 list1 list-inline-item event-list1">
                                        <div className="event-date1 bg-radial-gradient-primary1"
                                            style={startDate ? { backgroundImage: 'radial-gradient(circle,green 40%, #fff 40%)' } : {}} ></div>
                                        <h6 style={{ marginLeft: "-30%" }}>{CommonUtil.getMessageText(Constants.RESERVATION_CREATION, "Reservation Creation")}</h6>

                                        <p style={{ marginLeft: "-30%" }} className="text-muted">{startDate}</p>
                                    </li>
                                    <li style={{ width: "32%" }} className="list2 text-center list-inline-item event-list">
                                        <div className="event-date2 bg-radial-gradient-primary1" style={activationDate ? { backgroundImage: 'radial-gradient(circle,green 40%, #fff 40%)' } : {}}></div>

                                        <h6>{CommonUtil.getMessageText(Constants.RESERVATION_ACTIVATION, "Reservation Activation")}</h6>

                                        <p className="text-muted">{activationDate ? activationDate : <>&nbsp;</>}</p>
                                    </li>
                                    <li style={{ width: "32%" }} className="p-0 list3 text-right list-inline-item event-list">
                                        <div className="event-date bg-radial-gradient-primary1 deaction-box" style={deActivationDate ? { backgroundImage: 'radial-gradient(circle,green 40%, #fff 40%)' } : {}}></div>
                                        <h6 style={{ position: 'relative', right: '-30%', top: '50%' }} >{CommonUtil.getMessageText(Constants.RESERVATION_DEACTIVATION, "Reservation Deactivation")}</h6>

                                        <p className="text-muted" style={{ position: "relative", left: "30%" }}>{deActivationDate ? deActivationDate : <>&nbsp;</>}</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-1"></div>

                </div>
            </div>
            <div className="row">
                <div className="col-6 col-sm-12">
                    <section>
                        <div className="row">
                            <div className="col-sm-12">
                                <h6>{CommonUtil.getMessageText(Constants.OWNER_INFORMATION, "Owner Information")}</h6>
                                <p className="m-0" style={{display:"none"}}>{CommonUtil.getMessageText(Constants.ALL_REIMBURSEMENT, "All your reimbursement requests will be added in this section.")}</p>
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-2 col-sm-2 col-md-1">
                                <img src={ownerimageSrc} alt="" style={{ width: "50px" }} />
                            </div>
                            <div className="col-10 col-sm-10 col-md-11">
                                <div className="row">
                                    <div className="col-md-8">
                                        <span className="details-head" style={{ paddingLeft: "20px" }}><strong>{ownercompanyName}</strong></span>
                                        <div className="rating" style={{ display: "none" }}>
                                            <i className="fa fa-star"></i> <i className="fa fa-star"></i> <i
                                                className="fa fa-star"></i> <i className="fa fa-star"></i> <i
                                                    className="fa fa-star-o"></i> 4 | 39 Ratings
                                        </div>

                                    </div>
                                    <div className="col-md-4" style={{display:"none"}}>
                                        <span className="details-head">{CommonUtil.getMessageText(Constants.INSURENCE_PROOF, "Insurance Proof")}</span><label>
                                            {ownerinsurencId ? <a style={{ color: "blue" }} onClick={() => { onDownloadInsurence(ownerinsurencId) }}><i className="fa fa-eye"></i>
                                                {CommonUtil.getMessageText(Constants.VIEW_DOCUMENT, "View Document")}
                                            </a> : ""}</label>
                                    </div>

                                    <div className="col-12 flex" >
                                        <div className="col-sm-6">
                                            <span>{CommonUtil.getMessageText(Constants.CONTACT_NAME, "Contact Name")}:</span>&nbsp;&nbsp;
                                        </div>
                                        <div className="col-sm-6">
                                            <label>{ownercontactName}</label>
                                        </div>

                                    </div>
                                    <div className="col-12 flex">
                                        <div className="col-sm-6">
                                            <span>{CommonUtil.getMessageText(Constants.CONTACT_EMAIL, "Contact Email")}:</span>&nbsp;&nbsp;
                                        </div>
                                        <div className="col-sm-6">
                                            <label>{ownermail}</label>
                                        </div>

                                    </div>
                                    <div className="col-12 flex">
                                        <div className="col-sm-6">
                                            <span>{CommonUtil.getMessageText(Constants.CONTACT_PHONE, "Contact Phone")}:</span>&nbsp;&nbsp;
                                        </div>
                                        <div className="col-sm-6">
                                            <label>{ownerphoneNum}</label>
                                        </div>

                                    </div>
                                    <div className="col-12 flex">
                                        <div className="col-sm-6">
                                            <span>{CommonUtil.getMessageText(Constants.TRANS_COMM_NO, "Transport Commission Number")}:</span>&nbsp;&nbsp;
                                        </div>
                                        <div className="col-sm-6">
                                            <label>{ownerctq}</label>
                                        </div>

                                    </div>
                                    <div className="col-12 flex">
                                        <div className="col-sm-6">
                                            <label >&nbsp;<a style={{color:"#0d6efd",textDecoration:"underline"}} onClick={()=>onDownloadDoc(termsFile)}>Terms and Conditions</a>&nbsp;</label>
                                        </div>
                                        <div className="col-sm-6">
                                            <label >&nbsp;<a style={{color:"#0d6efd",textDecoration:"underline"}} onClick={()=>onDownloadDoc(agreementFile)}>Master Rental Agreement</a>&nbsp;</label>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                <div className="col-6 col-sm-12">
                    <section>
                        <div className="row">
                            <div className="col-sm-12">
                                <h6>{CommonUtil.getMessageText(Constants.RENTER_INFORMATION, "Renter Information")}</h6>
                                <p className="m-0" style={{display:"none"}}>{CommonUtil.getMessageText(Constants.ALL_REIMBURSEMENT, "All your reimbursement requests will be added in this section.")}</p>
                            </div>
                            <div className="row">
                                <div className="col-md-6 col-6 mt-0">
                                    <span className="details-head" style={{fontWeight:"bold",textAlign:"left"}}>Insurance Details</span>
                                </div>

                                <div className="col-md-6 col-6 mt-0">
                                    <div className="form-group row" style={{marginBottom:"1px"}}>
                                        <div className="col-sm-12">
                                            <div className="gallery flex documents-gallary" style={{marginTop:"-40px", justifyContent:"flex-end"}}>
                                                {attachDocs.map((item: any, idx: number) => {
                                                    if (item && (item.documentType == CommonUtil.INSUR_DOC_TYPE)) {
                                                        var list = (
                                                            <div className="img-fluid  document-box" key={idx}>
                                                                <div style={{ display: "flex", alignItems: "center",justifyContent:"center" }}>
                                                                    <img src="../../images/document.png" alt="" className={"financial-download-image"}
                                                                         onClick={() => { onDownloadFP2506Doc(item.fullFilePath) }} />

                                                                    <span style={{ display: "none" }}><a><i className="fa fa-download" onClick={() => { onDownloadFP2506Doc(item.fullFilePath) }} title={item.documentName}></i></a></span>
                                                                </div>
                                                                <label className={"financial-download-label"} style={{marginBottom:"2px",color:"blue", textAlign:"center"}} onClick={() => { onDownloadFP2506Doc(item.fullFilePath) }} title={item.documentName}>{(item.documentName.substring(0,10))}</label>
                                                            </div>
                                                        )
                                                        return list
                                                    }else if(item && (item.documentType == "Rental_insurance")){
                                                        var list = (
                                                            <div className="img-fluid  document-box" key={idx}>
                                                                <div style={{ display: "flex", alignItems: "center",justifyContent:"center" }}>
                                                                    <img src="../../images/document.png" alt="" className={"financial-download-image"}
                                                                         onClick={() => { getRentalAttachment() }} />

                                                                    <span style={{ display: "none" }}><a><i className="fa fa-download" onClick={() => { onDownloadFP2506Doc(item.fullFilePath) }} title={item.documentName}></i></a></span>
                                                                </div>
                                                                <label className={"financial-download-label"} style={{marginBottom:"2px",color:"blue"}} onClick={() => { onDownloadFP2506Doc(item.fullFilePath) }} title={item.documentName}>{(item.documentName.substring(0,10))}</label>
                                                            </div>
                                                        )
                                                        return list
                                                    }
                                                })}

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row mt-0">
                            <div className="col-2 col-sm-2 col-md-1">
                                <img src={imageSrc} alt="" style={{ width: "50px" }} />
                            </div>
                            <div className="col-10 col-sm-10 col-md-11">
                                <div className="row">
                                    <div className="col-md-8">
                                        <span className="details-head"><strong>{companyName}</strong></span>
                                        <div className="rating" style={{ display: "none" }}>
                                            <i className="fa fa-star"></i> <i className="fa fa-star"></i> <i
                                                className="fa fa-star"></i> <i className="fa fa-star"></i> <i
                                                    className="fa fa-star-o"></i> 4 | 39 Ratings
                                        </div>

                                    </div>


                                    <div className="col-12 flex">
                                        <div className="col-6">
                                            <span>{CommonUtil.getMessageText(Constants.CONTACT_NAME, "Contact Name")}:</span>&nbsp;&nbsp;
                                        </div>
                                        <div className="col-6">
                                            <label>{contactName}</label>
                                        </div>

                                    </div>
                                    <div className="col-12 flex">
                                        <div className="col-6">
                                            <span>{CommonUtil.getMessageText(Constants.CONTACT_EMAIL, "Contact Email")}:</span>&nbsp;&nbsp;
                                        </div>
                                        <div className="col-6">
                                            <label>{mail}</label>
                                        </div>

                                    </div>
                                    <div className="col-12 flex">
                                        <div className="col-6">
                                            <span>{CommonUtil.getMessageText(Constants.CONTACT_PHONE, "Contact Phone")}:</span>&nbsp;&nbsp;
                                        </div>
                                        <div className="col-6">
                                            <label>{phoneNum}</label>
                                        </div>

                                    </div>
                                    <div className="col-12 flex">
                                        <div className="col-6">
                                            <span>{CommonUtil.getMessageText(Constants.TRANS_COMM_NO, "Transport Commission Number")}:</span>&nbsp;&nbsp;
                                        </div>
                                        <div className="col-6">
                                            <label>{ctq}</label>
                                        </div>

                                    </div>


                                </div>


                            </div>
                        </div>

                    </section>
                </div>

            </div>

            <section className="p-0">
                <div className="row m-0">
                    <div className="col-sm-6 col-12 p-4">
                        <h6>{CommonUtil.getMessageText(Constants.ACTIVATION, "Activation")}</h6>
                        <span className="details-head">{CommonUtil.getMessageText(Constants.STARTS_AT, "Starts At")}</span><label>{activationDate}</label>
                        <span className="details-head">{CommonUtil.getMessageText(Constants.ADDRESS, "Address")}</span><label>{departure}</label>
                        <span className="details-head">{CommonUtil.getMessageText(Constants.CONTACT_NAME_AT, "Contact Name")}</span><label>{activationName}</label>
                        <span className="details-head">{CommonUtil.getMessageText(Constants.CONTACT_NUMBER_AT, "Contact Number")}</span><label>{activationNumber}</label>
                        <span className="details-head">{CommonUtil.getMessageText(Constants.PARTKING_LOT_ACCESS, "Parking Lot Access Instructions")}</span><label>{departureIns}</label>
                        {departTwicRequire?<div><span className="details-head" style={{fontWeight:"bold"}}>{CommonUtil.getMessageText(Constants.TWIC_ACCESS_REQUIRED, "This pickup location requires TWIC Access.")}</span><label className="text-muted">{arrivalTwicRequire}</label></div>:""}
                        <span className="details-head">{CommonUtil.getMessageText(Constants.ACTIVATION_INSTR, "Activation Instructions")}</span><label className="text-muted">{activeInstruction}</label>
                        <div className="row m-0" style={{display:(isDepVisible?"":"none")}}>
                            <div className="col-sm-8 col-12">
                                <label className="col-sm-12 col-form-label" style={{marginBottom:"0px"}}>{CommonUtil.getMessageText(Constants.DEPARTRE_PARK_LOT,"Departure Parking Lot")}</label>
                                    <div className="col-sm-12 col-12">
                                        <SingleSelectComp dataSource={parkingLots} selectedValue={selDepartureObj}
                                                          handleChange={onChangeDepartureLot} width={"100%"} value={"parkingLotId"} label={"parkingLotName"}
                                        />
                                        &nbsp;

                                    </div>
                            </div>
                            <div className="col-sm-4 col-12">
                                <label className="col-sm-12" style={{visibility:"hidden",marginBottom:"0px"}}>{CommonUtil.getMessageText(Constants.DEPARTRE_PARK_LOT,"Departure Parking Lot")}</label>
                                <button type="submit" className="btn btn-primary waves-effect waves-light" onClick={onClickUpdateAddr}>Update</button>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 col-12 p-0">
                        <iframe width="100%" height="400" loading="lazy" allowFullScreen referrerPolicy="no-referrer-when-downgrade" id={"depatureIframe"}></iframe>
                        <br />
                    </div>
                </div>
            </section>
            <section className="p-0">
                <div className="row m-0">
                    <div className="col-sm-6 col-12 p-4">
                        <h6>{CommonUtil.getMessageText(Constants.DEACTIVATION, "Deactivation")}</h6>
                        <span className="details-head">{CommonUtil.getMessageText(Constants.ENDS_AT, "Ends At")}</span><label>{deActivationDate}</label>     
                        <span className="details-head">{CommonUtil.getMessageText(Constants.ADDRESS, "Address")}</span><label>{arrival}</label>                   
                        <span className="details-head">{CommonUtil.getMessageText(Constants.CONTACT_NAME_AT, "Contact Name")}</span><label>{dactivationName}</label>
                        <span className="details-head">{CommonUtil.getMessageText(Constants.CONTACT_NUMBER_AT, "Contact Number")}</span><label>{dactivationNumber}</label>  
                        <span className="details-head">{CommonUtil.getMessageText(Constants.PARTKING_LOT_ACCESS, "Parking Lot Access Instructions")}</span><label>{arrivalIns}</label>
                        {arrivalTwicRequire?<div><span className="details-head" style={{fontWeight:"bold"}}>{CommonUtil.getMessageText(Constants.TWIC_ACCESS_REQUIRED, "This pickup location requires TWIC Access.")}</span><label className="text-muted">{departTwicRequire}</label></div>:""}
                        <span className="details-head">{CommonUtil.getMessageText(Constants.DEACTIVATION_INSTR, "Deactivation Instructions")}</span><label className="text-muted" style={{ wordBreak: "break-all" }}>{deActiveInstruction}</label>
                        <div className="row m-0" style={{display:(isDepVisible?"":"none")}}>
                            <div className="col-sm-8 col-12">
                                <label className="col-sm-12 col-form-label" style={{marginBottom:"0px"}}>{CommonUtil.getMessageText(Constants.ARRIVAL_PARK_LOT,"Arrival Parking Lot")}</label>
                                <div className="col-sm-12 col-12">
                                    <SingleSelectComp dataSource={parkingLots} selectedValue={selArrivalObj}
                                                      handleChange={onChangeArrivalLot} width={"100%"} value={"parkingLotId"} label={"parkingLotName"}
                                    />
                                    &nbsp;

                                </div>
                            </div>
                            <div className="col-sm-4 col-12">
                                <label className="col-sm-12" style={{visibility:"hidden",marginBottom:"0px"}}>{CommonUtil.getMessageText(Constants.DEPARTRE_PARK_LOT,"Departure Parking Lot")}</label>
                                <button type="submit" className="btn btn-primary waves-effect waves-light" onClick={onClickUpdateArrivalAddr}>Update</button>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 col-12 p-0">
                        <iframe width="100%" height="400" id={"iFRAME"} loading="lazy" allowFullScreen referrerPolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                </div>
            </section>
            {
                isLiabilityPaidByRenter && <section className="p-0">
                <div className="row m-0">
                    <div className="col-sm-12 col-12 p-3">
                        <h6>{CommonUtil.getMessageText(Constants.LIABILITY_INSURANCE_DETAILS, 'Liability Insurance Details')} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{liabilityDownload?<span style={{color:"blue",cursor:"pointer",textDecoration:"underline"}}
                                                                                                               onClick={onClickLiabilityDownload}>{CommonUtil.getMessageText(Constants.DOWNLOAD_FILE, 'Download File')}</span>:""}</h6>

                    </div>
                    <div className="col-sm-12 col-12 p-1 text-center">
                        <h6>{CommonUtil.getMessageText(Constants.INSURANCE_PAID_BY_OWNER, 'Insurance Paid By Owner')} - {isLiabilityPaidByOwner}</h6>
                    </div>
                </div>
                <div className="row m-0" style={{paddingLeft:"15px"}}>
                    <div className="col-md-6 col-lg-3 my-2" >
                        <p className="my-0">{CommonUtil.getMessageText(Constants.INSUR_POLICY_ID, 'Policy Id')}:</p>
                        <strong>{lPolicyName}</strong>
                    </div>
                    <div className="col-md-6 col-lg-3 my-2" >
                        <p className="my-0">{CommonUtil.getMessageText(Constants.INSURENCE_NAME, "Insurance Company Name")}:</p>
                        <strong>{lPolicyCompanyName}</strong>
                    </div>
                    <div className="col-md-6 col-lg-3 my-2" >
                        <p className="my-0">{CommonUtil.getMessageText(Constants.INSURANCE_PLAN_TYPE, "Insurance Type")}:</p>
                        <strong>{lPolicyType}</strong>
                    </div>
                    <div className="col-md-6 col-lg-3 my-2" >
                        <p className="my-0">{CommonUtil.getMessageText(Constants.RATE_PER_DAY, "Rate Per Day")}:</p>
                        <strong>{lPolicyRatePerDay || ""}</strong>
                    </div>
                    <div className="col-md-6 col-lg-3 my-2" >
                        <p className="my-0">{CommonUtil.getMessageText(Constants.DEDUCTABLE, "Deductible")}:</p>
                        <strong>{lPolicyDeductiable || ""}</strong>
                    </div>
                    <div className="col-md-6 col-lg-3 my-2" >
                        <p className="my-0">{CommonUtil.getMessageText(Constants.LIMIT, "Limit")}:</p>
                        <strong>{lPolicyLimit || ""}</strong>
                    </div>
                    <div className="col-md-6 col-lg-3 my-2" >
                        <p className="my-0">{CommonUtil.getMessageText(Constants.ESTIMATED_LOW_VALUE, "Estimated Lower Value")}:</p>
                        <strong>{lPolicyLowerValue || ""}</strong>
                    </div>
                    <div className="col-md-6 col-lg-3 my-2" >
                        <p className="my-0">{CommonUtil.getMessageText(Constants.ESTIMATED_HIGH_VALUE, "Estimated Upper Value")}:</p>
                        <strong>{lPolicyUpperValue || ""}</strong>
                    </div>

                </div>

            </section>
            }
            

            
            {
                isPhysicalDamagePaidByRenter &&<section className="p-0">
                <div className="row m-0">
                    <div className="col-sm-12 col-12 p-3">
                        <h6>{CommonUtil.getMessageText(Constants.PHYSICAL_DAMAGE_INSURANCE_PLAN_DETAILS, 'Physical Damage Insurance Details')} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{physicalDownload?<span style={{color:"blue",cursor:"pointer",textDecoration:"underline"}}
                                    onClick={onClickPhysicalDamage}>{CommonUtil.getMessageText(Constants.DOWNLOAD_FILE, 'Download File')}</span>:""}</h6>
                    </div>
                    <div className="col-sm-12 col-12 p-1 text-center">
                        <h6>{CommonUtil.getMessageText(Constants.INSURANCE_PAID_BY_OWNER, 'Insurance Paid By Owner')} - {isPhysicalPaidByOwner} </h6>
                    </div>
                </div>
                <div className="row m-0" style={{paddingLeft:"15px"}}>
                    <div className="col-md-6 col-lg-3 my-2" >
                        <p className="my-0">{CommonUtil.getMessageText(Constants.INSUR_POLICY_ID, 'Policy Id')}:</p>
                        <strong>{phyPolicyName}</strong>
                    </div>
                    <div className="col-md-6 col-lg-3 my-2" >
                        <p className="my-0">{CommonUtil.getMessageText(Constants.INSURENCE_NAME, "Insurance Company Name")}:</p>
                        <strong>{phyPolicyCompanyName}</strong>
                    </div>
                    <div className="col-md-6 col-lg-3 my-2" >
                        <p className="my-0">{CommonUtil.getMessageText(Constants.INSURANCE_PLAN_TYPE, "Insurance Type")}:</p>
                        <strong>{phyPolicyType}</strong>
                    </div>
                    <div className="col-md-6 col-lg-3 my-2" >
                        <p className="my-0">{CommonUtil.getMessageText(Constants.RATE_PER_DAY, "Rate Per Day")}:</p>
                        <strong>{phyPolicyRatePerDay || ""}</strong>
                    </div>
                    <div className="col-md-6 col-lg-3 my-2" >
                        <p className="my-0">{CommonUtil.getMessageText(Constants.DEDUCTABLE, "Deductible")}:</p>
                        <strong>{phyPolicyDeductiable || ""}</strong>
                    </div>
                    <div className="col-md-6 col-lg-3 my-2" >
                        <p className="my-0">{CommonUtil.getMessageText(Constants.LIMIT, "Limit")}:</p>
                        <strong>{phyPolicyLimit || ""}</strong>
                    </div>
                    <div className="col-md-6 col-lg-3 my-2" >
                        <p className="my-0">{CommonUtil.getMessageText(Constants.ESTIMATED_LOW_VALUE, "Estimated Lower Value")}:</p>
                        <strong>{phyPolicyLowerValue || ""}</strong>
                    </div>
                    <div className="col-md-6 col-lg-3 my-2" >
                        <p className="my-0">{CommonUtil.getMessageText(Constants.ESTIMATED_HIGH_VALUE, "Estimated Upper Value")}:</p>
                        <strong>{phyPolicyUpperValue || ""}</strong>
                    </div>

                </div>

            </section>
            }
            

            <section>
                <div className="row">
                    <div className="col-sm-12">
                        <h6>{CommonUtil.getMessageText(Constants.ESTIMATE, "Estimate")}</h6>
                        <table className=" w-100 general-three-box" style={{ display: (availType == CommonUtil.EQUIP_AVAIL_RENTAL_TYPE ? "" : "none") }}>
                            <tbody>
                                <tr>
                                    <td className="p-2"><img src="../../images/DailyRate.png" width="50px" /></td>
                                    <td className="p-2"><label className="m-0">{CommonUtil.getMessageText(Constants.DURATION, "Duration")}</label><span
                                        className="details-head">{days} {CommonUtil.getMessageText(Constants.DAYS, "Days")} * {defPrice} {(rateObj.ratePerDay).toFixed(2)}/{CommonUtil.getMessageText(Constants.DAY, "DAY")}</span></td>
                                    <td className="p-2 text-right"><h6>{defPrice} {durationPrice}</h6></td>
                                </tr>
                                <tr>
                                    <td className="p-2"><img src="../../images/DailyRate.png" width="50px" /></td>
                                    <td className="p-2"><label className="m-0">{CommonUtil.getMessageText(Constants.DISTANCE, "Distance")}</label><span
                                        className="details-head">{days} {CommonUtil.getMessageText(Constants.DAYS, "Days")} * {defPrice} {eDistance} {mesurement}/{CommonUtil.getMessageText(Constants.DAY, "DAY")} * {defPrice} {distanceRate} /{mesurement}</span></td>
                                    <td className="p-2 text-right"><h6>{defPrice} {distancePrice}</h6></td>
                                </tr>
                                <tr style={{ display: (equipType == CommonUtil.REEFER_EQUIP_TYPE ? "" : "none") }}>
                                    <td className="p-2"><img src="../../images/DailyRate.png" width="50px" /></td>
                                    <td className="p-2"><label className="m-0">{CommonUtil.getMessageText(Constants.ENGINE_UNIT_USAGE, "Engine usage unit")}</label><span
                                        className="details-head">{days} {CommonUtil.getMessageText(Constants.DAYS, "Days")} * {defPrice} {estimatedEngineHours} {CommonUtil.getMessageText(Constants.HOURS_DAY, "hours/day")} * {defPrice} {engineRate} /{CommonUtil.getMessageText(Constants.HOUR, "hour")}</span></td>
                                    <td className="p-2 text-right"><h6>{defPrice} {enginePrice}</h6></td>
                                </tr>
                                {CommonUtil.ORGANIZATION_TYPE === CommonUtil.ORG_TYPE_RENTER ?
                                <tr style={{ display: Math.abs(Number(insuranceAmount)) > 0?'':'none'}}> 
                                    <td className="p-2"><img src="../../images/DailyRate.png" width="50px" /></td>
                                    <td className="p-2"><label className="m-0">Insurance amount </label>
                                        <span className="details-head"><small style={{display:((isLiabilityPaidByRenter) && liabilityInsuracePerDayAmount)?"":"none"}}>({totalDays} {CommonUtil.getMessageText(Constants.DAYS, "Days")} * {liabilityInsuracePerDayAmount})</small> <small style={{display:(isPhysicalDamagePaidByRenter && physicalDamagePerDayAmount)?"":"none"}}>{isLiabilityPaidByRenter && isPhysicalDamagePaidByRenter ? "+" : ""}&nbsp;({totalDays} {CommonUtil.getMessageText(Constants.DAYS, "Days")} * {physicalDamagePerDayAmount})</small></span></td>
                                    <td className="p-2 text-right"><h6>{defPrice} {insuranceAmount?insuranceAmount:0}</h6></td>
                                </tr>:
                                <tr style={{ display: Math.abs(Number(insuranceAmount)) > 0?'':'none'}}> 
                                    <td className="p-2"><img src="../../images/DailyRate.png" width="50px" /></td>
                                    <td className="p-2"><label className="m-0">Insurance amount </label>
                                        <span className="details-head"><small style={{display:((liabilityPaidByOwner) && liabilityInsuracePerDayAmount)?"":"none"}}>({totalDays} {CommonUtil.getMessageText(Constants.DAYS, "Days")} * {liabilityInsuracePerDayAmount})</small> <small style={{display:(physicalDamagePaidByOwner && physicalDamagePerDayAmount)?"":"none"}}>{liabilityPaidByOwner && physicalDamagePaidByOwner ? "+" : ""}&nbsp;({totalDays} {CommonUtil.getMessageText(Constants.DAYS, "Days")} * {physicalDamagePerDayAmount})</small></span></td>
                                    <td className="p-2 text-right"><h6>{defPrice} {insuranceAmount?insuranceAmount:0}</h6></td>
                                </tr>}
                                <tr style={{ display: Math.abs(Number(insuranceAmount)) > 0?'':'none'}}>
                                    <td className="p-2"><img src="../../images/DailyRate.png" width="50px" /></td>
                                    <td className="p-2"><label className="m-0">Administration fee </label>
                                    <span className="details-head">{`flat rate of ${defPrice} ${String(defPrice).toUpperCase().indexOf("USD") !==-1 ? usInsuranceRate : canadaInsuranceRate} `}</span></td>
                                    <td className="p-2 text-right"><h6>{defPrice} {String(defPrice).toUpperCase().indexOf("USD") !==-1 ? usInsuranceRate : canadaInsuranceRate}</h6></td>
                                </tr>
                                <tr>
                                    <td className="p-2"><img src="../../images/DailyRate.png" width="50px" /></td>
                                    <td className="p-2"><label className="m-0">{CommonUtil.getMessageText(Constants.SERVICE_FEE, "Service Fee")}</label><span
                                        className="details-head">{cServiceFeeText}</span></td>
                                    <td className="p-2 text-right"><h6>{defPrice} {signforServiceFee}{Number(cServiceFee)}</h6></td>

                                </tr>
                            </tbody>
                        </table>
                        <table className=" w-100 general-three-box" style={{ display: (availType == CommonUtil.EQUIP_AVAIL_REPOSITION_TYPE ? "" : "none") }}>
                            <tbody>
                                <tr>
                                    <td className="p-2"><img src="../../images/DailyRate.png" width="50px" /></td>
                                    <td className="p-2"><label className="m-0">{CommonUtil.getMessageText(Constants.PAID_PRICE_CARRIER, "Paid price to carrier making the move")}</label><span
                                        className="details-head">{noOfDays}&nbsp;{CommonUtil.getMessageText(Constants.DAYS_REPOSITION, "day(s) for repositioning")}</span></td>
                                    <td className="p-2 text-right"><h6>{defPrice} {(movePrice)}</h6></td>
                                </tr>
                                <tr>
                                    <td className="p-2"><img src="../../images/DailyRate.png" width="50px" /></td>
                                    <td className="p-2"><label className="m-0">{CommonUtil.getMessageText(Constants.EXCESS_DAILY_TOTAL, "Excess daily total")}</label><span
                                        className="details-head">{rextradays} {CommonUtil.getMessageText(Constants.DAYS, "day(s)")} X &nbsp;{defPrice} {dailyRate}/{CommonUtil.getMessageText(Constants.DAY, "day")}</span></td>
                                    <td className="p-2 text-right"><h6>{defPrice} {rdurationPrice}</h6></td>
                                </tr>
                                {CommonUtil.ORGANIZATION_TYPE === CommonUtil.ORG_TYPE_RENTER ?
                                    <tr style={{display:((isLiabilityPaidByRenter || isPhysicalDamagePaidByRenter) || isPhysicalDamagePaidByRenter)? Math.abs(Number(insuranceAmount)) > 0 ? "": "none":"none"}}>
                                        <td className="p-2"><img src="../../images/DailyRate.png" width="50px" /></td>
                                        <td className="p-2"><label className="m-0">Insurance amount </label>
                                            <span className="details-head"><small style={{display:((isLiabilityPaidByRenter) && liabilityInsuracePerDayAmount)?"":"none"}}>({totalDays} {CommonUtil.getMessageText(Constants.DAYS, "Days")} * {liabilityInsuracePerDayAmount})</small> <small style={{display:(isPhysicalDamagePaidByRenter && physicalDamagePerDayAmount)?"":"none"}}>{isLiabilityPaidByRenter && isPhysicalDamagePaidByRenter ? "+" : ""}&nbsp;({totalDays} {CommonUtil.getMessageText(Constants.DAYS, "Days")} * {physicalDamagePerDayAmount})</small></span></td>
                                        <td className="p-2 text-right"><h6 style={{display:insuranceAmount?"":""}}>{defPrice} -{insuranceAmount?insuranceAmount:0}</h6></td>
                                    </tr>
                                    :
                                    <tr style={{display:((liabilityPaidByOwner||physicalDamagePaidByOwner) || isPhysicalDamagePaidByRenter)? Math.abs(Number(insuranceAmount)) > 0 ? "": "none":"none"}}>
                                        <td className="p-2"><img src="../../images/DailyRate.png" width="50px" /></td>
                                        <td className="p-2"><label className="m-0">Insurance amount</label>                                        
                                            <span className="details-head"><small style={{display:(liabilityPaidByOwner && liabilityInsuracePerDayAmount)?"":"none"}}>({totalDays} {CommonUtil.getMessageText(Constants.DAYS, "Days")} * {liabilityInsuracePerDayAmount})</small> <small style={{display:(physicalDamagePaidByOwner && physicalDamagePerDayAmount)?"":"none"}}>{liabilityPaidByOwner && physicalDamagePaidByOwner ? "+" : ""}&nbsp;({totalDays} {CommonUtil.getMessageText(Constants.DAYS, "Days")} * {physicalDamagePerDayAmount})</small></span></td>
                                        <td className="p-2 text-right"><h6 style={{display:insuranceAmount?"":""}}>{defPrice} {liabilityPaidByOwner || physicalDamagePaidByOwner ? "" : "-"}{insuranceAmount?insuranceAmount:0}</h6></td>
                                    </tr>
                                }
                                <tr style={{display:(isLiabilityPaidByRenter || isPhysicalDamagePaidByRenter || liabilityPaidByOwner||physicalDamagePaidByOwner)? Math.abs(Number(insuranceAmount)) > 0 ?"":"none":"none"}}>
                                    <td className="p-2"><img src="../../images/DailyRate.png" width="50px" /></td>
                                    <td className="p-2"><label className="m-0">Administration fee </label>                                
                                    <span className="details-head">{`flat rate of ${defPrice} ${String(defPrice).toUpperCase().indexOf("USD") !==-1 ? usInsuranceRate : canadaInsuranceRate}`}</span></td>
                                    <td className="p-2 text-right"><h6>{defPrice} {CommonUtil.ORGANIZATION_TYPE === CommonUtil.ORG_TYPE_RENTER ?"-":""}{String(defPrice).toUpperCase().indexOf("USD") !==-1 ? usInsuranceRate : canadaInsuranceRate}</h6></td>
                                </tr>
                                {/* <tr >
                                    <td className="p-2"><img src="../../images/DailyRate.png" width="50px" /></td>
                                    <td className="p-2"><label className="m-0">Platform Fee </label>                                    
                                    </td>
                                    <td className="p-2 text-right"><h6>{defPrice} {platformFees}</h6></td>
                                </tr> */}

                            </tbody>
                        </table>
                        <table className="w-100 general-three-box general-three-box"
                            style={{ height: "40px", background: "#e4e4e4", display: (availType == CommonUtil.EQUIP_AVAIL_RENTAL_TYPE ? "" : "none") }}>
                            <thead>
                                <tr>
                                    <th><h6>{CommonUtil.getMessageText(Constants.TOTAL_BEFORE_TAXES, "Total Before Taxes")} :</h6></th>
                                    <th className={"text-right"}><h6>{defPrice} {tottalRentalPrice}</h6></th>
                                </tr>
                            </thead>
                        </table>
                        <table className="w-100 general-three-box general-three-box"
                            style={{ height: "40px", background: "#e4e4e4", display: (availType == CommonUtil.EQUIP_AVAIL_REPOSITION_TYPE ? "" : "none") }}>
                            <thead>
                                <tr>
                                    <th style={{ paddingLeft: "5px" }}><h6>{CommonUtil.getMessageText(Constants.TOTAL_BEFORE_TAXES, "Total Before Taxes")} :</h6></th>
                                    <th style={{ paddingRight: "5px" }} className={"text-right"}><h6>{defPrice} {totalRepositionPrice}</h6></th>
                                </tr>
                            </thead>
                        </table>
                    </div>
                </div>
            </section>

            <section>
                <div className="row">
                    <div className="col-sm-12">
                        <h6>Extend Reservation</h6>
                        <div className="row" style={{paddingTop:"10px"}}>
                            <div className="col-6 col-sm-3 col-md-3 col-lg-2">
                                <div className="form-group row">
                                    <label className="col-sm-12 col-form-label m-0">{CommonUtil.getMessageText(Constants.FROM_DATE, "From Date")}</label>
                                    <div className="col-sm-12">
                                        <div className="date-i">
                                            {/* <Flatpickr placeholder="From Date" ref={fromRef} options={dateOptions} onChange={onChangeFromDate}
                                                className="form-control" disabled={true} /> */}
                                            <input className="form-control" type='text' value={CommonUtil.getDateFormatForDatePicker(reservationStartDate)} disabled={true}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-6 col-sm-3 col-md-3 col-lg-2">
                                <div className="form-group row">
                                    <label className="col-sm-12 col-form-label m-0">{CommonUtil.getMessageText(Constants.TO_DATE, "To Date")}</label>
                                    <div className="col-sm-12">
                                        <div className="date-i">
                                            {/* <Flatpickr placeholder="To Date" ref={toRef} options={dateOptions} onChange={onChangeToDate}
                                                className="form-control" disabled={true} /> */}
                                            <input className="form-control" type='text' value={CommonUtil.getDateFormatForDatePicker(reservationEndDate)} disabled={true}/>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-12 col-sm-3 col-md-3 col-lg-2">
                                <div className="form-group row">
                                    <label className="col-sm-12 col-form-label m-0 d-none d-sm-block">&nbsp;</label>
                                    <div className="col-sm-12">
                                        <div className="date-i">
                                            <button type="button" disabled={isDateChanged?false:true}
                                                className="btn btn-warning waves-effect waves-light w-100" onClick={onClickEdit}>{CommonUtil.getMessageText(Constants.EXTEND, "Extend")}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>                          
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <div className="row">
                    <div className="col-sm-12">
                        <h6>Reservation History Details</h6>
                    </div>
                </div>
                <div className="row" style={{paddingTop:"10px"}}>
                    <Costestimatehistory update={update} insuranceAmount={liabilityInsuracePerDayAmount||physicalDamagePerDayAmount} 
                    currency={defaultCurrency} lastHubodometerReading= {updateLastHubodometerReading} lastEngineHourReading= {updateLastEngineHourReading}/>
                </div>                
            </section>

            <section>
                <div className="row">
                    <div className="col-sm-12">
                        <h6>Driver Details</h6>
                    </div>
                </div>
                <div className="row" style={{paddingTop:"10px"}}>
                    <div className="col-4">
                        <div className="col-12">
                            <span>Driver Name</span>&nbsp;&nbsp;
                        </div>
                        <div className="col-12">
                            <label>{driverName}</label>
                        </div>
                    </div>
                    <div className="col-4">
                        <div className="col-12">
                            <span>Mobile Number</span>&nbsp;&nbsp;
                        </div>
                        <div className="col-12">
                            <label>{driverPhoneNumber}</label>
                        </div>
                    </div>
                    <div className="col-4">
                        <div className="col-12">
                            <span>Email</span>&nbsp;&nbsp;
                        </div>
                        <div className="col-12">
                            <label>{driverEmailId}</label>
                        </div>
                    </div>
                    <div className="col-4">
                        <div className="col-12">
                            <span>Truck Number</span>&nbsp;&nbsp;
                        </div>
                        <div className="col-12">
                            <label>{truckNumber}</label>
                        </div>
                    </div>
                    <div className="col-4">
                        <div className="col-12">
                            <span>Carrier Name</span>&nbsp;&nbsp;
                        </div>
                        <div className="col-12">
                            <label>{carrierName}</label>
                        </div>
                    </div>
                    <div className="col-4">
                        <div className="col-12">
                            <span>US DOT Number</span>&nbsp;&nbsp;
                        </div>
                        <div className="col-12">
                            <label>{usdot}</label>
                        </div>
                    </div>
                </div>
            </section>
            <section style={{ display: "none" }}>
                <div className="row">
                    <div className="col-sm-12">
                        <h6>Distance Control</h6>
                        <p className="m-0">No Telemetry Data Available</p>
                    </div>
                </div>
            </section>            
           <MonthlyInvoices reservationId={CommonUtil.SELECT_RESERVATION_ID} showfor={"invoice"}/>
           <MonthlyInvoices reservationId={CommonUtil.SELECT_RESERVATION_ID} showfor={"statement"}/>
            <section style={{display:(isInvoice?"":"none")}}>
                <div className="row">
                    <div className="col-sm-12"><h6>{CommonUtil.getMessageText(Constants.INVOICES, "Invoices")}</h6>
                        <p>{CommonUtil.getMessageText(Constants.ALL_YOUR_INVOICES, "All your invoices will be added in this section")}</p></div>
                    <div className="col-sm-12">

                        <div className="row">
                            <div className="ag-theme-alpine gridHeight"
                                 style={{height: 'calc(100vh - 450px)', width: '100%', paddingLeft: '8px', paddingRight: '5px'}} >
                                <AGGrid id="OrgInvoiceGrid" cols={invoiceColumnDefs}
                                        rows={invoiceData} pageSize={500} headerHeight={CommonUtil.GRID_HEADER_HEIGHT} rowHeight={CommonUtil.GRID_ROW_HEIGHT}
                                        ref={gridRef} addNew={false}  />
                            </div>
                        </div>

                        <table className="equipment-invoice-table table-striped table-hover tables" style={{display:"none"}}>

                            <thead>
                                <tr>
                                    <th scope="col">{CommonUtil.getMessageText(Constants.DATE, "Date")}</th>
                                    <th scope="col">{CommonUtil.getMessageText(Constants.INVOICE, "Invoice")}</th>
                                    <th scope="col">{CommonUtil.getMessageText(Constants.AMOUNT, "Amount")}</th>
                                    <th scope="col">{CommonUtil.getMessageText(Constants.TYPE, "Type")}</th>
                                    <th scope="col">{CommonUtil.getMessageText(Constants.STATUS, "Status")}</th>
                                    <th scope="col">{CommonUtil.getMessageText(Constants.ACTION, "Action")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td data-label="Date">10 AUG 2022</td>
                                    <td data-label="Invoice">254542124</td>
                                    <td data-label="Amount">$ 100</td>
                                    <td data-label="Type">Rental</td>
                                    <td data-label="Status" className="text-danger"><strong>Payment Rejected</strong></td>
                                    <td data-label="Action"><a><i className="fa fa-file-pdf-o"></i> <strong>See
                                        Invoice</strong></a></td>
                                </tr>
                                <tr>
                                    <td data-label="Date">10 AUG 2022</td>
                                    <td data-label="Invoice">254542124</td>
                                    <td data-label="Amount">$ 100</td>
                                    <td data-label="Type">Rental</td>
                                    <td data-label="Status" className="text-danger"><strong>Preauthortization
                                        Rejected</strong></td>
                                    <td data-label="Action"><a><i className="fa fa-file-pdf-o"></i> <strong>See
                                        Invoice</strong></a></td>
                                </tr>
                                <tr>
                                    <td data-label="Date">10 AUG 2022</td>
                                    <td data-label="Invoice">254542124</td>
                                    <td data-label="Amount">$ 100</td>
                                    <td data-label="Type">Rental</td>
                                    <td data-label="Status" className="text-warning"><strong>Preauthortization
                                        Success</strong></td>
                                    <td data-label="Action"><a><i className="fa fa-file-pdf-o"></i> <strong>See
                                        Invoice</strong></a></td>
                                </tr>
                                <tr>
                                    <td data-label="Date">10 AUG 2022</td>
                                    <td data-label="Invoice">254542124</td>
                                    <td data-label="Amount">$ 100</td>
                                    <td data-label="Type">Rental</td>
                                    <td data-label="Status" className="text-primary"><strong>Payment Succeeded</strong></td>
                                    <td data-label="Action"><a><i className="fa fa-file-pdf-o"></i> <strong>See
                                        Invoice</strong></a></td>
                                </tr>


                            </tbody>
                        </table>


                    </div>
                </div>
            </section>
            <section style={{display:(isStatementOfIncome?"":"none")}}>
                <div className="row">
                    <div className="col-sm-12"><h6>{CommonUtil.getMessageText(Constants.STATEMENT_OF_INCOME, "Statement of Income")}</h6>
                        <p>{CommonUtil.getMessageText(Constants.STATEMENT_OF_INCOME_PAID, "The statements will be updated here at any time, when the reservation is completed.")}</p></div>
                    <div className="col-sm-12">

                        <div className="row">
                            <div className="ag-theme-alpine gridHeight"
                                 style={{height: 'calc(100vh - 450px)', width: '100%', paddingLeft: '8px', paddingRight: '5px'}} >
                                <AGGrid id="OrgInvoiceStatementGrid" cols={invoiceStatementColumnDefs}
                                        rows={invoiceStatementData} pageSize={500} headerHeight={CommonUtil.GRID_HEADER_HEIGHT} rowHeight={CommonUtil.GRID_ROW_HEIGHT}
                                         addNew={false}  />
                            </div>
                        </div>

                        <table className="equipment-invoice-table table-striped table-hover tables" style={{display:"none"}}>

                            <thead>
                                <tr>
                                    <th scope="col">Date</th>
                                    <th scope="col">Statement</th>
                                    <th scope="col">Amount Returned</th>
                                    <th scope="col">Status</th>
                                    <th scope="col">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td data-label="Date">10 AUG 2022</td>
                                    <td data-label="Statement">E-Q5TYE-3</td>
                                    <td data-label="Amount Returned">$ 339.17</td>
                                    <td data-label="Status" className="text-primary"><strong>Payable on 15th of current
                                        month</strong></td>
                                    <td data-label="Action"><a><i className="fa fa-file-pdf-o"></i> <strong>See
                                        Invoice</strong></a></td>
                                </tr>
                                <tr>
                                    <td data-label="Date">10 AUG 2022</td>
                                    <td data-label="Statement">E-Q5TYE-3</td>
                                    <td data-label="Amount Returned">$ 339.17</td>
                                    <td data-label="Status" className="text-primary"><strong>Payable on 15th of current
                                        month</strong></td>
                                    <td data-label="Action"><a><i className="fa fa-file-pdf-o"></i> <strong>See
                                        Invoice</strong></a></td>
                                </tr>
                                <tr>
                                    <td data-label="Date">10 AUG 2022</td>
                                    <td data-label="Statement">E-Q5TYE-3</td>
                                    <td data-label="Amount Returned">$ 339.17</td>
                                    <td data-label="Status" className="text-primary"><strong>Payable on 15th of current
                                        month</strong></td>
                                    <td data-label="Action"><a><i className="fa fa-file-pdf-o"></i> <strong>See
                                        Invoice</strong></a></td>
                                </tr>
                                <tr>
                                    <td data-label="Date">10 AUG 2022</td>
                                    <td data-label="Statement">E-Q5TYE-3</td>
                                    <td data-label="Amount Returned">$ 339.17</td>
                                    <td data-label="Status" className="text-primary"><strong>Payable on 15th of current
                                        month</strong></td>
                                    <td data-label="Action"><a><i className="fa fa-file-pdf-o"></i> <strong>See
                                        Invoice</strong></a></td>
                                </tr>


                            </tbody>
                        </table>


                    </div>
                </div>
            </section>

            <section>
                <div className="row">
                    <div className="col-sm-12">
                        <h6>Proof of Delivery Documents</h6>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-4 col-12" ref={dropInsurance}
                    style={{display:(CommonUtil.ORGANIZATION_TYPE == CommonUtil.ORG_TYPE_RENTER)?"none":""}}>

                        <div className="col-sm-12 cus-input-file-text financial-browse-button"
                             onClick={() => { uploadFinanceDocuments() }}>
                            {CommonUtil.getMessageText(Constants.DRAG_DRAP_FILES, "Drag and Drop files here Or")} &nbsp;
                            <button type="button" className="btn btn-outline-primary financial-button"
                                    disabled={(CommonUtil.ORGANIZATION_TYPE == CommonUtil.ORG_TYPE_RENTER)?true:false}>{CommonUtil.getMessageText(Constants.BROWSE_FILE, "Browse File")}</button>
                        </div>
                        <div className="col-sm-12 cus-input-file p-0"></div>

                    </div>
                    <div className="col-md-7 col-12 mt-2">
                        <div className="form-group row">

                            <div className="col-sm-12">
                                <div className="gallery flex documents-gallary">
                                    {equipDocs.map((item: any, idx: number) => {
                                        if (item && (item.documentType == CommonUtil.POD_DOC_TYPE)) {
                                            var list = (
                                                <div className="img-fluid  document-box" key={idx}>
                                                    <div style={{ display: "flex", alignItems: "center" }}>
                                                        <img src="../../images/document.png" alt="" className={"financial-download-image"}
                                                             onClick={() => { onDownloadFP2506Doc(item.fullFilePath) }} />
                                                        <span style={{ paddingLeft: "10px", paddingRight: "10px"}}>
                                                                            <a><i style={{ color: "red", cursor: "pointer" }} className={`${(CommonUtil.ORGANIZATION_TYPE == CommonUtil.ORG_TYPE_RENTER)?"":"remove-btn fa fa-remove"}`}
                                                                                  onClick={() => { onRemoveFP2506Doc(item.attachementId) }} ></i></a>
                                                                        </span>
                                                        <span style={{ display: "none" }}><a><i className="fa fa-download" onClick={() => { onDownloadFP2506Doc(item.fullFilePath) }}></i></a></span>
                                                    </div>
                                                    <label style={{maxWidth: "100px", wordWrap: "break-word",textAlign:"center"}} className={"financial-download-label"} onClick={() => { onDownloadFP2506Doc(item.fullFilePath) }}>{item.documentName}</label>
                                                </div>
                                            )
                                            return list
                                        }
                                    })}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* -----------Customer Contract Details------------ */}

               <div className="col-6 col-sm-12" style={{display:(isGeneric == "Y" && !isVin)?"none":""}}>
             
               <section>
       <div className="row">
           <div className="col-sm-12">
               <h6>{CommonUtil.getMessageText(Constants.CUSTOMER_CONTRACT_DETAILS, "Customer Contract Details")}</h6>                              
           </div>                         
       </div> 
       <div className="row mt-4">
           <div className='col-lg-4'>
             <div className="form-group row">
               <div className="col-sm-12" style={{display:"flex"}}>
                   <label className="col-sm-4 col-form-label mb-0">{CommonUtil.getMessageText(Constants.CUSTOMER_PO,"Customer PO #")}                                     
                 </label>
                <input type='text' className="form-control" value={custPo} onChange={(e: any) => { setCustPo(e.currentTarget.value) }}
                disabled={(CommonUtil.ORGANIZATION_TYPE == CommonUtil.ORG_TYPE_RENTER)?true : false} />
                                                     
               </div>
           </div>
           <div className="form-group row">
               <div className="col-sm-12" style={{display:"flex"}}>
                   <label className="col-sm-4 col-form-label">{CommonUtil.getMessageText(Constants.RENTER_PO,"Renter PO #")}                                     
                   </label>
                <input type='text' className="form-control" value={renterPo} onChange={(e: any) => { setRenterPo(e.currentTarget.value) }}
                                        disabled={(CommonUtil.ORGANIZATION_TYPE == CommonUtil.ORG_TYPE_OWNER) ? true : false} />
                                    
               </div>
           </div>
       </div>
       <div className='col-lg-8'>
           <div className="col-md-6 col-12" ref={dropInsurance}>
               {/* <div className="col-sm-12 cus-input-file-text"
                   >
                           {CommonUtil.getMessageText(Constants.DRAG_DRAP_FILES, "Drag and Drop files here Or")} &nbsp;
                           <button type="button" className="btn btn-outline-primary financial-button"  onClick={() => { uploadContactDetails() }}
                           //   disabled={(CommonUtil.ORGANIZATION_TYPE == CommonUtil.ORG_TYPE_RENTER) ? true : false}
                         >{CommonUtil.getMessageText(Constants.BROWSE_FILE, "Browse File")}</button>
               </div> */}

               <div className="col-sm-12 cus-input-file-text financial-browse-button"
                             onClick={() => {uploadContactDetails() }}>
                            {CommonUtil.getMessageText(Constants.DRAG_DRAP_FILES, "Drag and Drop files here Or")} &nbsp;
                            <button type="button" className="btn btn-outline-primary financial-button"
                                   >{CommonUtil.getMessageText(Constants.BROWSE_FILE, "Browse File")}</button>
                        </div>
                <div className="col-sm-12 cus-input-file p-0"></div>

                                </div>
                                <div className="col-md-7 col-12 mt-2">
                        <div className="form-group row">

                            <div className="col-sm-12">
                                <div className="gallery flex documents-gallary">
                                    {equipDocs.map((item: any, idx: number) => {
                                        if (item && (item.documentType == CommonUtil.CONTACT_DETAILS_DOC)) {
                                            var list = (
                                                <div className="img-fluid  document-box" key={idx}>
                                                    <div style={{ display: "flex", alignItems: "center" }}>
                                                        <img src="../../images/document.png" alt="" className={"financial-download-image"}
                                                             onClick={() => { onDownloadFP2506Doc(item.fullFilePath) }} />
                                                        <span style={{ paddingLeft: "10px", paddingRight: "10px"}}>
                                                                            <a><i style={{ color: "red", cursor: "pointer" }} className={`${(CommonUtil.ORGANIZATION_TYPE == CommonUtil.ORG_TYPE_RENTER)?(item.saasOrgId != CommonUtil.getSaasOrgId() ?"" : "remove-btn fa fa-remove"):(item.saasOrgId != CommonUtil.getSaasOrgId() ?"" : "remove-btn fa fa-remove")}`}
                                                                                  onClick={() => { onRemoveFP2506Doc(item.attachementId) }} ></i></a>
                                                                        </span>
                                                        <span style={{ display: "none" }}><a><i className="fa fa-download" onClick={() => { onDownloadFP2506Doc(item.fullFilePath) }}></i></a></span>
                                                    </div>
                                                    <label style={{maxWidth: "100px", wordWrap: "break-word",textAlign:"center"}} className={"financial-download-label"} onClick={() => { onDownloadFP2506Doc(item.fullFilePath) }}>{item.documentName}</label>
                                                </div>
                                            )
                                            return list
                                        }
                                    })}

                                </div>
                            </div>
                        </div>
                    </div>
                        </div>
                        <div className="modal-footer clsModalPermissionsFooter">
                        <button type="button"  className="btn-sm btn-primary waves-effect" style={{width:"100px"}}
                                   onClick={onClickSave}     data-dismiss="modal">{CommonUtil.getMessageText(Constants.SAVE,"Save")}
                            </button>
                            </div>
    </div>     
   </section>
                </div>
               

            

            <section style={{ display: "none" }}>
                <div className="row">
                    <div className="col-sm-12">
                        <h6>Admin Control Panel</h6>
                    </div>
                    <div className="col-sm-12">
                        <div className="row">
                            <div className="col-sm-6">
                                <table className=" w-100">
                                    <tbody>
                                        <tr>
                                            <td className="p-2">Current Service Fee</td>
                                            <td className="p-2">:</td>
                                            <td className="p-2"><h6>{serviceFee}%</h6></td>
                                        </tr>
                                        <tr>
                                            <td className="p-2">Current Owner Equipment cost per day</td>
                                            <td className="p-2">:</td>
                                            <td className="p-2"><h6>$ {owner}</h6></td>
                                        </tr>
                                        <tr>
                                            <td className="p-2">First Payment accepted received</td>
                                            <td className="p-2">:</td>
                                            <td className="p-2"><h6>-</h6></td>
                                        </tr>
                                        <tr>
                                            <td className="p-2">Owner wants first payment accepted</td>
                                            <td className="p-2">:</td>
                                            <td className="p-2"><h6>-</h6></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="col-sm-6 text-right">

                                <div className="form-group row">
                                    <label className="col-sm-12 col-form-label m-0 text-left">Ignore the first payment
                                        accepted Rule</label>
                                    <div className="col-sm-12">
                                        <select className="custom-select" value={'Disabled'} onChange={() => { }} >
                                            <option>Disabled</option>
                                            <option value="1">One</option>
                                            <option value="2">Two</option>
                                            <option value="3">Three</option>
                                        </select>
                                    </div>
                                </div>
                                <button type="button" className="btn btn-warning waves-effect waves-light">Refresh
                                    Informations
                                </button>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            
           
        </>
    );
}

export default ReservationDetails;
